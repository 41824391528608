import { filter, pipe } from 'rxjs';
import { EPages } from '../../__common__/constants';
import { selectPage } from '../../__common__/selectors';
import { selectIsAllFilled, selectIsNotSupportedZyfiSourceToken, selectIsValidInput } from '../selectors';
export const filterValidState = (state$) => {
    return pipe(filter(() => {
        const { value } = state$;
        return selectIsAllFilled(value);
    }), filter(() => {
        const { value } = state$;
        return selectPage(value) === EPages.init;
    }), filter(() => {
        // fields validation
        const { value } = state$;
        return selectIsValidInput(value);
    }), filter(() => {
        const { value } = state$;
        return !selectIsNotSupportedZyfiSourceToken(value);
    }));
};
