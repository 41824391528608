import { OButton, OIcon } from '@ondefy/ondefy-ui';
import React from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { selectBestSwapRate } from '../../selectors';
import { usePaperSwapRoutes } from './__hooks__/use-paper-swap-routes';
import { SSwapRoutesLink } from './__styled__/s-swap-routes-link';
export const SwapRoutesLink = () => {
    var _a;
    const paper = usePaperSwapRoutes();
    const bestRate = useAppSelector(selectBestSwapRate);
    if (!((_a = bestRate === null || bestRate === void 0 ? void 0 : bestRate.meta) === null || _a === void 0 ? void 0 : _a.pathVizImage)) {
        return null;
    }
    return (React.createElement(SSwapRoutesLink, null,
        React.createElement(OButton, { py: "0", iconRight: React.createElement(OIcon, { name: "info" }), variant: "primary-unstyled", onClick: () => {
                paper.setIsOpened(true);
            } }, "Routes")));
};
