import { useSearchParams } from 'react-router-dom';
import { ENV } from '../../../../env';
import { useGetTokenContracts } from '../../../__common__/hooks/use-get-token-contracts';
import { useUserWallet } from '../../../wallet/hooks/use-user-wallet';
import { SwapRoutesLink } from '../../papers/paper-swap-routes/swap-routes-link';
import { selectSourceTokenIdSwapIsEthereum, selectSourceTokenSwap, selectTargetTokenSwap } from '../../selectors';
import { ETokenPopupVariant } from '../../../__common__/papers/paper-token-select/constants';
import { swapTokens } from '../../slice';
import { ZyfiFeeToken } from '../__components__/zyfi-fee-token';
import { BestRates } from './__components__/6-best-rates-carousel';
import { SAlignedSpaced } from '../../../__common__/styled/s-aligned-spaced';
import { Header } from '../../../__common__/components/header';
import { OMedia, SBox } from '@ondefy/ondefy-ui';
import { ArrowDown } from '../../../__common__/components/arrow-down';
import { Footer } from './__components__/7-footer';
import { SourceTokenAmountInput } from './__components__/1-token-source-amount/source-token-amount-input';
import { TargetTokenAmountInput } from './__components__/4-token-target-amount/target-token-amount-input';
import { SourceTokenBalance } from './__components__/3-source-token-balance/source-token-balance';
import { SourceTokenDetails } from './__components__/2-source-token-details/source-token-details';
import { TargetTokenDetails } from './__components__/5-target-token-details/target-token-details';
import { TokenDropdown } from '../../../__common__/components/token-select-dropdown/token-dropdown';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import React from 'react';
import { SScreenInner } from '../../../__common__/styled/s-screen-inner';
import { SAmountBlock } from '../../../__common__/styled/s-amount-block';
export const ScreenInitSwap = () => {
    const dispatch = useAppDispatch();
    // token to buy
    const targetToken = useAppSelector(selectTargetTokenSwap);
    const sourceToken = useAppSelector(selectSourceTokenSwap);
    const [urlSearchParams] = useSearchParams();
    const tokenSelectedFromUrl = urlSearchParams.get('tokenId');
    const isEthereumSource = useAppSelector(selectSourceTokenIdSwapIsEthereum);
    useGetTokenContracts();
    useUserWallet();
    // TODO: it doesn't work as expected and remove everything even if you just navigate between screens
    // TODO: reset after reload
    // useResetOnPageRefresh()
    return (React.createElement(React.Fragment, null,
        React.createElement(SScreenInner, null,
            React.createElement(Header, null),
            React.createElement(SBox, null,
                React.createElement(SAmountBlock, null,
                    React.createElement(SourceTokenAmountInput, null),
                    React.createElement(TokenDropdown, { variant: ETokenPopupVariant.source, selectedToken: sourceToken })),
                React.createElement(SourceTokenDetails, null),
                React.createElement(SourceTokenBalance, null)),
            React.createElement(ArrowDown, { onClick: () => {
                    dispatch(swapTokens());
                } }),
            React.createElement(SBox, { mb: "48px" },
                React.createElement(SAlignedSpaced, null,
                    React.createElement(TargetTokenAmountInput, null),
                    React.createElement(TokenDropdown, { selectedToken: targetToken, tokenSelectedFromUrl: tokenSelectedFromUrl, variant: ETokenPopupVariant.target })),
                React.createElement(OMedia, { justifyContent: "space-between", width: "100%" },
                    React.createElement(TargetTokenDetails, { showSlippage: true }),
                    ENV.isZyfi() && isEthereumSource ? React.createElement(ZyfiFeeToken, null) : null)),
            ENV.isOndefy() && React.createElement(BestRates, null),
            ENV.isZyfi() && React.createElement(SwapRoutesLink, null)),
        React.createElement(Footer, null)));
};
