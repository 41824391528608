const suffixes = [
    { threshold: 3, suffix: 'K' }, // Thousands
    { threshold: 6, suffix: 'M' }, // Millions
    { threshold: 9, suffix: 'B' }, // Billions
    { threshold: 12, suffix: 'T' } // Trillions
    // { threshold: 15, suffix: 'Q' }, // Quadrillions
    // { threshold: 18, suffix: 'Qi' }, // Quintillions
    // { threshold: 21, suffix: 'S' }, // Sextillions
    // { threshold: 24, suffix: 'Sp' }, // Septillions
    // { threshold: 27, suffix: 'O' }, // Octillions
    // { threshold: 30, suffix: 'N' }, // Nonillions
    // { threshold: 33, suffix: 'D' } // Decillions
    //   { power: 36, suffix: 'Ud' }, // Undecillions
    //   { power: 39, suffix: 'Dd' }, // Duodecillions
    //   { power: 42, suffix: 'Td' }, // Tredecillions
    //   { power: 45, suffix: 'Qd' }, // Quattuordecillions
    //   { power: 48, suffix: 'Qid' }, // Quindecillions
    //   { power: 51, suffix: 'Sd' }, // Sexdecillions
    //   { power: 54, suffix: 'Spd' }, // Septendecillions
    //   { power: 57, suffix: 'Od' }, // Octodecillions
    //   { power: 60, suffix: 'Nd' }, // Novemdecillions
    //   { power: 63, suffix: 'V' } // Vigintillions
    // // If you want to go crazy ^(o_x)^ you can continue up to Infinity just ask ChatGPT to continue the sequence
];
export function formatNumberLarge(str, options) {
    if (str === null || str === undefined) {
        return [str, ''];
    }
    const integerLength = String(str).replace(/(\..*)/, '').length;
    // Handle large numbers with exponent notation
    if (integerLength >= ((options === null || options === void 0 ? void 0 : options.expStart) ? options.expStart + 1 : 37)) {
        if (integerLength > 61) {
            return ['>1', 'e62'];
        }
        const exponent = 'e' + (integerLength - 1).toString();
        const maxJavascriptSafeDigits = 15;
        let decimalValue = (parseFloat(str) / Math.pow(10, integerLength - 1)).toFixed(maxJavascriptSafeDigits);
        decimalValue = decimalValue.replace(/\.?0+$/, ''); // Remove trailing zeros
        return [decimalValue, exponent];
    }
    const lengthBeforeDecimal = str.includes('.') ? str.indexOf('.') : str.length;
    for (let i = suffixes.length - 1; i >= 0; i--) {
        if (lengthBeforeDecimal > suffixes[i].threshold) {
            const power = Math.pow(10, suffixes[i].threshold);
            let formattedNumber = (parseFloat(str) / power).toFixed(6);
            formattedNumber = formattedNumber.replace(/\.?0+$/, ''); // Remove trailing zeros
            return [formattedNumber, suffixes[i].suffix];
        }
    }
    return [
        parseFloat(str)
            .toFixed(6)
            .replace(/\.?0+$/, ''),
        ''
    ]; // No suffix for numbers less than 1000
}
