import { merge, map, filter } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EPages } from '../../__common__/constants';
import { ofValue } from '../../__common__/rxjs-utils/of-value';
import { selectPage } from '../../__common__/selectors';
import { selectDestinationAddress, selectIsAllFilled, selectIsValidInput, selectNetworkIdTargetCustom, selectTargetTokenCustom, selectTransactionData } from '../selectors';
import { getZyfiGeneralTx } from '../slice';
export const epicCustomTriggerGetGeneralTx = (action$, state$) => {
    return merge(
    // TODO: update on user connected/disconnected
    // TODO: update on user connected/disconnected
    // TODO: update on user connected/disconnected
    ofValue(state$, selectNetworkIdTargetCustom), ofValue(state$, selectTargetTokenCustom), ofValue(state$, selectDestinationAddress), ofValue(state$, selectTransactionData)).pipe(debounceTime(500), filter(() => {
        const { value } = state$;
        return selectIsAllFilled(value);
    }), filter(() => {
        const { value } = state$;
        return selectPage(value) === EPages.init;
    }), filter(() => {
        // fields validation
        const { value } = state$;
        return selectIsValidInput(value);
    }), 
    // TODO add take until in case of Insufficient balance in error rejected
    map(() => getZyfiGeneralTx()));
};
