/*
 * SELECTORS
 * */
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash/fp';
import { selectTokenContractsAssoc } from '../__common__/selectors';
import { findTokenByIdInAssoc } from '../__common__/transducers/find-token-by-id';
import { sliceNamespace } from './constants';
import { convertTransakFiatToOptions } from './transducers/convert-transak-fiat-to-options';
import { findCurrencyBySymbol } from './transducers/find-currency-by-symbol';
export const selectBuy = (state) => _.path(sliceNamespace, state);
export const selectRefuelAmount = createSelector(selectBuy, state => state.refuelAmount);
export const selectAllowWithWarning = createSelector(selectBuy, state => state.allowWithWarning);
export const selectTransactionStatus = createSelector(selectBuy, state => state.transactionStatus);
export const selectSelectedFiatSymbol = createSelector(selectBuy, state => state.selectedFiatSymbol);
export const selectFiatToTokenRate = createSelector(selectBuy, state => state.fiatToTokenRate);
export const selectSourceAmount = createSelector(selectBuy, state => state.sourceAmount);
export const selectPartnerOrderId = createSelector(selectBuy, state => state.partnerOrderId);
export const selectOrderPermitSigned = createSelector(selectBuy, state => state.orderPermitSigned);
export const selectTransakFiatCurrencies = createSelector(selectBuy, state => state.transakFiatCurrencies);
export const selectTransakCryptoCurrencies = createSelector(selectBuy, state => state.transakCryptoCurrencies);
export const makeTransakFiatOptionsSelector = () => createSelector(selectTransakFiatCurrencies, convertTransakFiatToOptions);
export const selectTransakOrderWalletAddress = createSelector(selectBuy, state => state.transakOrderWalletAddress);
export const selectTransakOrderNetworkId = createSelector(selectBuy, state => state.transakOrderNetworkId);
export const selectIsTransakSupported = createSelector(selectBuy, state => state.isTransakSupported);
export const selectNetworkIdTargetFiat = createSelector(selectBuy, state => state.networkIdTargetFiat);
export const selectTargetTokenIdFiat = createSelector(selectBuy, state => state.targetTokenIdFiat);
export const selectTargetTokenFiat = createSelector([selectTokenContractsAssoc, selectNetworkIdTargetFiat, selectTargetTokenIdFiat], findTokenByIdInAssoc);
export const makeSelectedFiatSelector = () => createSelector([selectSelectedFiatSymbol, selectTransakFiatCurrencies], findCurrencyBySymbol);
export const makeSelectAllParamsSelected = () => createSelector(selectTargetTokenFiat, makeSelectedFiatSelector(), selectSourceAmount, (selectedToken, selectedFiatCurrency, selectedFiatAmount) => !!selectedToken && !!selectedFiatCurrency && !!selectedFiatAmount);
