import React, { useState } from 'react';
import { useSize } from 'react-use';
import { PaperLayoutMeasureContext, PaperStackContext } from './context';
export const OPaperProvider = ({ children }) => {
    const [stack, setStack] = useState([]);
    const [papers, setPapers] = useState({});
    const [sized, { width, height }] = useSize(({ width, height }) => {
        return children;
    });
    return (React.createElement(PaperStackContext.Provider, { value: { stack, setStack, papers, setPapers } },
        React.createElement(PaperLayoutMeasureContext.Provider, { value: { width, height } }, sized)));
};
