import { themeLight, themeDark } from '@ondefy/ondefy-ui';
import { useAppSelector } from '../../../redux/hooks';
import { selectIframeColorPrimary } from '../selectors';
function isColorDark(hexColor) {
    // Convert hex color to RGB
    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);
    // Calculate relative luminance
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    // Determine best text color
    return luminance >= 128;
}
export const useIframeUrlColors = () => {
    const iframeColorPrimary = useAppSelector(selectIframeColorPrimary);
    // const theme = useTheme()
    const darkColor = iframeColorPrimary ? isColorDark(iframeColorPrimary) : undefined;
    return {
        colorPrimary: iframeColorPrimary ? '#' + iframeColorPrimary : undefined,
        colorSecondary: iframeColorPrimary ? (darkColor ? themeLight.colors.typo : themeDark.colors.typo) : undefined
        // colorInvert: iframeColorPrimary
    };
};
