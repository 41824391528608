var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ENV } from '../../env';
function zyfiNonce(target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args) {
        if (ENV.isOndefy()) {
            return;
        }
        return originalMethod.apply(this, args);
    };
}
// interface IApplyNonce {
//   (): void
//   (nonce: number): void
// }
// interface INonce {
//   nonce?: number
//   applyNonce: () => number | undefined
//   setNonce: ISetNonce
//   flush: () => void
// }
export class Nonce {
    static setNonce(nonce) {
        Nonce.nonce = nonce;
    }
    static applyNonce() {
        const nonce = Nonce.nonce;
        Nonce.flush();
        return nonce !== undefined ? nonce + 1 : undefined;
    }
    static flush() {
        Nonce.nonce = undefined;
    }
}
__decorate([
    zyfiNonce
], Nonce, "setNonce", null);
__decorate([
    zyfiNonce
], Nonce, "applyNonce", null);
__decorate([
    zyfiNonce
], Nonce, "flush", null);
