import axios from 'axios';
import { of, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiObservable } from '../../api-observable/api-observable';
import { ONDEFY_WALLET } from '../../constants';
import qs from 'qs';
import { ESwappers, SWAPPER_NAME_BY_IDX } from '../../constants/contracts';
import { getGasFeeForLifi } from './transducers/get-gas-fee-for-lifi';
// https://docs.li.fi/li.fi-api/li.fi-api/requesting-a-quote/token-transfer
const apirxLiFi = ApiObservable.from(axios.create({
    baseURL: 'https://li.quest/v1',
    headers: { 'x-lifi-api-key': process.env.LIFI_API_KEY }
}));
function apiRequestUrl(methodName, queryParams) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `/${methodName}?${qs.stringify(queryParams)}`;
}
export function getLiFiSwapDataRx({ networkId, usingOndefyRouter, fromAddress, destReceiver, srcToken, destToken, amount, slippage }) {
    const swapParams = {
        fromChain: networkId,
        toChain: networkId,
        fromToken: srcToken,
        toToken: destToken,
        fromAmount: amount,
        fromAddress: fromAddress || ONDEFY_WALLET,
        toAddress: destReceiver || ONDEFY_WALLET,
        slippage: slippage / 100,
        // TODO: ask Clement about these fields
        // TODO: ask Clement about these fields
        // TODO: ask Clement about these fields
        integrator: 'ondefy',
        referrer: ONDEFY_WALLET
    };
    const req = apiRequestUrl('quote', swapParams);
    return apirxLiFi.get(req).pipe(switchMap(resData => {
        var _a, _b;
        if (!(((_a = resData === null || resData === void 0 ? void 0 : resData.estimate) === null || _a === void 0 ? void 0 : _a.toAmount) && ((_b = resData === null || resData === void 0 ? void 0 : resData.transactionRequest) === null || _b === void 0 ? void 0 : _b.data) && resData.transactionRequest.to)) {
            return throwError(() => new Error('invalid response from LiFi'));
        }
        const { gasEstimate, gasLimit } = getGasFeeForLifi(networkId, resData, usingOndefyRouter);
        const result = {
            swapperIdx: ESwappers.LIFI_SWAPPER_IDX,
            swapperName: SWAPPER_NAME_BY_IDX[ESwappers.LIFI_SWAPPER_IDX],
            // extra fields
            networkId,
            fromTokenAddress: srcToken,
            fromAmount: amount,
            toTokenAddress: destToken,
            // TODO: check
            toAmount: resData.estimate.toAmount,
            gasEstimate,
            gasLimit
        };
        if (!fromAddress) {
            return of(result);
        }
        /* note : only relevant if we want to broadcast transactions to our router contract from here
        const callData = getCallData({
          srcToken,
          destToken,
          data: resData.transactionRequest.data,
          amount,
          swapperIndex: ESwappers.LIFI_SWAPPER_IDX,
          network: NETWORKS_DATA[networkId]
        })
  
        // else return all data needed for swap
        if (callData) {
          return of({
            ...result,
            ...callData
          })
        }
        */
        return of(Object.assign(Object.assign({}, result), { callData: resData.transactionRequest.data, contractAddress: resData.transactionRequest.to, spenderAddress: resData.transactionRequest.to }));
    }), catchError(err => {
        console.error('getLiFiSwapData', err);
        return of(null);
    }));
}
