import { SFlexbox, styled } from '@ondefy/ondefy-ui';
export const SBreadcrumb = styled(SFlexbox) `
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  &.breadcrumb__clickable {
    cursor: pointer;

    .breadcrumb__label {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
