import { ofType } from 'redux-observable';
import { filter, map, of, switchMap, takeUntil, zip } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ENV } from '../../../../env';
import { waitForState } from '../../../__common__/rxjs-utils/wait-for-state';
import { selectTokenContractsAssoc } from '../../../__common__/selectors';
import { setAppMode, setPage } from '../../../__common__/slice';
import { isEtherToken } from '../../../__common__/transducers/is-ether-token';
import { Numbo } from '../../../__common__/numbo/transducers';
import { selectUserAddress, selectUserWallet } from '../../../wallet/selectors';
import { generalTx } from '../../api/_zyfi_/general-tx';
import { getTokenApproval } from '../../api/_zyfi_/get-token-approval';
import { showSubtractInfo } from '../../epics.taps/show-subtract-info';
import { selectAllowance, selectBestSwapRate, selectCanAllowanceBeSkipped, selectFeeTokenIdSwap, selectFeeTokenSwap, selectIfAllowanceSufficient, selectPercentage, selectSourceTokenAmount, selectSourceTokenIdSwapIsEthereum, selectSourceTokenSwap } from '../../selectors';
import { getZyfiGeneralTx, getZyfiGeneralTxFullfilled, getZyfiGeneralTxRejected, setSourceTokenAmount, setSourceTokenIdSwap, setTargetTokenIdSwap } from '../../slice';
import { calculateNewSourceAmount } from '../../transducers/calculate-new-source-amount';
import { doZyfiGasFeeFusion } from '../../transducers/do-zyfi-gas-fee-fusion';
import { formatTokenAmountWithDecimals } from '../../transducers/format-token-amount-with-decimals';
export const epicGetGeneralTx = (action$, state$) => {
    return action$.pipe(ofType(getZyfiGeneralTx.toString()), waitForState(state$, selectTokenContractsAssoc), waitForState(state$, selectBestSwapRate), waitForState(state$, selectAllowance), waitForState(state$, selectUserWallet), waitForState(state$, (value) => {
        if (!selectSourceTokenIdSwapIsEthereum(value)) {
            return true;
        }
        return selectFeeTokenSwap(value);
    }), filter(() => {
        return !!selectBestSwapRate(state$.value);
    }), filter(() => {
        const { value } = state$;
        const feeTokenId = selectFeeTokenIdSwap(value);
        const sourceTokenIsEther = selectSourceTokenIdSwapIsEthereum(value);
        const isRawTransaction = ENV.isZyfi() && isEtherToken(feeTokenId) && sourceTokenIsEther;
        return !isRawTransaction;
    }), switchMap(() => {
        const { value } = state$;
        const sourceToken = selectSourceTokenSwap(value);
        const userAddress = selectUserAddress(value);
        const bestRate = selectBestSwapRate(value);
        const isSourceEthereum = selectSourceTokenIdSwapIsEthereum(value);
        const feeToken = selectFeeTokenSwap(value);
        const sourceTokenAmount = selectSourceTokenAmount(value);
        const percentage = selectPercentage(value);
        const canAllowanceBeSkipped = selectCanAllowanceBeSkipped(value);
        const allowenceIsSufficient = selectIfAllowanceSufficient(value);
        const generalTxPayload = {
            feeTokenAddress: isSourceEthereum ? feeToken.address : sourceToken.address,
            gasLimit: bestRate.gasLimit,
            txData: {
                from: userAddress,
                to: bestRate.contractAddress,
                data: bestRate.callData,
                value: isSourceEthereum ? formatTokenAmountWithDecimals(sourceTokenAmount, sourceToken) : undefined
            }
        };
        const doRequest = () => {
            if (canAllowanceBeSkipped || allowenceIsSufficient) {
                return generalTx(generalTxPayload);
            }
            const { value } = state$;
            const userAddress = selectUserAddress(value);
            const bestRate = selectBestSwapRate(value);
            const sourceTokenAmount = selectSourceTokenAmount(value);
            const sourceToken = selectSourceTokenSwap(value);
            const decimalValue = Numbo.parseUnits(sourceTokenAmount, sourceToken.decimals).toString();
            return zip(generalTx(generalTxPayload), getTokenApproval({
                from: userAddress,
                spender: bestRate.spenderAddress,
                tokenAddress: sourceToken.address,
                allowanceAmount: decimalValue
            })).pipe(map(doZyfiGasFeeFusion));
        };
        return doRequest().pipe(takeUntil(action$.pipe(ofType(setPage.toString(), setAppMode.toString(), setSourceTokenIdSwap.toString(), setTargetTokenIdSwap.toString()))), switchMap(v => {
            if (!percentage || isSourceEthereum) {
                // 1. We skip Ethereum source
                // 2. We skip if user didn't click percentage buttons
                return of(getZyfiGeneralTxFullfilled(v));
            }
            const { shouldSubtract, newSourceTokenAmount } = calculateNewSourceAmount(value, v);
            if (shouldSubtract && newSourceTokenAmount !== sourceTokenAmount) {
                return of(setSourceTokenAmount(newSourceTokenAmount)).pipe(tap(() => {
                    // console.log('Gas fee subtracted from source')
                    showSubtractInfo();
                }));
            }
            return of(getZyfiGeneralTxFullfilled(v));
        }), catchError(e => {
            return of(getZyfiGeneralTxRejected(e));
        }));
    }));
};
