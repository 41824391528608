/*
 * STATE DEFAULT
 * */
import { ReduxEntity } from '../../redux/redux-entity';
import { DEFAULT_CHAIN_ID } from '../__common__/constants/networks';
import { DEFAULT_CURRENCY } from './constants';
export const initialState = {
    isIframe: false,
    selectedFiatSymbol: DEFAULT_CURRENCY,
    partnerOrderId: '',
    transakOrderIsProcessing: false,
    transakOrderWalletAddress: '',
    // TODO: why 0?
    // @ts-ignore
    transakOrderNetworkId: 0,
    refuelAmount: '',
    allowWithWarning: false,
    sourceAmount: '',
    targetTokenIdFiat: '',
    networkIdTargetFiat: DEFAULT_CHAIN_ID,
    // backend entities
    transakFiatCurrencies: ReduxEntity.init(),
    transakCryptoCurrencies: ReduxEntity.init(),
    fiatToTokenRate: ReduxEntity.init(),
    earlyOrderSaved: ReduxEntity.init(),
    orderPermitSigned: ReduxEntity.init(),
    transactionStatus: ReduxEntity.init()
};
