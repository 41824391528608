import { toast } from '@ondefy/ondefy-ui';
import { ofType } from 'redux-observable';
import { from, of, pipe, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NETWORKS_DATA } from '../../__common__/constants/networks';
import { Analytics } from '../../analytics';
import { selectUserAddress } from '../../wallet/selectors';
import { selectFiatToTokenRate, selectPartnerOrderId, selectTransakOrderNetworkId } from '../selectors';
import { signPermit, signPermitRejected, signPermitFulfilled, postOrder } from '../slice';
import { getPermitToSign as fetchPermitToSign } from '../api/get-permit-to-sign';
import { getParseUnits } from '../transducers/get-parse-units';
const errorHandler = () => pipe(tap({
    error: () => {
        toast.next({
            variant: 'error',
            message: 'Failed to sign permit'
        });
    }
}), catchError(e => {
    console.log(e, '<<<<==== Signing');
    return of(signPermitRejected({ message: 'Signing failed' }));
}));
export const epicSignPermit = (action$, state$) => {
    return action$.pipe(ofType(signPermit.toString()), switchMap(({ payload: signMessage }) => {
        var _a, _b, _c, _d;
        const { value } = state$;
        const userAddress = selectUserAddress(value);
        const partnerOrderId = selectPartnerOrderId(value);
        const transakOrderNetworkId = selectTransakOrderNetworkId(value);
        const fiatToTokenRate = selectFiatToTokenRate(value);
        const intermediateToken = (_a = NETWORKS_DATA[transakOrderNetworkId]) === null || _a === void 0 ? void 0 : _a.ftatInputToken;
        const routerAddress = (_b = NETWORKS_DATA[transakOrderNetworkId]) === null || _b === void 0 ? void 0 : _b.routerAddress;
        if (!userAddress ||
            !partnerOrderId ||
            !transakOrderNetworkId ||
            !((_c = fiatToTokenRate === null || fiatToTokenRate === void 0 ? void 0 : fiatToTokenRate.value) === null || _c === void 0 ? void 0 : _c.intermediateBuyAmount) ||
            !intermediateToken ||
            !routerAddress) {
            return of(signPermitRejected({
                message: 'Not all required fields are ready'
            }));
        }
        const units = getParseUnits((_d = fiatToTokenRate === null || fiatToTokenRate === void 0 ? void 0 : fiatToTokenRate.value) === null || _d === void 0 ? void 0 : _d.intermediateBuyAmount, intermediateToken.decimals);
        return from(fetchPermitToSign(userAddress, routerAddress, units, transakOrderNetworkId, intermediateToken.contractAddress)).pipe(switchMap(v => {
            const { domain, types, msg, deadline, name, nonce, primaryType } = v;
            const params = {
                domain,
                types,
                msg,
                networkId: transakOrderNetworkId,
                primaryType
            };
            // web3 feature from IWeb3 Provider
            return from(signMessage(params)).pipe(tap(() => {
                Analytics.permitSigned();
            }), switchMap(signature => {
                const permitParams = {
                    permitValue: units,
                    permitTokenName: name,
                    permitTokenVersion: domain.version,
                    permitTokenNonce: nonce.toString(),
                    permitDeadline: deadline,
                    permitSignature: signature
                };
                return of(signPermitFulfilled(permitParams), postOrder(permitParams));
            }), errorHandler());
        }), errorHandler());
    }));
};
