import { EPages } from '../__common__/constants';
import { selectPage } from '../__common__/selectors';
import { selectUserAddress } from '../wallet/selectors';
import { encodeApproveMaxERC20 } from './api/encode-max-rpc-eth-20';
import { selectBestSwapRate, selectSourceTokenSwap } from './selectors';
export const getRawTx = (state$) => {
    const { value } = state$;
    const sourceToken = selectSourceTokenSwap(value);
    const bestRate = selectBestSwapRate(value);
    const userAddress = selectUserAddress(value);
    const isUnlockPage = selectPage(value) === EPages.swapUnlock;
    if (isUnlockPage) {
        return {
            from: userAddress,
            to: sourceToken.address,
            data: encodeApproveMaxERC20(bestRate.spenderAddress),
            value: sourceToken.isNative ? bestRate.fromAmount : '0'
        };
    }
    // TODO: check with Giovanni that it's a correct fields for the 3-rd screen
    return {
        from: userAddress,
        to: bestRate.contractAddress,
        data: bestRate.callData,
        value: sourceToken.isNative ? bestRate.fromAmount : '0'
    };
};
