// in case of multiple next.js pages we should add this wrapper:
// https://github.com/kirill-konshin/next-redux-wrapper
import { configureStore } from '@reduxjs/toolkit';
// @ts-ignore
import { combineReducers } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import localForage from 'localforage';
import { ENV } from '../env';
import { addEpics, rootEpic } from './async-core/epic-manager';
import { reducerManager } from './async-core/reducer-manager';
import { appReducers } from './app-reducers';
import { appEpics } from './app-epics';
// keep in localStorage, by default it uses indexedDB
// https://localforage.github.io/localForage/#localforage
localForage.config({
    driver: localForage.LOCALSTORAGE,
    name: ENV.app
});
export function makeStore() {
    let enhancers;
    if (process.env.NODE_ENV === 'development') {
        const { reactotron
        // eslint-disable-next-line @typescript-eslint/no-var-requires
         } = require('./reactotron-config');
        if (reactotron) {
            enhancers = [reactotron.createEnhancer()];
        }
    }
    const epicMiddleware = createEpicMiddleware();
    const store = configureStore({
        reducer: combineReducers(reducerManager.getReducers()),
        // reducer: combineReducers(appReducers),
        enhancers,
        middleware: getDefaultMiddleware => getDefaultMiddleware({
            // // https://stackoverflow.com/a/62610422/2614096
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
            // serializableCheck: false
        })
            // .concat(web3Reducer.middleware),
            // @ts-ignore
            .concat(epicMiddleware)
    });
    // // Replace the store's reducer whenever a new reducer is registered.
    reducerManager.setChangeListener((reducers) => {
        // @ts-ignore
        store.replaceReducer(combineReducers(reducers));
        // For lazy reducers
        // https://github.com/rt2zz/redux-persist/issues/490#issuecomment-341330154
        // https://gist.github.com/mbret/511edf9627b743ad8a52559be61e3d45
        // alternative for the future https://github.com/redux-offline/redux-offline
        // https://github.com/rt2zz/redux-persist/issues/717
        // if (persistor) {
        //   persistor.persist()
        // }
    });
    // set initial reducers
    reducerManager.registerAll(appReducers);
    // TODO: check how it works
    if (module.hot) {
        const reloadReducers = () => {
            // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
            const nextReducer = require('./app-reducers');
            store.replaceReducer(nextReducer);
            // persistor.persist();
        };
        module.hot.accept('./app-reducers', reloadReducers);
    }
    epicMiddleware.run(rootEpic);
    // we need skip adding epics asynchronously until the store is ready
    setTimeout(() => {
        addEpics('root', appEpics);
    });
    return store;
}
export const appReduxStore = makeStore();
if (process.env.NODE_ENV === 'development') {
    // @ts-ignore
    global.store = appReduxStore;
}
export const persistor = persistStore(appReduxStore);
