import axios from 'axios';
import _ from 'lodash/fp';
import { of, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiObservable } from '../../api-observable/api-observable';
import { ONDEFY_WALLET } from '../../constants';
import { ESwappers, SWAPPER_NAME_BY_IDX } from '../../constants/contracts';
import { ENetworkIdByName } from '../../constants/networks';
import { getGasFeeForKyberswap } from './transducers/get-gas-fee-for-kyberswap';
// https://docs.kyberswap.com/kyberswap-solutions/kyberswap-aggregator/aggregator-api-specification/evm-swaps
const apirxKyberSwap = ApiObservable.from(axios.create({
    baseURL: 'https://aggregator-api.kyberswap.com',
    headers: { 'x-client-id': 'ondefy' }
}));
const networkNames = {
    [ENetworkIdByName['arbitrum-one']]: 'arbitrum',
    [ENetworkIdByName.avalanche]: 'avalanche',
    [ENetworkIdByName['binance-smart-chain']]: 'bsc',
    [ENetworkIdByName.ethereum]: 'ethereum',
    [ENetworkIdByName['optimistic-ethereum']]: 'optimism',
    [ENetworkIdByName['polygon-pos']]: 'polygon',
    [ENetworkIdByName.fantom]: 'fantom',
    [ENetworkIdByName.zksync]: 'zksync',
    [ENetworkIdByName.base]: 'base'
};
function apiRequestUrlRoutes(networkId) {
    return `${networkNames[networkId]}/api/v1/routes`;
}
function apiRequestUrlRouteBuild(networkId) {
    return `${networkNames[networkId]}/api/v1/route/build`;
}
export function getKyberSwapDataRx({ networkId, usingOndefyRouter, userAddress, srcToken, destToken, amount, slippage }) {
    // not supported network check
    if (!_.has(networkId, networkNames)) {
        return of(null);
    }
    const routeParams = {
        tokenIn: srcToken,
        tokenOut: destToken,
        amountIn: amount,
        isInBps: true
    };
    if (networkId === ENetworkIdByName.zksync) {
        routeParams.excludedSources = 'maverick-v1';
    }
    const url = apiRequestUrlRoutes(networkId);
    return apirxKyberSwap.get(url, routeParams).pipe(switchMap(routeResData => {
        var _a, _b;
        if (!((_a = routeResData === null || routeResData === void 0 ? void 0 : routeResData.data) === null || _a === void 0 ? void 0 : _a.routeSummary)) {
            return throwError(() => new Error('invalid response from KyberSwap'));
        }
        // step 2: get swap data
        const swapParams = {
            routeSummary: (_b = routeResData === null || routeResData === void 0 ? void 0 : routeResData.data) === null || _b === void 0 ? void 0 : _b.routeSummary,
            slippageTolerance: slippage,
            recipient: userAddress || ONDEFY_WALLET,
            sender: userAddress || ONDEFY_WALLET,
            source: 'ondefy'
        };
        const urlBuild = apiRequestUrlRouteBuild(networkId);
        return apirxKyberSwap.post(urlBuild, swapParams).pipe(switchMap(swapResData => {
            const resData = swapResData === null || swapResData === void 0 ? void 0 : swapResData.data;
            if (!((resData === null || resData === void 0 ? void 0 : resData.amountOut) || (resData === null || resData === void 0 ? void 0 : resData.routerAddress) || (resData === null || resData === void 0 ? void 0 : resData.data))) {
                return throwError(() => new Error('invalid response from Kyberswap'));
            }
            const { gasEstimate, gasLimit } = getGasFeeForKyberswap(networkId, swapResData, usingOndefyRouter);
            const result = {
                swapperIdx: ESwappers.KYBERSWAP_SWAPPER_IDX,
                swapperName: SWAPPER_NAME_BY_IDX[ESwappers.KYBERSWAP_SWAPPER_IDX],
                // extra fields
                networkId,
                fromTokenAddress: srcToken,
                fromAmount: amount,
                toTokenAddress: destToken,
                toAmount: resData.amountOut,
                gasEstimate,
                gasLimit
            };
            // for display only, not used in an actual swap
            if (!userAddress) {
                return of(result);
            }
            /* note : only relevant if we want to broadcast transactions to our router contract from here
            const callData = getCallData({
              srcToken,
              destToken,
              data: resData.data,
              amount,
              swapperIndex: ESwappers.KYBERSWAP_SWAPPER_IDX,
              network: NETWORKS_DATA[networkId]
            })
  
            // else return all data needed for swap
            if (callData) {
              return of({
                ...result,
                ...callData
              })
            }
            */
            return of(Object.assign(Object.assign({}, result), { callData: resData.data, contractAddress: resData.routerAddress, spenderAddress: resData.routerAddress }));
        }), catchError(e => {
            console.error('getKyberSwap Data', e);
            return of(null);
        }));
    }), catchError(e => {
        console.error('getKyberSwap Data', e);
        return of(null);
    }));
}
