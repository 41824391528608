import _ from 'lodash/fp';
import { EEntityStatus, ReduxEntity } from '../../redux/redux-entity';
import { Numbo } from '../__common__/numbo/transducers';
import { initialState } from './state';
export const setSelectedFiatSymbol = (state, { payload }) => {
    state.selectedFiatSymbol = payload;
};
export const setPartnerOrderId = (state, { payload }) => {
    state.partnerOrderId = payload;
};
export const setTransakOrderIsProcessing = (state, { payload }) => {
    state.transakOrderIsProcessing = payload;
};
export const setTransakOrderWalletAddress = (state, { payload }) => {
    state.transakOrderWalletAddress = payload;
};
export const setTransakOrderNetworkId = (state, { payload }) => {
    state.transakOrderNetworkId = payload;
};
export const setRefuelAmount = (state, { payload }) => {
    state.refuelAmount = payload;
};
export const flush = (state) => {
    return Object.assign(Object.assign({}, initialState), { 
        // keep only these fields
        networkIdTargetFiat: state.networkIdTargetFiat, transakFiatCurrencies: state.transakFiatCurrencies, transakCryptoCurrencies: state.transakCryptoCurrencies, isIframe: state.isIframe, targetTokenIdSwap: state.isIframe ? state.targetTokenIdFiat : initialState.targetTokenIdFiat });
};
export const reset = (state) => {
    return Object.assign(Object.assign({}, initialState), { 
        // keep only these fields
        // add these sideeffect to auth
        networkIdTargetFiat: state.networkIdTargetFiat, transakFiatCurrencies: state.transakFiatCurrencies, transakCryptoCurrencies: state.transakCryptoCurrencies, selectedFiatSymbol: state.selectedFiatSymbol, isIframe: state.isIframe, targetTokenIdFiat: state.isIframe ? state.targetTokenIdFiat : initialState.targetTokenIdFiat });
};
export const resetFiatToTokenRate = (state) => {
    state.fiatToTokenRate = initialState.fiatToTokenRate;
};
export const getTransactionStatus = (state) => {
    state.transactionStatus.status = EEntityStatus.loading;
};
export const getTransactionStatusPending = (state, { payload }) => {
    state.transactionStatus.status = EEntityStatus.loading;
    if (payload) {
        state.transactionStatus.value = payload;
    }
};
export const getTransactionStatusRejected = (state) => {
    state.transactionStatus.status = EEntityStatus.error;
};
export const getTransactionStatusFullfilled = (state, { payload }) => {
    state.transactionStatus.status = EEntityStatus.success;
    if (payload) {
        state.transactionStatus.value = payload;
    }
};
export const setAllowWithWarning = (state, { payload }) => {
    state.allowWithWarning = payload;
};
export const getFiatToTokenRate = (state) => {
    ReduxEntity.loading(state.fiatToTokenRate);
    state.isTransakSupported = undefined;
};
export const getFiatToTokenRateRejected = (state, action) => {
    ReduxEntity.error(state.fiatToTokenRate, action);
    state.isTransakSupported = undefined;
};
export const getFiatToTokenRateFulfilled = (state, action) => {
    ReduxEntity.success(state.fiatToTokenRate, action);
    state.isTransakSupported = _.path('isTransakSupported', action.payload);
};
export const getTransakCryptoCurrencies = (state) => {
    ReduxEntity.loading(state.transakCryptoCurrencies);
};
export const getTransakCryptoCurrenciesRejected = (state, action) => {
    ReduxEntity.error(state.transakCryptoCurrencies, action);
};
export const getTransakCryptoCurrenciesFulfilled = (state, action) => {
    ReduxEntity.success(state.transakCryptoCurrencies, action);
};
export const getTransakFiatCurrencies = (state) => {
    ReduxEntity.loading(state.transakFiatCurrencies);
};
export const getTransakFiatCurrenciesRejected = (state, action) => {
    ReduxEntity.error(state.transakFiatCurrencies, action);
};
export const getTransakFiatCurrenciesFulfilled = (state, action) => {
    ReduxEntity.success(state.transakFiatCurrencies, action);
};
export const postOrder = (state, { payload }) => {
    ReduxEntity.loading(state.earlyOrderSaved);
};
export const postOrderRejected = (state, action) => {
    ReduxEntity.error(state.earlyOrderSaved, action);
};
export const postOrderFulfilled = (state, action) => {
    ReduxEntity.success(state.earlyOrderSaved, action);
};
export const signPermit = (state, { payload }) => {
    ReduxEntity.loading(state.orderPermitSigned);
};
export const signPermitRejected = (state, action) => {
    ReduxEntity.error(state.orderPermitSigned, action);
};
export const signPermitFulfilled = (state, action) => {
    ReduxEntity.success(state.orderPermitSigned, action);
};
export const setSourceAmount = (state, { payload }) => {
    // TODO: move to transducer and add unit test
    // TODO: move to transducer and add unit test
    // TODO: move to transducer and add unit test
    state.sourceAmount = Numbo.sanitizeFloatValue(payload);
};
export const setNetworkIdTargetFiat = (state, { payload }) => {
    state.networkIdTargetFiat = payload;
};
export const setTargetTokenIdFiat = (state, { payload }) => {
    state.targetTokenIdFiat = payload;
};
export const setIframeMode = (state, { payload }) => {
    state.isIframe = payload;
};
