// import { useReset } from '@components/popups/popup-reset-confirm/__hooks__/use-reset';
import { ENV } from '../../../../env';
import { SLogoContainer } from './__styled__/s-logo-container';
import { useTheme, SBox } from '@ondefy/ondefy-ui';
import React from 'react';
// @ts-ignore
import LogoDark from '@ondefy/ondefy-ui/dist/assets/logo-ondefy-dark.svg';
// @ts-ignore
import LogoLight from '@ondefy/ondefy-ui/dist/assets/logo-ondefy-light.svg';
import LogoZkSync from './__assets__/zk.svg';
export const OLogo = ({ size, onClick }) => {
    // const { tryReset, isResetPossible } = useReset();
    const { theme } = useTheme();
    if (ENV.isZyfi()) {
        return (React.createElement(SBox, { transform: "scale(0.5) translateX(-5px)", p: "8px 0 0 0" },
            React.createElement(LogoZkSync, null)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SLogoContainer, { onClick: onClick, size: size }, theme.id === 'light' ? React.createElement(LogoDark, null) : React.createElement(LogoLight, null))));
};
