import { OMedia, styled, breakpoint } from '@ondefy/ondefy-ui';
export const SLinks = styled(OMedia) `
  flex-direction: column;
  width: 100%;
  align-items: center;
  row-gap: 4px;
  justify-content: center;
  @media ${breakpoint.sm} {
    width: 100%;
    row-gap: 8px;
    flex-direction: column;
  }
`;
