import { ENV } from '../../../env';
import { EEntityStatus } from '../../../redux/redux-entity';
import { Numbo } from '../../__common__/numbo/transducers';
import _ from 'lodash/fp';
/// TODO - add try catch
export const checkIfEnoughBalance = (userWallet, sourceToken, sourceTokenAmount, feeToken
// generalTx: IState['zyfiGeneralTx']
) => {
    const isEthereumSourceZyfi = ENV.isZyfi() && (sourceToken === null || sourceToken === void 0 ? void 0 : sourceToken.id) === 'ethereum';
    if (userWallet.status === EEntityStatus.idle ||
        !sourceToken ||
        sourceTokenAmount === '' ||
        (isEthereumSourceZyfi && !feeToken)
    // || isEthereumSourceZyfi && (!_.isEqual(feeToken, sourceToken) && !generalTx.value)
    ) {
        return undefined;
    }
    const tokenInUserWallet = _.path(['value', 'entities', sourceToken.cgKey, 'assetsAssoc', sourceToken.id], userWallet);
    if (!tokenInUserWallet) {
        return false;
    }
    const tokenValueInUserWallet = Numbo.floatToBigNumber(_.path('amount', tokenInUserWallet), _.path('decimals', tokenInUserWallet));
    const desiredSwapValue = Numbo.floatToBigNumber(sourceTokenAmount, _.path('decimals', tokenInUserWallet));
    let res;
    if (isEthereumSourceZyfi) {
        if (_.isEqual(feeToken, sourceToken)) {
            // 1. if Source = Ethereum and fee = Ethereum - no need to calculate fees
            // @ts-ignore
            res = tokenValueInUserWallet.gte(desiredSwapValue);
        }
        else {
            // // Fee token balance is handled in a different selector - is-zyfi-token-not-enough
            // const feeTokenInUserWallet = _.path(
            //   ['value', 'entities', feeToken!.cgKey, 'assetsAssoc', feeToken!.id],
            //   userWallet
            // )
            // const availableFeeTokenGasValue = Numbo.floatToBigNumber(
            //   feeTokenInUserWallet,
            //   _.path('decimals', feeTokenInUserWallet)
            // )
            // const requiredFeeTokenGasValue = Numbo.floatToBigNumber(
            //   generalTx.value!.feeTokenAmount,
            //   Number(generalTx.value!.feeTokendecimals)
            // )
            //
            // // 2. if Source = Ethereum but fee != Ethereum - we need calculate fees in addition
            // res = tokenValueInUserWallet.gte(desiredSwapValue) && availableFeeTokenGasValue.gte(requiredFeeTokenGasValue)
            res = tokenValueInUserWallet.gte(desiredSwapValue);
        }
        // @ts-ignore
    }
    else {
        // 3. by default we look only that amount that is in our wallet is greater or equal to the value from source input
        // @ts-ignore
        res = tokenValueInUserWallet.gte(desiredSwapValue);
    }
    // console.log('is enough?', res)
    return res;
};
