import { ETransactionStatus } from '../../__common__/constants/transaction';
import _ from 'lodash/fp';
// TODO: add unit tests
// TODO: add unit tests
// TODO: add unit tests
export const getTransactionStatus = (tx) => {
    const status = _.path(['value', 'status'], tx);
    const error = _.path(['error'], tx);
    return {
        isSuccess: status === ETransactionStatus.confirmed,
        isError: error || status === ETransactionStatus.failed || status === ETransactionStatus.expired,
        isLoading: status === ETransactionStatus.pending
    };
};
