import { makeSelectAllParamsSelected, selectFiatToTokenRate } from '../../../../selectors';
import { formatValueLoss } from '../../../../transducers/format-value-loss';
import { isMaximumValueLossExceeded } from '../../../../transducers/is-maximum-value-loss-exceeded';
import { isRateAbnormal } from '../../../../transducers/is-rate-abnormal';
import { useWarning } from './__hooks__/use-warning';
import _ from 'lodash/fp';
import { SWarningBlock } from './__styled__/s-warning-block';
import { useIsAmountTyping } from '../../__hooks__/use-is-amount-typing';
import { OCheckbox, SBox } from '@ondefy/ondefy-ui';
import React, { useMemo } from 'react';
import { useAppSelector } from '../../../../../../redux/hooks';
import { EEntityStatus } from '../../../../../../redux/redux-entity';
export const WarningBlock = () => {
    var _a;
    const { allowWithWarning, setAllowWithWarning } = useWarning();
    const fiatToTokenRate = useAppSelector(selectFiatToTokenRate);
    const selectAllParamsSelected = useMemo(makeSelectAllParamsSelected, []);
    const allParamsSelected = useAppSelector(selectAllParamsSelected);
    const { isTyping } = useIsAmountTyping();
    const valueLoss = _.path(['value', 'valueLoss'], fiatToTokenRate);
    return (React.createElement(React.Fragment, null, fiatToTokenRate.status === EEntityStatus.success &&
        !isTyping &&
        allParamsSelected &&
        isRateAbnormal((_a = fiatToTokenRate.value) === null || _a === void 0 ? void 0 : _a.valueLoss) && (React.createElement(SBox, { px: "8px", pb: "8px" },
        React.createElement(SWarningBlock, { onClick: () => {
                setAllowWithWarning(!allowWithWarning);
            } },
            React.createElement(OCheckbox, { variant: 'secondary', onChange: val => {
                    setAllowWithWarning(val);
                }, checked: allowWithWarning, label: isMaximumValueLossExceeded(valueLoss)
                    ? `High value loss ! (${formatValueLoss(valueLoss)})`
                    : `High value divergence, proceed with caution ! (${formatValueLoss(valueLoss)})` }))))));
};
