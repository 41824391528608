import { styled, SBox } from '@ondefy/ondefy-ui';
export const SLogoContainer = styled(SBox) `
  padding: 8px 0 0 8px;
  transform: scale(${p => (p.size === 'sm' ? '0.5' : '1')});

  clip-path: polygon(0 0, 40px 0px, 40px 50px, 0% 100%);
  width: 40px;
  overflow: hidden;

  svg {
    width: 120px;
  }
`;
