// import { ERC20_ABI } from './constants'; // Import the ABI from your constants file
import { ERC20_ABI } from '../../2 - swap/constants';
import { Numbo } from '../../__common__/numbo/transducers';
import { encodeFunctionData } from 'viem';
export const getTransferData = ({ 
// tokenAddress,
toAddress, amount, decimals }) => {
    // Destination address and amount
    // const toAddress = 'DESTINATION_ADDRESS' // Replace with the destination address
    const amountParsed = Numbo.floatToBigNumber(amount, decimals).toBigInt(); // Replace AMOUNT and TOKEN_DECIMALS appropriately
    // Encode the transaction data
    const data = encodeFunctionData({
        functionName: 'transfer',
        args: [toAddress, amountParsed],
        abi: ERC20_ABI
    });
    return data;
};
