import { of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { ESwappers, NATIVE_ADDRESS, ZERO_ADDRESS } from '../../../__common__/constants/contracts';
import { getAdapterAddress } from '../../transducers/get-adapter-address';
import { getBestQuoteData } from '../../transducers/get-best-quote-data';
import { get0xSwapDataRx } from '../../../__common__/api/integrations/0x';
import { get1inchSwapDataRx } from '../../../__common__/api/integrations/1inch';
import { getLiFiSwapDataRx } from '../../../__common__/api/integrations/lifi';
import { getParaswapSwapDataRx } from '../../../__common__/api/integrations/paraswap';
export function getBestQuoteRx({ networkId, srcToken, destToken, amountMinusFee, slippage, srcDecimals, destDecimals }) {
    const st = srcToken === ZERO_ADDRESS ? NATIVE_ADDRESS : srcToken;
    const dt = destToken === ZERO_ADDRESS ? NATIVE_ADDRESS : destToken;
    const zeroXAdapter = getAdapterAddress(networkId, ESwappers.ZERO_X_SWAPPER_IDX);
    const oneInchAdapter = getAdapterAddress(networkId, ESwappers.ONE_INCH_SWAPPER_IDX);
    const paraswapAdapter = getAdapterAddress(networkId, ESwappers.PARASWAP_SWAPPER_IDX);
    const liFiAdapter = getAdapterAddress(networkId, ESwappers.LIFI_SWAPPER_IDX);
    return zip(liFiAdapter
        ? getLiFiSwapDataRx({
            networkId,
            usingOndefyRouter: true,
            fromAddress: liFiAdapter,
            destReceiver: liFiAdapter,
            srcToken: st,
            destToken: dt,
            amount: amountMinusFee,
            slippage
        })
        : of(null), zeroXAdapter
        ? get0xSwapDataRx({
            networkId,
            usingOndefyRouter: true,
            userAddress: zeroXAdapter,
            srcToken: st,
            destToken: dt,
            amount: amountMinusFee,
            slippage
        })
        : of(null), oneInchAdapter
        ? get1inchSwapDataRx({
            networkId,
            usingOndefyRouter: true,
            fromAddress: oneInchAdapter,
            destReceiver: oneInchAdapter,
            srcToken: st,
            destToken: dt,
            amount: amountMinusFee,
            slippage
        })
        : of(null), paraswapAdapter
        ? getParaswapSwapDataRx({
            networkId,
            usingOndefyRouter: true,
            userAddress: paraswapAdapter,
            receiver: paraswapAdapter,
            srcToken: st,
            destToken: dt,
            amount: amountMinusFee,
            slippage,
            srcDecimals,
            destDecimals
        })
        : of(null)).pipe(map(getBestQuoteData(destDecimals)));
}
