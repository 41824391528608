import { SFlexbox, styled } from '@ondefy/ondefy-ui';
export const SCopyTokenUrl = styled(SFlexbox) `
  flex: 1;
  justify-content: center;
  padding: 8px;

  .copy-url__text {
    user-select: all;
  }
`;
