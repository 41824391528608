import { selectSourceAmount, selectFiatToTokenRate, selectNetworkIdTargetFiat, selectTargetTokenFiat, makeSelectedFiatSelector } from '../../../../../selectors';
import { checkIfNetworkIsValid } from '../../../../../transducers/check-iIf-network-is-valid';
import { isRateAbnormal } from '../../../../../transducers/is-rate-abnormal';
import { useIsAmountTyping } from '../../../__hooks__/use-is-amount-typing';
import { useWarning } from '../../7-warning-block/__hooks__/use-warning';
import { useMemo } from 'react';
import { useAppSelector } from '../../../../../../../redux/hooks';
import { EEntityStatus } from '../../../../../../../redux/redux-entity';
export const useContinueDisabledForBuy = () => {
    var _a;
    const networkId = useAppSelector(selectNetworkIdTargetFiat);
    const targetToken = useAppSelector(selectTargetTokenFiat);
    const selectedFiatSelector = useMemo(makeSelectedFiatSelector, []);
    const selectedFiatCurrency = useAppSelector(selectedFiatSelector);
    const selectedFiatAmount = useAppSelector(selectSourceAmount);
    const fiatToTokenRate = useAppSelector(selectFiatToTokenRate);
    const allParamsSelected = !!targetToken && !!selectedFiatCurrency && !!selectedFiatAmount;
    const isNetworkValid = useMemo(() => {
        return networkId && checkIfNetworkIsValid(networkId);
    }, [networkId]);
    const { isTyping } = useIsAmountTyping();
    const { allowWithWarning } = useWarning();
    return (!isNetworkValid ||
        !allParamsSelected ||
        isTyping ||
        fiatToTokenRate.status === EEntityStatus.error ||
        fiatToTokenRate.status === EEntityStatus.loading ||
        (isRateAbnormal((_a = fiatToTokenRate === null || fiatToTokenRate === void 0 ? void 0 : fiatToTokenRate.value) === null || _a === void 0 ? void 0 : _a.valueLoss) && !allowWithWarning));
};
