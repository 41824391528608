import { ESwappers } from '../constants/contracts';
export const getSwapperIconName = (swapperIdx, themeId) => {
    switch (swapperIdx) {
        case ESwappers.ZERO_X_SWAPPER_IDX:
            return themeId === 'dark' ? '0x-light' : '0x-dark';
        case ESwappers.ONE_INCH_SWAPPER_IDX:
            return '1inch';
        case ESwappers.PARASWAP_SWAPPER_IDX:
            return themeId === 'dark' ? 'paraswap-light' : 'paraswap-dark';
        case ESwappers.LIFI_SWAPPER_IDX:
            return themeId === 'dark' ? 'lifi-light' : 'lifi-dark';
        case ESwappers.PANCAKESWAP_SWAPPER_IDX:
            return 'pancakeswap';
        case ESwappers.KYBERSWAP_SWAPPER_IDX:
            return themeId === 'dark' ? 'kyberswap-white' : 'kyberswap-black';
        case ESwappers.ODOS_SWAPPER_IDX:
            return themeId === 'dark' ? 'odos-white' : 'odos-black';
        default:
            return themeId === 'dark' ? 'ondefy-white' : 'ondefy';
    }
};
