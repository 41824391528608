import { ofType } from 'redux-observable';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { waitForState } from '../../__common__/rxjs-utils/wait-for-state';
import { selectTokenContractsAssoc } from '../../__common__/selectors';
import { selectUserAddress } from '../../wallet/selectors';
import { generalTx } from '../api/general-tx';
import { selectDestinationAddress, selectTransactionData, selectTargetTokenCustom, selectNetworkIdTargetCustom } from '../selectors';
import { getZyfiGeneralTx, getZyfiGeneralTxFullfilled, getZyfiGeneralTxRejected } from '../slice';
export const epicCustomGetGeneralTx = (action$, state$) => {
    return action$.pipe(ofType(getZyfiGeneralTx.toString()), waitForState(state$, selectTokenContractsAssoc), waitForState(state$, selectDestinationAddress), waitForState(state$, selectNetworkIdTargetCustom), waitForState(state$, selectTransactionData), waitForState(state$, selectTargetTokenCustom), switchMap(() => {
        const { value } = state$;
        const targetToken = selectTargetTokenCustom(value);
        const userAddress = selectUserAddress(value);
        const transactionData = selectTransactionData(value);
        const toAddress = selectDestinationAddress(value);
        const generalTxPayload = {
            feeTokenAddress: targetToken.address,
            txData: {
                from: userAddress,
                to: toAddress,
                data: transactionData
            }
        };
        return generalTx(generalTxPayload).pipe(map(v => getZyfiGeneralTxFullfilled(v)), catchError(e => {
            return of(getZyfiGeneralTxRejected(e));
        }));
    }));
};
