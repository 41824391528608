import { OButton, OIcon, SFlexbox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import _ from 'lodash/fp';
export const HeaderNavBack = ({ title, onBack, hideGoBack }) => {
    return (React.createElement(SFlexbox, { alignItems: "center", justifyContent: "center", height: "50px" },
        !hideGoBack && (React.createElement(OButton, { variant: "primary-unstyled", size: "lg", left: "-4px", icon: React.createElement(OIcon, { name: "left", size: 24 }), position: "absolute", onClick: () => {
                if (_.isFunction(onBack))
                    onBack();
            } })),
        typeof title === 'string' ? (React.createElement(SText, { type: "h5", mb: "0" }, title)) : (title)));
};
