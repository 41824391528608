import { SBox, SFlexbox, SText, useTheme, OIcon } from '@ondefy/ondefy-ui';
import React from 'react';
import { useIframeUrlColors } from '../../../iframe/hooks/use-iframe-url-colors';
// import SvgDone from '@ondefy/ondefy-ui/dist/assets/svg/done-white.svg'
// import { makeSelectedTokenSelector, selectIsTransakSupported } from '../../features/web3/slice.web3'
// import { useAppSelector } from '../../redux/hooks'
import { SBreadcrumb } from './s-components/s-breadcrumb';
export const Breadcrumb = ({ inProgress, done, iconName, label, last, onClick }) => {
    const { theme } = useTheme();
    const { colorPrimary } = useIframeUrlColors();
    return (React.createElement(SBreadcrumb, { opacity: inProgress || done ? 1 : 0.5, className: onClick && 'breadcrumb__clickable', onClick: onClick
            ? () => {
                onClick();
            }
            : undefined },
        React.createElement(SFlexbox, { bg: "bg2", width: "32px", height: "32px", borderRadius: "100%", outline: theme.id === 'light' ? 'inherit' : '1px solid ' + theme.colors.hoverTransparent, alignItems: "center", justifyContent: "center", position: "relative" },
            (inProgress || !done) && (React.createElement(SBox, { borderRadius: "20px", width: "12px", height: "12px", bg: inProgress ? colorPrimary || 'accent' : 'bg4' })),
            done && (React.createElement(SFlexbox, { alignItems: "center", justifyContent: "center", width: "20px", height: "20px", position: "absolute", top: "-4px" }, done && (React.createElement(OIcon, { name: "check", 
                // @ts-ignore
                color: colorPrimary || 'accent', size: 24 })))),
            !last && (React.createElement(SBox, { borderBottom: "1px dotted", borderColor: "typo", width: "40px", position: "absolute", top: "7px", left: "calc(100% + 3px)" }))),
        React.createElement(SText, { type: "small", 
            // @ts-ignore
            color: done || inProgress ? colorPrimary || 'accent' : undefined, mt: "6px", className: "breadcrumb__label" }, label)));
};
