import _ from 'lodash/fp';
import { getUserAssetsByNetwork } from '../../__common__/papers/paper-token-select/__transducers__/get-user-assets-by-network';
// TODO: write unit tests
// TODO: write unit tests
// TODO: write unit tests
export const filterUserTokens = (networkId, userWallet, searchValue) => {
    const userAssets = getUserAssetsByNetwork(networkId, userWallet);
    if (!userAssets) {
        return [];
    }
    const sortByPrice = _.compose(_.reverse, _.sortBy(_.compose(parseFloat, _.path('amountUSD'))));
    if (!searchValue) {
        return sortByPrice(userAssets);
    }
    const str = searchValue.toLowerCase();
    const filter = _.filter((c) => {
        var _a, _b;
        return c.address === str || ((_a = c.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(str)) || ((_b = c.symbol) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(str));
    });
    return sortByPrice(filter(userAssets));
};
