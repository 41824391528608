import { styled, breakpoint, SBox } from '@ondefy/ondefy-ui';
export const SLogoContainer = styled(SBox) `
  padding: 10px 0 0 10px;
  svg {
    width: 120px;
  }
  @media ${breakpoint.sm} {
    padding: 10px 0 0;
    svg {
      width: 120px;
    }
  }
`;
