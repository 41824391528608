/*
 * SELECTORS
 * */
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash/fp';
import { sliceNamespace } from './constants';
import { indexTokensByAddress } from './transducers/index-tokens-by-address';
// import { findTokenByIdInAssoc } from './transducers/find-token-by-id'
export const selectCommon = (state) => _.path(sliceNamespace, state);
export const selectAppMode = createSelector(selectCommon, state => state.appMode);
export const selectPage = createSelector(selectCommon, state => state.page);
export const selectTokenContracts = createSelector(selectCommon, state => state.tokenContracts);
export const selectTokenContractsAssoc = createSelector(selectCommon, state => state.tokenContractsAssoc);
export const selectTokenContractsByAddress = createSelector(selectTokenContracts, indexTokensByAddress);
export const selectSearchTokenValue = createSelector(selectCommon, state => state.searchTokenValue);
export const selectTemporaryNetworkId = createSelector(selectCommon, state => state.temporaryNetworkId);
// export const selectIsIframe = createSelector(selectCommon, state => state.isIframe)
