import { OLink, SBox, SFlexbox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { NETWORK_NAME_BY_ID } from '../../../constants/networks';
import { DateTime } from '../../../date-time';
import { selectTokenContractsByAddress } from '../../../selectors';
import { getExplorerLink } from '../../../transducers/get-explorer-link';
import { ORDER_RAMP_STATUS_FAILED, ORDER_RAMP_STATUS_FULFILLED, ORDER_RAMP_STATUS_PENDING } from '../../../../1 - buy/constants/order-ramp-status';
import { ORDER_SWAP_STATUS_ABORTED, ORDER_SWAP_STATUS_EXPIRED, ORDER_SWAP_STATUS_FULFILLED, ORDER_SWAP_STATUS_IDLE, ORDER_SWAP_STATUS_PENDING } from '../../../../1 - buy/constants/order-swap-status';
import { useIframeUrlColors } from '../../../../iframe/hooks/use-iframe-url-colors';
import { useAppSelector } from '../../../../../redux/hooks';
import _ from 'lodash/fp';
import { Numbo } from '../../../numbo/transducers';
export const LineRamp = ({ order }) => {
    const tokenAssocByAddress = useAppSelector(selectTokenContractsByAddress);
    const { colorPrimary } = useIframeUrlColors();
    return (React.createElement(SBox, null,
        React.createElement(SFlexbox, { justifyContent: "space-between" },
            React.createElement(SText, null,
                order.fiatAmount,
                " ",
                order.fiatCurrency,
                " to",
                ' ',
                Numbo.formatWithDecimals(order.toTokenValue, order.toTokenDecimals, 6),
                ' ',
                _.path([NETWORK_NAME_BY_ID[order.networkId], order.toTokenAddress, 'symbol'], tokenAssocByAddress)),
            React.createElement(SText, null, DateTime.formatTimezoneWithTimestamp(order.timestamp / 1000, 'DD.MM.YYYY'))),
        React.createElement(SFlexbox, { justifyContent: "space-between" },
            React.createElement("div", null,
                // case 1
                ((order.isTransakSupported && order.rampStatus === ORDER_RAMP_STATUS_FULFILLED) ||
                    // case 2
                    (order.rampStatus === ORDER_RAMP_STATUS_FULFILLED &&
                        order.swapStatus === ORDER_SWAP_STATUS_FULFILLED)) && (React.createElement(SText, { type: "small", 
                    // @ts-ignore
                    color: colorPrimary || 'accent' }, "Completed")),
                (order.rampStatus === ORDER_RAMP_STATUS_FAILED ||
                    order.swapStatus === ORDER_SWAP_STATUS_ABORTED ||
                    (order.rampStatus !== ORDER_RAMP_STATUS_PENDING && order.swapStatus === ORDER_SWAP_STATUS_EXPIRED)) && (React.createElement(SText, { type: "small", color: "error" }, "Failed")),
                (order.rampStatus === ORDER_RAMP_STATUS_PENDING ||
                    order.swapStatus === ORDER_SWAP_STATUS_PENDING ||
                    order.swapStatus === ORDER_SWAP_STATUS_IDLE) && React.createElement(SText, { type: "small" }, "In Progress")),
            order.swapStatus === ORDER_SWAP_STATUS_FULFILLED && (React.createElement(SText, { type: "small" },
                React.createElement(OLink, { isExternal: true, borderless: true, noIcon: true, href: getExplorerLink(_.path('swapTxHash', order) || _.path('rampTxHash', order), _.path('networkId', order)) }, "View on explorer"))))));
};
