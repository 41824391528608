import { makeSelectAllParamsSelected, makeSelectedFiatSelector, selectFiatToTokenRate } from '../../../../selectors';
import { useIsAmountTyping } from '../../__hooks__/use-is-amount-typing';
import { ExchangeRates } from './exchange-rates';
import { OSkeleton, SFlexbox } from '@ondefy/ondefy-ui';
import React, { useMemo } from 'react';
import { useAppSelector } from '../../../../../../redux/hooks';
import { EEntityStatus } from '../../../../../../redux/redux-entity';
export const ExchangeRate = () => {
    const selectAllParamsSelected = useMemo(makeSelectAllParamsSelected, []);
    const allParamsSelected = useAppSelector(selectAllParamsSelected);
    const selectedFiatSelector = useMemo(makeSelectedFiatSelector, []);
    const selectedFiatCurrency = useAppSelector(selectedFiatSelector);
    const fiatToTokenRate = useAppSelector(selectFiatToTokenRate);
    const { isTyping } = useIsAmountTyping();
    const showSkeleton = fiatToTokenRate.status === EEntityStatus.loading || (isTyping && allParamsSelected);
    return (React.createElement(SFlexbox, { alignSelf: fiatToTokenRate.status === EEntityStatus.loading || (isTyping && allParamsSelected) ? 'center' : undefined },
        showSkeleton && (React.createElement(SFlexbox, null,
            React.createElement(OSkeleton, { height: 38, width: 130 }))),
        !isTyping && allParamsSelected && fiatToTokenRate.status === EEntityStatus.success && (React.createElement(ExchangeRates, { fiatToTokenRate: fiatToTokenRate, selectedFiatCurrency: selectedFiatCurrency }))));
};
