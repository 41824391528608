// import { selectUserAddress } from '../../../../../../../features/wallet/selectors'
import { ENV } from '../../../../../../../env';
// import { useNanoid } from '@ondefy/ondefy-ui'
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { EPages } from '../../../../../../__common__/constants';
// import { selectNetworkIdTargetSwap, selectBestSwapRate } from '../../../../../../../features/_swap_/selectors'
import { setPage } from '../../../../../../__common__/slice';
import { Analytics } from '../../../../../../analytics';
import { selectCanAllowanceBeSkipped, selectFeeTokenIdSwap, selectIfAllowanceSufficient, selectSourceTokenIdSwapIsEthereum } from '../../../../../selectors';
import { swapTransactionRaw } from '../../../../../slice';
import { isEtherToken } from '../../../../../../__common__/transducers/is-ether-token';
export const useOnContinue = () => {
    const dispatch = useAppDispatch();
    // https://academy.bit2me.com/en/que-es-token-allowance/
    // https://www.web3.university/tracks/create-a-smart-contract/what-is-gas-and-how-is-it-used
    const isAllowanceCanBeSkipped = useAppSelector(selectCanAllowanceBeSkipped);
    const isAllowanceSufficient = useAppSelector(selectIfAllowanceSufficient);
    const isSourceEthereumZyfi = useAppSelector(selectSourceTokenIdSwapIsEthereum);
    const feeTokenId = useAppSelector(selectFeeTokenIdSwap);
    // const userAddress = useAppSelector(selectUserAddress)
    // const networkId = useAppSelector(selectNetworkIdTargetSwap)
    // const bestRate = useAppSelector(selectBestSwapRate)
    return () => {
        // 1. For native tokens we can skip allowance and go directly to swap
        // 2. For non native we should check at first if allowance is sufficient - if yes - go to swap - if no - go to unlock
        // check allowence getAllowance() -> and compare with userWallet.token.amount
        // getAllowance < source amount -> UNLOCK BALANCE
        // TODO: if zksync - ignore allowance???
        // TODO: if zksync - ignore allowance???
        // TODO: if zksync - ignore allowance???
        if (ENV.isZyfi() && isSourceEthereumZyfi && isEtherToken(feeTokenId)) {
            dispatch(swapTransactionRaw());
            Analytics.swapButtonTriggered();
            return;
        }
        dispatch(setPage(isAllowanceCanBeSkipped || isAllowanceSufficient
            ? ENV.isOndefy()
                ? EPages.swapSubmit
                : EPages.swapSubmitZyfi
            : ENV.isOndefy()
                ? EPages.swapUnlock
                : EPages.swapUnlockZyfi));
        Analytics.continueTriggered();
    };
};
