var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Observable, pipe, Subscriber, throwError, of, retry, timer, map } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import _ from 'lodash/fp';
import { pipeIf } from './operators/pipe-if';
import { shareReplayAbortable } from './operators/share-replay-abortable';
import { CacheInMemory } from './cache-in-memory';
const decorateError = (res) => {
    // console.log(res)
    let error = {
        message: res.message
    };
    if (res.isAxiosError) {
        error = Object.assign(error, Object.assign(Object.assign({}, _.pathOr({}, ['response', 'data'], res)), { code: _.path(['code'], res), status: _.path(['response', 'status'], res), statusText: _.path(['response', 'statusText'], res) }));
    }
    // const apiError = new ApiError(message)
    // apiError.add('problem', problem)
    if (process.env.NODE_ENV === 'development') {
        // // eslint-disable-next-line no-console
        console.log(`%c Request ${_.pathOr('', ['config', 'method'], res).toUpperCase()} %c ${_.path(['request', 'responseURL'], res)} %c ${error.message}`, 'color: grey; font-weight: bold;', 'color: grey; font-style: italic;', 'color: red;');
    }
    return error;
};
class ApiSubscriber extends Subscriber {
    constructor(observer, method, handler, url, queryParamsOrPostBody, config) {
        super(observer);
        // XHR complete pointer
        this.completed = false;
        // Create cancelable source
        this.abortController = new AbortController();
        const API_TIMEOUT = 30000;
        const cancelRequest = { signal: this.abortController.signal };
        const defaultTimeout = { timeout: config.timeout || API_TIMEOUT };
        const isArity3 = ['PUT', 'put', 'POST', 'post', 'PATCH', 'patch'].includes(method);
        // TODO: why do I use cancelRequest in secondArgument and in thirdArgument for PUT and POST
        const configForBodilessRequests = Object.assign(Object.assign({ params: queryParamsOrPostBody }, cancelRequest), defaultTimeout);
        const secondArgument = isArity3 ? queryParamsOrPostBody : configForBodilessRequests;
        const thirdArgument = isArity3 ? Object.assign(Object.assign(Object.assign({}, defaultTimeout), config), cancelRequest) : undefined;
        // const thirdArgument = isArity3 ? { ...defaultTimeout, ...config, ...cancelRequest, params: { some_query_param: 'ok' } } : undefined
        handler(url, secondArgument, thirdArgument)
            .then((response) => __awaiter(this, void 0, void 0, function* () {
            // console.log('REQUEST COMPLETE!!!!', response)
            // Error handler
            if (response.problem) {
                return yield Promise.reject(response);
            }
            this.next(response);
            this.completed = true;
            this.complete();
        }))
            .catch((thrown) => {
            this.completed = true;
            this.error(thrown);
        });
    }
    unsubscribe() {
        // This 'unsubscribe' hook is available due to implementation of Subsriber
        // utils like switchMap will call this hook
        super.unsubscribe();
        // cancel XHR
        if (!this.completed) {
            if (this.abortController) {
                // console.log('Cancel Request')
                this.abortController.abort();
            }
            this.completed = true;
        }
    }
}
const errorHandler = () => pipe(catchError(e => {
    return throwError(() => decorateError(e));
}));
const buildObservable = (method, handler, url, queryParamsOrPostBody, config) => new Observable(observer => {
    return new ApiSubscriber(observer, method, handler, url, queryParamsOrPostBody, config);
});
const buildObservableWithCache = (method, handler, url, queryParamsOrPostBody, config) => {
    const params = queryParamsOrPostBody;
    const postQueryParams = (config === null || config === void 0 ? void 0 : config.params) || {};
    const cacheId = `${method}:${url}?${JSON.stringify(params)}:${JSON.stringify(postQueryParams)}`;
    const cacheReqId = `req_${cacheId}`;
    // Retrieve data from the cache if available
    const cachedResponse = CacheInMemory.get(cacheId);
    if (cachedResponse) {
        return cachedResponse;
    }
    // If the request is already ongoing, return that
    const ongoingRequest = CacheInMemory.get(cacheReqId);
    if (ongoingRequest) {
        return ongoingRequest;
    }
    // Function to handle cache updates on various lifecycle events
    const handleCacheUpdates = (type, response) => {
        switch (type) {
            case 'next':
                const res = config.returnResponseConfig ? response : _.path('data', response);
                CacheInMemory.set(cacheId, of(res), config);
                // If server returns Time To Live header for some request in SECONDS! update the cache automatically
                const xTtl = _.path(['headers', 'x-ttl'], response);
                if (xTtl) {
                    CacheInMemory.update(cacheId, {
                        cacheSeconds: parseInt(xTtl, 10)
                    });
                }
                CacheInMemory.delete(cacheReqId);
                break;
            case 'error':
                CacheInMemory.delete(cacheReqId);
                break;
            case 'complete':
                const isResponseCached = !!CacheInMemory.get(cacheId);
                if (!isResponseCached)
                    CacheInMemory.delete(cacheReqId);
                break;
        }
    };
    const request$ = buildObservable(method, handler, url, params, config).pipe(tap({
        next: response => handleCacheUpdates('next', response),
        error: () => handleCacheUpdates('error'),
        complete: () => handleCacheUpdates('complete')
    }), 
    // TODO: check if it works as expected
    pipeIf(!!config.retry, retry({
        count: config.retry,
        // eslint-disable-next-line n/handle-callback-err
        delay: (error, retryCount) => timer(retryCount * 1000)
    })), pipeIf(!config.returnResponseConfig, map(_.path('data'))), shareReplayAbortable(), 
    // it's necessary to make sure that shareReplay don't affect completed state of our shared api
    take(1), errorHandler());
    // Store ongoing request in cache
    CacheInMemory.set(cacheReqId, request$, config);
    return request$;
};
const buildRxApiService = (apiInstance) => {
    return {
        get: (url, queryParams = {}, config = {}) => {
            return buildObservableWithCache('GET', apiInstance.get.bind(apiInstance), url, queryParams, config);
        },
        post: (url, postBody = {}, config = {}) => {
            return buildObservableWithCache('POST', apiInstance.post.bind(apiInstance), url, postBody, config);
        },
        put: (url, putBody = {}, config = {}) => buildObservable('PUT', apiInstance.put.bind(apiInstance), url, putBody, config).pipe(pipeIf(!config.returnResponseConfig, map(_.path('data'))), errorHandler()),
        delete: (url, queryParams = {}, config = {}) => buildObservable('DELETE', apiInstance.delete.bind(apiInstance), url, queryParams, config).pipe(pipeIf(!config.returnResponseConfig, map(_.path('data'))), errorHandler()),
        head: (url, queryParams = {}, config = {}) => buildObservable('HEAD', apiInstance.head.bind(apiInstance), url, queryParams, config).pipe(pipeIf(!config.returnResponseConfig, map(_.path('data'))), errorHandler())
    };
};
export const ApiObservable = {
    from: buildRxApiService
};
