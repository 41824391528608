// https://github.com/vercel/next.js/blob/canary/examples/with-redux/src/features/counter/counterSlice.ts
import { createSlice } from '@reduxjs/toolkit';
// import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
// import localForage from 'localforage'
import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
// import { TAction } from '../../redux/types'
import { sliceNamespace } from './constants';
import { initialState } from './state';
import * as reducers from './reducers';
/*
 * REDUCERS
 * */
export const slice = createSlice({
    name: sliceNamespace,
    initialState,
    reducers
});
/*
 * ACTIONS
 * */
export const { flush, reset, setDestinationAddress, getZyfiGeneralTx, getZyfiGeneralTxRejected, getZyfiGeneralTxFullfilled, saveTransaction, saveTransactionComplete, sendTransaction, sendTransactionRejected, sendTransactionFullfilled, sendTransactionReset, setPercentage, setSourceTokenAmount, setSourceTokenIdSend, setFeeTokenIdSend, setNetworkIdSourceSend, setNetworkIdFeeSend } = slice.actions;
/*
 * PERSIST CONFIG
 * */
const persistConfig = {
    key: sliceNamespace,
    // storage: localForage,
    storage: sessionStorage,
    stateReconciler: autoMergeLevel2,
    whitelist: [
        'transactionData',
        'destinationAddress',
        'sourceTokenIdSend',
        'feeTokenIdSend',
        'networkIdFeeSend',
        'networkIdSourceSend',
        'sourceTokenAmount',
        'sendTransaction',
        'percentage',
        'pendingTransactions'
    ]
};
export default persistReducer(persistConfig, slice.reducer);
