import { EFontSizes, OButton, OMedia, SText, OIcon } from '@ondefy/ondefy-ui';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { Analytics } from '../../../../analytics';
import { useIframeUrlColors } from '../../../../iframe/hooks/use-iframe-url-colors';
import { APP_MODE } from '../../../constants';
import { selectAppMode } from '../../../selectors';
import { setAppMode } from '../../../slice';
import _ from 'lodash/fp';
export const ModeButton = ({ variant, hidden }) => {
    const { colorPrimary } = useIframeUrlColors();
    const appMode = useAppSelector(selectAppMode);
    const dispatch = useAppDispatch();
    const isActive = variant === appMode;
    const [urlSearchParams] = useSearchParams();
    const isDisabledViaQuery = urlSearchParams.get('noswap') !== null;
    let iconName;
    let text;
    let logAnalytics;
    const disabled = appMode === variant;
    switch (variant) {
        case APP_MODE.send: {
            text = 'Send';
            logAnalytics = Analytics.sendButtonTriggered;
            iconName = 'arrow-top-right';
            break;
        }
        case APP_MODE.swap: {
            text = 'Swap';
            logAnalytics = Analytics.swapButtonTriggered;
            iconName = 'swap';
            break;
        }
        case APP_MODE.buy: {
            text = 'Buy';
            logAnalytics = Analytics.buyButtonTriggered;
            iconName = 'wallet';
            break;
        }
        case APP_MODE.custom: {
            text = 'Custom';
            logAnalytics = Analytics.customButtonTriggered;
            iconName = 'wrench';
            break;
        }
        default: {
            // console.log('change mode')
        }
    }
    return (React.createElement(OButton, { display: hidden ? 'none' : undefined, variant: "secondary", opacity: !isActive ? 0.6 : 1, disabled: disabled || isDisabledViaQuery, iconLeft: React.createElement(OIcon
        // @ts-ignore
        , { 
            // @ts-ignore
            name: iconName, 
            // @ts-ignore
            color: isActive ? colorPrimary || 'accent' : undefined }), onClick: () => {
            dispatch(setAppMode(variant));
            if (_.isFunction(logAnalytics)) {
                logAnalytics();
            }
        } },
        React.createElement(OMedia, { alignItems: "center", position: "relative" },
            React.createElement(SText, { fontWeight: "bold", fontSize: {
                    xs: EFontSizes.bodySMobile,
                    md: EFontSizes.bodyM
                } }, text))));
};
