import { useEffect, useMemo, useRef } from 'react';
import { useDebounce } from 'react-use';
import { selectTargetTokenFiat, selectSourceAmount, selectRefuelAmount, makeSelectedFiatSelector } from '../../../selectors';
import { getFiatToTokenRate, getFiatToTokenRateRejected } from '../../../slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
export const useDebounceGetFiatToTokenRates = () => {
    const selectedToken = useAppSelector(selectTargetTokenFiat);
    const selectedFiatAmount = useAppSelector(selectSourceAmount);
    const dispatch = useAppDispatch();
    const selectedFiatSelector = useMemo(makeSelectedFiatSelector, []);
    const selectedFiat = useAppSelector(selectedFiatSelector);
    const selectedFiatAmountRef = useRef(selectedFiatAmount);
    const refuelAmount = useAppSelector(selectRefuelAmount);
    const checkAndFetchRate = () => {
        if (selectedFiatAmount && selectedToken && selectedFiat) {
            if (parseInt(selectedFiatAmount) < (selectedFiat === null || selectedFiat === void 0 ? void 0 : selectedFiat.minAmount)) {
                dispatch(getFiatToTokenRateRejected({
                    message: `Minimum ${selectedFiat === null || selectedFiat === void 0 ? void 0 : selectedFiat.symbol} buy amount is ${selectedFiat === null || selectedFiat === void 0 ? void 0 : selectedFiat.minAmount}`
                }));
            }
            else if (parseInt(selectedFiatAmount) > (selectedFiat === null || selectedFiat === void 0 ? void 0 : selectedFiat.maxAmount)) {
                dispatch(getFiatToTokenRateRejected({
                    message: `Maximum ${selectedFiat === null || selectedFiat === void 0 ? void 0 : selectedFiat.symbol} buy amount is ${selectedFiat === null || selectedFiat === void 0 ? void 0 : selectedFiat.maxAmount}`
                }));
            }
            else {
                dispatch(getFiatToTokenRate());
            }
            selectedFiatAmountRef.current = selectedFiatAmount;
        }
    };
    useDebounce(() => {
        // console.log('typing stopped');
        /*
        // it will skip this function after refresh and field rehydration from localStorage
        if (selectedFiatAmountRef.current === selectedFiatAmount) {
          return;
        }
        */
        checkAndFetchRate();
    }, 1500, [selectedFiatAmount]);
    useEffect(() => {
        checkAndFetchRate();
        // eslint-disable-next-line
    }, [selectedToken, selectedFiat, refuelAmount]);
};
