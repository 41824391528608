import { encodeFunctionData } from 'viem';
import { MaxUint256 } from '../../__common__/web3/constants';
import { ERC20_ABI } from '../constants';
export const encodeApproveMaxERC20 = (spender) => {
    return encodeFunctionData({
        abi: ERC20_ABI,
        functionName: 'approve',
        args: [spender, BigInt(MaxUint256)]
    });
};
