import { toast } from '@ondefy/ondefy-ui';
import { ofType } from 'redux-observable';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ofValue } from '../../__common__/rxjs-utils/of-value';
import { waitForState } from '../../__common__/rxjs-utils/wait-for-state';
import { selectUserAddress } from '../selectors';
import { getUserWalletFullfilled, getUserWalletRejected, getUserWallet } from '../slice';
import { of, catchError, map, switchMap, merge, tap, filter } from 'rxjs';
import { getUserWalletRx } from '../api/get-user-wallet';
import { normalizeUserWalletResponse } from '../transducers/normalize-user-wallet-response';
import _ from 'lodash/fp';
export const epicGetUserWallet = (action$, state$) => {
    return action$.pipe(ofType(getUserWallet.toString()), waitForState(state$, selectUserAddress), debounceTime(16), switchMap(({ payload }) => {
        const req$ = () => {
            return getUserWalletRx(payload).pipe(takeUntil(
            // stop fetching user wallet on disconnect
            ofValue(state$, selectUserAddress).pipe(filter(_.isNil), tap(() => {
                console.log('Stop fetching user assets after disconnect');
            }))), map(normalizeUserWalletResponse), map(v => getUserWalletFullfilled(v)), tap({
                error: () => {
                    toast.next({
                        variant: 'error',
                        message: "Failed to fetch the user's wallet"
                    });
                }
            }), catchError(e => {
                console.log('Can not fetch user wallet information', e);
                return of(getUserWalletRejected(e));
            }));
        };
        return merge(req$()
        // merge(
        //   windowFocus$,
        //   windowOnline$
        // ).pipe(switchMap(() => req$()))
        );
    }));
};
