import { OTag, styled } from '@ondefy/ondefy-ui';
export const SIconNetTag = styled(OTag) `
  background-color: ${p => p.theme.colors.bg};
  height: 40px;
  position: relative;
  left: 20px;
  width: 63px;
  justify-content: flex-start;
  border: none;
`;
