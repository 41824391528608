import { isEtherToken } from '../../__common__/transducers/is-ether-token';
import { Numbo } from '../../__common__/numbo/transducers';
export const isZyfiPriceHigh = (generalTxZyfi, sourceTokenAmount, isEthereumSourceToken, feeToken, bestSwapRate) => {
    var _a;
    if (!bestSwapRate) {
        return;
    }
    if (isEthereumSourceToken && isEtherToken(feeToken)) {
        return Numbo.from((_a = bestSwapRate.meta) === null || _a === void 0 ? void 0 : _a.feeTokenAmount).gt(Numbo.floatToBigNumber(sourceTokenAmount, bestSwapRate.fromDecimals).div(2));
    }
    if (!generalTxZyfi.value || isEthereumSourceToken) {
        return;
    }
    return Numbo.from(generalTxZyfi.value.feeTokenAmount).gt(Numbo.floatToBigNumber(sourceTokenAmount, bestSwapRate.fromDecimals).div(2));
};
