import React from 'react';
import { OIcon, OMedia, SBox, SText, OButton, useTheme, EThemeVariant } from '@ondefy/ondefy-ui';
import { SWAPPER_NAME_BY_IDX } from '../../../../../__common__/constants/contracts';
import { selectBestSwapRate, selectSwapRates } from '../../../../selectors';
import { Analytics } from '../../../../../analytics';
import { useAppSelector } from '../../../../../../redux/hooks';
import { EEntityStatus } from '../../../../../../redux/redux-entity';
import { usePaperSwapDetails } from '../../../../papers/paper-swap-details/__hooks__/use-paper-swap-details';
import { SCarousel } from './__styled__/s-carousel';
import _ from 'lodash/fp';
export const BestRates = () => {
    const paperSwapDetails = usePaperSwapDetails();
    const { theme } = useTheme();
    const { status, value } = useAppSelector(selectSwapRates);
    const loading = status === EEntityStatus.loading;
    const loaded = !!value;
    const bestRate = useAppSelector(selectBestSwapRate);
    return (React.createElement(SBox, { px: "8px", mb: "2px" },
        loading && (React.createElement(SBox, { border: `1px solid ${theme.id === EThemeVariant.dark ? theme.colors.hoverTransparent : theme.colors.bg3}`, borderRadius: "12.5px", overflow: "hidden" },
            React.createElement(SCarousel, null,
                React.createElement("div", null,
                    React.createElement(OIcon, { name: "search", color: "accent", mr: "8px" }),
                    React.createElement(SText, { width: "100%", textAlign: "left" }, "Searching for the best rate...")),
                React.createElement("div", null,
                    React.createElement(OIcon, { name: "badge", color: "accent", mr: "8px" }),
                    React.createElement(SText, { width: "100%" }, "Checking 6 different DEX aggregators...")),
                React.createElement("div", null,
                    React.createElement(OIcon, { name: "dollar-circle", size: 20, color: "accent", mr: "8px" }),
                    React.createElement(SText, { width: "100%", textAlign: "left" }, "Ondefy finds the best rate for you"))))),
        !loading && loaded && (React.createElement(OButton, { variant: "secondary", bg: "bg2", width: "100%", onClick: () => {
                paperSwapDetails.setIsOpened(true);
                Analytics.dexSelectionOpened();
            } },
            React.createElement(OMedia, { alignItems: "center", justifyContent: "space-between", width: "100%" },
                React.createElement(OIcon, { name: "rocket", color: "accent", mr: "8px" }),
                React.createElement(SText, { width: "100%", textAlign: "left" },
                    React.createElement(SText, { color: "accent", fontWeight: "semibold" }, "BEST"),
                    React.createElement(SText, { fontWeight: "semibold", ml: "4px" },
                        _.path('toAmountUsdWithGasFeesFormatted', bestRate),
                        " after gas fees on",
                        ' ',
                        SWAPPER_NAME_BY_IDX[_.path('swapperIdx', bestRate)])),
                React.createElement(OIcon, { name: "down", color: "accent", mr: "8px" }))))));
};
