import { selectTargetTokenFiat, selectSourceAmount, selectSelectedFiatSymbol, selectPartnerOrderId, selectIsTransakSupported, selectTransakOrderNetworkId, selectTransakOrderWalletAddress } from '../../selectors';
import { Analytics } from '../../../analytics';
import { useLayoutMeasure } from '../../../__common__/components/o-paper/__hooks__/use-layout-measure';
import { useIframeUrlColors } from '../../../iframe/hooks/use-iframe-url-colors';
import { HeaderWithBreadcrumbs } from '../../../__common__/components/header-with-breadcrumbs';
import { useTransakProcessing } from './__hooks__/use-transak-processing';
import React from 'react';
import qs from 'qs';
import _ from 'lodash/fp';
import { useTheme } from '@ondefy/ondefy-ui';
import { useAppSelector } from '../../../../redux/hooks';
import { STransak } from './__styled__/s-transak';
import { SScreenInner } from './__styled__/s-screen-inner';
import { NETWORKS_DATA } from '../../../__common__/constants/networks';
import { useGetTokenContracts } from '../../../__common__/hooks/use-get-token-contracts';
export const ScreenRamp = () => {
    const { theme } = useTheme();
    const transakOrderNetworkId = useAppSelector(selectTransakOrderNetworkId);
    const transakOrderWalletAddress = useAppSelector(selectTransakOrderWalletAddress);
    const fiatAmount = useAppSelector(selectSourceAmount);
    const fiatSymbol = useAppSelector(selectSelectedFiatSymbol);
    const targetToken = useAppSelector(selectTargetTokenFiat);
    const partnerOrderId = useAppSelector(selectPartnerOrderId);
    const isTransakSupported = useAppSelector(selectIsTransakSupported);
    const network = NETWORKS_DATA[transakOrderNetworkId];
    const intermediateToken = isTransakSupported ? null : _.path([transakOrderNetworkId, 'ftatInputToken'], NETWORKS_DATA);
    const { colorPrimary } = useIframeUrlColors();
    const queryParams = {
        apiKey: process.env.TRANSAK_API_KEY,
        network: network === null || network === void 0 ? void 0 : network.transakId,
        walletAddress: transakOrderWalletAddress,
        fiatCurrency: fiatSymbol,
        fiatAmount,
        cryptoCurrencyCode: isTransakSupported ? _.path('symbol', targetToken) : intermediateToken === null || intermediateToken === void 0 ? void 0 : intermediateToken.symbol,
        disableWalletAddressForm: true,
        partnerOrderId,
        themeColor: colorPrimary || theme.colors.accent,
        exchangeScreenTitle: ' ',
        defaultPaymentMethod: 'credit_debit_card',
        hideMenu: true
    };
    useTransakProcessing();
    useGetTokenContracts();
    const layoutMeasure = useLayoutMeasure();
    // console.log(layoutMeasure)
    const params = qs.stringify(queryParams);
    return (React.createElement(SScreenInner, null,
        React.createElement(HeaderWithBreadcrumbs, null),
        layoutMeasure.height && (React.createElement(STransak, { onLoad: () => {
                Analytics.transakLoaded();
            }, title: "Transak On/Off Ramp Widget", allow: "payment", height: "100%", src: `${process.env.TRANSAK_WIDGET_URL}/?${params}` }))));
};
