import { ofType } from 'redux-observable';
import { EMPTY, map, of, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { ofValue } from '../../../__common__/rxjs-utils/of-value';
import { waitForState } from '../../../__common__/rxjs-utils/wait-for-state';
import { selectBestSwapRate, selectFeeTokenIdSwap, selectSourceTokenIdSwapIsEthereum } from '../../selectors';
import { getSwapRatesFullfilled, getZyfiGeneralTx, setFeeTokenIdSwap } from '../../slice';
import { isEtherToken } from '../../../__common__/transducers/is-ether-token';
export const epicOnBestRateSuccess = (action$, state$) => {
    return action$.pipe(ofType(getSwapRatesFullfilled.toString(), setFeeTokenIdSwap.toString()), waitForState(state$, selectBestSwapRate), switchMap(() => {
        const { value } = state$;
        const isSourceTokenEthereum = selectSourceTokenIdSwapIsEthereum(value);
        const feeTokenId = selectFeeTokenIdSwap(value);
        if (isSourceTokenEthereum && isEtherToken(feeTokenId)) {
            return EMPTY;
        }
        if (!isSourceTokenEthereum || (isSourceTokenEthereum && feeTokenId)) {
            return of(getZyfiGeneralTx());
        }
        return ofValue(state$, selectFeeTokenIdSwap).pipe(take(1), map(() => {
            return getZyfiGeneralTx();
        }));
    }));
};
