import { selectNetworkIdTargetFiat, selectTransakOrderNetworkId, selectTransakOrderWalletAddress } from '../../../selectors';
import { selectUserAddress } from '../../../../wallet/selectors';
import { useWallet } from '../../../../wallet/hooks/use-wallet';
import { toast } from '@ondefy/ondefy-ui';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { signPermit } from '../../../slice';
export const usePermit = () => {
    const dispatch = useAppDispatch();
    // IWeb3 Feature!
    const { signMessage } = useWallet();
    const userAddress = useAppSelector(selectUserAddress);
    const networkId = useAppSelector(selectNetworkIdTargetFiat);
    const transakOrderWalletAddress = useAppSelector(selectTransakOrderWalletAddress);
    const transakOrderNetworkId = useAppSelector(selectTransakOrderNetworkId);
    return () => {
        if (transakOrderWalletAddress !== userAddress) {
            toast.next({
                variant: 'warning',
                message: 'Please use the same address as the one used to place the order'
            });
            return;
        }
        if (transakOrderNetworkId !== networkId) {
            toast.next({
                variant: 'warning',
                message: 'Please set the same network as the one you used to place the order'
            });
            return;
        }
        // IWeb3 Feature!
        dispatch(signPermit(signMessage));
    };
};
