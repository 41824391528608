// https://github.com/vercel/next.js/blob/canary/examples/with-redux/src/features/counter/counterSlice.ts
import { createSlice } from '@reduxjs/toolkit';
// import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
// import localForage from 'localforage'
import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { sliceNamespace } from './constants';
import { initialState } from './state';
// @ts-ignore
import * as reducers from './reducers';
/*
 * REDUCERS
 * */
export const slice = createSlice({
    name: sliceNamespace,
    initialState,
    /*
     * REDUCERS SIMPLE
     * */
    reducers
});
/*
 * ACTIONS
 * */
export const { setSelectedFiatSymbol, setPartnerOrderId, setTransakOrderIsProcessing, setTransakOrderWalletAddress, setTransakOrderNetworkId, flush, reset, resetFiatToTokenRate, getTransactionStatus, getTransactionStatusPending, getTransactionStatusRejected, getTransactionStatusFullfilled, setRefuelAmount, setAllowWithWarning, getFiatToTokenRateRejected, getFiatToTokenRateFulfilled, getFiatToTokenRate, getTransakCryptoCurrenciesRejected, getTransakCryptoCurrenciesFulfilled, getTransakCryptoCurrencies, getTransakFiatCurrencies, getTransakFiatCurrenciesRejected, getTransakFiatCurrenciesFulfilled, postOrderRejected, postOrderFulfilled, postOrder, signPermitRejected, signPermitFulfilled, signPermit, setSourceAmount, setNetworkIdTargetFiat, setTargetTokenIdFiat, setIframeMode } = slice.actions;
/*
 * PERSIST CONFIG
 * */
const persistConfig = {
    key: sliceNamespace,
    // storage: localForage,
    storage: sessionStorage,
    stateReconciler: autoMergeLevel2,
    whitelist: [
        'selectedFiatSymbol',
        'partnerOrderId',
        'transakOrderIsProcessing',
        'transakOrderWalletAddress',
        'transakOrderNetworkId',
        'earlyOrderSaved',
        'orderPermitSigned',
        'fiatToTokenRate',
        'isTransakSupported',
        'refuelAmount',
        'networkIdTargetFiat',
        'targetTokenIdFiat',
        'isIframe'
    ]
};
export default persistReducer(persistConfig, slice.reducer);
