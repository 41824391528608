import { useSearchParams } from 'react-router-dom';
import { getTokenContracts } from '../../../__common__/slice';
import { selectTargetTokenFiat } from '../../selectors';
import { getTransakCryptoCurrencies, getTransakFiatCurrencies } from '../../slice';
import { ETokenPopupVariant } from '../../../__common__/papers/paper-token-select/constants';
import { SAlignedSpaced } from '../../../__common__/styled/s-aligned-spaced';
import { Header } from '../../../__common__/components/header';
import { SBox } from '@ondefy/ondefy-ui';
import { ArrowDown } from '../../../__common__/components/arrow-down';
import { GasRefuel } from './__components__/6-gas-refuel';
import { SelectCurrency } from './__components__/2-select-currency';
import { Footer } from './__components__/8-footer';
import { SourceFiatAmountInput } from './__components__/1-source-fiat-amount/source-fiat-amount-input';
import { ExchangeRate } from './__components__/4-exchange-rate';
import { TokenDropdown } from '../../../__common__/components/token-select-dropdown/token-dropdown';
import { WarningBlock } from './__components__/7-warning-block';
import { useDebounceGetFiatToTokenRates } from './__hooks__/use-debounce-get-fiat-to-token-rates';
import { useMount } from 'react-use';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { MoreDetails } from './__components__/5-more-details';
import React from 'react';
import { SScreenInner } from '../../../__common__/styled/s-screen-inner';
import { SAmountBlock } from '../../../__common__/styled/s-amount-block';
import { SourceFiatAmountDetails } from './__components__/3-source-fiat-amount-details/source-fiat-amount-details';
export const ScreenInitBuy = () => {
    const dispatch = useAppDispatch();
    // token to buy
    const targetToken = useAppSelector(selectTargetTokenFiat);
    const [urlSearchParams] = useSearchParams();
    const tokenSelectedFromUrl = urlSearchParams.get('tokenId') || (urlSearchParams.get('tokenAddress') && urlSearchParams.get('networkId'));
    useMount(() => {
        dispatch(getTransakFiatCurrencies());
        dispatch(getTransakCryptoCurrencies());
        dispatch(getTokenContracts());
    });
    useDebounceGetFiatToTokenRates();
    return (React.createElement(React.Fragment, null,
        React.createElement(SScreenInner, null,
            React.createElement(Header, null),
            React.createElement(SBox, null,
                React.createElement(SAmountBlock, null,
                    React.createElement(SourceFiatAmountInput, null),
                    React.createElement(SelectCurrency, null)),
                React.createElement(SourceFiatAmountDetails, null)),
            React.createElement(ArrowDown, null),
            React.createElement(SBox, { mb: "48px" },
                React.createElement(SAlignedSpaced, null,
                    React.createElement(ExchangeRate, null),
                    React.createElement(TokenDropdown, { selectedToken: targetToken, tokenSelectedFromUrl: tokenSelectedFromUrl, variant: ETokenPopupVariant.target }))),
            React.createElement(SBox, null,
                React.createElement(MoreDetails, null),
                React.createElement(GasRefuel, null)),
            React.createElement(WarningBlock, null)),
        React.createElement(Footer, null)));
};
