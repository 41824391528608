import { SBox, styled } from '@ondefy/ondefy-ui';
export const SWrapper = styled(SBox) `
  border: 1px solid ${p => p.theme.colors.bg};
  border-radius: 12.5px;
  margin: 0 8px;
  padding: 15px 0;

  .rates__fuel-icon {
    position: relative;
    top: 1px;
    font-weight: bold;
  }
`;
