import { usePaperResetConfirm } from '../../papers/paper-reset-confirm/__hooks__/use-paper-reset-confirm';
import { useTheme } from '@ondefy/ondefy-ui';
// @ts-ignore
import LogoDark from '@ondefy/ondefy-ui/dist/assets/logo-ondefy-dark.svg';
// @ts-ignore
import LogoLight from '@ondefy/ondefy-ui/dist/assets/logo-ondefy-light.svg';
import React from 'react';
import { SHeader } from './__styled__/s-header';
import { SLogoContainer } from './__styled__/s-logo-container';
export const GlobalHeader = () => {
    const { theme } = useTheme();
    const { tryReset } = usePaperResetConfirm();
    // @ts-ignore
    // const LogoDark = require('@ondefy/ondefy-ui/dist/assets/logo-ondefy-dark.svg');
    return (React.createElement(SHeader, null,
        React.createElement(SLogoContainer, { order: { xs: 1 }, cursor: "pointer", onClick: tryReset }, theme.id === 'light' ? React.createElement(LogoDark, null) : React.createElement(LogoLight, null))));
};
