var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// import { selectTargetTokenAmount } from '../../../features/__common__/selectors/tokens'
// import { setTargetTokenAmount } from '../../../features/__common__/slice'
import { OInput, OSkeleton } from '@ondefy/ondefy-ui';
import React, { useEffect } from 'react';
import { useInputNumberFormatted } from '../../../../../__common__/numbo/hooks/use-input-number-formatted';
import { selectBestSwapRate, selectSwapRates } from '../../../../selectors';
import { useAppSelector } from '../../../../../../redux/hooks';
import { EEntityStatus } from '../../../../../../redux/redux-entity';
import { formatTargetTokenAmount } from '../../../../transducers/format-target-token-amount';
export const TargetTokenAmountInput = () => {
    // const dispatch = useAppDispatch()
    // const value = useAppSelector(selectTargetTokenAmount)
    const swapRates = useAppSelector(selectSwapRates);
    const bestRate = useAppSelector(selectBestSwapRate);
    const value = formatTargetTokenAmount(bestRate);
    const _a = useInputNumberFormatted(value), { blockChanges } = _a, rest = __rest(_a, ["blockChanges"]);
    useEffect(() => {
        if (swapRates.status === EEntityStatus.loading) {
            rest.onBlur();
        }
    }, [swapRates.status]);
    if (swapRates.status === EEntityStatus.loading) {
        return (React.createElement(OSkeleton, { height: 40, width: 131 }));
    }
    return (React.createElement(OInput, Object.assign({ zIndex: "2", 
        // it should be number of mobile
        size: "lg", height: "40px !important", variant: "borderless", placeholder: "" }, rest, { 
        // onChange={(value: string) => dispatch(setTargetTokenAmount(value))}
        onChange: () => { } })));
};
