import { makeSelectedFiatSelector, selectTransakFiatCurrencies } from '../../../../selectors';
import { useIframeUrlColors } from '../../../../../iframe/hooks/use-iframe-url-colors';
import { EEntityStatus } from '../../../../../../redux/redux-entity';
import { usePaperCurrencyList } from '../../../../papers/paper-currency-list/__hooks__/use-paper-currency-list';
import { SIconNetTag } from '../../../../../__common__/styled/s-icon-net-tag';
import { OButton, OIcon, OIconFiat, OMedia, OSkeleton, SBox, SFlexbox, SText } from '@ondefy/ondefy-ui';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../../redux/hooks';
export const SelectCurrency = () => {
    const selectedFiatSelector = useMemo(makeSelectedFiatSelector, []);
    const selectedFiatCurrency = useAppSelector(selectedFiatSelector);
    const transakFiatCurrencies = useAppSelector(selectTransakFiatCurrencies);
    const [urlSearchParams] = useSearchParams();
    const paper = usePaperCurrencyList();
    const { colorPrimary } = useIframeUrlColors();
    const { status, value } = transakFiatCurrencies;
    const isLoading = status === EEntityStatus.loading || status === EEntityStatus.idle;
    const isLoaded = !!value;
    if (isLoading && !isLoaded) {
        return (React.createElement(OSkeleton, { width: 122, height: 40 }));
    }
    return (React.createElement(SFlexbox, { position: "relative", alignItems: "center", opacity: urlSearchParams.get('fiatCurrency') ? 0.5 : 1, pointerEvents: urlSearchParams.get('fiatCurrency') ? 'none' : 'all' },
        selectedFiatCurrency && (React.createElement(SIconNetTag, { label: React.createElement(SBox, { position: "relative", left: "-2px", top: "2px" },
                React.createElement(OIconFiat, { logoSVG: selectedFiatCurrency === null || selectedFiatCurrency === void 0 ? void 0 : selectedFiatCurrency.logoSVG })), variant: "quaternary" })),
        React.createElement(OButton, { disabled: status === EEntityStatus.error, id: paper.id, bg: "bg2", iconRight: React.createElement(OIcon, { name: "down", 
                // @ts-ignore
                color: colorPrimary || 'accent' }), variant: "secondary", onClick: () => {
                paper.setIsOpened(!paper.isOpened);
            } },
            React.createElement(OMedia, { alignItems: "center" },
                React.createElement(SBox, null,
                    React.createElement(SText, { fontWeight: "semibold", color: "inherit" }, status === EEntityStatus.error
                        ? 'N/A'
                        : (selectedFiatCurrency === null || selectedFiatCurrency === void 0 ? void 0 : selectedFiatCurrency.symbol) || (urlSearchParams.get('fiatCurrency') ? 'N/A' : 'Select Currency')))))));
};
