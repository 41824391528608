import { SBox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { selectNetworkIdTargetFiat, selectTargetTokenFiat } from '../../selectors';
import { OPaper } from '../../../__common__/components/o-paper';
import { useAppSelector } from '../../../../redux/hooks';
import { usePaperTransactionDescription } from './__hooks__/use-paper-transaction-description';
export const PaperTransactionDescription = () => {
    const paper = usePaperTransactionDescription();
    const selectedToken = useAppSelector(selectTargetTokenFiat);
    const networkId = useAppSelector(selectNetworkIdTargetFiat);
    return (React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom" }),
        React.createElement(SBox, { p: "12px" },
            React.createElement(SText, { type: "p" },
                "You will be prompted to buy a corresponding amount of USDC token from Transak. By approving our conditions and signing a permit message, you then allow us to automatically swap your purchased USDCs to",
                ' ', selectedToken === null || selectedToken === void 0 ? void 0 :
                selectedToken.symbol,
                " on Polygon network."),
            React.createElement(SText, { type: "p" },
                "The displayed rate gives an estimation of the ", selectedToken === null || selectedToken === void 0 ? void 0 :
                selectedToken.symbol,
                " amount you will receive. The final amount may vary depending on ", selectedToken === null || selectedToken === void 0 ? void 0 :
                selectedToken.symbol,
                " price at the time the USDCs will arrive in your wallet."),
            React.createElement(SText, { type: "p" }, "Your tokens should arrive in your wallet within 2 hours. This excludes the possible delay related to ID verification by Transak."),
            React.createElement(SText, { type: "p", mb: "0" }, networkId === 1
                ? "Swap transaction gas fees on Ethereum network are paid by Ondefy and deduced from user's USDC balance. An estimation of swap transaction gas fees is initially computed for information purpose, and then re-computed right before the swap execution."
                : 'Ondefy charges a fee of 1 USDC per order, which includes Polygon network fees.'))));
};
