var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getPublicClient } from '../../__common__/web3/get-public-client';
import { ERC20_ABI } from '../constants';
// Reads the allowance of an ERC20 token
export const getAllowance = ({ userAddress, spenderAddress, networkId, tokenSourceAddress }) => __awaiter(void 0, void 0, void 0, function* () {
    const client = getPublicClient(networkId);
    // const etherContract = getNewContract(tokenSourceAddress, ERC20_ABI, provider)
    //
    // console.log({
    //   address: tokenSourceAddress,
    //   abi: ERC20_ABI,
    //   functionName: 'allowance',
    //   args: [userAddress, spenderAddress]
    // })
    return yield client
        .readContract({
        address: tokenSourceAddress,
        abi: ERC20_ABI,
        functionName: 'allowance',
        args: [userAddress, spenderAddress]
    })
        .then(res => res === null || res === void 0 ? void 0 : res.toString());
});
