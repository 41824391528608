import _ from 'lodash/fp';
import { NETWORK_NAME_BY_ID } from '../../../constants/networks';
// TODO: write unit tests
// TODO: write unit tests
// TODO: write unit tests
export const getUserAssetsByNetwork = (temporaryNetworkId, userWallet) => {
    return temporaryNetworkId
        ? _.path(['entities', NETWORK_NAME_BY_ID[temporaryNetworkId], 'assets'], userWallet.value)
        : _.compose(_.flatten, _.map(_.path('assets')), _.flatten, _.values, _.path(['value', 'entities']))(userWallet);
};
