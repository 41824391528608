import { NETWORKS_DATA } from '../constants/networks';
import _ from 'lodash/fp';
export const filterTokens = (networkId, tokens, searchValue) => {
    var _a, _b;
    if (!(tokens === null || tokens === void 0 ? void 0 : tokens.value)) {
        return [];
    }
    const sortByPrice = _.compose(_.reverse, _.sortBy(_.path('price')));
    if (!searchValue) {
        if (networkId) {
            return sortByPrice(tokens.value[(_a = NETWORKS_DATA[networkId]) === null || _a === void 0 ? void 0 : _a.id]) || [];
        }
        return _.compose(sortByPrice, _.flatten, _.values)(tokens.value);
    }
    const str = searchValue.toLowerCase();
    const filter = _.filter((c) => { var _a, _b; return c.address === str || ((_a = c.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(str)) || ((_b = c.symbol) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(str)); });
    if (networkId) {
        return sortByPrice(filter(tokens.value[(_b = NETWORKS_DATA[networkId]) === null || _b === void 0 ? void 0 : _b.id]));
    }
    return _.compose(sortByPrice, filter, _.flatten, _.values)(tokens.value);
};
