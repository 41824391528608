export const getFormattedSelectedPair = ({ selectedTokenId }) => {
    const url = selectedTokenId ? `${process.env.FRONTEND_SERVER_URL}?tokenId=${selectedTokenId}` : '';
    // const urlShort = selectedTokenId ? `tokenId=${selectedTokenId}` : '';
    /*
    if (selectedFiat && url) {
      url += `&fiat=${selectedFiat}`;
      urlShort += `&fiat=${selectedFiat}`;
    }
    */
    return url;
};
