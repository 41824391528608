import { OIconNet, OMedia, OSvgIcon } from '@ondefy/ondefy-ui';
import _ from 'lodash/fp';
import React from 'react';
import { ENV } from '../../../../../env';
import { NETWORKS_DATA } from '../../../../__common__/constants/networks';
import { useGetTokenContracts } from '../../../../__common__/hooks/use-get-token-contracts';
import { NumboLabel } from '../../../../__common__/numbo/components/numbo-label';
import { useGasFee } from '../../../hooks/use-gas-fee';
import { selectBestSwapRate, selectNetworkIdTargetSwap, selectZyFiGeneralTx } from '../../../selectors';
import { useAppSelector } from '../../../../../redux/hooks';
import { ZyfiTxFee } from '../zyfi-tx-fee';
import { InfoBlock } from './__components__/__shared__/info-block';
import { Slippage } from './__components__/3-slippage/slippage';
import { Swapper } from './__components__/1-swapper/swapper';
import { TransactionLine } from './__components__/2-transaction-lines/transaction-line';
export const TransactionDetails = () => {
    var _a;
    const bestRate = useAppSelector(selectBestSwapRate);
    const targetNetworkId = useAppSelector(selectNetworkIdTargetSwap);
    const generalTxZyfi = useAppSelector(selectZyFiGeneralTx);
    useGetTokenContracts();
    const networkName = _.path([targetNetworkId, 'name'], NETWORKS_DATA);
    const networkKey = _.path([targetNetworkId, 'id'], NETWORKS_DATA);
    let gasFee;
    if (ENV.isOndefy()) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        gasFee = useGasFee();
    }
    if (!bestRate) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Swapper, null),
        React.createElement(InfoBlock, null,
            React.createElement(TransactionLine, { icon: React.createElement(OIconNet, { network: bestRate.fromNetwork, coinImage: bestRate.fromLogo, coinName: bestRate.fromSymbol }), value: React.createElement(React.Fragment, null,
                    React.createElement(NumboLabel, { label: _.path('fromAmountParsed', bestRate), groupZeros: 3, toFixed: 4 }),
                    ' ',
                    _.path('fromSymbol', bestRate)), description: ENV.isOndefy() ? `on ${networkName}` : '', showVerticalGuide: true }),
            React.createElement(TransactionLine, { icon: React.createElement(OMedia, { height: "24px", width: "24px", alignItems: "center", justifyContent: "center" },
                    React.createElement(OSvgIcon, { name: networkKey, size: 20 })), loading: ENV.isZyfi() ? generalTxZyfi.isLoading : gasFee.isLoading || gasFee.isLoading, value: ENV.isZyfi() ? React.createElement(ZyfiTxFee, { showIncludingGasCost: true }) : ((_a = gasFee.value) === null || _a === void 0 ? void 0 : _a.feeFormatted) || 'N/A', description: ENV.isOndefy() ? `gas fees on ${networkName}` : '', showVerticalGuide: true }),
            React.createElement(TransactionLine, { icon: React.createElement(OIconNet, { network: bestRate.toNetwork, coinImage: bestRate.toLogo, coinName: bestRate.toSymbol }), value: React.createElement(React.Fragment, null,
                    React.createElement(NumboLabel, { label: _.path('toAmountParsed', bestRate), groupZeros: 3, toFixed: 4 }),
                    ' ',
                    _.path('toSymbol', bestRate)), description: ENV.isOndefy() ? `on ${networkName}` : '' })),
        React.createElement(Slippage, null)));
};
