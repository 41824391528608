import { OMedia, styled } from '@ondefy/ondefy-ui';
export const SPoweredBy = styled(OMedia) `
  align-items: center;
  justify-content: center;
  padding-top: 6px;
  overflow: hidden;

  small {
    opacity: 0.3;
    position: relative;

    svg {
      width: 14px;
      height: 14px;
      position: relative;
      top: 2px;
    }
  }
`;
