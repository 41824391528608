import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { apirxBlockNative } from '../../../__common__/api/services/api-block-native';
import { NETWORKS_DATA } from '../../../__common__/constants/networks';
import _ from 'lodash/fp';
import { Numbo } from '../../../__common__/numbo/transducers';
import { getPublicClient } from '../../../__common__/web3/get-public-client';
export function getGasPriceGweiRx(networkId) {
    if (NETWORKS_DATA[networkId].supportsEIP1559) {
        return apirxBlockNative
            .get('/gasprices/blockprices', {
            confidenceLevels: 99,
            withBaseFees: false,
            chainId: networkId
        })
            .pipe(map(_.path(['blockPrices', 0, 'estimatedPrices', 0, 'price'])));
    }
    else {
        const client = getPublicClient(networkId);
        return from(client.getGasPrice().then(res => Number(Numbo.formatUnits(res, 'gwei'))));
    }
}
