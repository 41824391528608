import { SBox, styled } from '@ondefy/ondefy-ui';
export const STransactionLine = styled(SBox) `
  display: flex;
  align-items: center;
  height: 60px;
  position: relative;

  .transaction__dots {
    position: absolute;
    top: 45px;
    left: 10px;
    overflow: hidden;
    height: 30px;
  }
`;
