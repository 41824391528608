/*
 * STATE DEFAULT
 * */
import { ReduxEntity } from '../../redux/redux-entity';
import { APP_MODE, EPages } from './constants';
export const initialState = {
    isIframe: false,
    appMode: APP_MODE.buy,
    page: EPages.init,
    // common entity
    tokenContracts: ReduxEntity.init(),
    tokenContractsAssoc: undefined,
    searchTokenValue: '',
    temporaryNetworkId: undefined
};
