/*
 * @example
 * action$.pipe(
 *    ofType(TYPE.GET_ADDITIONS_SUCCESS),
 *    withLatestFrom(ofValue(state$, makeLastStreamDateSelector())),
 * */
import _ from 'lodash/fp';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { reject } from './reject';
export const ofValue = (state$, selector, composedKey, logging) => state$.pipe(map(selector), composedKey
    ? distinctUntilChanged((prev, next) => {
        return _.isEqual(_.pick(composedKey, prev), _.pick(composedKey, next));
    })
    : distinctUntilChanged(_.isEqual), reject(_.isNil));
