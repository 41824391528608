import { OMedia, OSvgIcon, SText, OTag } from '@ondefy/ondefy-ui';
import SvgDone from '@ondefy/ondefy-ui/dist/assets/svg/done.svg';
import _ from 'lodash/fp';
import React, { useMemo } from 'react';
import { selectTokenContracts } from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { SNetworkLine } from './__styled__/s-network-line';
import { setTemporaryNetworkId } from '../../slice';
import { getTokenCountForNetwork } from './transducers/get-token-count-for-network';
export const ONetworkLine = ({ selected, network, displayTokenCount, onSelect }) => {
    const dispatch = useAppDispatch();
    const tokenContracts = useAppSelector(selectTokenContracts);
    const selectedNetworkId = _.path('networkId', network);
    const selectedNetwork = network || {
        id: 'ondefy',
        networkId: undefined,
        name: 'All Networks'
    };
    const tokenCount = useMemo(() => {
        return getTokenCountForNetwork(selectedNetworkId, tokenContracts);
    }, [selectedNetworkId, tokenContracts]);
    return (React.createElement(SNetworkLine, { className: selected && 'selected', onClick: () => {
            dispatch(setTemporaryNetworkId(selectedNetwork.networkId));
            onSelect();
        } },
        React.createElement(OMedia, { alignItems: "center" },
            React.createElement(OMedia, { alignItems: "center" },
                React.createElement(OSvgIcon, { name: selectedNetwork.id, mr: "7px", color: selectedNetworkId ? undefined : 'accent', size: 18 }),
                React.createElement(SText, { className: "dropdown__item-text", fontWeight: selected ? 'semibold' : 'normal', type: "h5" }, selectedNetwork.name)),
            !!tokenCount && displayTokenCount && (React.createElement("div", { className: "dropdown__item-token-count" },
                React.createElement(OTag, { variant: "secondary", size: "md", label: `${tokenCount} tokens` })))),
        selected && React.createElement(SvgDone, { width: "16px" })));
};
