import _ from 'lodash/fp';
import { Numbo } from '../../__common__/numbo/transducers';
import { selectFeeTokenSend, selectSourceTokeInUserWallet, selectSourceTokenAmount, selectSourceTokenSend } from '../selectors';
// TODO: write unit tests!!!
// TODO: write unit tests!!!
// TODO: write unit tests!!!
export const calculateNewSourceAmount = (value, res) => {
    // @ts-ignore
    const sourceTokenInUserWallet = selectSourceTokeInUserWallet(value);
    // @ts-ignore
    const sourceToken = selectSourceTokenSend(value);
    // @ts-ignore
    const feeToken = selectFeeTokenSend(value);
    // @ts-ignore
    const sourceTokenAmount = selectSourceTokenAmount(value);
    const txFee = Numbo.from(res.feeTokenAmount);
    const maxSourceToken = Numbo.floatToBigNumber(_.path('amount', sourceTokenInUserWallet), sourceToken.decimals);
    const sourceTokenAmountDesired = Numbo.floatToBigNumber(sourceTokenAmount, sourceToken.decimals);
    if (_.isEqual(feeToken, sourceToken)) {
        return {
            shouldSubtract: sourceTokenAmountDesired.add(txFee).gt(maxSourceToken) && maxSourceToken.sub(txFee).gte(0),
            newSourceTokenAmount: Numbo.formatUnits(maxSourceToken.sub(txFee), sourceToken.decimals)
        };
    }
    return {
        shouldSubtract: false,
        newSourceTokenAmount: sourceTokenAmount
    };
};
