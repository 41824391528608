import { ofType } from 'redux-observable';
import { EMPTY, switchMap, mergeMap, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { saveTransaction, saveTransactionComplete } from '../slice';
import { saveTransactionCustom } from '../api/save-transaction-custom';
export const epicCustomSaveTransaction = action$ => {
    return action$.pipe(ofType(saveTransaction.toString()), mergeMap(({ payload }) => {
        // TODO: retry polling
        // TODO: retry polling
        // TODO: retry polling
        return saveTransactionCustom(payload).pipe(switchMap(() => {
            // dispatch pendingTransaction update - remove from array
            return of(saveTransactionComplete(payload));
        }), catchError(() => {
            return EMPTY;
        }));
    }));
};
