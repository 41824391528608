import React from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { InfoTitle } from '../../../__common__/components/info-title';
import { TokenDropdown } from '../../../__common__/components/token-select-dropdown/token-dropdown';
import { ETokenPopupVariant } from '../../../__common__/papers/paper-token-select/constants';
import { usePaperFeeTokenInfo } from '../../papers/paper-fee-token-info/__hooks__/use-paper-fee-token-info';
import { selectFeeTokenSwap } from '../../selectors';
import { SZyfiFeeToken } from './__styled__/s-zyfi-fee-token';
export const ZyfiFeeToken = () => {
    const feeToken = useAppSelector(selectFeeTokenSwap);
    const paperFeeTokenInfo = usePaperFeeTokenInfo();
    return (React.createElement(SZyfiFeeToken, null,
        React.createElement(InfoTitle, { onClick: () => {
                paperFeeTokenInfo.setIsOpened(true);
            }, title: "Fee Token", textType: "small" }),
        React.createElement(TokenDropdown, { selectedToken: feeToken, variant: ETokenPopupVariant.fee })));
};
