import { produce } from 'immer';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
export var EEntityStatus;
(function (EEntityStatus) {
    EEntityStatus["idle"] = "idle";
    EEntityStatus["loading"] = "loading";
    EEntityStatus["error"] = "error";
    EEntityStatus["success"] = "success";
})(EEntityStatus || (EEntityStatus = {}));
export const ReduxEntity = {
    init: (overrides) => {
        return Object.assign({
            status: EEntityStatus.idle,
            isIdle: true,
            isLoading: false,
            isError: false,
            isLoaded: false,
            value: undefined
        }, overrides || {});
    },
    reset: (entity) => {
        const empty = ReduxEntity.init();
        entity.status = empty.status;
        entity.isLoading = empty.isLoading;
        entity.isIdle = empty.isIdle;
        entity.isError = empty.isError;
        entity.isLoaded = empty.isLoaded;
    },
    loading: (entity) => {
        entity.status = EEntityStatus.loading;
        entity.isLoading = true;
        entity.isIdle = false;
        entity.isError = false;
        entity.isLoaded = false;
    },
    error: (entity, { payload }) => {
        entity.status = EEntityStatus.error;
        entity.error = payload;
        entity.isError = true;
        entity.isLoading = false;
        entity.isIdle = false;
        entity.isLoaded = false;
        entity.value = undefined;
    },
    success: (entity, { payload }) => {
        entity.status = EEntityStatus.success;
        entity.error = undefined;
        entity.isLoaded = true;
        entity.isLoading = false;
        entity.isIdle = false;
        entity.isError = false;
        entity.value = payload;
    },
    successPayload: (entity, payload) => {
        return produce(entity, draft => {
            return ReduxEntity.success(draft, { type: '', payload });
        });
    },
    errorPayload: (entity, payload) => {
        return produce(entity, draft => {
            return ReduxEntity.error(draft, { type: '', payload });
        });
    }
};
export const useEntity = ({ queryAction, selector, auto }, deps) => {
    const entity = useAppSelector(selector);
    const dispatch = useAppDispatch();
    // const isMounted = useMountedState()
    // this is for automatic query
    useEffect(() => {
        if (auto === false) {
            return;
        }
        // queryAction should return already completed object with payload and/or meta fields
        // this could be done during hook declaration
        const action = queryAction();
        if (action) {
            setTimeout(() => dispatch(action));
        }
        return () => {
            // If I ever need cancel request on unmount
            // ApiAbort.abort(UNIQUE_REQUEST_ID)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps || []);
    const entityExtended = useMemo(() => (Object.assign(Object.assign({}, entity), { 
        // otherwise you can call this function whenever you wish
        query: () => {
            // queryAction should return already completed object with payload and/or meta fields
            // this could be done during hook declaration
            const action = queryAction();
            if (action) {
                dispatch(action);
            }
        } })), [entity, dispatch, queryAction]);
    return entityExtended;
};
