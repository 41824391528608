import mixpanel from 'mixpanel-browser';
import { AnalyticsEvents } from './constants';
const send = (event, payload) => {
    mixpanel.track(event, payload);
};
export const Analytics = {
    walletConnected: ({ walletType }) => send(AnalyticsEvents.walletConnected),
    permitSigned: () => send(AnalyticsEvents.permitSigned),
    transakLoaded: () => send(AnalyticsEvents.transakLoaded),
    transakCompleted: () => send(AnalyticsEvents.transakCompleted),
    transactionCompleted: () => send(AnalyticsEvents.transactionCompleted),
    widgetLoaded: () => send(AnalyticsEvents.widgetLoaded),
    appOpened: () => send(AnalyticsEvents.appOpened),
    // unused
    transactionLoading: () => send(AnalyticsEvents.transactionLoading),
    buttonLoaded: () => send(AnalyticsEvents.buttonLoaded),
    buttonTriggered: () => send(AnalyticsEvents.buttonTriggered),
    // unused swap
    swapButtonTriggered: () => send(AnalyticsEvents.swapButtonTriggered),
    customButtonTriggered: () => send(AnalyticsEvents.customButtonTriggered),
    sendButtonTriggered: () => send(AnalyticsEvents.sendButtonTriggered),
    buyButtonTriggered: () => send(AnalyticsEvents.buyButtonTriggered),
    tokenFromSelected: () => send(AnalyticsEvents.tokenFromSelected),
    tokenToSelected: () => send(AnalyticsEvents.tokenToSelected),
    tokenFeeSelected: () => send(AnalyticsEvents.tokenFeeSelected),
    dexSelectionOpened: () => send(AnalyticsEvents.dexSelectionOpened),
    continueTriggered: () => send(AnalyticsEvents.continueTriggered),
    swapTriggered: () => send(AnalyticsEvents.swapTriggered),
    completeTriggered: () => send(AnalyticsEvents.completeTriggered)
};
