import _ from 'lodash/fp';
export const normalizeUserWalletResponse = (assets) => {
    const normalized = {
        entities: {},
        ids: []
    };
    normalized.ids = _.map(_.path('cgKey'), assets);
    const extendedAssets = _.map(asset => {
        return Object.assign(Object.assign({}, asset), { assetsAssoc: _.indexBy('id', asset.assets) });
    }, assets);
    normalized.entities = _.indexBy('cgKey', extendedAssets);
    return normalized;
};
