import { SBox, styled } from '@ondefy/ondefy-ui';
import { css } from 'styled-components';
export const STokenLine = styled(SBox) `
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: 67px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;

  ${p => p.disabled &&
    css `
      opacity: 0.4;
    `};

  &:hover {
    background-color: ${p => p.disabled ? undefined : p.theme.id === 'light' ? p.theme.colors.bg6 : p.theme.colors.hoverTransparent};
  }
  &:active {
    background-color: ${p => p.disabled ? undefined : p.theme.id === 'light' ? p.theme.colors.bg6 : p.theme.colors.hoverTransparent};
  }

  &.token__selected {
    background-color: ${p => (p.theme.id === 'light' ? p.theme.colors.bg6 : p.theme.colors.hoverTransparent)};
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  .token__title {
    display: inline-flex;
  }

  .token__name {
    max-width: 150px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 4px;
    height: 18px;
  }

  .token-line__tooltip .token-line__mask-transak-wrap {
    display: block;
    width: 20px;
    height: 30px;
  }

  .token-line__mask-transak {
    clip: rect(0px, 12px, 12px, 0px);
  }

  .token-line__galxe {
    a > div {
      display: flex;
      align-items: center;
    }

    a span {
      font-size: 10px;
      width: 75px;
      display: flex;
    }
  }
`;
