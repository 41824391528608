import { EBreakpoints, styled } from '@ondefy/ondefy-ui';
export const SExchangeRates = styled.div `
  small + small {
    position: relative;
    top: 2px;
  }

  .exchange__label {
    max-width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1;
    margin-bottom: 0;
  }

  @media (max-width: ${EBreakpoints.sm}) {
    .exchange__label {
      max-width: 140px;
    }
  }
`;
