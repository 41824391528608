import { of } from 'rxjs';
import { getKyberSwapDataRx } from '../../__common__/api/integrations/kyberswap';
import { getOdosSwapDataRx } from '../../__common__/api/integrations/odos';
import { getPancakeswapSwapDataRx } from '../../__common__/api/integrations/pancakeswap';
import { ESwappers, NATIVE_ADDRESS } from '../../__common__/constants/contracts';
import { get0xSwapDataRx } from '../../__common__/api/integrations/0x';
import { get1inchSwapDataRx } from '../../__common__/api/integrations/1inch';
import { getParaswapSwapDataRx } from '../../__common__/api/integrations/paraswap';
import { getLiFiSwapDataRx } from '../../__common__/api/integrations/lifi';
// import {getK}
// import {getPan}
export const getSwapperRateRx = ({ swapperIndex, networkId, userAddress, srcToken, destToken, amount, slippage }) => {
    const srcTokenAddress = srcToken.isNative ? NATIVE_ADDRESS : srcToken.address;
    const destTokenAddress = destToken.isNative ? NATIVE_ADDRESS : destToken.address;
    // const amountWithDigits = amount + '0'.repeat(_.path('decimals', srcToken))
    // console.log('FETCHING RATE', networkId, amount, ondefyFee);
    switch (swapperIndex) {
        case ESwappers.ZERO_X_SWAPPER_IDX:
            return get0xSwapDataRx({
                networkId,
                usingOndefyRouter: false,
                userAddress,
                srcToken: srcTokenAddress,
                destToken: destTokenAddress,
                amount,
                slippage
            });
        case ESwappers.ONE_INCH_SWAPPER_IDX:
            return get1inchSwapDataRx({
                networkId,
                usingOndefyRouter: false,
                fromAddress: userAddress,
                destReceiver: userAddress,
                srcToken: srcTokenAddress,
                destToken: destTokenAddress,
                amount,
                slippage
            });
        case ESwappers.PARASWAP_SWAPPER_IDX:
            return getParaswapSwapDataRx({
                networkId,
                usingOndefyRouter: false,
                userAddress,
                receiver: userAddress,
                srcToken: srcTokenAddress,
                destToken: destTokenAddress,
                amount,
                slippage,
                srcDecimals: srcToken.decimals,
                destDecimals: destToken.decimals
            });
        case ESwappers.LIFI_SWAPPER_IDX:
            return getLiFiSwapDataRx({
                networkId,
                usingOndefyRouter: false,
                fromAddress: userAddress,
                destReceiver: userAddress,
                srcToken: srcTokenAddress,
                destToken: destTokenAddress,
                amount,
                slippage
            });
        case ESwappers.PANCAKESWAP_SWAPPER_IDX:
            return getPancakeswapSwapDataRx({
                networkId,
                userAddress,
                srcToken: srcTokenAddress,
                destToken: destTokenAddress,
                amount,
                // TODO: ask Clement about fee?
                // TODO: ask Clement about fee?
                // TODO: ask Clement about fee?
                // ondefyFee,
                slippage
            });
        case ESwappers.KYBERSWAP_SWAPPER_IDX:
            return getKyberSwapDataRx({
                networkId,
                usingOndefyRouter: false,
                userAddress,
                srcToken: srcTokenAddress,
                destToken: destTokenAddress,
                amount,
                // ondefyFee,
                slippage
            });
        case ESwappers.ODOS_SWAPPER_IDX:
            return getOdosSwapDataRx({
                networkId,
                usingOndefyRouter: false,
                fromAddress: userAddress,
                destReceiver: userAddress,
                srcToken: srcTokenAddress,
                destToken: destTokenAddress,
                amount,
                slippage
            });
        default:
            return of(null);
    }
};
