// import store from '../../../redux/store';
import { ofType } from 'redux-observable';
import { of, switchMap, takeUntil, timer, tap } from 'rxjs';
import { catchError, expand, map, filter, takeWhile } from 'rxjs/operators';
import { Analytics } from '../../analytics';
import { resetUserWalletCache } from '../../wallet/api/get-user-wallet';
import { checkOrder } from '../api/check-order';
import { ORDER_RAMP_STATUS_FAILED, ORDER_RAMP_STATUS_FULFILLED, ORDER_RAMP_STATUS_PENDING } from '../constants/order-ramp-status';
import { ORDER_SWAP_STATUS_ABORTED, ORDER_SWAP_STATUS_EXPIRED, ORDER_SWAP_STATUS_FULFILLED } from '../constants/order-swap-status';
import { selectPartnerOrderId } from '../selectors';
import { getTransactionStatus, getTransactionStatusRejected, getTransactionStatusFullfilled, getTransactionStatusPending, reset
// selectPage,
// setPage,
 } from '../slice';
export const epicTransactionPolling = (action$, state$) => action$.pipe(ofType(getTransactionStatus.toString()), switchMap(() => {
    const POLL = 10000;
    const { value } = state$;
    const partnerOrderId = selectPartnerOrderId(value);
    const check = () => checkOrder(partnerOrderId).pipe(map(res => {
        const { swapStatus, rampStatus, isTransakSupported } = res;
        if (isTransakSupported) {
            if (
            // 1.
            rampStatus === ORDER_RAMP_STATUS_FULFILLED) {
                // console.log('stop polling it failed');
                // @ts-ignore
                return getTransactionStatusFullfilled(res);
            }
        }
        else {
            if (
            // 1.
            rampStatus === ORDER_RAMP_STATUS_FAILED ||
                // 2.
                swapStatus === ORDER_SWAP_STATUS_ABORTED ||
                // 3.
                (rampStatus !== ORDER_RAMP_STATUS_PENDING && swapStatus === ORDER_SWAP_STATUS_EXPIRED)) {
                // console.log('stop polling it failed');
                // @ts-ignore
                return getTransactionStatusFullfilled(res);
            }
        }
        // @ts-ignore
        return res.swapStatus === ORDER_SWAP_STATUS_FULFILLED
            ? getTransactionStatusFullfilled(res)
            : getTransactionStatusPending(res);
    }), catchError(() => of(getTransactionStatusRejected())));
    return check().pipe(expand(() => timer(POLL).pipe(switchMap(() => check()))), takeUntil(action$.pipe(ofType(reset.toString()))), takeWhile(action => {
        const { swapStatus, rampStatus, isTransakSupported } = action.payload || {};
        if (rampStatus === ORDER_RAMP_STATUS_FAILED) {
            // stop polling
            return false;
        }
        if (isTransakSupported) {
            return rampStatus !== ORDER_RAMP_STATUS_FULFILLED;
        }
        if (swapStatus === ORDER_SWAP_STATUS_ABORTED) {
            // stop polling
            return false;
        }
        if (rampStatus !== ORDER_RAMP_STATUS_PENDING && swapStatus === ORDER_SWAP_STATUS_EXPIRED) {
            // stop polling
            return false;
        }
        return swapStatus !== ORDER_SWAP_STATUS_FULFILLED;
    }, true));
}), filter(({ type }) => type !== getTransactionStatus.toString()), tap(v => {
    if (v.type === getTransactionStatusFullfilled.toString()) {
        Analytics.transactionCompleted();
        resetUserWalletCache();
    }
}), catchError(() => {
    // console.log(e);
    return of(getTransactionStatusRejected());
}));
