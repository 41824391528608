var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ENV } from '../../../../../env';
import { getNewWeb3Provider } from '../../../../__common__/web3/get-new-web3-provider';
// TODO: defined type for provider
export const getSignature = (params, provider) => __awaiter(void 0, void 0, void 0, function* () {
    let signature;
    const web3Provider = yield getNewWeb3Provider(provider, params.networkId);
    const signer = ENV.isZyfi() ? web3Provider.getSigner() : web3Provider;
    // @ts-ignore
    if (signer._signTypedData) {
        // @ts-ignore
        signature = yield signer._signTypedData(params.domain, params.types, params.msg);
    }
    else {
        // TODO: should I do
        const accounts = yield provider.request({
            method: 'eth_requestAccounts'
        });
        const [account] = accounts;
        // TODO: should I do like this?
        // const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' })
        signature = yield signer.signTypedData({
            domain: params.domain,
            types: params.types,
            message: params.msg,
            primaryType: params.primaryType,
            account
        });
    }
    return signature;
});
