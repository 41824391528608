import { Numbo } from '../../__common__/numbo/transducers';
export const getGasFeesConfig = (feeData, networkId) => {
    const prices = feeData.blockPrices[0].estimatedPrices;
    const getConfigForIndex = (index) => {
        return {
            // polgyon and ethereum use not gasPrice but maxFeePerGas and maxPriorityFeePerGas
            maxFeePerGas: Numbo.parseUnits(prices[index].maxFeePerGas.toString(), 'gwei').toString(),
            maxPriorityFeePerGas: Numbo.parseUnits(prices[index].maxPriorityFeePerGas.toString(), 'gwei').toString(),
            gasPrice: Numbo.parseUnits(prices[index].price.toString(), 'gwei').toString()
        };
    };
    return {
        networkId,
        slow: getConfigForIndex(0),
        normal: getConfigForIndex(1),
        fast: getConfigForIndex(2)
    };
};
