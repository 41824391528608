import { SBox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { OPaper } from '../../../__common__/components/o-paper';
import { usePaperGasFuelDescription } from './__hooks__/use-paper-gas-fuel-description';
export const PaperGasFuelDescription = () => {
    const paper = usePaperGasFuelDescription();
    return (React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom", title: "What is Gas Refuel?" }),
        React.createElement(SBox, { p: "12px" },
            React.createElement(SText, { type: "p" }, "Supported for orders involving a swap, gas refuel enables you to receive additionnally some amount of gas token in your wallet, in order to cover gas fees for your future transactions. The amount of received gas token is deduced from your purchased token amount, at the current market price."))));
};
