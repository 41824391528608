import { toast } from '@ondefy/ondefy-ui';
import { ofType } from 'redux-observable';
import { EMPTY, from, map, of, switchMap, takeUntil } from 'rxjs';
import { catchError, debounceTime, tap } from 'rxjs/operators';
import { ENetworkIdByName, ENetworkNames, NETWORK_NAME_BY_ID, NETWORKS_DATA } from '../../../__common__/constants/networks';
import { waitForState } from '../../../__common__/rxjs-utils/wait-for-state';
import { selectTokenContractsAssoc } from '../../../__common__/selectors';
import { setPage } from '../../../__common__/slice';
import { selectUserAddress } from '../../../wallet/selectors';
import { getGasFeeEstimate } from '../../api/_ondefy_/get-gas-fee-estimate';
import { TX_SPEED_DEFAULT } from '../../constants';
import { getRawTx } from '../../frp-transducers';
import { selectBestSwapRate, selectGasFees, selectNetworkIdSourceSwap, selectSourceTokenSwap } from '../../selectors';
import { getGasFee, getGasFeeFullfilled, getGasFeeRejected } from '../../slice';
import { getGasFeeUsd } from '../../transducers/get-gas-fee-usd';
import _ from 'lodash/fp';
import { Numbo } from '../../../__common__/numbo/transducers';
const getGasToken = (state$) => {
    const { value } = state$;
    const sourceNetworkId = selectNetworkIdSourceSwap(value);
    // const network = NETWORKS_DATA[sourceNetworkId];
    const tokensAssoc = selectTokenContractsAssoc(value);
    const network = NETWORKS_DATA[sourceNetworkId];
    const networkName = NETWORK_NAME_BY_ID[sourceNetworkId];
    const gasToken = _.path([networkName, network.defaultCoinId], tokensAssoc);
    return gasToken;
};
export const epicGetGasFeeFinal = (action$, state$) => {
    return action$.pipe(ofType(getGasFee.toString())).pipe(waitForState(state$, selectBestSwapRate), waitForState(state$, selectUserAddress), waitForState(state$, selectSourceTokenSwap), waitForState(state$, selectGasFees), debounceTime(16), 
    // 1. rawTx
    switchMap(() => {
        const { value } = state$;
        const sourceToken = selectSourceTokenSwap(value);
        const bestRate = selectBestSwapRate(value);
        const userAddress = selectUserAddress(value);
        if (!userAddress || !bestRate.contractAddress || !bestRate.callData || !bestRate.fromAmount || !sourceToken) {
            // TODO if !userAddress - reset everything and return the user back to the home screen
            // TODO if !userAddress - reset everything and return the user back to the home screen
            // TODO if !userAddress - reset everything and return the user back to the home screen
            console.log('Missing data');
            return EMPTY;
        }
        return of(getRawTx(state$));
    }), 
    // 2. gasEstimate
    switchMap((payload) => {
        const { value } = state$;
        const sourceNetworkId = selectNetworkIdSourceSwap(value);
        const gasFeesConfig = selectGasFees(value);
        return from(
        // TODO: 1. instead use our ZyFi api
        // TODO: 1. instead use our ZyFi api
        // TODO: 1. instead use our ZyFi api
        getGasFeeEstimate({
            networkId: sourceNetworkId,
            rawTx: payload,
            gasPriceData: _.path(['value', TX_SPEED_DEFAULT], gasFeesConfig)
        })).pipe(map(Numbo.from), takeUntil(action$.pipe(ofType(setPage.toString()))), 
        // 3. gasFeeInUsd
        switchMap(gasEstimate => {
            const { value } = state$;
            const sourceNetworkId = selectNetworkIdSourceSwap(value);
            const gasToken = getGasToken(state$);
            const gasPrices = _.path('value', selectGasFees(value));
            // TODO: ask Clement what to do with it
            // TODO: ask Clement what to do with it
            // TODO: ask Clement what to do with it
            const txSpeed = TX_SPEED_DEFAULT;
            const tokenAssoc = selectTokenContractsAssoc(value);
            const ethereumNetwork = NETWORKS_DATA[ENetworkIdByName.ethereum];
            const L1GasToken = _.path([ENetworkNames.ethereum, ethereumNetwork.defaultCoinId], tokenAssoc);
            // TODO: 2. rewrite this logic
            const swapGasFeeUsd = getGasFeeUsd({
                networkId: sourceNetworkId,
                gasToken,
                l1GasToken: L1GasToken,
                gasUsage: gasEstimate,
                gasPrices,
                txSpeed
            });
            return of(getGasFeeFullfilled({
                fee: swapGasFeeUsd,
                feeFormatted: Numbo.formatCurrency(swapGasFeeUsd)
            }));
        }), 
        // @ts-ignore
        catchError((err) => {
            var _a, _b, _c, _d;
            console.dir(err);
            let message = (_b = (_a = err === null || err === void 0 ? void 0 : err.error) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.message;
            if (((_d = (_c = err === null || err === void 0 ? void 0 : err.error) === null || _c === void 0 ? void 0 : _c.error) === null || _d === void 0 ? void 0 : _d.code) === -32000) {
                const gasToken = getGasToken(state$);
                message = `Insufficient ${gasToken === null || gasToken === void 0 ? void 0 : gasToken.symbol} balance for gas`;
            }
            return of(getGasFeeRejected({
                message
            }));
        }), 
        // show an error toast
        tap((action) => {
            if (action.type === getGasFeeRejected.toString()) {
                toast.next({
                    variant: 'error',
                    message: action.payload.message
                });
            }
        }));
    }));
};
