// import { Header } from '../header'
import { useIsIframe } from '../../hooks/use-is-iframe';
import { useIsXsScreen } from '../../hooks/use-is-xs-screen';
import { OPaperProvider } from '../o-paper';
import { SWrapper } from './__styled__/s-wrapper';
import React from 'react';
export const GlobalContentWrapper = ({ children }) => {
    const isIframe = useIsIframe();
    const isXsScreen = useIsXsScreen();
    return (React.createElement(OPaperProvider, null,
        React.createElement(SWrapper, { isIframe: isIframe, isXsScreen: isXsScreen }, children)));
};
