import _ from 'lodash/fp';
export const CacheInMemory = {
    get(key) {
        if (this._has(key) && this.isExpired(key)) {
            this.delete(key);
            return;
        }
        const entity = this._cache[key];
        return _.path('value', entity);
    },
    set(key = '', value, options) {
        const { cacheResetId, cacheResetOnLogout } = options || {};
        this._cache[key] = {
            expirationTimestamp: this._getExpirationTimestamp(options),
            value,
            cacheResetOnLogout,
            cacheResetId
        };
    },
    resetOnLogout() {
        this._cache = _.compose(_.omitBy(_.isNil), _.mapValues((item) => (item.cacheResetOnLogout ? undefined : item)))(this._cache);
    },
    resetById(resetId) {
        this._cache = _.compose(_.omitBy(_.isNil), _.mapValues((item) => (resetId === item.cacheResetId ? undefined : item)))(this._cache);
    },
    isExpired(key) {
        if (this._has(key)) {
            const now = this._unix();
            return this._cache[key].expirationTimestamp < now;
        }
        return true;
    },
    delete(key) {
        if (this._has(key)) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete this._cache[key];
        }
    },
    update(key = '', options) {
        const { cacheResetId, cacheResetOnLogout } = options || {};
        this._cache[key] = Object.assign(Object.assign({}, this._cache[key]), { expirationTimestamp: this._getExpirationTimestamp(options), cacheResetId: cacheResetId || this._cache[key].cacheResetId, cacheResetOnLogout: cacheResetOnLogout || this._cache[key].cacheResetOnLogout });
    },
    _cache: {},
    _has(key) {
        return key in this._cache;
    },
    _unix: () => new Date().getTime() / 1000,
    _getExpirationTimestamp: (options = {
        cacheSeconds: undefined,
        cacheMinutes: undefined,
        cacheHours: undefined
    }) => {
        const { cacheSeconds, cacheMinutes, cacheHours } = options;
        let expirationTimestamp;
        const now = CacheInMemory._unix();
        if (cacheSeconds) {
            expirationTimestamp = now + cacheSeconds;
        }
        else if (cacheMinutes) {
            expirationTimestamp = now + cacheMinutes * 60;
        }
        else if (cacheHours) {
            expirationTimestamp = now + cacheHours * 60 * 60;
        }
        else {
            // DEFAULT = now
            expirationTimestamp = now;
        }
        return expirationTimestamp;
    }
};
