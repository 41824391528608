import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { ENV } from '../../../env';
import { EPages } from '../../__common__/constants';
import { ETransactionStatus } from '../../__common__/constants/transaction';
import { setPage } from '../../__common__/slice';
import { unlockTransactionFullfilled } from '../slice';
import { filter } from 'rxjs';
import _ from 'lodash/fp';
export const epicOnUnlockTransactionSuccess = action$ => {
    return action$.pipe(ofType(unlockTransactionFullfilled.toString()), filter(v => {
        return _.pathEq(['payload', 'status'], ETransactionStatus.confirmed)(v);
    }), map(() => {
        return setPage(ENV.isOndefy() ? EPages.swapSubmit : EPages.swapSubmitZyfi);
        // TODO: I have to leave it here???
        // return getAllowance()
    }));
};
