/*
 * SELECTORS
 * */
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash/fp';
import { isNotSupportedZyfiSourceToken } from './transducers/is-not-supported-zyfi-source-token';
import { checkIfEnoughSourceBalance } from './transducers/check-if-enough-source-balance';
import { selectUserWallet } from '../wallet/selectors';
import { getTokenAmountInUsd } from './transducers/get-token-amount-in-usd';
import { checkIfAllExist } from '../__common__/transducers/check-if-all-exist';
import { getTransactionStatus } from './transducers/get-transaction-status';
import { selectTokenContractsAssoc } from '../__common__/selectors';
import { findTokenByIdInAssoc } from '../__common__/transducers/find-token-by-id';
import { sliceNamespace } from './constants';
import { validateInput } from './transducers/validate-input';
import { sourceTokenInUserWallet } from './transducers/source-token-in-user-wallet';
import { checkIfEnoughFeeBalance } from './transducers/check-if-enough-fee-balance';
export const selectSend = (state) => _.path(sliceNamespace, state);
export const selectDestinationAddress = createSelector(selectSend, state => state.destinationAddress);
export const selectZyFiGeneralTx = createSelector(selectSend, state => state.zyfiGeneralTx);
export const selectPendingTransactions = createSelector(selectSend, state => state.pendingTransactions);
export const selectSendTransaction = createSelector(selectSend, state => state.sendTransaction);
export const selectSendTransactionStatus = createSelector(selectSendTransaction, getTransactionStatus);
export const selectIsValidInput = createSelector(selectDestinationAddress, validateInput);
export const selectNetworkIdSourceSend = createSelector(selectSend, state => state.networkIdSourceSend);
export const selectSourceTokenIdSend = createSelector(selectSend, state => state.sourceTokenIdSend);
export const selectSourceTokenSend = createSelector([selectTokenContractsAssoc, selectNetworkIdSourceSend, selectSourceTokenIdSend], findTokenByIdInAssoc);
export const selectSourceTokenAmount = createSelector(selectSend, state => state.sourceTokenAmount);
export const selectSourceTokenValueInUsd = createSelector(selectSourceTokenSend, selectSourceTokenAmount, getTokenAmountInUsd);
export const selectPercentage = createSelector(selectSend, state => state.percentage);
export const selectSourceTokeInUserWallet = createSelector(selectUserWallet, selectNetworkIdSourceSend, selectSourceTokenSend, sourceTokenInUserWallet);
export const selectSourceTokenIdSendIsEthereum = createSelector(selectSourceTokenIdSend, _.eq('ethereum'));
export const selectNetworkIdFeeSend = createSelector(selectSend, state => state.networkIdFeeSend);
export const selectFeeTokenIdSend = createSelector(selectSend, state => state.feeTokenIdSend);
export const selectFeeTokenSend = createSelector([selectTokenContractsAssoc, selectNetworkIdFeeSend, selectFeeTokenIdSend], findTokenByIdInAssoc);
export const selectIsEnoughSourceBalance = createSelector(selectUserWallet, selectSourceTokenSend, selectSourceTokenAmount, selectFeeTokenSend, selectZyFiGeneralTx, checkIfEnoughSourceBalance);
export const selectFeeTokeInUserWallet = createSelector(selectUserWallet, selectNetworkIdFeeSend, selectFeeTokenSend, sourceTokenInUserWallet);
export const selectIsEnoughFeeToken = createSelector(selectZyFiGeneralTx, selectSourceTokenAmount, selectSourceTokeInUserWallet, selectFeeTokeInUserWallet, checkIfEnoughFeeBalance);
export const selectIsAllFilled = createSelector(selectNetworkIdSourceSend, selectNetworkIdFeeSend, selectSourceTokenIdSend, selectFeeTokenSend, selectSourceTokenAmount, selectDestinationAddress, checkIfAllExist);
export const selectIsNotSupportedZyfiSourceToken = createSelector(selectFeeTokenIdSend, isNotSupportedZyfiSourceToken);
