import device from 'current-device';
import { UAParser } from 'ua-parser-js';
import { useMemo } from 'react';
export const useCurrentBrowser = () => {
    return useMemo(() => {
        var _a, _b;
        const parser = new UAParser();
        const { browser, os } = parser.getResult();
        const browserName = (_a = browser.name) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        return {
            isMobile: device.mobile(),
            isDesktop: device.desktop(),
            isChrome: browserName === 'chrome',
            isSafari: browserName === 'safari',
            isFirefox: browserName === 'firefox',
            isBrave: browserName === 'brave',
            browser: browserName,
            os: (_b = os.name) === null || _b === void 0 ? void 0 : _b.toLowerCase()
        };
    }, []);
};
