import { map } from 'rxjs/operators';
import { apirxToken } from '../../../__common__/api/services/api-token';
import { NETWORKS_DATA } from '../../../__common__/constants/networks';
import _ from 'lodash/fp';
// TODO return type
export const getCoinPriceUsdRx = (networkId) => {
    return apirxToken
        .get('/getPrice', {
        coinId: NETWORKS_DATA[networkId].defaultCoinId
    })
        .pipe(map(_.path('price')));
};
