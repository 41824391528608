var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createWeb3Modal, defaultConfig, useWeb3ModalProvider, useWeb3ModalAccount, useWeb3Modal, useDisconnect, useWeb3ModalEvents } from '@web3modal/ethers5/react';
import { useCallback, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { useAppDispatch } from '../../../../redux/hooks';
import { ENetworkIdByName } from '../../../__common__/constants/networks';
import { METAMASK_CONFIGS } from '../../constants/metamask.configs';
import { WALLETS } from '../../constants/wallets';
import { Address } from '../../model.address';
import { resetUserWallet, setIsWalletConnected, setUserAddress, setWalletType } from '../../slice';
import { getSignature } from './utils/get-signature';
import { sendTransaction } from './utils/send-transaction';
// 1. Get projectId
const projectId = process.env.WALLETCONNECT_PROJECT_ID;
// // 2. Set chains
// const mainnet = {
//   chainId: ENetworkIdByName.ethereum,
//   name: 'Ethereum',
//   currency: 'ETH',
//   explorerUrl: 'https://etherscan.io',
//   rpcUrl: process.env.RPC_ETHEREUM!
// }
const zksyncMainnet = {
    chainId: ENetworkIdByName.zksync, // This is a placeholder chain ID for zkSync. Replace with the actual ID.
    name: 'zkSync',
    currency: 'ETH', // zkSync uses ETH for transactions.
    explorerUrl: 'https://zkscan.io',
    rpcUrl: process.env.RPC_ZKSYNC
};
// 3. Create modal
const metadata = {
    name: 'Zyfi',
    description: 'Your portal and SDK to gasless transactions on zkSync',
    url: 'https://zyfi.org', // origin must match your domain & subdomain
    icons: ['https://zyfi.org/']
};
createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    // chains: [mainnet],
    chains: [zksyncMainnet],
    projectId,
    // enableAnalytics: true // Optional - defaults to your Cloud configuration
    // includeWalletIds: [
    //   'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    //   '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709'
    // ]
    featuredWalletIds: [
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709'
    ]
    // excludeWalletIds: [
    //   'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    //   '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709'
    // ]
});
export const useWeb3ModalConnector = () => {
    const dispatch = useAppDispatch();
    const { open } = useWeb3Modal();
    const { disconnect: disconnectWeb3Modal } = useDisconnect();
    const events = useWeb3ModalEvents();
    // const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { address, isConnected } = useWeb3ModalAccount();
    const { walletProvider: provider } = useWeb3ModalProvider();
    useEffect(() => {
        console.log('EVENT:', events);
    }, [events]);
    const prevAddress = usePrevious(address);
    useEffect(() => {
        var _a;
        if (prevAddress && address && prevAddress !== address) {
            resetUserWallet();
            (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.reload();
            return;
        }
        if (address) {
            dispatch(setUserAddress(Address.asString(address)));
            dispatch(setWalletType(WALLETS.web3Modal.key));
            dispatch(setIsWalletConnected(true));
        }
    }, [address, prevAddress]);
    const disconnect = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield disconnectWeb3Modal();
        dispatch(resetUserWallet());
    }), [dispatch]);
    const connect = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isConnected) {
            return;
        }
        try {
            yield open();
        }
        catch (e) {
            console.error(e);
        }
    }), [open, isConnected]);
    const broadcastTransaction = (params, broadcastConfig) => __awaiter(void 0, void 0, void 0, function* () {
        if (!provider) {
            throw new Error('Web3Modal is not initialized');
        }
        try {
            // @ts-ignore
            yield provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${params.networkId.toString(16)}` }]
            });
        }
        catch (err) {
            const error = err;
            if (error.code === 4902) {
                // @ts-ignore
                yield provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [METAMASK_CONFIGS[params.networkId]]
                });
            }
            else {
                throw err;
            }
        }
        return yield sendTransaction(params, provider, broadcastConfig);
    });
    const signMessage = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        if (!provider) {
            throw new Error('Web3Modal is not initialized');
        }
        try {
            // @ts-ignore
            yield provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${params.networkId.toString(16)}` }]
            });
        }
        catch (err) {
            console.error(err);
            const error = err;
            if (error.code === 4902) {
                // @ts-ignore
                yield provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [METAMASK_CONFIGS[params.networkId]]
                });
            }
            else {
                throw err;
            }
        }
        return yield getSignature(params, provider);
    }), [provider]);
    // const addTokenToPlugin = useCallback(
    //   async (params: TTokenToWatchParams) => {
    //     if (!provider) {
    //       throw new Error('Metamask not initialized')
    //     }
    //     try {
    //       await provider.request({
    //         method: 'wallet_switchEthereumChain',
    //         params: [{ chainId: `0x${params.networkId.toString(16)}` }]
    //       })
    //     } catch (err) {
    //       const error = err as IMetamaskError
    //       if (error.code === 4902) {
    //         await provider.request({
    //           method: 'wallet_addEthereumChain',
    //           params: [METAMASK_CONFIGS[params.networkId]]
    //         })
    //       } else {
    //         throw err
    //       }
    //     }
    //
    //     await provider.request({
    //       method: 'wallet_watchAsset',
    //       params: {
    //         type: 'ERC20',
    //         options: {
    //           address: params.address,
    //           symbol: params.symbol,
    //           decimals: params.decimals,
    //           image: params.logoURI
    //         }
    //       }
    //     })
    //   },
    //   [provider]
    // )
    const web3Config = {
        walletType: WALLETS.web3Modal.key,
        isSupportedByBrowser: true,
        connect,
        disconnect,
        broadcastTransaction,
        signMessage
        // addTokenToPlugin
    };
    return web3Config;
};
