import _ from 'lodash/fp';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useMount, useSearchParam } from 'react-use';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setNetworkIdTargetFiat, setTargetTokenIdFiat } from '../../../1 - buy/slice';
import { setNetworkIdTargetCustom, setTargetTokenIdCustom } from '../../../3 - custom/slice';
import { setNetworkIdFeeSwap, setNetworkIdTargetSwap, setTargetTokenIdSwap } from '../../../2 - swap/slice';
import { setNetworkIdFeeSend, setFeeTokenIdSend } from '../../../4 - send/slice';
import { APP_MODE, EPages } from '../../constants';
import { ENetworkIdByName } from '../../constants/networks';
import { selectPage, selectTokenContractsAssoc } from '../../selectors';
import { setAppMode, setIframeMode } from '../../slice';
export const useInitFromUrlZyfi = () => {
    const dispatch = useAppDispatch();
    const tokensAssoc = useAppSelector(selectTokenContractsAssoc);
    const [inited, setInited] = useState(false);
    const page = useAppSelector(selectPage);
    const mode = useSearchParam('mode');
    const networkId = ENetworkIdByName.zksync;
    // const isIframe = useAppSelector(selectIsIframe)
    useEffect(() => {
        if (page === EPages.init) {
            setInited(false);
        }
    }, [page]);
    useMount(() => {
        if (mode) {
            dispatch(setAppMode(mode));
        }
        else {
            // set default app mode to swap
            dispatch(setAppMode(APP_MODE.swap));
        }
        dispatch(setNetworkIdTargetFiat(ENetworkIdByName.ethereum));
        dispatch(setNetworkIdTargetSwap(networkId));
        dispatch(setNetworkIdFeeSwap(networkId));
        dispatch(setNetworkIdTargetCustom(networkId));
        dispatch(setNetworkIdFeeSend(networkId));
    });
    useEffect(() => {
        // 1. Do nothing if not ready
        // TODO: we can't wait so long
        if (inited || !tokensAssoc) {
            return;
        }
        // 2. Do this hook only once and then block
        setInited(true);
        // 3. Parse url query parameters
        const { tokenId } = qs.parse(window.location.search, {
            ignoreQueryPrefix: true
        });
        dispatch(setIframeMode(window.self !== window.top));
        // 8. If token id is also in query parameters
        if (tokenId) {
            // 9. Let's find this token using coingecko response
            const token = _.path([networkId, String(tokenId).toLowerCase()], tokensAssoc);
            if (token) {
                dispatch(setTargetTokenIdFiat(token.id));
                // 10. Do it again for swap mode
                dispatch(setTargetTokenIdSwap(token.id));
                // Do it again for custom mode
                dispatch(setTargetTokenIdCustom(token.id));
                dispatch(setFeeTokenIdSend(token.id));
            }
            else {
                // 11. If we didn't find this token in the provided network we will try to find it in all supported networks and update networkId
                // setTargetTokenFromSupportedNetworks()
                console.info(`didnt find the token: ${tokenId}`);
            }
        }
        // eslint-disable-next-line
    }, [tokensAssoc, page]);
};
