import { SBox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { OPaper } from '../../../__common__/components/o-paper';
import { usePaperSendTokenInfo } from './__hooks__/use-paper-send-token-info';
export const PaperSendTokenInfo = () => {
    const paper = usePaperSendTokenInfo();
    return (React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom", title: "What is Token to pay?" }),
        React.createElement(SBox, { p: "12px" },
            React.createElement(SText, { type: "p" }, "Select the ERC20 token you wish to pay for this transaction gaslessly. When signing the transaction, you will be setting to the Zyfi paymaster only the displayed allowance."))));
};
