import { OIcon, OMedia, OSpinner, OSvgIcon, SFlexbox, SText } from '@ondefy/ondefy-ui';
import _ from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { NETWORKS_DATA } from '../../constants/networks';
import { selectUserAddress } from '../../../wallet/selectors';
import { useAppSelector } from '../../../../redux/hooks';
import { ReduxEntity } from '../../../../redux/redux-entity';
import { OLine } from '../../components/o-line';
import { OPaper } from '../../components/o-paper';
import { LineRamp } from './__components__/line-ramp';
import { LineSwap } from './__components__/line-swap';
import { usePaperHistoryTransactions } from './__hooks__/use-paper-history-transactions';
import { SMyTransactions } from './__styled__/s-my-transactions';
import { getPastTransactions } from './api';
export const PaperHistoryTransactions = () => {
    var _a, _b, _c;
    const paperHistory = usePaperHistoryTransactions();
    const userAddress = useAppSelector(selectUserAddress);
    const [transactions, setTransactions] = useState(ReduxEntity.init());
    useEffect(() => {
        if (paperHistory.isOpened) {
            setTransactions(ReduxEntity.init());
            const sub = getPastTransactions({
                userAddress: userAddress
            }).subscribe({
                next: res => {
                    setTransactions(v => ReduxEntity.successPayload(v, res));
                },
                error: e => {
                    setTransactions(v => ReduxEntity.errorPayload(v, e));
                }
            });
            return () => {
                sub.unsubscribe();
            };
        }
        // eslint-disable-next-line
    }, [paperHistory.isOpened]);
    return (React.createElement(OPaper, Object.assign({}, paperHistory, { appearFrom: "bottom", title: "Transaction history" }),
        React.createElement(SMyTransactions, null,
            React.createElement(React.Fragment, null, (!transactions.value || ((_a = transactions.value) === null || _a === void 0 ? void 0 : _a.length) === 0) && (React.createElement(SFlexbox, { justifyContent: "flex-start", pt: "30px", alignItems: "center", flex: "1", flexDirection: "column" },
                transactions.isLoading && (React.createElement(SFlexbox, { justifyContent: "center", alignItems: "center", flex: "1" },
                    React.createElement(OSpinner, { size: 32 }))),
                transactions.isError && (React.createElement(OMedia, { alignItems: "center", justifyContent: "center" },
                    React.createElement(OIcon, { name: "warning", color: "error", mr: "10px", size: 18 }),
                    React.createElement(SText, { type: "h6", color: "error", mb: "0" }, "Failed to load data. Try again later"))),
                ((_b = transactions.value) === null || _b === void 0 ? void 0 : _b.length) === 0 && (React.createElement(OMedia, { alignItems: "center", justifyContent: "center" },
                    React.createElement(SText, { type: "h6", color: "inherit", mb: "0" }, "No transactions yet.")))))), (_c = transactions.value) === null || _c === void 0 ? void 0 :
            _c.map((o) => {
                return (React.createElement(OLine, { className: "my-transaction__item", key: o.key },
                    React.createElement(OSvgIcon, { name: _.path([o.networkId, 'id'], NETWORKS_DATA), size: 20 }),
                    o.type === 'ramp' && React.createElement(LineRamp, { order: o }),
                    o.type === 'swap' && React.createElement(LineSwap, { order: o })));
            }))));
};
