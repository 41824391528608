import { OInput, SBox } from '@ondefy/ondefy-ui';
import React, { useState } from 'react';
import { OPaper } from '../../components/o-paper';
import { selectIsWeb3Busy } from '../../../wallet/selectors';
import { MainButton } from '../../components/main-button';
import { usePaperConnectionMagicAuth } from './__hooks__/use-paper-connection-magic-auth';
import { useAppSelector } from '../../../../redux/hooks';
import { useWallet } from '../../../wallet/hooks/use-wallet';
import { WALLETS } from '../../../wallet/constants/wallets';
export const PaperConnectionMagicAuth = () => {
    const { connectWallet } = useWallet();
    const [inputValue, setInputValue] = useState('');
    const isWeb3Busy = useAppSelector(selectIsWeb3Busy);
    const connectToMagic = () => {
        connectWallet(WALLETS.magicAuth.key, inputValue).catch(() => { });
    };
    const paper = usePaperConnectionMagicAuth();
    return (React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom", title: "Connect with email" }),
        React.createElement(SBox, { px: "8px" },
            React.createElement(OInput, { mt: "16px", mb: "12px", width: "100%", placeholder: "E-mail...", size: "lg", value: inputValue, onChange: (e) => setInputValue(e), onEnter: connectToMagic })),
        React.createElement(SBox, { px: "8px", pb: "8px" },
            React.createElement(MainButton, { onClick: connectToMagic, loading: isWeb3Busy }, "Log in / Sign up"))));
};
