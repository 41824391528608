import { ofType } from 'redux-observable';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getTransakCryptoCurrencies, getTransakCryptoCurrenciesRejected, getTransakCryptoCurrenciesFulfilled } from '../slice';
import { getTransakCryptoCurrenciesRx } from '../api/integrations/transak';
export const epicGetTransakTokens = (action$, state$) => {
    return action$.pipe(ofType(getTransakCryptoCurrencies.toString()), switchMap(() => {
        return getTransakCryptoCurrenciesRx().pipe(map(v => {
            return getTransakCryptoCurrenciesFulfilled(v);
        }), catchError(e => {
            return of(getTransakCryptoCurrenciesRejected(e));
        }));
    }));
};
