import { toast } from '@ondefy/ondefy-ui';
import { ofType } from 'redux-observable';
import { from, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Web3$ } from '../../__common__/web3/web3-observable';
import { EPages } from '../../__common__/constants';
import { ETransactionStatus, ETransactionType } from '../../__common__/constants/transaction';
import { setPage } from '../../__common__/slice';
import { selectUserAddress } from '../../wallet/selectors';
// import { ETransactionStatus, ETransactionType } from '../constants'
import { selectZyFiGeneralTx, selectNetworkIdTargetCustom, selectTargetTokenCustom } from '../selectors';
import { saveTransaction, customTransaction, customTransactionFullfilled, customTransactionRejected } from '../slice';
export const epicCustomRunTransaction = (action$, state$) => {
    return action$.pipe(ofType(customTransaction.toString()), switchMap(() => {
        const { value } = state$;
        const targetNetworkId = selectNetworkIdTargetCustom(value);
        const generalTx = selectZyFiGeneralTx(value).value;
        const { value: Web3 } = Web3$;
        const transactionPayload = {
            rawTx: generalTx.txData,
            networkId: targetNetworkId
        };
        // console.log('Broadcasting ====> ', transactionPayload)
        return from(Web3.broadcastTransaction(transactionPayload)).pipe(
        // 4. Save transaction to our backend
        switchMap(txHash => {
            const { value } = state$;
            const userAddress = selectUserAddress(value);
            const targetToken = selectTargetTokenCustom(value);
            const submitParamsWithHash = {
                type: ETransactionType.custom,
                userAddress,
                networkId: targetNetworkId,
                // feeRateBps: number
                toTokenId: targetToken.id,
                toTokenAddress: targetToken.address,
                toTokenSymbol: targetToken.symbol,
                toTokenLogoURI: targetToken.logoURI,
                // toTokenAmount: string
                toTokenDecimals: targetToken.decimals,
                toTokenPriceUsd: targetToken.price,
                // volumeUsd: string
                status: ETransactionStatus.pending,
                isHidden: 0,
                timestamp: Math.floor(Date.now() / 1000),
                txHash
            };
            return from([
                customTransactionFullfilled(submitParamsWithHash),
                setPage(EPages.customResult),
                saveTransaction(submitParamsWithHash)
            ]);
        }), catchError(e => {
            var _a;
            console.log(((_a = e.data) === null || _a === void 0 ? void 0 : _a.message) || e.message);
            toast.next({ variant: 'error', message: e.message });
            return of(customTransactionRejected({
                message: 'Transaction failed'
            }));
        }));
    }));
};
