var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ENV } from '../../../../../env';
import { WALLETS } from '../../../../wallet/constants/wallets';
import { useWallet } from '../../../../wallet/hooks/use-wallet';
import { usePaper } from '../../../components/o-paper';
export const usePaperConnection = () => {
    const paper = usePaper('paper-connection');
    const { connectWallet } = useWallet();
    return Object.assign(Object.assign({}, paper), { setIsOpened: (opened) => __awaiter(void 0, void 0, void 0, function* () {
            if (ENV.isZyfi()) {
                return yield connectWallet(WALLETS.web3Modal.key).catch(() => { });
            }
            if (ENV.isOndefy()) {
                paper.setIsOpened(opened);
            }
        }) });
};
