import _ from 'lodash/fp';
import { Numbo } from '../../__common__/numbo/transducers';
import { selectPercentage, selectSourceTokeInUserWallet, selectSourceTokenAmount, selectSourceTokenSwap } from '../selectors';
// TODO: write unit tests!!!
// TODO: write unit tests!!!
// TODO: write unit tests!!!
export const calculateNewSourceAmount = (value, res) => {
    // @ts-ignore
    const percentage = selectPercentage(value);
    // @ts-ignore
    const sourceTokenInUserWallet = selectSourceTokeInUserWallet(value);
    // @ts-ignore
    const sourceTokenAmount = selectSourceTokenAmount(value);
    // @ts-ignore
    const sourceToken = selectSourceTokenSwap(value);
    const sourceDecimals = sourceToken.decimals;
    const sourceAmountInWallet = Numbo.floatToBigNumber(_.path('amount', sourceTokenInUserWallet), sourceDecimals)
        .mul(percentage)
        .div(100);
    const feeTokenAmount = Numbo.from(res.feeTokenAmount);
    const sourceTokenAmountDesired = Numbo.floatToBigNumber(sourceTokenAmount, sourceDecimals);
    const shouldSubtract = sourceTokenAmountDesired.add(feeTokenAmount).gt(sourceAmountInWallet) &&
        sourceAmountInWallet.sub(feeTokenAmount).gt(0);
    const newSourceTokenAmount = shouldSubtract
        ? Numbo.formatUnits(sourceAmountInWallet.sub(feeTokenAmount), sourceDecimals)
        : sourceTokenAmount;
    return {
        shouldSubtract,
        newSourceTokenAmount
    };
};
