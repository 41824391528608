import { SBox, styled } from '@ondefy/ondefy-ui';
import { css } from 'styled-components';
export const SLine = styled(SBox) `
  padding: 0 10px;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;

  border-top: 1px solid ${p => p.theme.colors.bg};

  ${p => p.dropdown &&
    css({
        position: 'relative',
        cursor: 'pointer',
        backgroundColor: p.theme.id === 'light' ? p.theme.colors.bg6 : p.theme.colors.hoverTransparent
    })}

  ${p => p.disabled &&
    css({
        pointerEvents: 'none',
        cursor: 'forbidden'
    })}

  [class*='icon']:not(:last-child) {
    margin-right: 8px;
    width: 16px;
  }
`;
