// https://github.com/vercel/next.js/blob/canary/examples/with-redux/src/features/counter/counterSlice.ts
import { createSlice } from '@reduxjs/toolkit';
// import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
// import localForage from 'localforage'
import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
// import { TAction } from '../../redux/types'
import { sliceNamespace } from './constants';
import { initialState } from './state';
import * as reducers from './reducers';
/*
 * REDUCERS
 * */
export const slice = createSlice({
    name: sliceNamespace,
    initialState,
    reducers
});
/*
 * ACTIONS
 * */
export const { setSourceTokenAmount, setSourceTokenIdSwap, flush, reset, setSlippage, getSwapRates, getSwapRatesFullfilled, getSwapRatesRejected, getGasFees, getGasFeesRejected, getGasFeesFullfilled, setPercentage, setNetworkIdTargetSwap, setNetworkIdSourceSwap, setTargetTokenIdSwap, setSelectedSwapperIndex, getAllowance, getAllowanceRejected, getAllowanceFullfilled, getGasFee, getGasFeeRejected, getGasFeeFullfilled, swapTransaction, swapTransactionRejected, swapTransactionFullfilled, swapTransactionReset, unlockTransaction, unlockTransactionRejected, unlockTransactionFullfilled, unlockTransactionReset, saveTransaction, saveTransactionComplete, updateSwapperRate, updateSwapperRateFullfilled, updateSwapperRateRejected, setIframeMode, getZyfiTokenApprovalTx, getZyfiTokenApprovalTxFullfilled, getZyfiTokenApprovalTxRejected, getZyfiGeneralTx, getZyfiGeneralTxFullfilled, getZyfiGeneralTxRejected, swapTokens, setFeeTokenIdSwap, setNetworkIdFeeSwap, restartBestRatesPolling, swapTransactionRaw, abortPending } = slice.actions;
/*
 * PERSIST CONFIG
 * */
const persistConfig = {
    key: sliceNamespace,
    // storage: localForage,
    storage: sessionStorage,
    stateReconciler: autoMergeLevel2,
    whitelist: [
        'networkIdTargetSwap',
        'networkIdSourceSwap',
        'networkIdFeeSwap',
        'sourceTokenIdSwap',
        'targetTokenIdSwap',
        'feeTokenIdSwap',
        'slippage',
        'swapRates',
        'gasFees',
        'sourceTokenAmount',
        'percentage',
        'selectedSwapperIndex',
        // TODO: sync it with local storage - but only in the end when you make sure that everything else works fine
        'swapTransaction',
        'pendingTransactions',
        'unlockTransaction',
        'isIframe'
        // 'zyfiTokenApprovalTx'
    ]
};
export default persistReducer(persistConfig, slice.reducer);
