// Contracts
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const NATIVE_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const WETH_DEPOSIT_SIG_HASH = '0xd0e30db0';
export const WETH_WITHDRAW_SIG_HASH = '0x2e1a7d4d';
export var ESwappers;
(function (ESwappers) {
    ESwappers[ESwappers["WETH_SWAPPER_IDX"] = -2] = "WETH_SWAPPER_IDX";
    ESwappers[ESwappers["UNKNOWN_SWAPPER_IDX"] = -1] = "UNKNOWN_SWAPPER_IDX";
    ESwappers[ESwappers["ZERO_X_SWAPPER_IDX"] = 0] = "ZERO_X_SWAPPER_IDX";
    ESwappers[ESwappers["ONE_INCH_SWAPPER_IDX"] = 1] = "ONE_INCH_SWAPPER_IDX";
    ESwappers[ESwappers["PARASWAP_SWAPPER_IDX"] = 2] = "PARASWAP_SWAPPER_IDX";
    ESwappers[ESwappers["LIFI_SWAPPER_IDX"] = 3] = "LIFI_SWAPPER_IDX";
    ESwappers[ESwappers["PANCAKESWAP_SWAPPER_IDX"] = 4] = "PANCAKESWAP_SWAPPER_IDX";
    ESwappers[ESwappers["KYBERSWAP_SWAPPER_IDX"] = 5] = "KYBERSWAP_SWAPPER_IDX";
    ESwappers[ESwappers["ODOS_SWAPPER_IDX"] = 6] = "ODOS_SWAPPER_IDX";
})(ESwappers || (ESwappers = {}));
export const SWAPPER_NAME_BY_IDX = {
    [ESwappers.ZERO_X_SWAPPER_IDX]: '0x',
    [ESwappers.ONE_INCH_SWAPPER_IDX]: '1inch',
    [ESwappers.PARASWAP_SWAPPER_IDX]: 'ParaSwap',
    [ESwappers.LIFI_SWAPPER_IDX]: 'LI.FI',
    [ESwappers.PANCAKESWAP_SWAPPER_IDX]: 'PancakeSwap',
    [ESwappers.KYBERSWAP_SWAPPER_IDX]: 'KyberSwap',
    [ESwappers.ODOS_SWAPPER_IDX]: 'Odos'
};
export const DEFAULT_SWAP_SLIPPAGE = 4;
export const MAXIMUM_VALUE_LOSS = 0.1;
