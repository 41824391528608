import { ofType } from 'redux-observable';
import { delay, filter, of, switchMap, takeUntil, zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EPages } from '../../__common__/constants';
import { NETWORKS_DATA } from '../../__common__/constants/networks';
import { repeatSwitch } from '../../__common__/rxjs-utils/repeat-switch';
import { waitForState } from '../../__common__/rxjs-utils/wait-for-state';
import { selectPage, selectTokenContracts, selectTokenContractsAssoc } from '../../__common__/selectors';
import { reset, setAppMode, setPage } from '../../__common__/slice';
import { selectUserAddress } from '../../wallet/selectors';
import { getSwapperRateRx } from '../api/get-swapper-rate';
import { selectBestSwapRate, selectGasFees, selectNetworkIdTargetSwap, selectSlippage, selectSourceTokenAmount, selectSourceTokenAmountWithDecimals, selectSourceTokenSwap, selectTargetTokenSwap } from '../selectors';
import { updateSwapperRate, updateSwapperRateFullfilled, updateSwapperRateRejected } from '../slice';
import { normalizeBestRatesWithGasFees } from '../transducers/normalize-best-rates-with-gas-fees';
export const epicPollingUpdateSelectedSwapper = (action$, state$) => {
    return action$.pipe(ofType(updateSwapperRate.toString()), waitForState(state$, selectTokenContracts), repeatSwitch({
        // TODO: ask Clement if 10 seconds is ok
        // TODO: ask Clement if 10 seconds is ok
        // TODO: ask Clement if 10 seconds is ok
        delay: () => action$.pipe(ofType(
        // wait until the next success getSwapRates action
        updateSwapperRateFullfilled.toString(), 
        // wait until the next error getSwapRates action
        updateSwapperRateRejected.toString()), 
        // then wait for 30 seconds and run the process again
        delay(30000), takeUntil(action$.pipe(ofType(setPage.toString(), reset.toString(), setAppMode.toString())))
        // TODO: takeUntil all the fields are valid
        )
        // count: 3
    }), filter(() => {
        const { value } = state$;
        return selectPage(value) > EPages.init;
    }), switchMap(v => {
        const { value } = state$;
        const networkTargetId = selectNetworkIdTargetSwap(value);
        const tokenSource = selectSourceTokenSwap(value);
        const tokenTarget = selectTargetTokenSwap(value);
        const sourceTokenAmount = selectSourceTokenAmountWithDecimals(value);
        const userAddress = selectUserAddress(value);
        const slippage = selectSlippage(value);
        const bestRate = selectBestSwapRate(value);
        if (!bestRate) {
            return of(reset());
        }
        const params = {
            networkId: networkTargetId,
            userAddress,
            amount: sourceTokenAmount,
            slippage,
            srcToken: tokenSource,
            destToken: tokenTarget
        };
        // TODO: ask Clement what is this logic about
        const { authorizedSwappersFOT, authorizedSwappers } = NETWORKS_DATA[networkTargetId];
        const swappers = tokenSource.feesOnTransfer === 1 || tokenTarget.feesOnTransfer === 1
            ? authorizedSwappersFOT
            : authorizedSwappers;
        const requests = swappers
            .filter(swapperIndex => swapperIndex === bestRate.swapperIdx)
            .map(swapperIndex => {
            return getSwapperRateRx(Object.assign({ swapperIndex }, params));
        });
        // TODO: check with debounce that all parameters have been selected
        return zip(...requests).pipe(
        // TODO: check if gasFees are flushed during new iteration after timeout
        waitForState(state$, selectGasFees, { ignoreSourceCompletion: true }), takeUntil(action$.pipe(ofType(updateSwapperRateRejected.toString()))), switchMap((swapRatesRes) => {
            const { value } = state$;
            const tokensAssoc = selectTokenContractsAssoc(value);
            const { value: gasPrices } = selectGasFees(value);
            const tokenSource = selectSourceTokenSwap(value);
            const sourceTokenAmount = selectSourceTokenAmount(value);
            const formattedResponse = normalizeBestRatesWithGasFees({
                tokensAssoc: tokensAssoc,
                gasPrices: gasPrices,
                networkTargetId,
                tokenTarget,
                tokenSource,
                sourceTokenAmount,
                swapRatesRes
            });
            return of(updateSwapperRateFullfilled(formattedResponse));
        }), catchError(e => {
            return of(updateSwapperRateRejected(e));
        }));
    }));
};
