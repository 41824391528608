import { styled } from '@ondefy/ondefy-ui';
export const SWarningBlock = styled.div `
  background-color: ${p => p.theme.colors.error};
  border-radius: 12.5px;
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
`;
