import { ofType } from 'redux-observable';
import { of, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { showSubtractInfo } from '../epics.taps/show-subtract-info';
import { setSourceTokenAmount, getZyfiGeneralTx, getZyfiGeneralTxFullfilled, getZyfiGeneralTxRejected } from '../slice';
import { calculateNewSourceAmount } from '../transducers/calculate-new-source-amount';
import { waitForState } from '../../__common__/rxjs-utils/wait-for-state';
import { selectTokenContractsAssoc } from '../../__common__/selectors';
import { selectUserAddress, selectUserWallet } from '../../wallet/selectors';
import { generalTx } from '../api/general-tx';
import { getTransferData } from '../api/get-transfer-data';
import { filterValidState } from '../epics.pipes/filter-valid-state';
import { selectDestinationAddress, selectFeeTokenSend, selectNetworkIdFeeSend, selectSourceTokenAmount, selectSourceTokenSend, selectPercentage } from '../selectors';
export const epicSendGetGeneralTx = (action$, state$) => {
    return action$.pipe(ofType(getZyfiGeneralTx.toString()), waitForState(state$, selectTokenContractsAssoc), waitForState(state$, selectDestinationAddress), waitForState(state$, selectNetworkIdFeeSend), waitForState(state$, selectFeeTokenSend), waitForState(state$, selectSourceTokenSend), waitForState(state$, selectUserWallet), filterValidState(state$), switchMap(() => {
        const { value } = state$;
        const feeToken = selectFeeTokenSend(value);
        const sourceToken = selectSourceTokenSend(value);
        const userAddress = selectUserAddress(value);
        const toAddress = selectDestinationAddress(value);
        const sourceTokenAmount = selectSourceTokenAmount(value);
        const sourceTokenDecimals = sourceToken === null || sourceToken === void 0 ? void 0 : sourceToken.decimals;
        let txDataPayload;
        try {
            txDataPayload = getTransferData({
                toAddress: toAddress,
                amount: sourceTokenAmount,
                decimals: sourceTokenDecimals
            });
        }
        catch (e) {
            console.log(e);
            return of(getZyfiGeneralTxRejected(e));
        }
        const generalTxPayload = {
            feeTokenAddress: feeToken.address,
            txData: {
                from: userAddress,
                to: sourceToken.address,
                data: txDataPayload
            }
        };
        return generalTx(generalTxPayload).pipe(switchMap(v => {
            const percentage = selectPercentage(value);
            if (!percentage) {
                // 1. We skip Ethereum source
                // 2. We skip if user didn't click percentage buttons
                return of(getZyfiGeneralTxFullfilled(v));
            }
            const { shouldSubtract, newSourceTokenAmount } = calculateNewSourceAmount(value, v);
            // TODO: subtract higher?
            if (!shouldSubtract || String(newSourceTokenAmount) === String(sourceTokenAmount)) {
                // 3. We skip as it should work fine as we have enough money in source token to pay gas
                // 4. We skip if it was not possible to subtract gas fee from source token
                return of(getZyfiGeneralTxFullfilled(v));
            }
            // update source input field
            return of(setSourceTokenAmount(String(newSourceTokenAmount))).pipe(tap(() => {
                // console.log('Gas fee subtracted from source')
                showSubtractInfo();
            }));
        }), catchError(e => {
            return of(getZyfiGeneralTxRejected(e));
        }));
    }));
};
