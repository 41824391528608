import React, { useContext, useEffect, useRef, useState } from 'react';
import { useClickAway, useMount, useUnmount } from 'react-use';
import { useIsXsScreen } from '../../hooks/use-is-xs-screen';
import { useLayoutMeasure } from './__hooks__/use-layout-measure';
import { SPaper } from './__styled__/s-paper';
import { OButton, OIcon, SBox, SFlexbox, SText, useNanoid } from '@ondefy/ondefy-ui';
import { STitle } from './__styled__/s-title';
import { PaperStackContext } from './context';
export const OPaper = ({ isOpened, id, setIsOpened, children, appearFrom, title, onBeforeClose }) => {
    const timerId = useRef();
    const timerAnimationId = useRef();
    // this state is to phisically remove unnecessary block from DOM
    const [isPhisicalyOpened, setIsPhisicallyOpened] = useState(isOpened);
    // this state is to animate smoothly this element after it has been inserted into the DOM
    const [isOpenedWithAnimated, setIsOpenedWithAnimated] = useState(isOpened);
    const layoustSize = useLayoutMeasure();
    const { stack: globalStack, setStack: setGlobalStack } = useContext(PaperStackContext);
    const [isAnimationDisabled, setIsAnimationDisabled] = useState(!!isOpened);
    const isXsScreen = useIsXsScreen();
    const uniqId = useNanoid();
    useMount(() => {
        if (isAnimationDisabled) {
            setTimeout(() => {
                setIsAnimationDisabled(false);
            }, 200);
        }
    });
    useUnmount(() => {
        setGlobalStack((v) => v.filter((v) => v !== uniqId));
    });
    useEffect(() => {
        if (timerId.current) {
            clearTimeout(timerId.current);
        }
        if (timerAnimationId.current) {
            clearTimeout(timerAnimationId.current);
        }
        if (isOpened) {
            // @ts-ignore
            setGlobalStack(v => [...v, uniqId]);
            setIsPhisicallyOpened(true);
            timerAnimationId.current = setTimeout(() => setIsOpenedWithAnimated(true), 16);
        }
        else if (!isOpened) {
            setGlobalStack((v) => v.slice(0, -1));
            setIsOpenedWithAnimated(false);
            timerId.current = setTimeout(() => setIsPhisicallyOpened(false), 300);
        }
        // TODO: why I don't do it
        // TODO: why I don't do it
        // TODO: why I don't do it
        // TODO: why I don't do it
        // return () => {
        //   clearTimeout(timerId.current)
        // }
        // eslint-disable-next-line
    }, [isOpened]);
    const onClickAway = (e) => {
        // TODO: move to some parameter
        if (e.target.tagName === 'W3M-MODAL') {
            return;
        }
        if (globalStack.slice(-1)[0] !== uniqId) {
            return;
        }
        if (e.target.id === id) {
            return;
        }
        if (onBeforeClose) {
            onBeforeClose();
            return;
        }
        setIsOpened(false);
    };
    const ref = useRef(null);
    useClickAway(ref, onClickAway);
    if (!isPhisicalyOpened && !isAnimationDisabled) {
        return null;
    }
    return (React.createElement(SPaper, { isFixed: isXsScreen, className: [isOpenedWithAnimated && 'opened', isAnimationDisabled && 'no-animation'].join(' '), width: layoustSize.width + 'px', height: layoustSize.height + 'px', appearFrom: appearFrom },
        React.createElement(SBox, { ref: ref, className: ['paper__box', isOpenedWithAnimated && 'opened'].join(' ') },
            React.createElement(STitle, null, typeof title === 'string' || !title ? (React.createElement(SFlexbox, { cursor: "pointer", alignItems: "center", justifyContent: "center", height: "100%", onClick: () => {
                    if (onBeforeClose) {
                        onBeforeClose();
                        return;
                    }
                    setIsOpened(false);
                } },
                React.createElement(OButton, { variant: "primary-unstyled", size: "lg", left: "-4px", icon: React.createElement(OIcon, { name: appearFrom === 'right' ? 'left' : appearFrom === 'bottom' ? 'down' : 'up' }), position: "absolute" }),
                React.createElement(SText, { type: "h5", mb: "0" }, title))) : (title)),
            children)));
};
