import { useIframeUrlColors } from '../../../iframe/hooks/use-iframe-url-colors';
import { SAmountBlockArrow } from './s-components/s-amount-block-arrow';
import { SAmountBockArrowButton, SAmountBockArrowTag } from './s-components/s-amount-bock-arrow-tag';
import { OIcon, useTheme } from '@ondefy/ondefy-ui';
import React from 'react';
export const ArrowDown = ({ onClick }) => {
    const { theme } = useTheme();
    const { colorPrimary } = useIframeUrlColors();
    const Component = onClick ? SAmountBockArrowButton : SAmountBockArrowTag;
    return (React.createElement(SAmountBlockArrow, { onClick: onClick },
        React.createElement(Component, { variant: theme.id === 'light' ? 'quaternary' : 'secondary', icon: React.createElement(OIcon, { name: "arrow-down", 
                // @ts-ignore
                color: colorPrimary || 'accent' }) })));
};
