import { SFlexbox, styled } from '@ondefy/ondefy-ui';
export const SRootWrapper = styled(SFlexbox) `
  flex-direction: column;
  height: ${p => (!p.isIframe && !p.isXsScreen ? '750px' : p.viewportHeight)};
  width: ${p => (p.isDesktop ? '450px' : '100%')};
  background-color: ${p => p.theme.colors.bg};
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-top: ${p => (p.isIframe || p.isXsScreen ? undefined : p.isDesktop ? '60px' : '40px')};
  padding-bottom: ${p => (p.isIframe || p.isXsScreen ? undefined : p.isDesktop ? '60px' : '20px')};
`;
