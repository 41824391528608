import _ from 'lodash/fp';
import { NETWORKS_DATA } from '../constants/networks';
export const findTokenById = (tokenId, networkId, tokens) => {
    var _a;
    if (!tokenId || !tokens || !tokens.value)
        return;
    return _.find({ id: tokenId }, tokens.value[(_a = NETWORKS_DATA[networkId]) === null || _a === void 0 ? void 0 : _a.id]);
};
export const findTokenByIdInAssoc = (tokens, networkId, tokenId) => {
    if (!tokenId || !tokens || !networkId) {
        return;
    }
    return _.path([_.path([networkId, 'id'], NETWORKS_DATA), tokenId], tokens);
};
