import { OIconNet, OMedia, SBox, SFlexbox, SText } from '@ondefy/ondefy-ui';
import { useIframeUrlColors } from '../../../../iframe/hooks/use-iframe-url-colors';
import _ from 'lodash/fp';
import React, { useMemo } from 'react';
import { NETWORKS_DATA } from '../../../constants/networks';
import { NumboLabel } from '../../../numbo/components/numbo-label';
import { Numbo } from '../../../numbo/transducers';
import { STokenLine } from '../__styled__/s-token-line';
// @ts-ignore
import TransakLogo from '../__assets__/transak-logo.svg';
export const TokenLine = ({ isWalletAsset = false, isTransakSupported = false, networkId, name, symbol, price, amount, decimals, img, prct, onSelect, id, selectedId, disabled, onDisabledClick }) => {
    const network = NETWORKS_DATA[networkId];
    const icon = useMemo(() => (React.createElement(OIconNet, { network: _.path('id', network), coinImage: img, coinName: name, mr: "8px" })), 
    // eslint-disable-next-line
    [_.path('id', network), img, name]);
    const { colorPrimary } = useIframeUrlColors();
    return (React.createElement(STokenLine, { onClick: () => {
            if (disabled) {
                if (onDisabledClick) {
                    onDisabledClick(id);
                }
                return;
            }
            onSelect(id);
        }, disabled: disabled, className: id === selectedId && 'token__selected' },
        React.createElement(OMedia, { alignItems: "center" },
            icon,
            React.createElement(SBox, null,
                React.createElement(SBox, null,
                    React.createElement(SText, { lineHeight: "1", className: "token__title" },
                        React.createElement(SText, { lineHeight: "1", className: "token__name", fontWeight: id === selectedId ? 'semibold' : 'normal' }, name))),
                React.createElement(SBox, { lineHeight: "0", display: "flex", className: "token-line__tooltip" },
                    React.createElement(SText, { type: "small", lineHeight: "1" },
                        symbol,
                        " on ",
                        _.capitalize(network === null || network === void 0 ? void 0 : network.name)),
                    isTransakSupported && (React.createElement(SBox, { display: "block", position: "relative", className: "token-line__mask-transak-wrap" },
                        React.createElement(SBox, { position: "absolute", left: "5px", top: "3px", className: "token-line__mask-transak" },
                            React.createElement(TransakLogo, { height: "10px" }))))))),
        React.createElement(SBox, null,
            React.createElement(SFlexbox, { justifyContent: "flex-end" },
                React.createElement(SText, null, isWalletAsset ? (React.createElement(NumboLabel, { label: !amount ? '0' : Numbo.floatToFloat(amount, decimals), groupZeros: 3, toFixed: 4 })) : (React.createElement(React.Fragment, null,
                    "~$",
                    React.createElement(NumboLabel, { label: price, toFixed: 4, groupZeros: 3 }))))),
            React.createElement(SFlexbox, { justifyContent: "flex-end", lineHeight: "0" },
                isWalletAsset && (React.createElement(SText, { type: "small", opacity: "0.7", lineHeight: "1" },
                    "\u2248 $ ",
                    Numbo.format(Number(amount) * Number(price), { toFixed: 2, min: 0.01 }))),
                !isWalletAsset && (React.createElement(SText, { type: "small", 
                    // @ts-ignore
                    color: prct > 0 ? colorPrimary || 'accent' : 'error', opacity: "0.7", lineHeight: "1" }, prct > 0 ? `+${prct}%` : `${prct}%`))))));
};
