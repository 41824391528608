import { toast } from '@ondefy/ondefy-ui';
import { ofType } from 'redux-observable';
import { EMPTY, from, of, switchMap, takeUntil } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { waitForState } from '../../../__common__/rxjs-utils/wait-for-state';
import { setAppMode, setPage } from '../../../__common__/slice';
import { Numbo } from '../../../__common__/numbo/transducers';
import { selectUserAddress } from '../../../wallet/selectors';
import { getTokenApproval } from '../../api/_zyfi_/get-token-approval';
import { getRawTx } from '../../frp-transducers';
import { selectBestSwapRate, selectSourceTokenAmount, selectSourceTokenSwap } from '../../selectors';
import { getZyfiTokenApprovalTx, getZyfiTokenApprovalTxFullfilled, getZyfiTokenApprovalTxRejected } from '../../slice';
export const epicGetTransactionApprovalDetailsZyfi = (action$, state$) => {
    return action$.pipe(ofType(getZyfiTokenApprovalTx.toString())).pipe(waitForState(state$, selectBestSwapRate), waitForState(state$, selectUserAddress), waitForState(state$, selectSourceTokenSwap), 
    // waitForState(state$, selectAllowance),
    // 1. rawTx
    switchMap(() => {
        const { value } = state$;
        const sourceToken = selectSourceTokenSwap(value);
        const bestRate = selectBestSwapRate(value);
        const userAddress = selectUserAddress(value);
        if (!userAddress || !bestRate.contractAddress || !bestRate.callData || !bestRate.fromAmount || !sourceToken) {
            // TODO if !userAddress - reset everything and return the user back to the home screen
            // TODO if !userAddress - reset everything and return the user back to the home screen
            // TODO if !userAddress - reset everything and return the user back to the home screen
            console.log('Missing data');
            return EMPTY;
        }
        return of(getRawTx(state$));
    }), 
    // 2. gasEstimate
    switchMap((payload) => {
        // console.log('fetch approval')
        const { value } = state$;
        const { from: userAddress } = payload;
        const bestRate = selectBestSwapRate(value);
        const sourceTokenAmount = selectSourceTokenAmount(value);
        const sourceToken = selectSourceTokenSwap(value);
        const decimalValue = Numbo.parseUnits(sourceTokenAmount, sourceToken.decimals).toString();
        return from(getTokenApproval({
            from: userAddress,
            spender: bestRate.spenderAddress,
            tokenAddress: sourceToken.address,
            allowanceAmount: decimalValue
        })).pipe(takeUntil(action$.pipe(ofType(setPage.toString(), setAppMode.toString()))), 
        // 3. gasFeeInUsd
        switchMap(tokenApprovalTxDetails => {
            return of(getZyfiTokenApprovalTxFullfilled(tokenApprovalTxDetails));
        }), 
        // @ts-ignore
        catchError((err) => {
            console.dir(err);
            return of(getZyfiTokenApprovalTxRejected({
                message: err === null || err === void 0 ? void 0 : err.message
            }));
        }), 
        // show an error toast
        tap((action) => {
            if (action.type === getZyfiTokenApprovalTxRejected.toString()) {
                toast.next({
                    variant: 'error',
                    message: action.payload.message
                });
            }
        }));
    }));
};
