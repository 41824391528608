// Wallets
export const WALLETS = {
    magicAuth: {
        key: 'magicAuth',
        name: 'Connect with email'
    },
    metamask: {
        key: 'metamask',
        name: 'MetaMask'
    },
    walletConnect: {
        key: 'walletConnect',
        name: 'WalletConnect'
    },
    web3Modal: {
        key: 'web3Modal',
        name: 'Web3Modal'
    }
};
