export var EAppVariant;
(function (EAppVariant) {
    EAppVariant["ondefy"] = "ondefy";
    EAppVariant["zyfi"] = "zyfi";
})(EAppVariant || (EAppVariant = {}));
export const ENV = {
    app: EAppVariant.ondefy,
    isZyfi: () => ENV.app === EAppVariant.zyfi,
    isOndefy: () => ENV.app === EAppVariant.ondefy
};
export const initEnv = () => {
    ENV.app = (new URLSearchParams(window.location.search).get('app') || EAppVariant.ondefy);
};
initEnv();
