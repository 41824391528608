import { OPaper } from '../../components/o-paper';
import { MainButton } from '../../components/main-button';
import { usePaperResetConfirm } from './__hooks__/use-paper-reset-confirm';
import { OIcon, OMedia, SFlexbox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
export const PaperResetConfirm = () => {
    const paperReset = usePaperResetConfirm();
    return (React.createElement(OPaper, Object.assign({}, paperReset, { appearFrom: "top", title: "Reset?" }),
        React.createElement(SFlexbox, { flexDirection: "column", flex: "1", p: "8px" },
            React.createElement(SFlexbox, { flex: "1", flexDirection: "column", justifyContent: "center" },
                React.createElement(OMedia, { alignItems: "center", px: "8px", justifyContent: "center" },
                    React.createElement(OIcon, { name: "warning", mr: "4px", size: 22, mb: "10px" }),
                    React.createElement(SText, { type: "h5", lineHeight: 1, mb: "10px" }, "Go back to start ?")),
                React.createElement(SText, { type: "h6", px: "8px", textAlign: "center" }, "All progress for this current order will be lost.")),
            React.createElement(MainButton, { onClick: () => {
                    paperReset.setIsOpened(false);
                    paperReset.reset();
                } }, "Confirm"))));
};
