import { OIconFiat, SText } from '@ondefy/ondefy-ui';
// @ts-ignore
import SvgDone from '@ondefy/ondefy-ui/dist/assets/svg/done.svg';
import { makeSelectedFiatSelector, makeTransakFiatOptionsSelector } from '../../selectors';
import { OLine } from '../../../__common__/components/o-line';
import { OPaper } from '../../../__common__/components/o-paper';
import { setSelectedFiatSymbol } from '../../slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { usePaperCurrencyList } from './__hooks__/use-paper-currency-list';
import { SPaperCurrencyList } from './__styled__/s-paper-currency-list';
import React, { useMemo } from 'react';
export const PaperCurrencyList = () => {
    const options = useAppSelector(makeTransakFiatOptionsSelector());
    const paper = usePaperCurrencyList();
    const selectedFiatSelector = useMemo(makeSelectedFiatSelector, []);
    const selectedFiatCurrency = useAppSelector(selectedFiatSelector);
    const selected = selectedFiatCurrency === null || selectedFiatCurrency === void 0 ? void 0 : selectedFiatCurrency.symbol;
    const dispatch = useAppDispatch();
    const onSelect = (value) => {
        dispatch(setSelectedFiatSymbol(value));
    };
    return (React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom", title: "Choose a currency" }),
        React.createElement(SPaperCurrencyList, null, options.map(option => {
            return (React.createElement(OLine, { key: option.value, className: option.value === selected ? 'selected' : '', onClick: () => {
                    onSelect(option.value);
                    paper.setIsOpened(false);
                } },
                React.createElement("div", { style: {
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '8px'
                    } },
                    React.createElement(OIconFiat, { logoSVG: option.logoSVG, style: { marginRight: '8px' } }),
                    React.createElement(SText, { className: "dropdown__item-text", type: "h5", mb: "0", fontWeight: option.value === selected ? 'semibold' : 'normal' }, option.label)),
                option.value === selected && React.createElement(SvgDone, { width: "16px" })));
        }))));
};
