// import { of } from 'rxjs'
import { CacheInMemory } from '../../__common__/api-observable/cache-in-memory';
import { apirxToken } from '../../__common__/api/services/api-token';
// eslint-disable-next-line
// import mockData from '../__mock__/user-wallet.json'
const USER_WALLET_CACHE_ID = 'USER_WALLET_CACHE_ID';
export const resetUserWalletCache = () => {
    CacheInMemory.resetById(USER_WALLET_CACHE_ID);
};
export const getUserWalletRx = (userAddress) => {
    // return of(mockData as any)
    return apirxToken.get('/getUserWallet', {
        evmAddr: userAddress
    }, {
        cacheMinutes: 2,
        cacheResetId: USER_WALLET_CACHE_ID
    });
};
