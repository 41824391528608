import { parseUnits } from './big-numbers-utils';
export function floatToBigNumber(amount, decimals) {
    // Convert the amount to a string to handle it correctly
    const amountString = amount.toString();
    // Find the position of the decimal point
    const decimalIndex = amountString.indexOf('.');
    let truncatedNumber;
    if (decimalIndex !== -1) {
        // If there's a decimal point, truncate the number to the specified decimals
        truncatedNumber = amountString.substring(0, decimalIndex + 1 + decimals);
    }
    else {
        // If there's no decimal point, use the number as is
        truncatedNumber = amountString;
    }
    // Use ethers.js utilities to handle the conversion
    return parseUnits(truncatedNumber, decimals);
}
