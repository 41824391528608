// import { HeaderNavBack } from '../header-nav-back'
import { selectTokenContracts } from '../../../__common__/selectors';
import { selectOrderPermitSigned, selectTargetTokenFiat } from '../../selectors';
import { selectIsWalletConnected } from '../../../wallet/selectors';
import { HeaderWithBreadcrumbs } from '../../../__common__/components/header-with-breadcrumbs';
import { MainButton } from '../../../__common__/components/main-button';
// import { usePaperResetConfirm } from '../paper-reset-confirm/__hooks__/use-paper-reset-confirm'
import { usePermit } from './__hooks__/use-permit';
import { OSpinner, SFlexbox, SBox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { useGetTokenContracts } from '../../../__common__/hooks/use-get-token-contracts';
import { EEntityStatus } from '../../../../redux/redux-entity';
import { SScreenInner } from './__styled__/s-screen-inner';
export const ScreenPermit = () => {
    const isWalletConnected = useAppSelector(selectIsWalletConnected);
    const targetToken = useAppSelector(selectTargetTokenFiat);
    const tokenContracts = useAppSelector(selectTokenContracts);
    const orderPermitSigned = useAppSelector(selectOrderPermitSigned);
    const permit = usePermit();
    useGetTokenContracts();
    // const paperResetConfirm = usePaperResetConfirm()
    return (React.createElement(SScreenInner, null,
        React.createElement(HeaderWithBreadcrumbs, null),
        React.createElement(SBox, null,
            React.createElement(SText, { type: "h5", textAlign: "center", my: "30px" }, "Sign Permit")),
        React.createElement(SFlexbox, { flex: "1", alignItems: "center", px: "8px", justifyContent: "center" },
            React.createElement(SText, { type: "h5", textAlign: "center" },
                "When your USDCs arrive on your wallet, this permit will allow us to automatically swap them to",
                ' ',
                tokenContracts.status === EEntityStatus.loading ? React.createElement(OSpinner, null) : (targetToken === null || targetToken === void 0 ? void 0 : targetToken.symbol) || 'N/A',
                '.')),
        React.createElement(MainButton, { loading: orderPermitSigned.status === EEntityStatus.loading, onClick: permit, disabled: orderPermitSigned.status === EEntityStatus.loading || !isWalletConnected }, orderPermitSigned.status === EEntityStatus.loading ? 'Awaiting user validation ' : 'Sign Permit')));
};
