import _ from 'lodash/fp';
// TODO: write unit tests
// TODO: write unit tests
// TODO: write unit tests
export const getBestSwapRate = (swapRates, selectedSwapperIndex) => {
    let bestRate;
    if (selectedSwapperIndex !== undefined) {
        bestRate = _.find({ swapperIdx: selectedSwapperIndex }, swapRates.value || []);
        if (bestRate) {
            return bestRate;
        }
    }
    return _.path(['value', '0'], swapRates);
};
