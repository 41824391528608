import { SBox, styled } from '@ondefy/ondefy-ui';
export const SScreenInner = styled(SBox) `
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  padding: 8px;
  background-color: ${p => p.theme.colors.bg2};
  overflow: hidden;
  border-radius: 12.5px;
`;
