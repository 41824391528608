import { createRoot } from 'react-dom/client';
import React from 'react';
import App from './app';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(React.createElement(App, null));
setTimeout(() => {
    var _a, _b;
    const skeleton = document.getElementById('skeleton');
    (_a = skeleton === null || skeleton === void 0 ? void 0 : skeleton.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(skeleton);
    const skeletonZyfi = document.getElementById('skeleton-zyfi');
    (_b = skeletonZyfi === null || skeletonZyfi === void 0 ? void 0 : skeletonZyfi.parentNode) === null || _b === void 0 ? void 0 : _b.removeChild(skeletonZyfi);
}, 1000);
window.addEventListener('unhandledrejection', event => {
    // @ts-ignore
    console.log(`An unhandled promise rejection occurred`, event);
});
// TODO: what is it? remove???
// export { APP_MODE } from './features/__common__/constants'
