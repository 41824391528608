import { OButton, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { useIframeUrlColors } from '../../../iframe/hooks/use-iframe-url-colors';
export const ButtonOnly = ({ text, onClick, size }) => {
    const { colorPrimary, colorSecondary } = useIframeUrlColors();
    return (React.createElement(OButton, { size: size, 
        // @ts-ignore
        bg: colorPrimary || undefined, onClick: onClick },
        React.createElement(SText
        // @ts-ignore
        , { 
            // @ts-ignore
            color: colorSecondary || 'inherit' }, text)));
};
