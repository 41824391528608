import { ofType } from 'redux-observable';
import { getTokenContracts, getTokenContractsFullfilled, getTokenContractsRejected } from '../slice';
import { of, catchError, map, exhaustMap } from 'rxjs';
import { getTokenContractsRx } from '../api/get-token-contracts';
export const epicGetTokenContracts = action$ => {
    return action$.pipe(ofType(getTokenContracts.toString()), exhaustMap(() => {
        return getTokenContractsRx().pipe(map(v => {
            return getTokenContractsFullfilled(v);
        }), catchError(e => {
            return of(getTokenContractsRejected(e));
        }));
    }));
};
