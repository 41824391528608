import { OLink, SBox, SFlexbox, SText } from '@ondefy/ondefy-ui';
import _ from 'lodash/fp';
import React from 'react';
import { ETransactionStatus } from '../../../constants/transaction';
import { DateTime } from '../../../date-time';
import { getExplorerLink } from '../../../transducers/get-explorer-link';
import { useIframeUrlColors } from '../../../../iframe/hooks/use-iframe-url-colors';
import { Numbo } from '../../../numbo/transducers';
export const LineSwap = ({ order }) => {
    const { colorPrimary } = useIframeUrlColors();
    return (React.createElement(SBox, null,
        React.createElement(SFlexbox, { justifyContent: "space-between" },
            React.createElement(SText, null,
                Numbo.formatWithDecimals(order.fromTokenAmount, order.fromTokenDecimals, 4),
                " ",
                order.fromTokenSymbol,
                " to",
                ' ',
                Numbo.formatWithDecimals(order.toTokenAmount, order.toTokenDecimals, 4),
                " ",
                order.toTokenSymbol),
            React.createElement(SText, null, DateTime.formatTimezoneWithTimestamp(order.timestamp / 1000, 'DD.MM.YYYY'))),
        React.createElement(SFlexbox, { justifyContent: "space-between" },
            React.createElement("div", null,
                // case 1
                order.status === ETransactionStatus.confirmed && (React.createElement(SText, { type: "small", 
                    // @ts-ignore
                    color: colorPrimary || 'accent' }, "Completed")),
                (order.status === ETransactionStatus.expired || order.status === ETransactionStatus.failed) && (React.createElement(SText, { type: "small", color: "error" }, order.status === ETransactionStatus.expired ? 'Expired' : 'Failed')),
                order.status === ETransactionStatus.pending && React.createElement(SText, { type: "small" }, "In Progress")),
            order.status === ETransactionStatus.confirmed && (React.createElement(SText, { type: "small" },
                React.createElement(OLink, { isExternal: true, borderless: true, noIcon: true, href: getExplorerLink(_.path('txHash', order), _.path('networkId', order)) }, "View on explorer"))))));
};
