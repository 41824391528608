import { SBox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { OPaper } from '../../../__common__/components/o-paper';
import { usePaperCustomTransactionInfo } from './__hooks__/use-paper-custom-transaction-info';
export const PaperCustomTransactionInfo = () => {
    const paper = usePaperCustomTransactionInfo();
    return (React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom", title: "What is Custom Data?" }),
        React.createElement(SBox, { p: "12px" },
            React.createElement(SText, { type: "p" }, "Input the encoded call data required by the smart contract for this transaction."))));
};
