import { OButton, OInput, SFlexbox, SText } from '@ondefy/ondefy-ui';
import React, { useState } from 'react';
import { selectSlippage } from '../../selectors';
import { setSlippage } from '../../slice';
import { initialState } from '../../state';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { SWrapper } from './__styled__/s-wrapper';
export const Slippage = () => {
    const slippage = useAppSelector(selectSlippage);
    const dispatch = useAppDispatch();
    const [focused, setFocused] = useState(false);
    return (React.createElement(SWrapper, null,
        React.createElement(SText, { px: "15px", type: "h6", fontWeight: "semibold" }, "Slippage"),
        React.createElement(SFlexbox, { px: "15px", gap: "8px", mt: "15px" },
            React.createElement(OButton, { variant: !focused && slippage === 0.5 ? 'tertiary' : 'secondary', onClick: () => {
                    dispatch(setSlippage(0.5));
                } }, "0.5%"),
            React.createElement(OButton, { variant: !focused && slippage === 1 ? 'tertiary' : 'secondary', onClick: () => {
                    dispatch(setSlippage(1));
                } }, "1%"),
            React.createElement(OButton, { variant: !focused && slippage === 3 ? 'tertiary' : 'secondary', onClick: () => {
                    dispatch(setSlippage(3));
                } }, "3%"),
            React.createElement(OInput, { size: "md", variant: "_", type: "number", placeholder: "Custom", value: (focused || ![0.5, 1, 3].includes(slippage)) && slippage, onBlur: () => {
                    setFocused(false);
                    if (!slippage) {
                        return dispatch(setSlippage(initialState.slippage));
                    }
                }, onChange: (value) => {
                    let normalizedValue = Math.abs(parseFloat(value));
                    if (normalizedValue > 50) {
                        normalizedValue = 50;
                    }
                    setFocused(true);
                    dispatch(setSlippage(normalizedValue));
                } }))));
};
