import { SText } from '@ondefy/ondefy-ui';
import _ from 'lodash/fp';
import React from 'react';
import { selectBestSwapRate } from '../../../../../selectors';
import { useAppSelector } from '../../../../../../../redux/hooks';
import { InfoBlock } from '../__shared__/info-block';
export const Swapper = () => {
    const bestRate = useAppSelector(selectBestSwapRate);
    return (React.createElement(InfoBlock, null,
        React.createElement(SText, { fontWeight: "semibold" },
            "Swap using ",
            _.path('swapperName', bestRate))));
};
