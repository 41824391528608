import { SBox, styled } from '@ondefy/ondefy-ui';
export const SPaperCurrencyList = styled(SBox) `
  & > div {
    display: flex;
    justify-content: space-between;

    &:not(.disabled) {
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.selected,
    &:hover {
      &:not(.disabled) {
        background-color: ${p => (p.theme.id === 'light' ? p.theme.colors.bg6 : p.theme.colors.hoverTransparent)};
      }
    }
  }

  .dropdown__item-text {
    white-space: nowrap;
  }
`;
