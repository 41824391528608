var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable import/prefer-default-export */
import { catchError, from, of, switchMap } from 'rxjs';
import { ESwappers, NATIVE_ADDRESS, SWAPPER_NAME_BY_IDX, ZERO_ADDRESS } from '../../constants/contracts';
import { ENetworkIdByName } from '../../constants/networks';
import { Numbo } from '../../numbo/transducers';
import { encodeFunctionData, parseAbi } from 'viem';
import { getPublicClient } from '../../web3/get-public-client';
// https://academy.binance.com/en/articles/a-guide-to-pancakeswap
const WBNB_ADDRESS = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
const PANCAKE_FACTORY_ADDRESS = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73';
const PANCAKE_FACTORY_ABI = parseAbi([
    'function getPair(address tokenA, address tokenB) external view returns (address pair)'
]);
const PANCAKE_ROUTER_ADDRESS = '0x10ED43C718714eb63d5aA57B78B54704E256024E';
const PANCAKE_ROUTER_ABI = parseAbi([
    'function getAmountsOut(uint amountIn, address[] memory path) external view returns (uint[] memory amounts)',
    'function swapExactETHForTokensSupportingFeeOnTransferTokens(uint amountOutMin, address[] calldata path, address to, uint deadline) external payable returns (uint[] memory amounts)',
    'function swapExactTokensForETHSupportingFeeOnTransferTokens(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)',
    'function swapExactTokensForTokensSupportingFeeOnTransferTokens(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)'
]);
const POSSIBLE_INTERMEDIATE_TOKENS = [
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', // BUSD
    WBNB_ADDRESS, // WBNB
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', // CAKE
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', // USDC
    '0x55d398326f99059fF775485246999027B3197955' // USDT
];
const getPancakeSwapData = ({ srcToken, destToken, networkId, amount, userAddress, slippage }) => __awaiter(void 0, void 0, void 0, function* () {
    const client = getPublicClient(networkId);
    const path = [srcToken];
    const getPair = (...args) => __awaiter(void 0, void 0, void 0, function* () {
        return yield client.readContract({
            address: PANCAKE_FACTORY_ADDRESS,
            abi: PANCAKE_FACTORY_ABI,
            args,
            functionName: 'getPair'
        });
    });
    const pairAddress = yield getPair(srcToken, destToken);
    if (pairAddress === ZERO_ADDRESS) {
        let pair1;
        let pair2;
        for (let i = 0; i < POSSIBLE_INTERMEDIATE_TOKENS.length; i += 1) {
            const intermediateToken = POSSIBLE_INTERMEDIATE_TOKENS[i];
            if (intermediateToken !== srcToken && intermediateToken !== destToken) {
                const pairs = yield Promise.all([getPair(srcToken, intermediateToken), getPair(intermediateToken, destToken)]);
                pair1 = pairs[0];
                pair2 = pairs[1];
                if (pair1 !== ZERO_ADDRESS && pair2 !== ZERO_ADDRESS) {
                    path.push(intermediateToken);
                    break;
                }
            }
        }
        if (pair1 === ZERO_ADDRESS || pair2 === ZERO_ADDRESS) {
            throw new Error('No path found');
        }
    }
    path.push(destToken);
    const amountsOut = yield client.readContract({
        abi: PANCAKE_ROUTER_ABI,
        address: PANCAKE_ROUTER_ADDRESS,
        functionName: 'getAmountsOut',
        args: [BigInt(amount), path]
    });
    const toAmount = amountsOut[amountsOut.length - 1].toString();
    // TODO pack pancakeRouter calldata
    let psrCalldata;
    if (userAddress) {
        // compute minAmountOut with given slippage
        const amountOutMin = Numbo.from(Math.round(10000 - slippage).toString())
            .mul(toAmount)
            .div(10000)
            .toBigInt();
        // deadline is 10 minutes from now
        const deadline = BigInt(Math.round((Date.now() + 600000) / 1000));
        if (srcToken === NATIVE_ADDRESS) {
            psrCalldata = encodeFunctionData({
                functionName: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
                abi: PANCAKE_ROUTER_ABI,
                // address: PANCAKE_ROUTER_ADDRESS,
                args: [amountOutMin, path, userAddress, deadline]
            });
        }
        else if (destToken === NATIVE_ADDRESS) {
            psrCalldata = encodeFunctionData({
                abi: PANCAKE_ROUTER_ABI,
                // address: PANCAKE_ROUTER_ADDRESS,
                functionName: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
                args: [
                    // TODO: ask Clement about fee
                    // TODO: ask Clement about fee
                    // TODO: ask Clement about fee
                    BigInt(amount), // amountMinusFee,
                    amountOutMin,
                    path,
                    userAddress,
                    deadline
                ]
            });
        }
        else {
            psrCalldata = encodeFunctionData({
                abi: PANCAKE_ROUTER_ABI,
                // address: PANCAKE_ROUTER_ADDRESS,
                functionName: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
                args: [BigInt(amount) /* amountMinusFee */, amountOutMin, path, userAddress, deadline]
            });
        }
    }
    return {
        toAmount,
        psrCalldata
    };
});
export const getPancakeswapSwapDataRx = ({ networkId, userAddress, srcToken, destToken, amount, 
// fee,
slippage }) => {
    // not supported network check
    if (networkId !== ENetworkIdByName['binance-smart-chain']) {
        return of(null);
    }
    const srcTokenAddress = srcToken === NATIVE_ADDRESS ? WBNB_ADDRESS : srcToken;
    const destTokenAddress = destToken === NATIVE_ADDRESS ? WBNB_ADDRESS : destToken;
    return from(getPancakeSwapData({
        srcToken: srcTokenAddress,
        destToken: destTokenAddress,
        amount,
        networkId,
        slippage,
        userAddress: userAddress
    })).pipe(switchMap(swapData => {
        const gasEstimate = 4000000;
        const gasLimit = 4000000;
        const result = {
            swapperIdx: ESwappers.PANCAKESWAP_SWAPPER_IDX,
            swapperName: SWAPPER_NAME_BY_IDX[ESwappers.ZERO_X_SWAPPER_IDX],
            // extra fields
            networkId,
            fromTokenAddress: srcTokenAddress,
            fromAmount: amount,
            toTokenAddress: destTokenAddress,
            toAmount: swapData.toAmount,
            gasEstimate,
            gasLimit
        };
        // for display only, not used in an actual swap
        if (!userAddress) {
            return of(result);
        }
        /* note : only relevant if we want to broadcast transactions to our router contract from here
        const callData = getCallData({
          srcToken: srcTokenAddress,
          destToken: destTokenAddress,
          data: swapData.psrCalldata!,
          network: NETWORKS_DATA[networkId],
          swapperIndex: ESwappers.PANCAKESWAP_SWAPPER_IDX,
          amount
        })
  
        // return all data needed for swap
        if (callData) {
          return of({
            ...result,
            ...callData
          })
        }
        */
        return of(Object.assign(Object.assign({}, result), { callData: swapData.psrCalldata, contractAddress: PANCAKE_ROUTER_ADDRESS, spenderAddress: PANCAKE_ROUTER_ADDRESS }));
    }), catchError(e => {
        console.error('Pancake get swap rates error', e);
        return of(null);
    }));
};
