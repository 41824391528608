// Order Swap Status
export const ORDER_SWAP_STATUS_IDLE = 'swap-idle';
export const ORDER_SWAP_STATUS_PENDING = 'swap-pending';
export const ORDER_SWAP_STATUS_FULFILLED = 'swap-fulfilled';
// show spinner
export const ORDER_SWAP_STATUS_ERROR = 'swap-error';
// show cross icon
export const ORDER_SWAP_STATUS_EXPIRED = 'swap-expired';
export const ORDER_SWAP_STATUS_ABORTED = 'swap-aborted';
// case where isTransakSupported is true
export const ORDER_SWAP_STATUS_NONE = 'swap-none';
