import { EPages } from '../../../../__common__/constants';
import { selectFiatToTokenRate } from '../../../selectors';
import { useTrackOrderCreation } from './use-track-order-creation';
import { setTransakOrderIsProcessing } from '../../../slice';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { setPage } from '../../../../__common__/slice';
export const useTransakProcessing = () => {
    const dispatch = useAppDispatch();
    const progressRef = useRef(false);
    const isOrderProcessing = useTrackOrderCreation();
    const fiatToTokenRate = useAppSelector(selectFiatToTokenRate);
    useEffect(() => {
        if (isOrderProcessing && !progressRef.current && fiatToTokenRate !== null) {
            progressRef.current = true;
            dispatch(setTransakOrderIsProcessing(true));
            dispatch(setPage(EPages.buySummary));
        }
        else {
            dispatch(setTransakOrderIsProcessing(false));
        }
        // eslint-disable-next-line
    }, [isOrderProcessing]);
};
