import { OIcon, OMedia, SText } from '@ondefy/ondefy-ui';
import React, { useMemo } from 'react';
import { selectTargetTokenFiat, selectSourceAmount, selectFiatToTokenRate, makeSelectedFiatSelector } from '../../../../selectors';
import { useAppSelector } from '../../../../../../redux/hooks';
import { EEntityStatus } from '../../../../../../redux/redux-entity';
import { useIsAmountTyping } from '../../__hooks__/use-is-amount-typing';
import { SDetails } from '../../../../../__common__/styled/s-details';
export const SourceFiatAmountDetails = () => {
    var _a;
    const targetToken = useAppSelector(selectTargetTokenFiat);
    const selectedFiatSelector = useMemo(makeSelectedFiatSelector, []);
    const selectedFiatCurrency = useAppSelector(selectedFiatSelector);
    const selectedFiatAmount = useAppSelector(selectSourceAmount);
    const fiatToTokenRate = useAppSelector(selectFiatToTokenRate);
    const allParamsSelected = !!targetToken && !!selectedFiatCurrency && !!selectedFiatAmount;
    const { isTyping } = useIsAmountTyping();
    const error = !isTyping && allParamsSelected && fiatToTokenRate.status === EEntityStatus.error
        ? (_a = fiatToTokenRate.error) === null || _a === void 0 ? void 0 : _a.message
        : undefined;
    const anyError = error;
    return (React.createElement(SDetails, null,
        React.createElement(OMedia, { alignItems: "center", px: "8px", className: `ondefy__validations-inner ${anyError ? 'ondefy__validations-inner--visible' : ''}` },
            anyError && (React.createElement(OIcon, { name: "warning", mr: "4px", color: "warning" })),
            React.createElement(SText, { type: "small", color: "warning" }, error))));
};
