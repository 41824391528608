export const convertTransakFiatToOptions = (entityCurrency) => {
    if (!entityCurrency.value) {
        return [];
    }
    return entityCurrency.value.map(item => ({
        value: item.symbol,
        label: item.symbol,
        logoSVG: item.logoSVG
    }));
};
