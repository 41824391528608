import { ENetworkIdByName } from '../../../constants/networks';
import { GAS_OVERHEAD_SWAP_ROUTER, GAS_OVERHEAD_SWAP_ROUTER_ARBITRUM } from '../constants/gas-fee-estimate';
export const getGasFeeForLifi = (networkId, resData, usingOndefyRouter) => {
    var _a, _b, _c, _d, _e, _f;
    let gasEstimate = Number((_c = (_b = (_a = resData === null || resData === void 0 ? void 0 : resData.estimate) === null || _a === void 0 ? void 0 : _a.gasCosts) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.estimate);
    if (gasEstimate > 0 && usingOndefyRouter) {
        gasEstimate +=
            networkId === ENetworkIdByName['arbitrum-one'] ? GAS_OVERHEAD_SWAP_ROUTER_ARBITRUM : GAS_OVERHEAD_SWAP_ROUTER;
    }
    const gasLimit = Number((_f = (_e = (_d = resData === null || resData === void 0 ? void 0 : resData.estimate) === null || _d === void 0 ? void 0 : _d.gasCosts) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.limit);
    return {
        gasEstimate,
        gasLimit
    };
};
