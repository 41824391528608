/*
 * STATE DEFAULT
 * */
// import { ReduxEntity } from '../../../redux/redux-entity'
// import { APP_MODE, DEFAULT_CURRENCY } from './index'
// import { DEFAULT_CHAIN_ID } from '../../networks/constants/networks'
/*
 * STATE INTERFACE
 * */
import { ReduxEntity } from '../../redux/redux-entity';
export const initialState = {
    zyfiGeneralTx: ReduxEntity.init(),
    pendingTransactions: [],
    customTransaction: ReduxEntity.init()
};
