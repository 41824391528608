import { ENetworkIdByName } from '../../__common__/constants/networks';
import { Numbo } from '../../__common__/numbo/transducers';
import { GAS_AMOUNT_L1_FOR_OPTIMISM } from '../constants';
import _ from 'lodash/fp';
// TODO: add unit tests
// TODO: add unit tests
// TODO: add unit tests
export const getGasFeeUsd = ({ networkId, gasToken, l1GasToken, gasUsage, gasPrices, txSpeed }) => {
    const txGas = _.pathOr({}, [txSpeed], gasPrices);
    // TODO ask clement why we don't do return after this calculation
    // TODO ask clement why we don't do return after this calculation
    // TODO ask clement why we don't do return after this calculation
    let result = parseFloat(Numbo.formatEther(Numbo.from(_.path('gasPrice', txGas)).mul(gasUsage.toString()))) *
        (gasToken === null || gasToken === void 0 ? void 0 : gasToken.price) || 0;
    if (networkId === ENetworkIdByName['optimistic-ethereum'] && result > 0) {
        result +=
            parseFloat(Numbo.formatEther(Numbo.from(_.path('L1GasPrice', txGas)).mul(GAS_AMOUNT_L1_FOR_OPTIMISM.toString()))) * (l1GasToken === null || l1GasToken === void 0 ? void 0 : l1GasToken.price) || 0;
    }
    return result;
};
