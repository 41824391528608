var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// import { ExternalProvider, JsonRpcFetchFunc } from '@ethersproject/providers/src.ts/web3-provider'
import { Web3Provider } from 'zksync-web3';
import { ENV } from '../../../env';
import { createWalletClient, custom } from 'viem';
import { arbitrum, avalanche, base, bsc, celo, fantom, mainnet, optimism, polygon, zkSync } from 'viem/chains';
import { ENetworkIdByName } from '../constants/networks';
// TODO: find where we can get this provider
export const providersByNetworkId = {
    [ENetworkIdByName['arbitrum-one']]: arbitrum,
    [ENetworkIdByName['binance-smart-chain']]: bsc,
    [ENetworkIdByName.celo]: celo,
    [ENetworkIdByName.fantom]: fantom,
    [ENetworkIdByName['optimistic-ethereum']]: optimism,
    [ENetworkIdByName.avalanche]: avalanche,
    [ENetworkIdByName['polygon-pos']]: polygon,
    [ENetworkIdByName.zksync]: zkSync,
    [ENetworkIdByName.ethereum]: mainnet,
    [ENetworkIdByName.base]: base
};
export const getNewWeb3ProviderViem = (provider, networkId) => __awaiter(void 0, void 0, void 0, function* () {
    const chain = providersByNetworkId[networkId];
    // TODO: should I do
    const accounts = yield provider.request({
        method: 'eth_requestAccounts'
    });
    const [account] = accounts;
    // TODO: should I do like this?
    // const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' })
    const client = createWalletClient({
        account,
        chain,
        // @ts-ignore
        // transport: custom(window.ethereum)
        transport: custom(provider)
    });
    return client;
});
export const getNewWeb3Provider = (provider, networkId) => __awaiter(void 0, void 0, void 0, function* () {
    // return ENV.isZyfi() ? new Web3Provider(provider) : new ethers.providers.Web3Provider(provider)
    return ENV.isZyfi() ? new Web3Provider(provider) : yield getNewWeb3ProviderViem(provider, networkId);
});
