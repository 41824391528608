import { OButton, OIcon, OMedia, SText } from '@ondefy/ondefy-ui';
import React from 'react';
export const InfoTitle = ({ onClick, title, textType, icon }) => {
    return (React.createElement(OMedia, { alignItems: "center" },
        React.createElement(SText
        // @ts-ignore
        , { 
            // @ts-ignore
            type: textType }, title),
        React.createElement(OButton, { icon: React.createElement(OIcon, { name: icon || 'issue' }), variant: "secondary-unstyled", onClick: () => {
                onClick();
            } })));
};
