import { parseAbi } from 'viem';
export const sliceNamespace = 'swap';
export var ETxSpeedMagic;
(function (ETxSpeedMagic) {
    ETxSpeedMagic["slow"] = "slow";
    ETxSpeedMagic["normal"] = "normal";
    ETxSpeedMagic["fast"] = "fast";
})(ETxSpeedMagic || (ETxSpeedMagic = {}));
export const TX_SPEED_DEFAULT = ETxSpeedMagic.normal;
export const GAS_AMOUNT_L1_FOR_OPTIMISM = 6000;
export const ROUTER_ABI = parseAbi([
    'function getAgent() public view returns (address)',
    'function getFeeRateBps() public view returns (uint16)',
    'function swap(uint256 _adapterIndex, address _fromToken, uint256 _fromAmount, address _toToken, bytes memory _swapCallData) public payable',
    'function computeSwapFee(uint256 amount) public view returns (uint)'
]);
export const ERC20_ABI = parseAbi([
    // Read-Only Functions
    'function allowance(address,address) view returns (uint256)',
    'function decimals() view returns (uint8)',
    'function balanceOf(address) view returns (uint)',
    'function approve(address,uint256) returns (bool)',
    'function transfer(address,uint256) returns (bool)'
]);
