import React from 'react';
import { SLoadingIndicator } from './__styled__/s-loading-indicator';
export const LoadingIndicator = () => {
    const svgSize = 111;
    const strokeWidth = 6;
    const spinnerSize = (svgSize - strokeWidth) / 2;
    return (React.createElement(SLoadingIndicator, { svgSize: svgSize, spinnerSize: spinnerSize, strokeWidth: strokeWidth, width: svgSize + 'px', height: svgSize + 'px', viewBox: `0 0 ${svgSize} ${svgSize}`, x: "0px", y: "0px" },
        React.createElement("circle", { cx: svgSize / 2, cy: svgSize / 2, r: spinnerSize, stroke: "url(#paint0_linear_1000_15)" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_1000_15", x1: "0", y1: "0", x2: "123.272", y2: "16.7914", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#7AD1BF" }),
                React.createElement("stop", { offset: "1", stopColor: "#25B0C4" })))));
};
