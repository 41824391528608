import { Decimal } from 'decimal.js-light';
export const formatNumberSmall = (number, options) => {
    // Check for null, undefined, or non-string inputs
    if (number === null || number === undefined || typeof number !== 'string') {
        return number;
    }
    const { toFixed, groupZeros } = options !== null && options !== void 0 ? options : {};
    const numberValue = new Decimal(number);
    const thresholdAdjusted = Math.pow(10, -Math.abs(groupZeros || 0));
    // Check if threshold option should be applied
    if (groupZeros !== undefined && numberValue.gt(thresholdAdjusted)) {
        if (toFixed !== undefined && toFixed >= 0) {
            return numberValue.toDecimalPlaces(toFixed, Decimal.ROUND_HALF_UP).toString();
        }
        return number;
    }
    // Find repeated zeros after the decimal point
    // TODO: it's possible to do groupping not just for zeros but also for any repetitive number, though it might make no sence
    const match = /\.0+/.exec(number);
    if (match) {
        // Count the number of zeros
        const zeros = match[0].length - 1; // Subtract 1 for the decimal point
        if (zeros === 1) {
            return number;
        }
        if (toFixed !== undefined && toFixed >= 0) {
            // Apply rounding logic when toFixed is provided
            const startOfSignificantDigits = match.index + 1 + zeros;
            const significantDigits = number.slice(startOfSignificantDigits);
            const toFixedAdjusted = Math.max(toFixed, 2);
            let roundedDigits = significantDigits;
            if (significantDigits.length >= toFixedAdjusted - 1) {
                // Round the significant digits
                roundedDigits = new Decimal('0.' + significantDigits)
                    .toDecimalPlaces(toFixedAdjusted - 1, Decimal.ROUND_HALF_UP)
                    .toString()
                    .substring(2);
            }
            // Construct the final formatted number
            const formattedNumber = number.substring(0, startOfSignificantDigits) + roundedDigits;
            // Replace the zeros with the grouping format
            return formattedNumber.replace(new RegExp(`\\.0{${zeros}}`), `.0{${zeros}}`);
        }
        else {
            // Original logic when toFixed is not provided
            return number.replace(new RegExp(`\\.0{${zeros}}`), `.0{${zeros}}`);
        }
    }
    return number;
};
