import { makeSelectAllParamsSelected, selectFiatToTokenRate } from '../../../../selectors';
import { usePaperTransactionDetails } from '../../../../papers/paper-transaction-details/__hooks__/use-paper-transaction-details';
import { useIsAmountTyping } from '../../__hooks__/use-is-amount-typing';
import { OButton, OIcon, SBox, SText } from '@ondefy/ondefy-ui';
import { EEntityStatus } from '../../../../../../redux/redux-entity';
import React, { useMemo } from 'react';
import { SCollapseMoreDetails } from './s-components/s-collapse-more-details';
import { useAppSelector } from '../../../../../../redux/hooks';
export const MoreDetails = () => {
    const fiatToTokenRate = useAppSelector(selectFiatToTokenRate);
    const selectAllParamsSelected = useMemo(makeSelectAllParamsSelected, []);
    const allParamsSelected = useAppSelector(selectAllParamsSelected);
    const { isTyping } = useIsAmountTyping();
    const paperTransactionDetails = usePaperTransactionDetails();
    return (React.createElement(SBox, { px: "8px" }, fiatToTokenRate.status === EEntityStatus.success && !isTyping && allParamsSelected && (React.createElement(SCollapseMoreDetails, null,
        React.createElement(OButton, { variant: "secondary", className: "dropdown__trigger", onClick: () => {
                paperTransactionDetails.setIsOpened(true);
            }, iconRight: React.createElement(OIcon, { name: "down" }) },
            React.createElement(SText, { fontWeight: "semibold" }, "More details"))))));
};
