import { formatCurrencyRate } from '../../../../transducers/format-currency-rate';
import { formatValueLoss } from '../../../../transducers/format-value-loss';
import { isMaximumValueLossExceeded } from '../../../../transducers/is-maximum-value-loss-exceeded';
import { SExchangeRates } from './__styled__/s-exchange-rates';
import { OIcon, SFlexbox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import _ from 'lodash/fp';
export const ExchangeRates = ({ fiatToTokenRate, selectedFiatCurrency }) => {
    const valueLoss = _.path(['value', 'valueLoss'], fiatToTokenRate);
    return (React.createElement(SExchangeRates, null,
        React.createElement(SText, { type: "h4", className: "exchange__label" }, !fiatToTokenRate.value ? '' : fiatToTokenRate.value.buyAmount),
        React.createElement(SFlexbox, { alignItems: "center" },
            fiatToTokenRate.value && (React.createElement(SText, { type: "small", opacity: "0.5", mr: "4px" }, formatCurrencyRate(selectedFiatCurrency === null || selectedFiatCurrency === void 0 ? void 0 : selectedFiatCurrency.symbol, fiatToTokenRate.value.buyAmountInFiatCurrency))),
            React.createElement(SFlexbox, { alignItems: "center" },
                React.createElement(SText, { type: "small", opacity: "0.5", mr: "2px", fontWeight: "inherit", color: isMaximumValueLossExceeded(valueLoss) ? 'error' : 'inherit' }, ` (${formatValueLoss(valueLoss)})`),
                isMaximumValueLossExceeded(valueLoss) && (React.createElement(OIcon, { name: "warning", color: "error", size: 17 }))))));
};
