import { combineEpics } from 'redux-observable';
import { BehaviorSubject, Subject } from 'rxjs';
import { mergeMap, filter, takeUntil, switchMap } from 'rxjs/operators';
import _ from 'lodash/fp';
const defaultEpics = [
// commonEpics,
// †epic
];
const dynamicallyAdded = new Set();
const flattenValues = _.flow(_.map(_.values), _.flatten);
/*
 * https://redux-observable.js.org/docs/recipes/AddingNewEpicsAsynchronously.html
 * */
const epic$ = new BehaviorSubject(combineEpics(...flattenValues(defaultEpics)));
export const rootEpic = (action$, state$, deps) => epic$.pipe(
// tap(v => {
//   console.log('New Epic Added', v)
// }),
mergeMap(epic => {
    // console.log(epic)
    return epic(action$, state$, deps);
}));
export function addEpic(epicName, epic) {
    // Add epics only once
    if (!dynamicallyAdded.has(epicName)) {
        dynamicallyAdded.add(epicName);
        epic$.next(epic);
    }
}
export function addEpics(epicGroupName, epics) {
    // Add epics only once
    if (!dynamicallyAdded.has(epicGroupName)) {
        dynamicallyAdded.add(epicGroupName);
        _.flow(_.flatten, _.map(_.values), _.flatten, _.forEach((v) => {
            epic$.next(v);
        }))(epics);
    }
}
export const epicsBus = new Subject();
export const onEpicMount = (key) => epicsBus.pipe(filter((v) => v.key === key && v.mounted));
export const onEpicUnmount = (key) => epicsBus.pipe(filter((v) => v.key === key && !v.mounted));
export const takeUntilEpicUnmount = (key) => takeUntil(onEpicUnmount(key));
// on each did mount run epic like for the first time
export const runOnEpicMount = (key) => (epic) => (action$, state$, deps) => onEpicMount(key).pipe(switchMap(() => epic(action$, state$, deps)));
