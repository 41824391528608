import axios from 'axios';
import { of, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiObservable } from '../../api-observable/api-observable';
import { ONDEFY_WALLET } from '../../constants';
import { ESwappers, SWAPPER_NAME_BY_IDX } from '../../constants/contracts';
import { ENetworkIdByName } from '../../constants/networks';
import { getGasFeeFor0x } from './transducers/get-gas-fee-for-0x';
import _ from 'lodash/fp';
export const ZERO_X_FEE = 0.0001; // 0.01% fee for tx verification
// https://0x.org/docs/0x-swap-api/api-references/overview
const apirx0X = ApiObservable.from(axios.create({
    baseURL: undefined,
    headers: { '0x-api-key': process.env.ZERO_X_API_KEY }
}));
const networkNames = {
    [ENetworkIdByName.ethereum]: '',
    [ENetworkIdByName['polygon-pos']]: 'polygon.',
    [ENetworkIdByName['binance-smart-chain']]: 'bsc.',
    [ENetworkIdByName['optimistic-ethereum']]: 'optimism.',
    [ENetworkIdByName.fantom]: 'fantom.',
    [ENetworkIdByName.celo]: 'celo.',
    [ENetworkIdByName.avalanche]: 'avalanche.',
    [ENetworkIdByName['arbitrum-one']]: 'arbitrum.',
    [ENetworkIdByName.base]: 'base.'
};
function apiRequestUrl(networkId, methodName) {
    return `https://${networkNames[networkId]}api.0x.org/swap/v1/${methodName}`;
}
export function get0xSwapDataRx({ networkId, usingOndefyRouter, userAddress, srcToken, destToken, amount, slippage }) {
    // not supported network check
    if (!_.has(networkId, networkNames)) {
        return of(null);
    }
    const swapParams = {
        sellToken: srcToken,
        buyToken: destToken,
        sellAmount: amount,
        slippagePercentage: slippage * 0.01,
        feeRecipientTradeSurplus: ONDEFY_WALLET,
        enableSlippageProtection: false,
        feeRecipient: ONDEFY_WALLET,
        buyTokenPercentageFee: ZERO_X_FEE
        // priceImpactProtectionPercentage: maxPriceImpact,
    };
    const url = apiRequestUrl(networkId, 'quote');
    return apirx0X.get(url, swapParams).pipe(switchMap((resData) => {
        if (!(resData === null || resData === void 0 ? void 0 : resData.buyAmount)) {
            return throwError(() => new Error('invalid response from 0x'));
        }
        const { gasEstimate, gasLimit } = getGasFeeFor0x(networkId, resData, usingOndefyRouter);
        const result = {
            swapperIdx: ESwappers.ZERO_X_SWAPPER_IDX,
            swapperName: SWAPPER_NAME_BY_IDX[ESwappers.ZERO_X_SWAPPER_IDX],
            // extra fields
            networkId,
            fromTokenAddress: srcToken,
            fromAmount: amount,
            toTokenAddress: destToken,
            toAmount: resData.buyAmount,
            gasEstimate,
            gasLimit
        };
        // if not login user then just return short result for demo purpose
        if (!userAddress) {
            return of(result);
        }
        /* note : only relevant if we want to broadcast transactions to our router contract from here
        const callData = getCallData({
          srcToken,
          destToken,
          data: resData.data,
          amount,
          swapperIndex: ESwappers.ZERO_X_SWAPPER_IDX,
          network: NETWORKS_DATA[networkId]
        })
  
        // else return all data needed for swap
        if (callData) {
          return of({
            ...result,
            ...callData
          } as TSwapRateRes)
        }
        */
        return of(Object.assign(Object.assign({}, result), { callData: resData.data, contractAddress: resData.to, spenderAddress: resData.allowanceTarget }));
    }), catchError(e => {
        console.error('get0xSwapData', e);
        return of(null);
    }));
}
