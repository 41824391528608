import { SBox, styled } from '@ondefy/ondefy-ui';
export const SScreenInner = styled(SBox) `
  margin: 0 auto;
  padding: 8px 0px;
  width: 100%;
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`;
