import _ from 'lodash/fp';
import { NETWORK_NAME_BY_ID } from '../../../constants/networks';
// TODO: write unit tests
// TODO: write unit tests
// TODO: write unit tests
export const getTokenCountForNetwork = (networkId, tokenContracts) => {
    const networkName = _.path(networkId, NETWORK_NAME_BY_ID);
    if (networkName) {
        return _.compose(_.size, _.path(networkName))(tokenContracts.value);
    }
    return _.compose(_.size, _.flatten, _.values)(tokenContracts.value);
};
