import { useBreakpoint } from '@ondefy/ondefy-ui';
import { useState, useEffect } from 'react';
export const useViewportHeight = () => {
    const [height, setHeight] = useState('100vh');
    const { xs } = useBreakpoint();
    useEffect(() => {
        const updateHeight = () => {
            const vh = window.innerHeight;
            setHeight(`${vh}px`);
            // Apply the height to the specified elements
            const elementsToUpdate = [document.documentElement, document.body, document.getElementById('root')];
            elementsToUpdate.forEach(el => {
                if (el)
                    el.style.height = `${vh}px`;
                if (el && xs)
                    el.style.overflow = `hidden`;
                if (el && xs)
                    el.style.display = `block`;
            });
        };
        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [xs]);
    return height;
};
