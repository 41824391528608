import { Numbo } from '../../__common__/numbo/transducers';
export const checkIfAllowanceSufficient = (allowance, amount, sourceToken) => {
    if (allowance.isLoading || allowance.isError || allowance.value === undefined || !sourceToken || amount === '') {
        return undefined;
    }
    const decimals = sourceToken.decimals;
    // TODO
    // Convert the amount to the same unit representation as the allowance
    let sourceTokenAmount;
    try {
        sourceTokenAmount = Numbo.parseUnits(Numbo.toFixedBig(amount, decimals), decimals);
    }
    catch (e) {
        console.log('ERROR: checkIfAllowanceSufficient', {
            amount,
            typofAmount: typeof amount,
            decimals
        });
    }
    return Numbo.from(allowance.value || 0).gte(sourceTokenAmount || 0);
};
