import _ from 'lodash/fp';
import { Numbo } from '../../__common__/numbo/transducers';
import { selectSourceTokenAmount, selectSelectedSwapperIndex, selectSourceTokeInUserWallet, selectSourceTokenSwap, selectFeeTokenSwap, selectPercentage } from '../selectors';
// TODO: write unit tests!!!
// TODO: write unit tests!!!
// TODO: write unit tests!!!
export const calculateNewSourceAmountForEthFee = (value, res) => {
    var _a;
    // @ts-ignore
    const selectSwapperIndex = selectSelectedSwapperIndex(value);
    const bestRate = _.find({ swapperIdx: selectSwapperIndex }, res) || res[0];
    // @ts-ignore
    const sourceTokenInUserWallet = selectSourceTokeInUserWallet(value);
    // @ts-ignore
    const percentage = selectPercentage(value);
    // @ts-ignore
    const sourceToken = selectSourceTokenSwap(value);
    // @ts-ignore
    const feeToken = selectFeeTokenSwap(value);
    const maxSourceTokenAmount = Numbo.from(_.path('amount', sourceTokenInUserWallet).replace('.', ''))
        .mul(percentage)
        .div(100);
    // @ts-ignore
    const sourceTokenAmount = selectSourceTokenAmount(value);
    const sourceAmountDesired = Numbo.floatToBigNumber(sourceTokenAmount, sourceTokenInUserWallet.decimals);
    const feeTokenAmount = Numbo.from((_a = bestRate === null || bestRate === void 0 ? void 0 : bestRate.meta) === null || _a === void 0 ? void 0 : _a.feeTokenAmount);
    if (_.isEqual(feeToken, sourceToken)) {
        // console.log({ maxSourceTokenAmount, feeTokenAmount, availableUsdToPayGas, newSourceTokenAmount })
        const newAmount = maxSourceTokenAmount.sub(feeTokenAmount);
        const shouldSubtract = newAmount.gt(0);
        return {
            shouldSubtract,
            newSourceTokenAmount: shouldSubtract
                ? Numbo.formatUnits(newAmount, sourceToken === null || sourceToken === void 0 ? void 0 : sourceToken.decimals)
                : Numbo.formatUnits(sourceAmountDesired, sourceToken === null || sourceToken === void 0 ? void 0 : sourceToken.decimals)
        };
    }
    return {
        shouldSubtract: false,
        newSourceTokenAmount: Numbo.formatUnits(sourceAmountDesired, sourceToken === null || sourceToken === void 0 ? void 0 : sourceToken.decimals)
    };
};
