import Decimal from 'decimal.js-light';
import _ from 'lodash/fp';
import numeral from 'numeral';
import { formatNumberLarge } from './format-number-large';
import { formatNumberSmall } from './format-number-small';
import { formatNumberSmallExp } from './format-number-small-exp';
export const formatNumber = (amount, options) => {
    var _a, _b;
    const defaultConfig = {
        min: undefined,
        toFixed: undefined,
        groupZeros: undefined,
        expStart: 15,
        expStartNegative: undefined
    };
    const config = Object.assign(defaultConfig, options || {});
    // 1. handle empty
    if (amount === null || amount === undefined) {
        return '';
    }
    if (amount === 0 || amount === '0') {
        return '0';
    }
    const amountAsString = new Decimal(amount).toFixed();
    const MILLION = 1000000;
    // const TRILLION = 1_000_000_000_000
    const zeros = _.repeat(config.toFixed || 20, '0');
    const integerLength = amountAsString.includes('.') ? amountAsString.indexOf('.') : amountAsString.length;
    // 2. handle extra large
    if (integerLength >= 13) {
        const [value, letter] = formatNumberLarge(amountAsString, {
            expStart: config.expStart || 15
        });
        if (integerLength > 61) {
            return value + letter;
        }
        // TODO: Allow groupping??
        return numeral(value).format(`0,0.[${zeros}]`) + letter;
    }
    const decimalValue = new Decimal(amount);
    const stringValue = decimalValue.toFixed();
    // 3. handle large
    if (decimalValue.gte(MILLION)) {
        // TODO: allow groupping here?
        // TODO: get rid of this library it seems like it have limit in 7 zeros after dot
        // TODO: instead implement just own formatting
        return numeral(stringValue).format(`0,0.[${zeros}]a`).toUpperCase();
    }
    if (config.min && decimalValue.lt(config.min)) {
        return '<' + config.min;
    }
    const noZeroGroupping = !config.groupZeros;
    const minValue = noZeroGroupping ? `0.${zeros.slice(0, -1)}1` : undefined;
    if (minValue && decimalValue.lt(minValue)) {
        return '<' + minValue;
    }
    if (config.expStartNegative && decimalValue.lt(Math.pow(10, Math.abs(config.expStartNegative - 1)))) {
        return formatNumberSmallExp(stringValue, { expStart: config.expStartNegative, toFixed: config.toFixed });
    }
    if (config.groupZeros && decimalValue.lt(0.01)) {
        const smallValue = formatNumberSmall(stringValue, {
            groupZeros: config.groupZeros,
            toFixed: config.toFixed
        });
        return smallValue;
    }
    // 4. handle normal e.g. integers up to 999K
    if (config.groupZeros && /\.0+/.test(stringValue)) {
        const integer = numeral(stringValue).format(`0,0`);
        const decimal = ((_a = formatNumberSmall('0.' + stringValue.split('.')[1], {
            groupZeros: config.groupZeros,
            toFixed: config.toFixed
        })) === null || _a === void 0 ? void 0 : _a.slice(1)) || '';
        return integer + decimal;
    }
    let integer = '0';
    if (decimalValue.gte(1)) {
        // TODO: get rid of this library it seems like it have limit in 7 zeros after dot
        // TODO: instead implement just own formatting
        integer = numeral(stringValue).format(`0,0`);
    }
    const decimalPart = stringValue.split('.')[1];
    const decimal = decimalPart
        ? (_b = formatNumberSmall('0.' + stringValue.split('.')[1], {
            toFixed: config.toFixed,
            groupZeros: 1e99
        })) === null || _b === void 0 ? void 0 : _b.slice(1)
        : '';
    return integer + (decimal || '');
};
