import { apirxBackend } from '../../__common__/api/services/api-backend';
export function postOrder(partnerOrderId, userAddress, isTransakSupported, networkId, fiatSymbol, fiatAmount, fromTokenAddress, fromTokenDecimals, fromTokenValue, toTokenAddress, toTokenDecimals, toTokenValue, signedPermit, refuelAmount) {
    const body = Object.assign({ partnerOrderId,
        userAddress,
        networkId,
        isTransakSupported, fiatCurrency: fiatSymbol, fiatAmount,
        fromTokenAddress,
        fromTokenDecimals,
        fromTokenValue,
        toTokenAddress,
        toTokenDecimals,
        toTokenValue, refuelAmount: refuelAmount || '0' }, signedPermit);
    return apirxBackend.post('/ramp/saveOrder', body);
}
