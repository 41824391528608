/*
 * STATE DEFAULT
 * */
// import { ReduxEntity } from '../../../redux/redux-entity'
// import { APP_MODE, DEFAULT_CURRENCY } from './index'
// import { DEFAULT_CHAIN_ID } from '../../networks/constants/networks'
import { ReduxEntity } from '../../redux/redux-entity';
export const initialState = {
    isIframe: false,
    sourceTokenAmount: '',
    sourceTokenIdSwap: undefined,
    targetTokenIdSwap: undefined,
    feeTokenIdSwap: undefined,
    slippage: 1,
    swapRates: ReduxEntity.init(),
    gasFees: ReduxEntity.init(),
    percentage: null,
    selectedSwapperIndex: undefined,
    networkIdSourceSwap: undefined,
    networkIdTargetSwap: undefined,
    networkIdFeeSwap: undefined,
    allowance: ReduxEntity.init(),
    gasFee: ReduxEntity.init(),
    swapTransaction: ReduxEntity.init(),
    unlockTransaction: ReduxEntity.init(),
    pendingTransactions: [],
    zyfiTokenApprovalTx: ReduxEntity.init(),
    zyfiGeneralTx: ReduxEntity.init()
};
