import { OMedia, styled } from '@ondefy/ondefy-ui';
export const SAmountBlock = styled(OMedia) `
  justify-content: space-between;
  align-items: center;
  padding: 38px 8px 0px;

  input {
    width: 100%;
  }
`;
