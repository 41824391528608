import { merge, map } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ofValue } from '../../__common__/rxjs-utils/of-value';
import { filterValidState } from '../epics.pipes/filter-valid-state';
import { selectDestinationAddress, selectSourceTokenAmount, selectSourceTokenSend, selectFeeTokenSend } from '../selectors';
import { getZyfiGeneralTx } from '../slice';
export const epicSendTriggerGetGeneralTx = (action$, state$) => {
    return merge(
    // TODO: update on user connected/disconnected
    // TODO: update on user connected/disconnected
    // TODO: update on user connected/disconnected
    ofValue(state$, selectFeeTokenSend), ofValue(state$, selectSourceTokenSend), ofValue(state$, selectDestinationAddress), ofValue(state$, selectSourceTokenAmount)).pipe(debounceTime(500), filterValidState(state$), 
    // TODO add take until in case of Insufficient balance in error rejected
    map(() => {
        return getZyfiGeneralTx();
    }));
};
