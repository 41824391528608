import { toast } from '@ondefy/ondefy-ui';
import { ofType } from 'redux-observable';
import { finalize, from, of, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Web3$ } from '../../../__common__/web3/web3-observable';
import { EPages } from '../../../__common__/constants';
import { ESwappers, WETH_DEPOSIT_SIG_HASH, WETH_WITHDRAW_SIG_HASH } from '../../../__common__/constants/contracts';
import { ETransactionStatus, ETransactionType } from '../../../__common__/constants/transaction';
import { setPage } from '../../../__common__/slice';
import { selectUserAddress } from '../../../wallet/selectors';
import { TX_SPEED_DEFAULT } from '../../constants';
import { getRawTx } from '../../frp-transducers';
import { selectBestSwapRate, selectGasFees, selectNetworkIdTargetSwap, selectSourceTokenAmount, selectSourceTokenSwap, selectTargetTokenSwap } from '../../selectors';
import { zyfiRawSwapProgress } from '../../signals/zyfi-raw-swap-progress';
import { saveTransaction, swapTransactionRaw, swapTransactionFullfilled, swapTransactionRejected } from '../../slice';
/*
 * This method is broadcasted when we have source token Ethereum and fee token is also Ethereum
 * */
export const epicRunSwapRawTransaction = (action$, state$) => {
    return action$.pipe(ofType(swapTransactionRaw.toString()), tap(() => {
        zyfiRawSwapProgress.value = true;
    }), switchMap(() => {
        const { value } = state$;
        const targetNetworkId = selectNetworkIdTargetSwap(value);
        // 3. Broadcast transaction
        const userAddress = selectUserAddress(value);
        const bestRate = selectBestSwapRate(value);
        const fromToken = selectSourceTokenSwap(value);
        const toToken = selectTargetTokenSwap(value);
        const fromAmount = selectSourceTokenAmount(value);
        const gasFees = selectGasFees(value);
        const submitParams = {
            type: ETransactionType.swap,
            userAddress,
            networkId: targetNetworkId,
            swapperIdx: bestRate.swapperIdx,
            feeRateBps: 0,
            fromTokenId: fromToken.id,
            fromTokenAddress: fromToken.address,
            fromTokenSymbol: fromToken.symbol,
            fromTokenLogoURI: fromToken.logoURI,
            fromTokenAmount: bestRate.fromAmount,
            fromTokenDecimals: fromToken.decimals,
            fromTokenPriceUsd: fromToken.price,
            toTokenId: toToken.id,
            toTokenAddress: toToken.address,
            toTokenSymbol: toToken.symbol,
            toTokenLogoURI: toToken.logoURI,
            toTokenAmount: bestRate.toAmount,
            toTokenDecimals: toToken.decimals,
            toTokenPriceUsd: toToken.price,
            volumeUsd: (fromToken.price * parseFloat(fromAmount)).toFixed(2),
            status: ETransactionStatus.pending,
            isHidden: 0,
            timestamp: Math.floor(Date.now() / 1000)
        };
        // TODO: ask Clement why 1.3
        // TODO: ask Clement why 1.3
        // TODO: ask Clement why 1.3
        const gasLimit = bestRate.gasLimit;
        const rawTx = getRawTx(state$);
        const { value: Web3 } = Web3$;
        // For wrapping or unwrapping native token (e.g. ETH -> WETH, WETH -> ETH, MATIC -> WMATIC...), the WETH contract can be called directly, which does not count as a swap
        if (String(rawTx.data).startsWith(WETH_DEPOSIT_SIG_HASH) ||
            String(rawTx.data).startsWith(WETH_WITHDRAW_SIG_HASH)) {
            submitParams.swapperIdx = ESwappers.WETH_SWAPPER_IDX;
        }
        // TODO: ask Clement how to handle this speed and what it is
        // TODO: ask Clement how to handle this speed and what it is
        // TODO: ask Clement how to handle this speed and what it is
        const txSpeed = TX_SPEED_DEFAULT;
        // this value is the id of the transaction - it should be stored to localStorage
        const transactionParams = {
            rawTx,
            networkId: targetNetworkId,
            gasPriceData: gasFees.value[txSpeed],
            gasLimit
        };
        return of(null).pipe(switchMap(() => {
            return from(Web3.broadcastTransaction(transactionParams, { type: ETransactionType.swap, useUncheckedTransaction: true }));
        }), 
        // retry({
        //   count: 10,
        //   delay: 1000
        // }),
        // 4. Save transaction to our backend
        switchMap(txHash => {
            const submitParamsWithHash = Object.assign(Object.assign({}, submitParams), { txHash });
            return from([
                swapTransactionFullfilled(submitParamsWithHash),
                setPage(EPages.swapResultZyfi),
                saveTransaction(submitParamsWithHash)
            ]);
        }), catchError(e => {
            var _a;
            console.log(e);
            console.log(((_a = e.data) === null || _a === void 0 ? void 0 : _a.message) || e.message);
            toast.next({ variant: 'error', message: 'Swap rejected' });
            return of(swapTransactionRejected({
                message: 'Transaction failed'
            }));
        }), finalize(() => {
            zyfiRawSwapProgress.value = false;
        }));
    }));
};
