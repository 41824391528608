import { ENV } from '../../../../../env';
import { useAppSelector } from '../../../../../redux/hooks';
import { selectBestSwapRate, selectEnoughBalance, selectIsZyfiNotEnough, selectSwapRates, selectZyFiGeneralTx } from '../../../selectors';
import { useDelayedTrue } from './use-delayed-true';
export const useIsInsufficientToPay = () => {
    const isNotEnoughZyfi = useAppSelector(selectIsZyfiNotEnough);
    const isEnoughTokens = useAppSelector(selectEnoughBalance);
    const bestRate = useAppSelector(selectBestSwapRate);
    const swapRates = useAppSelector(selectSwapRates);
    const generalTx = useAppSelector(selectZyFiGeneralTx);
    const isInsufficiantToPay = useDelayedTrue(Boolean(ENV.isZyfi() && !swapRates.isLoading && !generalTx.isLoading && isNotEnoughZyfi && !(isEnoughTokens && bestRate)), 1000);
    return isInsufficiantToPay;
};
