import { getPublicClient } from '../../__common__/web3/get-public-client';
import { from } from 'rxjs';
export const checkTransactionStatus = (transaction) => {
    const { txHash, networkId } = transaction;
    const client = getPublicClient(networkId);
    // TODO: CHECK THE STATUS OF TRANSACTION!!!
    // TODO: CHECK THE STATUS OF TRANSACTION!!!
    // TODO: CHECK THE STATUS OF TRANSACTION!!!
    return from(client.getTransactionReceipt({ hash: txHash }));
};
