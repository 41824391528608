import { SBox, styled } from '@ondefy/ondefy-ui';
import { css } from 'styled-components';
export const SCollapseMoreDetails = styled(SBox) `
  display: flex;
  flex: 1;
  flex-direction: column;

  .dropdown__trigger {
    width: 100%;
    justify-content: space-between;
    background: ${p => (p.theme.id === 'light' ? p.theme.colors.bg6 : p.theme.colors.bg2)};
    border-width: ${p => (p.theme.id === 'light' ? 0 : 'inherit')};
  }

  .dropdown__collapsable {
    overflow: hidden;
    background: ${p => (p.theme.id === 'light' ? p.theme.colors.bg6 : p.theme.colors.bg2)};
    transition: height ease 0.2s;
    padding: 0 10px;
    border-bottom-left-radius: 12.5px;
    border-bottom-right-radius: 12.5px;
  }

  [class*='icon-'] {
    transition: transform 0.2s;
  }

  .transaction__route {
    .transaction__icon {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: ${p => p.theme.colors.bg2};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
    }

    .transaction__icon--scaled {
      transform: scale(0.67);
      transform-origin: center;
    }

    .transaction__icon--light {
      border: 1px solid ${p => p.theme.colors.bg3};
    }

    .transaction__icon--ondefy {
      width: 16px;
      height: 16px;
      border: 1px solid ${p => p.theme.colors.accent};
      background-color: transparent;
      border-radius: 100%;

      > div {
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        background: ${p => p.theme.gradients.default};
        border-radius: 100%;
      }
    }

    .transaction__steps {
      padding-bottom: 15px;
    }

    .transaction__steps > div {
      position: relative;
    }

    .transaction__steps > div:not(:last-child) {
      margin-bottom: 19px;
    }

    .transaction__step-separator {
      display: none;
      top: -20px;
      position: absolute;
    }

    .transaction__steps > div:not(:first-child) {
      .transaction__step-separator {
        display: block;
      }
    }
  }

  ${p => p.opened &&
    css `
      .dropdown__trigger {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: 1px;

        [class*='icon-'] {
          transform: rotate(180deg);
        }
      }
    `};
`;
