import { ENV } from '../../../env';
import { Numbo } from '../../__common__/numbo/transducers';
export const isZyfiTokenNotEnough = (generalTxZyfi, sourceTokenAmount, sourceTokenInWallet, feeTokenInWallet, isEthereumSource, sourceToken) => {
    if (!generalTxZyfi.value || !sourceTokenAmount) {
        return;
    }
    const sourceDecimals = (sourceToken === null || sourceToken === void 0 ? void 0 : sourceToken.decimals) || 0;
    const feeTokenAmount = Numbo.from(generalTxZyfi.value.feeTokenAmount);
    const sourceAmount = Numbo.floatToBigNumber(sourceTokenAmount || '0', sourceDecimals);
    const sourceAmountInWallet = Numbo.floatToBigNumber((sourceTokenInWallet === null || sourceTokenInWallet === void 0 ? void 0 : sourceTokenInWallet.amount) || '0', sourceDecimals);
    if (ENV.isZyfi() && isEthereumSource) {
        const feeAmountInWallet = Numbo.floatToBigNumber((feeTokenInWallet === null || feeTokenInWallet === void 0 ? void 0 : feeTokenInWallet.amount) || '0', sourceDecimals);
        return feeTokenAmount.gt(feeAmountInWallet);
    }
    return sourceAmount.add(feeTokenAmount).gt(sourceAmountInWallet);
};
