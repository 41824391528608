import { ENetworkIdByName } from '../../../constants/networks';
import { GAS_OVERHEAD_SWAP_ROUTER, GAS_OVERHEAD_SWAP_ROUTER_ARBITRUM } from '../constants/gas-fee-estimate';
export const getGasFeeForParaswap = (networkId, routeResData, usingOndefyRouter) => {
    let gasEstimate = Number(routeResData.priceRoute.gasCost);
    if (gasEstimate > 0 && usingOndefyRouter) {
        gasEstimate +=
            networkId === ENetworkIdByName['arbitrum-one'] ? GAS_OVERHEAD_SWAP_ROUTER_ARBITRUM : GAS_OVERHEAD_SWAP_ROUTER;
    }
    const gasLimit = gasEstimate * 1.5;
    return {
        gasEstimate,
        gasLimit
    };
};
