// https://github.com/vercel/next.js/blob/canary/examples/with-redux/src/features/counter/counterSlice.ts
import { createSlice } from '@reduxjs/toolkit';
// import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { initialState } from './state';
// @ts-ignore
import * as reducers from './reducers';
import { sliceNamespace } from './constants';
/*
 * REDUCERS
 * */
export const slice = createSlice({
    name: sliceNamespace,
    initialState,
    /*
     * REDUCERS SIMPLE
     * */
    reducers
});
/*
 * ACTIONS
 * */
export const { setAppMode, setPage, reset, setSearchTokenValue, flush, getTokenContracts, getTokenContractsRejected, getTokenContractsFullfilled, setTemporaryNetworkId, setIframeMode } = slice.actions;
/*
 * PERSIST CONFIG
 * */
const persistConfig = {
    key: sliceNamespace,
    storage: localForage,
    whitelist: [
        'page',
        'appMode'
        // 'sourceAmount'
    ]
};
export default persistReducer(persistConfig, slice.reducer);
