import { SText, styled } from '@ondefy/ondefy-ui';
export const SNumboGroupLabel = styled(SText) `
  position: relative;
  display: inline-block;

  font-weight: inherit;
  font-size: inherit;
  color: inherit;

  &:before,
  &:after {
    content: attr(data-reps);
    display: block;
    transform: scale(0.6);
    transform-origin: bottom;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
