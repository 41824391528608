import { delayWhen, finalize, Observable, of } from 'rxjs';
import { expand, filter, take, tap } from 'rxjs/operators';
export function repeatSwitch(countOrConfig) {
    let config = {};
    if (typeof countOrConfig === 'number') {
        config.count = countOrConfig;
    }
    else if (typeof countOrConfig === 'object') {
        config = countOrConfig;
    }
    const { count = Infinity, delay: delayConfig = 1000 } = config;
    let i = count;
    let counter = 0;
    let timerId;
    let intervalId;
    let delaySubscription;
    const clearTimers = () => {
        if (timerId) {
            clearTimeout(timerId);
            timerId = null;
        }
        if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
        }
        // console.log('UNSUB', delaySubscription)
        if (delaySubscription) {
            delaySubscription.unsubscribe();
            delaySubscription = null;
        }
        counter = 0;
    };
    return (source) => {
        return new Observable(observer => {
            // console.log('OBSERVABLE INIT')
            // Subscription to the source observable
            const subscription = source
                .pipe(finalize(() => {
                // if the source is finalized like takeUntil or something
                // console.log('FINALIZE!!!')
                clearTimers();
            }))
                .subscribe({
                next(value) {
                    // console.log('NEXT')
                    clearTimers();
                    // console.log(`Received value: ${value}`)
                    // observer.next((value as any) * 2) // Assuming the source observable emits numbers
                    // pass the first value through
                    observer.next(value);
                    if (typeof delayConfig === 'number')
                        timerId = setTimeout(() => {
                            intervalId = setInterval(() => {
                                // console.log('NEXT TICK')
                                observer.next(value);
                                counter += 1;
                                i -= 1;
                                if (i === 0 || counter >= count) {
                                    clearTimers();
                                }
                            }, delayConfig);
                        }, delayConfig);
                    if (typeof delayConfig === 'function') {
                        // think up about setTimeout analog before setInterval for expand
                        delaySubscription = of(null)
                            .pipe(expand(() => {
                            // console.log('EXPAND TICK')
                            counter += 1;
                            return of(counter).pipe(delayWhen(() => delayConfig(counter)), tap(() => {
                                // console.log('AFTER DELAY')
                            }));
                        }), 
                        // To prevent emitting original value instantly when we init this recursion
                        filter(v => v !== null), take(count))
                            .subscribe(v => {
                            // console.log(' NEXT TICK SUBSCRIPTION', v, counter)
                            observer.next(value);
                        });
                    }
                },
                error(err) {
                    // console.log('ERROR')
                    clearTimers();
                    observer.error(err);
                },
                complete() {
                    // console.log('COMPLETE')
                    clearTimers();
                    observer.complete();
                }
            });
            // Return the teardown logic
            return () => {
                clearTimers();
                subscription.unsubscribe();
            };
        });
    };
}
