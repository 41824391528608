/*
 * SELECTORS
 * */
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash/fp';
import { checkIfAllExist } from '../__common__/transducers/check-if-all-exist';
import { getTransactionStatus } from './transducers/get-transaction-status';
import { selectTokenContractsAssoc } from '../__common__/selectors';
import { findTokenByIdInAssoc } from '../__common__/transducers/find-token-by-id';
import { sliceNamespace } from './constants';
import { validateInput } from './transducers/validate-input';
export const selectCustom = (state) => _.path(sliceNamespace, state);
export const selectTransactionData = createSelector(selectCustom, state => state.transactionData);
export const selectDestinationAddress = createSelector(selectCustom, state => state.destinationAddress);
export const selectTargetTokenIdCustom = createSelector(selectCustom, state => state.targetTokenIdCustom);
export const selectNetworkIdTargetCustom = createSelector(selectCustom, state => state.networkIdTargetCustom);
export const selectTargetTokenCustom = createSelector([selectTokenContractsAssoc, selectNetworkIdTargetCustom, selectTargetTokenIdCustom], findTokenByIdInAssoc);
export const selectZyFiGeneralTx = createSelector(selectCustom, state => state.zyfiGeneralTx);
export const selectPendingTransactions = createSelector(selectCustom, state => state.pendingTransactions);
export const selectCustomTransaction = createSelector(selectCustom, state => state.customTransaction);
export const selectCustomTransactionStatus = createSelector(selectCustomTransaction, getTransactionStatus);
export const selectIsAllFilled = createSelector(selectNetworkIdTargetCustom, selectTargetTokenCustom, selectDestinationAddress, selectTransactionData, checkIfAllExist);
export const selectIsValidInput = createSelector(selectDestinationAddress, selectTransactionData, validateInput);
