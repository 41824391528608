import { sliceNamespace as commonReducerName } from '../features/__common__/constants';
import commonReducer from '../features/__common__/slice';
import { sliceNamespace as buyReducerName } from '../features/1 - buy/constants';
import buyReducer from '../features/1 - buy/slice';
import { sliceNamespace as swapReducerName } from '../features/2 - swap/constants';
import swapReducer from '../features/2 - swap/slice';
import { sliceNamespace as iframeReducerName } from '../features/iframe/constants';
import iframeReducer from '../features/iframe/slice';
import { sliceNamespace as walletReducerName } from '../features/wallet/constants';
import walletReducer from '../features/wallet/slice';
import { sliceNamespace as customReducerNamespace } from '../features/3 - custom/constants';
import customReducer from '../features/3 - custom/slice';
import { sliceNamespace as sendReducerNamespace } from '../features/4 - send/constants';
import sendReducer from '../features/4 - send/slice';
// †import
export const appReducers = {
    [buyReducerName]: buyReducer,
    [swapReducerName]: swapReducer,
    [walletReducerName]: walletReducer,
    [commonReducerName]: commonReducer,
    [iframeReducerName]: iframeReducer,
    [customReducerNamespace]: customReducer,
    [sendReducerNamespace]: sendReducer
    // †reducer
};
