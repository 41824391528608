import dayjs from 'dayjs'; // ConfigType, //QUnitType, //OpUnitType, // ManipulateType, // Dayjs,
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import timezone from 'dayjs/plugin/timezone';
// import _ from 'lodash/fp';
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(timezone);
// type UnitTypeShort = 'd' | 'M' | 'y' | 'h' | 'm' | 's' | 'ms';
// type UnitTypeLong =
//   | 'millisecond'
//   | 'second'
//   | 'minute'
//   | 'hour'
//   | 'day'
//   | 'month'
//   | 'year'
//   | 'date';
// type UnitTypeLongPlural =
//   | 'milliseconds'
//   | 'seconds'
//   | 'minutes'
//   | 'hours'
//   | 'days'
//   | 'months'
//   | 'years'
//   | 'dates';
// type UnitType = UnitTypeLong | UnitTypeLongPlural | UnitTypeShort;
// type OpUnitType = UnitType | 'week' | 'weeks' | 'w';
// type QUnitType = UnitType | 'quarter' | 'quarters' | 'Q';
export const DateTime = {
    // static diff(
    //   dateFrom: ConfigType,
    //   dateTo: ConfigType,
    //   unit?: QUnitType | OpUnitType,
    //   float?: boolean
    // ): number {
    //   return dayjs.utc(dateFrom).diff(dayjs.utc(dateTo), unit, float);
    // }
    // static getUnix(date?: ConfigType): number {
    //   return dayjs(date).unix();
    // }
    // static format(
    //   date?: ConfigType,
    //   template?: string,
    //   options?: { useTimezone: boolean | undefined }
    // ): string {
    //   return dayjs(date)
    //     .utc(_.path('useTimezone', options) ? undefined : true)
    //     .format(template);
    // }
    // static formatTimezone(date?: ConfigType, template?: string): string {
    //   return dayjs(date)
    //     .utc(true)
    //     .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    //     .format(template);
    // }
    // static formatTimestamp(timestamp: number, template?: string): string {
    //   return dayjs.unix(timestamp).utc().format(template);
    // }
    formatTimezoneWithTimestamp(timestamp, template) {
        return dayjs.unix(timestamp).utc(true).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(template);
    }
    // static toDate(date?: ConfigType): Date {
    //   return dayjs.utc(date).toDate();
    // }
    // static add(value: number, unit: ManipulateType): Dayjs {
    //   return dayjs.utc().add(value, unit);
    // }
    //
    // static startOf(unit: OpUnitType, date: Dayjs): Dayjs {
    //   return date.startOf(unit);
    // }
    // static subtract(
    //   date: ConfigType,
    //   config: {
    //     years?: number;
    //     months?: number;
    //     weeks?: number;
    //     days?: number;
    //     hours?: number;
    //     minutes?: number;
    //     seconds?: number;
    //   }
    // ): Date {
    //   const { years, months, weeks, days, hours, minutes, seconds } = config;
    //
    //   return dayjs
    //     .utc(date)
    //     .subtract(
    //       years || months || weeks || days || minutes || hours || seconds || 0,
    //       ('years' in config && 'years') ||
    //         ('months' in config && 'months') ||
    //         ('weeks' in config && 'weeks') ||
    //         ('days' in config && 'days') ||
    //         ('hours' in config && 'hours') ||
    //         ('minutes' in config && 'minutes') ||
    //         ('seconds' in config && 'seconds') ||
    //         'seconds'
    //     )
    //     .toDate();
    // }
    // static weekday(date: ConfigType): number {
    //   return dayjs(date).weekday();
    // }
};
