import { toast } from '@ondefy/ondefy-ui';
import { ofType } from 'redux-observable';
import { of, switchMap } from 'rxjs';
import { catchError, debounceTime, map, takeUntil } from 'rxjs/operators';
import { setAppMode, setPage } from '../../__common__/slice';
import { selectNetworkIdTargetSwap } from '../selectors';
import { getGasPricesRx } from '../api/get-gas-fees';
import { getGasFees, getGasFeesFullfilled, getGasFeesRejected, getSwapRatesRejected } from '../slice';
export const epicGetGasFees = (action$, state$) => {
    return action$.pipe(ofType(getGasFees.toString()), debounceTime(16), switchMap(() => {
        const { value } = state$;
        const networkId = selectNetworkIdTargetSwap(value);
        return getGasPricesRx(networkId).pipe(takeUntil(action$.pipe(ofType(setPage.toString(), setAppMode.toString()))), map(v => getGasFeesFullfilled(v)), catchError(e => {
            toast.next({
                variant: 'error',
                message: 'Failed to fetch gas fees'
            });
            return of(getGasFeesRejected(e), getSwapRatesRejected(e));
        }));
    }));
};
