import numeral from 'numeral';
import _ from 'lodash/fp';
export const formatNumberWithLetterOrExp = (amount, numberOfFixed) => {
    const numberValue = typeof amount === 'string' ? Number(amount) : amount;
    if (numberValue === 0) {
        return '0';
    }
    if (!numberOfFixed) {
        return String(amount);
    }
    const threshold = 0.1 / 10 ** numberOfFixed;
    if (numberValue < threshold) {
        // Clean up the exponential format
        let exponential = numberValue.toExponential();
        // Remove unnecessary zeros after the decimal in the exponent
        const matches = exponential.match(/(\d+\.\d+)(e[+-]\d+)/);
        if (matches) {
            exponential = `${parseFloat(matches[1])}${matches[2]}`;
        }
        return exponential;
    }
    const zeros = _.repeat(numberOfFixed, '0');
    // TODO: get rid of this library it seems like it have limit in 7 zeros after dot
    // TODO: instead implement just own formatting
    return numeral(numberValue.toString()).format(`0.[${zeros}]a`);
};
