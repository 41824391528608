import { SText, useTheme } from '@ondefy/ondefy-ui';
import React from 'react';
import { ENV } from '../../../../../../env';
// import { usePaperResetConfirm } from '../../../../papers/paper-reset-confirm/__hooks__/use-paper-reset-confirm'
import { SPoweredBy } from './__styled__/s-powered-by';
import LogoZkSync from './__assets__/zk.svg';
export const PoweredBy = () => {
    // const { tryReset } = usePaperResetConfirm()
    const { theme } = useTheme();
    return (React.createElement(SPoweredBy, null,
        React.createElement(SText, { type: "small" },
            "Powered By",
            ' ',
            React.createElement(SText, null, ENV.isZyfi() ? (React.createElement(LogoZkSync, null)) : (React.createElement("svg", { className: "ondefy__skeleton-loader", fill: "none", height: "47", viewBox: "0 0 47 47", width: "47", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { d: "M23.1992 0C21.3422 0 19.537 0.220286 17.8061 0.634714V0.635392C7.59463 3.08497 0 12.3334 0 23.3682C0 34.4053 7.59492 43.6538 17.8068 46.1032C19.5375 46.5175 21.3425 46.7374 23.1992 46.7374C36.0113 46.7374 46.3974 36.2735 46.3974 23.3678C46.3974 10.462 36.0113 0 23.1992 0ZM17.8068 45.7982V38.9195C9.34998 38.8374 2.52235 31.9056 2.52235 23.3683C2.52235 14.8311 9.34998 7.90115 17.8068 7.81904V0.939242C27.44 3.78088 34.4749 12.7473 34.4749 23.3678C34.4749 33.9882 27.44 42.9566 17.8068 45.7982Z", fill: `${theme.colors.typo}` })))))));
};
