/*
 * HOOK
 * */
import { useAppSelector } from '../../../redux/hooks';
import { useEntity } from '../../../redux/redux-entity';
import { selectUserAddress, selectUserWallet } from '../selectors';
import { getUserWallet } from '../slice';
export const useUserWallet = (config) => {
    const userAddress = useAppSelector(selectUserAddress);
    return useEntity(Object.assign(Object.assign({}, (config || {})), { queryAction: () => {
            return getUserWallet(userAddress);
        }, selector: selectUserWallet }), [userAddress]);
};
