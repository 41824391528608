var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parseAbi } from 'viem';
import { getPublicClient } from '../../__common__/web3/get-public-client';
import { Numbo } from '../../__common__/numbo/transducers';
import { PERMIT_VALIDITY } from '../constants/permit-signing';
export function getPermitToSign(signerAddress, spenderAddress, value, networkId, tokenAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const USDC_MINI_ABI = parseAbi([
                'function name() public view returns (string memory)',
                'function nonces(address owner) external view returns (uint256)',
                'function DOMAIN_SEPARATOR() external view returns (bytes32)',
                'function PERMIT_TYPEHASH() external view returns (bytes32)',
                'function EIP712_VERSION() public view returns (string memory)', // polygon
                'function version() public view returns (string memory)' // ethereum
            ]);
            const client = getPublicClient(networkId);
            // const contract = getNewContractViem(tokenAddress as `0x${string}`, USDC_MINI_ABI, client)
            const readContract = (functionName) => __awaiter(this, void 0, void 0, function* () {
                return yield client.readContract({
                    // @ts-ignore
                    address: tokenAddress,
                    abi: USDC_MINI_ABI,
                    functionName
                });
            });
            const name = yield readContract('name');
            const nonce = yield readContract('nonces');
            const domain = Object.assign({
                name,
                verifyingContract: tokenAddress
            }, yield {
                1: () => __awaiter(this, void 0, void 0, function* () {
                    return ({
                        version: yield readContract('version'),
                        chainId: networkId
                    });
                }),
                137: () => __awaiter(this, void 0, void 0, function* () {
                    return ({
                        version: yield readContract('EIP712_VERSION'),
                        salt: Numbo.bytes.hexZeroPad(Numbo.from(networkId).toHexString(), 32)
                    });
                }),
                42161: () => __awaiter(this, void 0, void 0, function* () {
                    return ({
                        version: '1',
                        chainId: networkId
                    });
                })
            }[networkId]());
            const types = {
                Permit: [
                    {
                        name: 'owner',
                        type: 'address'
                    },
                    {
                        name: 'spender',
                        type: 'address'
                    },
                    {
                        name: 'value',
                        type: 'uint256'
                    },
                    {
                        name: 'nonce',
                        type: 'uint256'
                    },
                    {
                        name: 'deadline',
                        type: 'uint256'
                    }
                ]
            };
            const deadline = Date.now() + PERMIT_VALIDITY; // 2 days from now
            const msg = {
                owner: signerAddress,
                spender: spenderAddress,
                value,
                nonce,
                deadline
            };
            return { domain, types, msg, deadline, name, nonce, primaryType: 'Permit' };
        }
        catch (e) {
            return yield Promise.reject(e);
        }
    });
}
