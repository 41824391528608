import { toast } from '@ondefy/ondefy-ui';
import { useCallback, useRef, useEffect } from 'react';
export function useWalletOnReconnect() {
    const disconnectTimer = useRef(null);
    const callback = useCallback(() => {
        toast.next({
            variant: 'info',
            message: `Are you trying to completely disconnect your wallet? Try disconnecting from within your wallet app, as sometimes it’s not possible to do so from the widget.`
        });
    }, []);
    const onConnect = useCallback(() => {
        // Clear the timer if it's running
        if (disconnectTimer.current) {
            clearTimeout(disconnectTimer.current);
            disconnectTimer.current = null;
            callback();
        }
    }, [callback]);
    const onDisconnect = useCallback(() => {
        if (disconnectTimer.current) {
            clearTimeout(disconnectTimer.current);
        }
        disconnectTimer.current = setTimeout(() => {
            disconnectTimer.current = null;
        }, 5000);
    }, []);
    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (disconnectTimer.current) {
                clearTimeout(disconnectTimer.current);
            }
        };
    }, []);
    return {
        onConnect,
        onDisconnect
    };
}
