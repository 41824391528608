import { styled } from '@ondefy/ondefy-ui';
import { css } from 'styled-components';
export const SLoadingIndicator = styled.svg `
  ${p => {
    return css `
      circle {
        transform-origin: ${p.svgSize / 2}px ${p.svgSize / 2}px 0;
        animation: spinner 4s linear infinite;
        stroke-dasharray: ${3.14 * p.svgSize};
        fill: transparent;
        stroke-width: ${p.strokeWidth};
        stroke-linecap: round;
      }

      @keyframes spinner {
        0% {
          stroke-dashoffset: ${0.66 * p.svgSize};
          transform: rotate(0deg);
        }
        50% {
          stroke-dashoffset: ${3.14 * p.svgSize};
          transform: rotate(720deg);
        }
        100% {
          stroke-dashoffset: ${0.66 * p.svgSize};
          transform: rotate(1080deg);
        }
      }
    `;
}}
`;
