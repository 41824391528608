import React from 'react';
import { OSkeleton, SText, useTheme, SBox } from '@ondefy/ondefy-ui';
import { STransactionLine } from '../../__styled__/s-transaction-line';
export const TransactionLine = ({ icon, loading, value, description, showVerticalGuide }) => {
    const { theme } = useTheme();
    return (React.createElement(STransactionLine, { showVerticalGuide: showVerticalGuide },
        showVerticalGuide && (React.createElement("svg", { className: "transaction__dots", width: "4px", height: "40px" },
            React.createElement("line", { x1: "2", x2: "2", y1: "2", y2: "40", stroke: theme.colors.bg3, strokeWidth: "2", strokeLinecap: "round", strokeDasharray: "1, 6" }))),
        icon,
        loading && (React.createElement(SBox, { ml: "10px" },
            React.createElement(OSkeleton, { width: 40 }))),
        !loading && (React.createElement(SText, { ml: "8px", fontWeight: "semibold" }, value)),
        description && React.createElement(SText, { ml: "4px" }, description)));
};
