import localforage from 'localforage';
import { from, of, switchMap, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ENV } from '../../../env';
import { ENetworkNames, NETWORKS_DATA } from '../constants/networks';
import { apirxToken } from './services/api-token';
export const getTokenContractsRx = () => {
    return apirxToken.head('/lastUpdateTime', {}, { returnResponseConfig: true }).pipe(catchError(e => throwError(() => new Error(e))), switchMap(res => {
        const tokenContractsLastUpdateKey = 'xLastUpdateTime';
        const tokenContractsKey = 'tokenContracts';
        const lastUpdateTime = res.headers.get('x-last-update-time');
        const storage = localforage.createInstance({
            name: 'ondefy:cache'
        });
        const request = () => {
            return apirxToken.get('/getTokenContractsSorted', {
                byNetwork: true,
                platformId: ENV.isZyfi()
                    ? [ENetworkNames.zksync]
                    : Object.values(NETWORKS_DATA).map((network) => network.id)
            });
        };
        return from(storage.setDriver([localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE])).pipe(catchError(e => {
            console.log('localforage init error ', e);
            // ignore cache error and just call the api without caching
            return request();
        }), switchMap(db => from(storage.getItem(tokenContractsKey)).pipe(switchMap(cachedValue => {
            if (!cachedValue || parseInt(lastUpdateTime) > parseInt(cachedValue[tokenContractsLastUpdateKey])) {
                return request().pipe(tap(newRes => {
                    // trigger optional side effect to cache the response
                    storage
                        .setItem(tokenContractsKey, {
                        [tokenContractsLastUpdateKey]: lastUpdateTime,
                        response: newRes
                    })
                        .catch(e => {
                        console.log('Failed to save to Cache', e.message);
                    });
                }));
            }
            else {
                return of(cachedValue.response);
            }
        }), catchError(e => {
            console.log('Failed to get item from cache', e);
            return throwError(() => new Error(e));
        }))));
    }));
};
// WARNING: History snippet - works well but Brave browser Shields block IndexDB completely
// import { openDB } from 'idb'
// export const getTokenContractsRx = () => {
//   return apirxToken.head('/lastUpdateTime', {}, { returnResponseConfig: true }).pipe(
//     catchError(e => throwError(() => new Error(e))),
//     switchMap(res => {
//       const lastUpdateTime = res.headers.get('x-last-update-time')
//
//       return from(
//         openDB('ondefy', 1, {
//           upgrade(db) {
//             if (!db.objectStoreNames.contains('responses')) {
//               db.createObjectStore('responses')
//             }
//           }
//         })
//       ).pipe(
//         catchError(e => {
//           console.log('indexedb error ', e)
//           return throwError(() => new Error(e))
//         }),
//         switchMap(db =>
//           from(db.get('responses', 'lastUpdateTime')).pipe(
//             catchError(e => {
//               // Handle the case where the 'responses' object store does not exist yet
//               if (e.name === 'NotFoundError') {
//                 return of(null)
//               } else {
//                 return throwError(() => new Error(e))
//               }
//             }),
//             switchMap(savedRes => {
//               if (!savedRes || parseInt(lastUpdateTime) > parseInt(savedRes.xLastUpdateTime)) {
//                 return apirxToken
//                   .get<TTokenContacts>('/getTokenContractsSorted', {
//                     byNetwork: true,
//                     platformId: Object.values(NETWORKS_DATA).map((network: INetwork) => network.id)
//                   })
//                   .pipe(
//                     tap(newRes => {
//                       // trigger optional side effect to cache the response
//                       db.put(
//                         'responses',
//                         { xLastUpdateTime: lastUpdateTime, response: newRes },
//                         'lastUpdateTime'
//                       ).catch(e => {
//                         console.log('Failed to save to IndexDB', e.message)
//                       })
//                     })
//                   )
//               } else {
//                 return of(savedRes.response)
//               }
//             })
//           )
//         )
//       )
//     })
//   )
// }
