var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OIcon, OIconNet, OMedia, SBox, SFlexbox, SText, toast } from '@ondefy/ondefy-ui';
// @ts-ignore
import SvgOndefyWhite from '@ondefy/ondefy-ui/dist/assets/svg/ondefy-white.svg';
// @ts-ignore
import SvgTransak from '@ondefy/ondefy-ui/dist/assets/svg/transak.svg';
import _ from 'lodash/fp';
import { selectTokenContracts } from '../../../../../__common__/selectors';
import { selectTransactionStatus, selectTransakOrderNetworkId } from '../../../../selectors';
import { NETWORKS_DATA } from '../../../../../__common__/constants/networks';
import { ORDER_RAMP_STATUS_FAILED, ORDER_RAMP_STATUS_FULFILLED } from '../../../../constants/order-ramp-status';
import { ORDER_SWAP_STATUS_FULFILLED } from '../../../../constants/order-swap-status';
import { USDC_LOGO } from '../../../../constants';
import { selectWalletType } from '../../../../../wallet/selectors';
import { findTokenById } from '../../../../../__common__/transducers/find-token-by-id';
import { useWallet } from '../../../../../wallet/hooks/use-wallet';
import { WALLETS } from '../../../../../wallet/constants/wallets';
import { useIframeUrlColors } from '../../../../../iframe/hooks/use-iframe-url-colors';
import React from 'react';
import { EEntityStatus } from '../../../../../../redux/redux-entity';
import { useAppSelector } from '../../../../../../redux/hooks';
import { TransactionSpinner as Spinner } from './transaction-spinner';
export const TransactionRoute = React.forwardRef(({ fiatAmount, fiatSymbol, fiatToTokenRate, selectedToken, refuelAmount, isTransakSupported }, ref) => {
    var _a, _b;
    const { addTokenToPlugin } = useWallet();
    const walletType = useAppSelector(selectWalletType);
    const tokenContracts = useAppSelector(selectTokenContracts);
    const orderNetworkId = useAppSelector(selectTransakOrderNetworkId);
    const network = NETWORKS_DATA[orderNetworkId];
    const transactionStatus = useAppSelector(selectTransactionStatus);
    const { swapStatus, rampStatus } = transactionStatus.value || {};
    const isCompleted = transactionStatus.status === EEntityStatus.success;
    const { colorPrimary } = useIframeUrlColors();
    const selectedByUser = (React.createElement(OMedia, { "justify-content": "space-between" },
        React.createElement(SFlexbox, { alignItems: "start" },
            React.createElement(SBox, { className: "transaction__icon transaction__icon--scaled" },
                React.createElement(OIconNet, { network: _.path('cgKey', selectedToken), coinImage: selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.logoURI, coinName: selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.symbol })),
            React.createElement(SFlexbox, { flexDirection: "column" },
                React.createElement(SText, { type: "small", fontWeight: "semibold" },
                    React.createElement(React.Fragment, null,
                        parseFloat(_.path(['value', 'buyAmount'], fiatToTokenRate)).toFixed(6),
                        " ", selectedToken === null || selectedToken === void 0 ? void 0 :
                        selectedToken.symbol,
                        ' ',
                        React.createElement(SText, { opacity: "0.66", fontWeight: "normal" }, `on ${network === null || network === void 0 ? void 0 : network.name}`))),
                walletType === WALLETS.metamask.key && (selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.id) !== (network === null || network === void 0 ? void 0 : network.defaultCoinId) && (React.createElement(SFlexbox, { alignItems: "center", style: { cursor: 'pointer' } },
                    React.createElement(OIcon, { name: "arrow-right", 
                        // @ts-ignore
                        color: colorPrimary || 'accent' }),
                    React.createElement(SText, { fontWeight: "semibold", type: "small", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            return yield addTokenToPlugin({
                                networkId: orderNetworkId,
                                address: selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.address,
                                symbol: selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.symbol,
                                decimals: selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.decimals,
                                logoURI: selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.logoURI
                            }).catch(() => {
                                toast.next({
                                    variant: 'warning',
                                    message: 'Could not add token to Metamask.'
                                });
                            });
                        }) }, `Add ${selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.symbol} to Metamask`)))))));
    const selectedRefuel = refuelAmount ? (React.createElement(OMedia, { "justify-content": "space-between" },
        React.createElement(SFlexbox, { alignItems: "start" },
            React.createElement(SBox, { className: "transaction__icon transaction__icon--scaled" },
                React.createElement(OIconNet, { network: network === null || network === void 0 ? void 0 : network.id, coinImage: (_a = findTokenById(network === null || network === void 0 ? void 0 : network.defaultCoinId, network === null || network === void 0 ? void 0 : network.networkId, tokenContracts)) === null || _a === void 0 ? void 0 : _a.logoURI, coinName: 'ETH' })),
            React.createElement(SFlexbox, { flexDirection: "column" },
                React.createElement(SText, { type: "small", fontWeight: "semibold" },
                    React.createElement(React.Fragment, null,
                        refuelAmount,
                        " ", network === null || network === void 0 ? void 0 :
                        network.ticker,
                        ' ',
                        React.createElement(SText, { opacity: "0.66", fontWeight: "normal" }, `on ${network === null || network === void 0 ? void 0 : network.name}`))))))) : (React.createElement(React.Fragment, null));
    return (React.createElement(SBox, { mt: "20px", className: "transaction__route", ref: ref, p: undefined },
        React.createElement(SBox, { className: "transaction__steps", p: "18px" },
            React.createElement(OMedia, { justifyContent: "space-between" },
                React.createElement(SFlexbox, { alignItems: "center" },
                    React.createElement(SBox, { className: "transaction__icon transaction__icon--light" },
                        React.createElement(OIcon, { name: "money", size: 10, 
                            // @ts-ignore
                            color: colorPrimary || 'accent' })),
                    React.createElement(SText, { type: "small", fontWeight: "semibold" },
                        fiatAmount,
                        " ",
                        fiatSymbol))),
            React.createElement(OMedia, { justifyContent: "space-between" },
                React.createElement(SFlexbox, { alignItems: "center" },
                    React.createElement(SBox, { className: "transaction__icon transaction__icon--light" },
                        React.createElement(SvgTransak, null)),
                    React.createElement(SText, { type: "small", fontWeight: "semibold" }, "Transak")),
                !isCompleted && rampStatus !== ORDER_RAMP_STATUS_FAILED && rampStatus !== ORDER_RAMP_STATUS_FULFILLED && (React.createElement(Spinner, null)),
                rampStatus === ORDER_RAMP_STATUS_FULFILLED && (React.createElement(OIcon, { name: "check", 
                    // @ts-ignore
                    color: colorPrimary || 'accent' })),
                (rampStatus === ORDER_RAMP_STATUS_FAILED ||
                    (rampStatus !== ORDER_RAMP_STATUS_FULFILLED && isCompleted)) && (React.createElement(OIcon, { name: "close", color: "error" }))),
            isTransakSupported ? (selectedByUser) : (React.createElement(OMedia, { justifyContent: "space-between" },
                React.createElement(SFlexbox, { alignItems: "center" },
                    React.createElement(SBox, { className: "transaction__icon transaction__icon--scaled" },
                        React.createElement(OIconNet, { network: _.path('cgKey', selectedToken), coinImage: USDC_LOGO, coinName: "USDC" })),
                    React.createElement(SText, { type: "small", fontWeight: "semibold" }, (_b = fiatToTokenRate === null || fiatToTokenRate === void 0 ? void 0 : fiatToTokenRate.value) === null || _b === void 0 ? void 0 :
                        _b.intermediateBuyAmount,
                        " USDC",
                        ' ',
                        React.createElement(SText, { opacity: "0.66", fontWeight: "normal" }, `on ${network === null || network === void 0 ? void 0 : network.name}`))))),
            !isTransakSupported && (React.createElement(React.Fragment, null,
                React.createElement(OMedia, { justifyContent: "space-between" },
                    React.createElement(SFlexbox, { alignItems: "center" },
                        React.createElement(SBox, { className: "transaction__icon transaction__icon--ondefy" },
                            React.createElement(SBox, null,
                                React.createElement(SvgOndefyWhite, null))),
                        React.createElement(SText, { type: "small", fontWeight: "semibold" },
                            "Ondefy Swap",
                            ' ',
                            React.createElement(SText, { opacity: "0.66", fontWeight: "normal" }, `on ${network === null || network === void 0 ? void 0 : network.name}`))),
                    !isCompleted && React.createElement(Spinner, null),
                    isCompleted && swapStatus === ORDER_SWAP_STATUS_FULFILLED && (React.createElement(OIcon, { name: "check", 
                        // @ts-ignore
                        color: colorPrimary || 'accent' })),
                    isCompleted && swapStatus !== ORDER_SWAP_STATUS_FULFILLED && (React.createElement(OIcon, { name: "close", color: "error" }))),
                selectedByUser,
                selectedRefuel)))));
});
TransactionRoute.displayName = 'TransactionRoute';
