var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { SText } from '@ondefy/ondefy-ui';
import React, { useMemo } from 'react';
import { Numbo } from '../transducers';
import { SNumboGroupLabel } from './__styled__/s-numbo-group-label';
export const NumboLabel = (_a) => {
    var { label } = _a, rest = __rest(_a, ["label"]);
    const value = useMemo(() => {
        return Numbo.format(label, rest);
    }, [label]);
    const [start, reps, end] = (value === null || value === void 0 ? void 0 : value.includes('{')) ? value.split(/\{|\}/) : [];
    if (reps) {
        return (React.createElement(SText, { fontWeight: "inherit", fontSize: "inherit", color: "inherit" },
            start,
            React.createElement(SNumboGroupLabel, { type: "small", "data-reps": reps }, null),
            end));
    }
    return (React.createElement(SText, { fontWeight: "inherit", fontSize: "inherit", color: "inherit" }, value));
};
