var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// import { sanitizeFloatValue } from '../../../../../../features/__common__/transducers/sanitize-float-value'
import { useInputNumberFormatted } from '../../../../../__common__/numbo/hooks/use-input-number-formatted';
import { selectSourceAmount } from '../../../../selectors';
import { setSourceAmount } from '../../../../slice';
import { OInput } from '@ondefy/ondefy-ui';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
export const SourceFiatAmountInput = () => {
    const dispatch = useAppDispatch();
    const selectedFiatAmount = useAppSelector(selectSourceAmount);
    const [urlSearchParams] = useSearchParams();
    const _a = useInputNumberFormatted(selectedFiatAmount), { blockChanges } = _a, rest = __rest(_a, ["blockChanges"]);
    return (React.createElement(OInput, Object.assign({ zIndex: "2", height: "40px !important", placeholder: "Enter amount", size: "lg", variant: "borderless" }, rest, { onChange: (val) => {
            // const sanitizedValue = sanitizeFloatValue(val)
            // if (selectedFiatAmount !== sanitizedValue) {
            dispatch(setSourceAmount(val));
            // }
        }, disabled: 
        // TODO: it might work for the swap mode as well???
        !!(urlSearchParams.get('fiatAmount') && urlSearchParams.get('fiatCurrency')) })));
};
