/*
 * http://nicolasgallagher.com/redux-modules-and-code-splitting/
 * */
export class ReducerManager {
    constructor() {
        this._emitChange = null;
        this._reducers = {};
    }
    getReducers() {
        return Object.assign({}, this._reducers);
    }
    register(name, reducer) {
        this._reducers = Object.assign(Object.assign({}, this._reducers), { [name]: reducer });
        if (this._emitChange) {
            this._emitChange(this.getReducers());
        }
    }
    unregister(name) {
        this._reducers = Object.assign(Object.assign({}, this._reducers), { [name]: undefined });
        if (this._emitChange) {
            this._emitChange(this.getReducers());
        }
    }
    registerAll(reducers) {
        this._reducers = Object.assign(Object.assign({}, this._reducers), reducers);
        if (this._emitChange) {
            this._emitChange(this.getReducers());
        }
    }
    setChangeListener(listener) {
        this._emitChange = listener;
    }
}
export const reducerManager = new ReducerManager();
