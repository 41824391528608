var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import _ from 'lodash/fp';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../../redux/hooks';
import { NETWORKS_DATA } from '../../../__common__/constants/networks';
import { METAMASK_CONFIGS } from '../../constants/metamask.configs';
import { WALLETS } from '../../constants/wallets';
import { Address } from '../../model.address';
import { resetUserWallet, setIsWalletConnected, setUserAddress, setWalletType } from '../../slice';
import { getSignature } from './utils/get-signature';
import { sendTransaction } from './utils/send-transaction';
export const useWalletConnectConnector = () => {
    const dispatch = useAppDispatch();
    const [provider, setProvider] = useState(null);
    const disconnect = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!provider) {
            throw new Error('WalletConnectProvider is null');
        }
        yield provider.disconnect();
        setProvider(null);
        dispatch(resetUserWallet());
    }), [dispatch, provider]);
    const connect = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        // const optionalChains: number[] = _.flow(
        //   _.values,
        //   _.reduce((acc: number[], next: INetwork) => acc.concat(next.networkId !== 1 ? next.networkId : []))
        // )(NETWORKS_DATA)
        const optionalChains = _.compose(_.reduce((acc, next) => acc.concat(next.networkId !== 1 ? next.networkId : []), []), _.values)(NETWORKS_DATA);
        // const rpcMap = Object.fromEntries(Object.entries(NETWORKS_DATA).map(([key, network]) => [key, network.rpc]))
        const rpcMap = _.mapValues(v => v.rpc, NETWORKS_DATA);
        const config = {
            projectId: process.env.WALLETCONNECT_PROJECT_ID,
            chains: [1],
            optionalChains,
            showQrModal: true,
            events: ['connect', 'disconnect', 'accountsChanged'],
            metadata: {
                name: 'Ondefy Widget',
                description: 'Ondefy Widget',
                url: 'https://ramp.ondefy.com',
                icons: ['']
            },
            methods: ['eth_sendTransaction', 'eth_signTransaction', 'eth_sign', 'personal_sign', 'eth_signTypedData'],
            rpcMap
        };
        const newProvider = yield EthereumProvider.init(config);
        yield newProvider.connect();
        setProvider(newProvider);
        dispatch(setUserAddress(Address.asString(newProvider.accounts[0])));
        dispatch(setWalletType(WALLETS.walletConnect.key));
        dispatch(setIsWalletConnected(true));
    }), [dispatch]);
    const broadcastTransaction = useCallback((params, broadcastConfig) => __awaiter(void 0, void 0, void 0, function* () {
        if (!provider) {
            throw new Error('WalletConnect not initialized');
        }
        try {
            yield provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${params.networkId.toString(16)}` }]
            });
        }
        catch (err) {
            const error = err;
            if (error.code === 4902) {
                yield provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [METAMASK_CONFIGS[params.networkId]]
                });
            }
            else {
                throw err;
            }
        }
        return yield sendTransaction(params, provider, broadcastConfig);
    }), [provider]);
    const signMessage = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        if (!provider) {
            throw new Error('WalletConnect not initialized');
        }
        try {
            yield provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${params.networkId.toString(16)}` }]
            });
        }
        catch (err) {
            const error = err;
            if (error.code === 4902) {
                yield provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [METAMASK_CONFIGS[params.networkId]]
                });
            }
            else {
                throw err;
            }
        }
        return yield getSignature(params, provider);
    }), [provider]);
    useEffect(() => {
        if (provider) {
            const accountChangedHandler = (_accounts) => {
                var _a;
                if (_accounts.length > 0) {
                    (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.reload();
                }
            };
            const disconnectHandler = () => {
                disconnect().catch(() => { });
            };
            provider.on('accountsChanged', accountChangedHandler);
            provider.on('disconnect', disconnectHandler);
            return () => {
                provider.removeListener('accountsChanged', accountChangedHandler);
                provider.removeListener('disconnect', disconnectHandler);
            };
        }
        return () => { };
    }, [provider, disconnect]);
    const web3Config = useMemo(() => ({
        walletType: WALLETS.walletConnect.key,
        isSupportedByBrowser: true,
        connect,
        disconnect,
        broadcastTransaction,
        signMessage
    }), [connect, disconnect, broadcastTransaction, signMessage]);
    return web3Config;
};
