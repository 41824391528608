import { SBox } from '@ondefy/ondefy-ui';
import React from 'react';
import { usePrevious } from 'react-use';
import { useAppSelector } from '../../../../redux/hooks';
import { OPaper } from '../../../__common__/components/o-paper';
import { selectBestSwapRate } from '../../selectors';
import { usePaperSwapRoutes } from './__hooks__/use-paper-swap-routes';
export const PaperSwapRoutes = () => {
    var _a, _b, _c;
    const paper = usePaperSwapRoutes();
    const bestRate = useAppSelector(selectBestSwapRate);
    const cachedRate = usePrevious(bestRate);
    if (!((_a = bestRate === null || bestRate === void 0 ? void 0 : bestRate.meta) === null || _a === void 0 ? void 0 : _a.pathVizImage) && !((_b = cachedRate === null || cachedRate === void 0 ? void 0 : cachedRate.meta) === null || _b === void 0 ? void 0 : _b.pathVizImage)) {
        return null;
    }
    return (React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom", title: "Routes" }),
        React.createElement(SBox, { p: "12px" },
            React.createElement("img", { src: (_c = cachedRate === null || cachedRate === void 0 ? void 0 : cachedRate.meta) === null || _c === void 0 ? void 0 : _c.pathVizImage, alt: "Swap routes" }))));
};
