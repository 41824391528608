import { map } from 'rxjs/operators';
import _ from 'lodash/fp';
import { apirxCoingecko } from '../../../__common__/api/services/api-coingecko';
export function getCoingeckoTokenPriceRx({ tokenId, fiatSymbol }) {
    return apirxCoingecko
        .get('/simple/price', {
        ids: tokenId,
        vs_currencies: `usd,${fiatSymbol}`
    })
        .pipe(map(_.path([tokenId])));
}
