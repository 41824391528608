import _ from 'lodash/fp';
import { EQUAL_ROUND_SYMBOL } from '../../__common__/constants';
import { ENetworkIdByName, NETWORKS_DATA } from '../../__common__/constants/networks';
import { Numbo } from '../../__common__/numbo/transducers';
import { TX_SPEED_DEFAULT } from '../constants';
import { getGasFeeUsd } from './get-gas-fee-usd';
// TODO: write tests for all swappers
export const normalizeRateWithGasFee = ({ swapRateRes, networkTargetId, tokensAssoc, tokenTarget, tokenSource, gasPrices, sourceTokenAmount }) => {
    if (!swapRateRes)
        return swapRateRes;
    /*
      - for gasToken and L1GasToken have a look at index.jsx (swap), these are for each chain the token with id = network.defaultCoinId
      - rate.gasEstimate is simply a field returned with each swap rate object
      - for txSpeed, it can take 3 values (slow, normal by default, fast) and should be selected by the user, only when he is connected with Magic. For other wallets we put speed = normal
     */
    const targetNetwork = NETWORKS_DATA[networkTargetId];
    const gasToken = _.path([targetNetwork.id, targetNetwork.defaultCoinId], tokensAssoc);
    // required for optimism fee estimate
    const ethereumNetwork = NETWORKS_DATA[ENetworkIdByName.ethereum];
    const l1GasToken = _.path([ethereumNetwork.id, ethereumNetwork.defaultCoinId], tokensAssoc);
    const toAmountUsd = parseFloat(Numbo.formatUnits(swapRateRes.toAmount, tokenTarget.decimals)) * tokenTarget.price;
    const gasFeeUsd = getGasFeeUsd({
        networkId: networkTargetId,
        gasToken,
        l1GasToken,
        gasUsage: swapRateRes.gasEstimate,
        gasPrices,
        // TODO: ask Clement what to do with it
        // TODO: ask Clement what to do with it
        // TODO: ask Clement what to do with it
        txSpeed: TX_SPEED_DEFAULT
    });
    const targetPriceInUsd = Numbo.injectDecimalsForUsdNumber(swapRateRes.toAmount, tokenTarget.decimals) * tokenTarget.price;
    const sourcePriceInUsd = parseFloat(sourceTokenAmount) * tokenSource.price;
    const valueLossPercentage = (1 - targetPriceInUsd / sourcePriceInUsd) * 100;
    const fromAmountParsed = Numbo.injectDecimals(swapRateRes.fromAmount, tokenSource.decimals);
    const toAmountParsed = Numbo.injectDecimals(swapRateRes.toAmount, tokenTarget.decimals);
    return Object.assign(Object.assign({}, swapRateRes), { fromAmountParsed, fromAmountParsedFormatted: `${fromAmountParsed} ${tokenSource.symbol}`, fromLogo: tokenSource.logoURI, fromNetwork: tokenSource.cgKey, fromSymbol: tokenSource.symbol, fromDecimals: tokenSource.decimals, toLogo: tokenTarget.logoURI, toNetwork: tokenTarget.cgKey, toSymbol: tokenTarget.symbol, 
        // buyAmountFormatted: numeral(v.buyAmount.toString()).format('$0.[00]a'),
        toAmountDecimals: tokenTarget.decimals, toAmountParsed, toAmountParsedFormatted: `${toAmountParsed} ${tokenTarget.symbol}`, toAmountUsd, toAmountUsdFormatted: Numbo.formatCurrency(toAmountUsd), toAmountUsdWithGasFees: toAmountUsd - gasFeeUsd, toAmountUsdWithGasFeesFormatted: Numbo.formatCurrency(toAmountUsd - gasFeeUsd), gasFeeUsd, gasFeeUsdFormatted: Numbo.formatCurrency(gasFeeUsd), valueLoss: valueLossPercentage, valueLossFormatted: Math.abs(valueLossPercentage) < 0.01
            ? `${EQUAL_ROUND_SYMBOL} 0`
            : `${valueLossPercentage > 0 ? '- ' : ''}${Math.abs(valueLossPercentage).toFixed(2)}%`, isValueGain: valueLossPercentage < 0, isValueLossSmall: Math.abs(valueLossPercentage) < 0.01 });
};
// TODO: write unit tests
// TODO: write unit tests
// TODO: write unit tests
export const normalizeBestRatesWithGasFees = ({ swapRatesRes, networkTargetId, tokensAssoc, tokenTarget, tokenSource, gasPrices, sourceTokenAmount }) => {
    // const targetNetwork = NETWORKS_DATA[networkTargetId]
    // const gasToken = _.path([targetNetwork.id, targetNetwork.defaultCoinId], tokensAssoc)
    // // required for optimism fee estimate
    // const ethereumNetwork = NETWORKS_DATA[ENetworkIdByName.ethereum]
    // const l1GasToken = _.path([ethereumNetwork.id, ethereumNetwork.defaultCoinId], tokensAssoc)
    // console.log(
    //   JSON.stringify({
    //     swapRatesRes,
    //     networkTargetId,
    //     tokenAssoc: {
    //       [targetNetwork.id]: {
    //         [targetNetwork.defaultCoinId]: gasToken
    //       },
    //       [ethereumNetwork.id]: {
    //         [ethereumNetwork.defaultCoinId]: l1GasToken
    //       }
    //     },
    //     tokenTarget,
    //     tokenSource,
    //     gasPrices,
    //     sourceTokenAmount
    //   })
    // )
    return _.flow(_.map((v) => {
        return normalizeRateWithGasFee({
            swapRateRes: v,
            networkTargetId,
            tokensAssoc,
            tokenTarget,
            tokenSource,
            gasPrices,
            sourceTokenAmount
        });
    }), _.filter(_.complement(_.isNil)), (v) => v.sort((a, b) => b.toAmountUsdWithGasFees - a.toAmountUsdWithGasFees))(swapRatesRes);
};
