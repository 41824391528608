import { selectPage } from '../../__common__/selectors';
import { selectSourceAmount, selectSelectedFiatSymbol, selectPartnerOrderId, selectIsTransakSupported, selectTransakOrderWalletAddress, selectTargetTokenFiat } from '../selectors';
import { getTransactionStatus } from '../slice';
import { merge, of } from 'rxjs';
import { filter, map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import _ from 'lodash/fp';
export const epicTransactionPollingTrigger = (action$, state$) => {
    return merge(of(null), 
    //   .pipe(() => {
    //   // if we on the page !== EPages.init
    //   // show spinner - it will just do some opacity over the page that we fetched from localStorage
    // }),
    state$.pipe(map(selectPage), distinctUntilChanged(), filter(v => _.isNil(v)))).pipe(filter(() => {
        const { value } = state$;
        const orderId = selectPartnerOrderId(value);
        const transakOrderWalletAddress = selectTransakOrderWalletAddress(value);
        const selectedToken = selectTargetTokenFiat(value);
        const selectedFiatSymbol = selectSelectedFiatSymbol(value);
        const selectedFiatAmount = selectSourceAmount(value);
        const isTransakSupported = selectIsTransakSupported(value);
        return (!!orderId &&
            !!transakOrderWalletAddress &&
            !!selectedToken &&
            !!selectedFiatSymbol &&
            !!selectedFiatAmount &&
            isTransakSupported !== undefined);
    }), switchMap(() => {
        return of(getTransactionStatus());
    }));
};
