export function sanitizeFloatValue(value) {
    // If the value is '01', return '0.1'
    if (value && value.length === 2 && value[0] === '0' && /[1-9]/.test(value[1])) {
        return `0.${value[1]}`;
    }
    // Remove any character other than numbers, dot and comma
    value = value.replace(/[^\d.,]/g, '');
    // Replace commas with dots
    value = value.replace(/,/g, '.');
    if (value === '00')
        return '0.0';
    if (value === '' || !/[0-9.]/.test(value))
        return '';
    if (value.replace(/\./g, '') === '')
        return '0.';
    const parts = value.split('.');
    let integerPart = parts[0].replace(/\D/g, '');
    integerPart = integerPart.replace(/^0+/, '') || '0';
    const decimalPart = parts.slice(1).join('').replace(/\D/g, '');
    // If the original value started with dots, prepend '0' to the result
    if (value.startsWith('.') && integerPart === '0') {
        integerPart = '0';
    }
    let result = integerPart;
    if (decimalPart.length > 0 || value.includes('.')) {
        result += '.' + decimalPart;
    }
    return result;
}
