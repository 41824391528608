// TODO: add unit tests
export const resolvePath = (base, relativePath) => {
    if (base.endsWith('/') && relativePath.startsWith('/')) {
        // Remove the slash from the beginning of relativePath
        return base + relativePath.substring(1);
    }
    else if (!base.endsWith('/') && !relativePath.startsWith('/')) {
        // Add a slash between base and relativePath
        return base + '/' + relativePath;
    }
    // If neither of the above cases, return the concatenation directly
    return base + relativePath;
};
