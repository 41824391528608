import { ETextVariant, SFlexbox, SText, SBox, EFontSizes, OLink } from '@ondefy/ondefy-ui';
import _ from 'lodash/fp';
import React from 'react';
import { useGetTokenContracts } from '../../../__common__/hooks/use-get-token-contracts';
import { NumboLabel } from '../../../__common__/numbo/components/numbo-label';
import { Numbo } from '../../../__common__/numbo/transducers';
import { selectSwapTransaction, selectSwapTransactionStatus } from '../../selectors';
import { Analytics } from '../../../analytics';
import { useAppSelector } from '../../../../redux/hooks';
import { HeaderNavBack } from '../../../__common__/components/header-with-breadcrumbs/header-nav-back';
import { MainButton } from '../../../__common__/components/main-button';
import { usePaperResetConfirm } from '../../../__common__/papers/paper-reset-confirm/__hooks__/use-paper-reset-confirm';
import SuccessSvg from '../__components__/__assets__/success.svg';
import { LoadingIndicator } from '../__components__/loading-indicator';
import { SScreenInner } from './__styled__/s-screen-inner';
import { getExplorerLink } from '../../../__common__/transducers/get-explorer-link';
export const ScreenProgress = () => {
    // const isWalletConnected = useAppSelector(selectIsWalletConnected)
    // const bestRate = useAppSelector(selectBestSwapRate)
    const swapTransactionStatus = useAppSelector(selectSwapTransactionStatus);
    const swapTransaction = useAppSelector(selectSwapTransaction);
    useGetTokenContracts();
    const paperResetConfirm = usePaperResetConfirm();
    const value = !swapTransactionStatus.isError ? swapTransaction.value : swapTransaction.error;
    return (React.createElement(SScreenInner, null,
        React.createElement(HeaderNavBack, { title: !swapTransactionStatus.isSuccess ? 'Swap progress' : null, hideGoBack: true }),
        React.createElement(SFlexbox, { alignItems: "center", justifyContent: "center" },
            swapTransactionStatus.isLoading && React.createElement(LoadingIndicator, null),
            swapTransactionStatus.isSuccess && React.createElement(SuccessSvg, null),
            swapTransactionStatus.isError && React.createElement(SText, { variant: ETextVariant.heading3 }, "Transaction Failed")),
        swapTransactionStatus.isSuccess && (React.createElement(SFlexbox, { px: "8px", flexDirection: "column", justifyContent: "center" },
            React.createElement(SText, { type: "h4", textAlign: "center" }, "Executed"),
            React.createElement(SText, { type: "h5" },
                React.createElement(SFlexbox, { alignItems: "center", justifyContent: "center" },
                    React.createElement("img", { style: { maxWidth: '30px', marginRight: '8px' }, src: _.path('fromTokenLogoURI', value), alt: "" }),
                    React.createElement(NumboLabel, { label: Numbo.injectDecimals(_.path('fromTokenAmount', value), _.path('fromTokenDecimals', value)), groupZeros: 3, toFixed: 4 }),
                    ' ',
                    _.path('fromTokenSymbol', value),
                    React.createElement(SBox, { mx: "8px", fontSize: EFontSizes.heading4 }, "\u2192"),
                    React.createElement("img", { style: { maxWidth: '30px', marginRight: '8px' }, src: _.path('toTokenLogoURI', value), alt: "" }),
                    React.createElement(NumboLabel, { label: Numbo.injectDecimals(_.path('toTokenAmount', value), _.path('toTokenDecimals', value)), groupZeros: 3, toFixed: 4 }),
                    ' ',
                    _.path('toTokenSymbol', value))))),
        React.createElement(SFlexbox, { justifyContent: "center" },
            React.createElement(OLink, { isExternal: true, borderless: true, noIcon: true, href: getExplorerLink(_.path('txHash', value), _.path('networkId', value)) }, "View on explorer")),
        React.createElement(MainButton, { onClick: () => {
                paperResetConfirm.reset();
                Analytics.completeTriggered();
            } }, "Complete")));
};
