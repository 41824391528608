import { SFlexbox, styled } from '@ondefy/ondefy-ui';
export const SAmountBlockArrow = styled(SFlexbox) `
  padding: 0px 8px;
  border-bottom: 1px solid;
  border-color: ${p => p.theme.colors.bg};
  height: 0;
  margin: 28px 0px;
  justify-content: center;
  align-items: center;
`;
