var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Web3$ } from '../__common__/web3/web3-observable';
import React, { useMemo, useCallback, useEffect } from 'react';
import { useCurrentBrowser } from '../__common__/hooks/use-current-browser';
import { useRepeatedCallGuard } from '../__common__/hooks/use-repeated-call-guard';
import { Analytics } from '../analytics';
import { WALLETS } from './constants/wallets';
import { useWalletOnReconnect } from './hooks/use-wallet-on-reconnect';
import { useWeb3ModalConnector } from './hooks/wallet-connectors/use-web3-modal';
import { setIsWeb3Busy, resetUserWallet } from './slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ContextWallet } from './context.wallet';
import { toast } from '@ondefy/ondefy-ui';
import { useMetamaskConnector } from './hooks/wallet-connectors/use-metamask-connector';
import { useMagicAuthConnector } from './hooks/wallet-connectors/use-magic-auth-connector';
import { useWalletConnectConnector } from './hooks/wallet-connectors/use-wallet-connect-connector';
import { selectWallet } from './selectors';
import _ from 'lodash/fp';
export const ProviderWallet = ({ children }) => {
    const { isWeb3Busy, walletType, userEmail } = useAppSelector(selectWallet);
    const dispatch = useAppDispatch();
    const browser = useCurrentBrowser();
    const MetamaskAdapter = useMetamaskConnector();
    const MagicAdapter = useMagicAuthConnector();
    const WalletConnectAdapter = useWalletConnectConnector();
    const Web3ModalAdapter = useWeb3ModalConnector();
    const { onConnect, onDisconnect } = useWalletOnReconnect();
    const repeatedCallGuard = useRepeatedCallGuard();
    const connectWallet = useCallback((walletType_, userEmail = '') => __awaiter(void 0, void 0, void 0, function* () {
        if (isWeb3Busy) {
            console.error('Cannot connect wallet: already busy');
            toast.next({
                variant: 'warning',
                message: 'Cannot connect wallet: already busy'
            });
            return;
        }
        try {
            // dispatch(setIsWeb3Busy(true))
            switch (walletType_) {
                case WALLETS.magicAuth.key:
                    yield MagicAdapter.connect(userEmail);
                    break;
                case WALLETS.metamask.key:
                    if (browser.isChrome && browser.isDesktop) {
                        repeatedCallGuard(() => {
                            console.log('Calling Metamask...');
                        }, () => {
                            toast.next({
                                variant: 'info',
                                message: `Metamask popup doesn't show? Try to disable your adblocker, other extensions or use another browser`
                            });
                        });
                    }
                    yield MetamaskAdapter.connect();
                    break;
                case WALLETS.walletConnect.key:
                    yield WalletConnectAdapter.connect();
                    break;
                case WALLETS.web3Modal.key:
                    yield Web3ModalAdapter.connect();
                    break;
                default:
                    throw new Error('Unsupported wallet type');
            }
            console.log(`Connected to ${walletType_}`);
            Analytics.walletConnected({ walletType: walletType });
            onConnect();
        }
        catch (err) {
            console.error(`Could not connect to ${walletType_}: ${err === null || err === void 0 ? void 0 : err.message}`);
            toast.next({
                variant: 'error',
                message: _.path('message', err)
                    ? `Could not connect to ${walletType_}: ${_.path('message', err)}`
                    : `Could not connect to ${walletType_}`
            });
            dispatch(resetUserWallet());
        }
        finally {
            dispatch(setIsWeb3Busy(false));
        }
    }), 
    // eslint-disable-next-line
    [
        isWeb3Busy,
        setIsWeb3Busy,
        resetUserWallet,
        MagicAdapter.connect,
        MetamaskAdapter.connect,
        WalletConnectAdapter.connect,
        Web3ModalAdapter.connect,
        onConnect
    ]);
    const disconnectWallet = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isWeb3Busy) {
            console.error('Cannot disconnect wallet: already busy');
            return;
        }
        try {
            // dispatch(setIsWeb3Busy(true))
            if (!walletType) {
                throw new Error('No wallet connected');
            }
            // setWalletType(null) needs to be called directly in adapter's disconnect method (as it can be called from elsewhere than here)
            switch (walletType) {
                case WALLETS.magicAuth.key:
                    yield MagicAdapter.disconnect();
                    break;
                case WALLETS.metamask.key:
                    yield MetamaskAdapter.disconnect();
                    break;
                case WALLETS.walletConnect.key:
                    yield WalletConnectAdapter.disconnect();
                    break;
                case WALLETS.web3Modal.key:
                    yield Web3ModalAdapter.disconnect();
                    break;
                default:
                    throw new Error('Unsupported wallet type');
            }
        }
        catch (err) {
            console.error(`Error while disconnecting from wallet ${walletType}: ${err === null || err === void 0 ? void 0 : err.message}`);
            toast.next({
                variant: 'error',
                message: `Error while disconnecting from wallet ${walletType}: ${err === null || err === void 0 ? void 0 : err.code}`
            });
            dispatch(resetUserWallet());
        }
        finally {
            dispatch(setIsWeb3Busy(false));
            onDisconnect();
        }
    }), [dispatch, isWeb3Busy, walletType, MagicAdapter, MetamaskAdapter, WalletConnectAdapter, onDisconnect]);
    const broadcastTransaction = (params, broadcastConfig) => __awaiter(void 0, void 0, void 0, function* () {
        if (!walletType) {
            throw new Error('Wallet not connected');
        }
        switch (walletType) {
            case WALLETS.magicAuth.key:
                return yield MagicAdapter.broadcastTransaction(params, broadcastConfig);
            case WALLETS.metamask.key:
                return yield MetamaskAdapter.broadcastTransaction(params, broadcastConfig);
            case WALLETS.walletConnect.key:
                return yield WalletConnectAdapter.broadcastTransaction(params, broadcastConfig);
            case WALLETS.web3Modal.key:
                return yield Web3ModalAdapter.broadcastTransaction(params, broadcastConfig);
            default:
                throw new Error(`Unsupported wallet type: ${walletType}`);
        }
    });
    const signMessage = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        if (!walletType) {
            throw new Error('Wallet not connected');
        }
        switch (walletType) {
            case WALLETS.magicAuth.key:
                return yield MagicAdapter.signMessage(params);
            case WALLETS.metamask.key:
                return yield MetamaskAdapter.signMessage(params);
            case WALLETS.walletConnect.key:
                return yield WalletConnectAdapter.signMessage(params);
            case WALLETS.web3Modal.key:
                return yield Web3ModalAdapter.signMessage(params);
            default:
                throw new Error(`Unsupported wallet type: ${walletType}`);
        }
    }), 
    // eslint-disable-next-line
    [walletType, MagicAdapter.signMessage, MetamaskAdapter.signMessage, WalletConnectAdapter.signMessage]);
    const checkIfWalletIsConnected = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isWeb3Busy) {
            console.error('Cannot check wallet connection: already busy');
            return;
        }
        try {
            console.log('Checking if wallet is connected');
            // dispatch(setIsWeb3Busy(true))
            if (walletType) {
                switch (walletType) {
                    case WALLETS.magicAuth.key:
                        if (userEmail) {
                            yield connectWallet(WALLETS.magicAuth.key, userEmail);
                        }
                        else {
                            throw new Error('No user email address found');
                        }
                        break;
                    case WALLETS.metamask.key:
                        yield connectWallet(WALLETS.metamask.key);
                        break;
                    case WALLETS.walletConnect.key:
                        yield connectWallet(WALLETS.walletConnect.key);
                        break;
                    case WALLETS.web3Modal.key:
                        // TODO: not needed???
                        // TODO: not needed???
                        // TODO: not needed???
                        // await connectWallet(WALLETS.web3Modal.key)
                        break;
                    default:
                        dispatch(resetUserWallet());
                        break;
                }
            }
        }
        catch (err) {
            console.error(`Error while checking wallet connection: ${err === null || err === void 0 ? void 0 : err.message}`);
            dispatch(resetUserWallet());
        }
        finally {
            dispatch(setIsWeb3Busy(false));
        }
        // eslint-disable-next-line
    }), [isWeb3Busy, connectWallet]);
    const isWalletTypeSupportedByBrowser = useCallback((walletType) => {
        switch (walletType) {
            case WALLETS.magicAuth.key:
                return MagicAdapter.isSupportedByBrowser;
            case WALLETS.metamask.key:
                return MetamaskAdapter.isSupportedByBrowser;
            case WALLETS.walletConnect.key:
                return WalletConnectAdapter.isSupportedByBrowser;
            case WALLETS.web3Wallet.key:
                return Web3ModalAdapter.isSupportedByBrowser;
            default:
                return false;
        }
    }, [MagicAdapter.isSupportedByBrowser, MetamaskAdapter.isSupportedByBrowser, WalletConnectAdapter.isSupportedByBrowser]);
    const addTokenToPlugin = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        switch (walletType) {
            case WALLETS.metamask.key:
                yield MetamaskAdapter.addTokenToPlugin(params);
                break;
            default:
                throw new Error(`Unsupported wallet type: ${walletType}`);
        }
    }), 
    // eslint-disable-next-line
    [walletType, MetamaskAdapter.addTokenToPlugin]);
    useEffect(() => {
        if (MetamaskAdapter.isSupportedByBrowser === undefined ||
            MagicAdapter.isSupportedByBrowser === undefined ||
            WalletConnectAdapter.isSupportedByBrowser === undefined ||
            Web3ModalAdapter.isSupportedByBrowser === undefined) {
            return;
        }
        checkIfWalletIsConnected().catch(() => {
            console.log('could not connect');
        });
        // eslint-disable-next-line
    }, [
        MetamaskAdapter.isSupportedByBrowser,
        MagicAdapter.isSupportedByBrowser,
        WalletConnectAdapter.isSupportedByBrowser,
        WalletConnectAdapter.isSupportedByBrowser
    ]);
    const iWeb3 = useMemo(() => ({
        connectWallet,
        disconnectWallet,
        broadcastTransaction,
        signMessage,
        addTokenToPlugin,
        isWalletTypeSupportedByBrowser
    }), [
        connectWallet,
        disconnectWallet,
        broadcastTransaction,
        signMessage,
        addTokenToPlugin,
        isWalletTypeSupportedByBrowser
    ]);
    useEffect(() => {
        Web3$.next(iWeb3);
    }, [iWeb3]);
    return React.createElement(ContextWallet.Provider, { value: iWeb3 }, children);
};
