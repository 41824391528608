import { forkJoin, map } from 'rxjs';
import { apirxBackend } from '../../api/services/api-backend';
import _ from 'lodash/fp';
import { filterAndAddKey } from './__transducers__/filter-and-add-key';
// WARNING! Development only!
// import ordersJSON from './__mocks__/orders.json'
// import transactionJSON from './__mocks__/past-transactions.json'
export const getPastTransactions = ({ userAddress }) => {
    // WARNING! Development only!
    // userAddress = '0x93B8fe925f6Ee2974f1Ea74E9C5964948556191f'
    return forkJoin([
        apirxBackend.get('/ramp/userOrders', { userAddress }).pipe(map(_.map(_.merge({ type: 'ramp' })))),
        apirxBackend.get('/get/tx/past', { evmAddress: userAddress })
    ]).pipe(map(filterAndAddKey));
};
