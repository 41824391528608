// TODO: define any
import { createPublicClient, http } from 'viem';
import { arbitrum, avalanche, base, bsc, celo, fantom, mainnet, optimism, polygon, zkSync } from 'viem/chains';
// TODO: define any
// TODO: define any
import { ENetworkIdByName, NETWORKS_DATA } from '../constants/networks';
const providersByNetworkId = {
    [ENetworkIdByName['arbitrum-one']]: arbitrum,
    [ENetworkIdByName['binance-smart-chain']]: bsc,
    [ENetworkIdByName.celo]: celo,
    [ENetworkIdByName.fantom]: fantom,
    [ENetworkIdByName['optimistic-ethereum']]: optimism,
    [ENetworkIdByName.avalanche]: avalanche,
    [ENetworkIdByName['polygon-pos']]: polygon,
    [ENetworkIdByName.zksync]: zkSync,
    [ENetworkIdByName.ethereum]: mainnet,
    [ENetworkIdByName.base]: base
};
// https://viem.sh/docs/ethers-migration
export const getPublicClient = (networkId) => {
    const rpc = NETWORKS_DATA[networkId].rpc;
    const jsonRpcProvider = providersByNetworkId[networkId];
    return createPublicClient({
        chain: jsonRpcProvider,
        transport: http(rpc)
    });
};
