import { OButton, OIcon, OTag, SFlexbox, SText } from '@ondefy/ondefy-ui';
import { useIsIframe } from '../../../../hooks/use-is-iframe';
import { selectTargetTokenFiat } from '../../../../../1 - buy/selectors';
// import { TTokenContract } from '../../../../features/__common__/types'
import _ from 'lodash/fp';
import { useIframeUrlColors } from '../../../../../iframe/hooks/use-iframe-url-colors';
import { getFormattedSelectedPair } from '../../../../../1 - buy/transducers/get-formatted-selected-pair';
import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import { useAppSelector } from '../../../../../../redux/hooks';
import { SCopyTokenUrl } from './__styled__/s-copy-token-url';
import { SLinks } from './__styled__/s-links';
export const CopyTokenUrl = () => {
    const selectedToken = useAppSelector(selectTargetTokenFiat);
    const url = getFormattedSelectedPair({
        selectedTokenId: _.path('id', selectedToken)
    });
    const [copied, setCopied] = useState(false);
    const { colorPrimary } = useIframeUrlColors();
    const isIframe = useIsIframe();
    useDebounce(() => {
        if (copied) {
            setCopied(false);
        }
    }, 2000, [copied]);
    if (!selectedToken) {
        return null;
    }
    const copy = () => {
        navigator.clipboard.writeText(url).catch(() => { });
        setCopied(true);
    };
    return (React.createElement(SCopyTokenUrl, null,
        React.createElement(SLinks, null,
            React.createElement(SFlexbox, { onDoubleClick: copy, height: "40px", flexShrink: "1", mr: {
                    sm: '4px',
                    xs: '0'
                }, mb: {
                    sm: '0',
                    xs: '4px'
                } },
                React.createElement(OTag, { className: "copy-url__text", label: React.createElement(SText, { type: "small" }, url), height: "40px", variant: "secondary", bg: "bg2" })),
            !isIframe && (React.createElement(OButton, { iconRight: React.createElement(OIcon, { name: "unlink", 
                    // @ts-ignore
                    color: colorPrimary || 'accent' }), maxWidth: "140px", height: "40px", variant: "secondary", flexShrink: "2.5", disabled: !selectedToken, onClick: copy, bg: "bg2" },
                React.createElement(SText, { color: "inherit", type: "small" }, copied ? 'Copied' : 'Share this token'))))));
};
