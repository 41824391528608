import { OButton, OIcon, OMedia, OSwitch, SGridbox, SText } from '@ondefy/ondefy-ui';
import React, { useState, useEffect } from 'react';
import { selectIsTransakSupported, selectRefuelAmount, selectNetworkIdTargetFiat } from '../../../../selectors';
import { useIframeUrlColors } from '../../../../../iframe/hooks/use-iframe-url-colors';
import { NETWORKS_DATA } from '../../../../../__common__/constants/networks';
import { setRefuelAmount } from '../../../../slice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { usePaperGasFuelDescription } from '../../../../papers/paper-gas-fuel-description/__hooks__/use-paper-gas-fuel-description';
import { SGasFuel } from './__styled__/s-gas-fuel';
const options = {
    137: ['1', '2', '3'],
    1: ['0.005', '0.0075', '0.01'],
    42161: ['0.005', '0.0075', '0.01']
};
export const GasRefuel = () => {
    const [isActivated, setIsActivated] = useState(false);
    const networkId = useAppSelector(selectNetworkIdTargetFiat);
    const currentNetworkOptions = options[networkId];
    const paperGasFuelDescription = usePaperGasFuelDescription();
    const { colorPrimary } = useIframeUrlColors();
    const isTransakSupported = useAppSelector(selectIsTransakSupported);
    const refuelAmount = useAppSelector(selectRefuelAmount);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (isTransakSupported) {
            setIsActivated(false);
            dispatch(setRefuelAmount(''));
        }
        // eslint-disable-next-line
    }, [isTransakSupported]);
    useEffect(() => {
        setIsActivated(false);
        dispatch(setRefuelAmount(''));
        // eslint-disable-next-line
    }, [networkId]);
    return (React.createElement(SGasFuel, null,
        React.createElement(OMedia, { alignItems: "center" },
            React.createElement(OIcon, { name: "fuel", 
                // @ts-ignore
                color: colorPrimary || 'accent', size: 24 }),
            React.createElement(SText, { type: "h6", mb: "0", pl: "5px", pr: "10px" }, "Gas refuel"),
            React.createElement(OIcon, { name: "issue", className: "gas-fuel__issue-icon", size: 20, mr: "10px", onClick: () => {
                    paperGasFuelDescription.setIsOpened(true);
                } }),
            React.createElement(OSwitch, { value: isActivated, onChange: value => {
                    setIsActivated(value);
                    if (!value) {
                        dispatch(setRefuelAmount(''));
                    }
                    else {
                        dispatch(setRefuelAmount(currentNetworkOptions[0]));
                    }
                }, disabled: isTransakSupported })),
        isActivated && (React.createElement(SGridbox, { gridTemplateColumns: currentNetworkOptions.map(() => '1fr').join(' '), gap: "8px", mt: "10px" }, currentNetworkOptions.map(value => {
            return (React.createElement(OButton, { key: value, variant: refuelAmount === value ? 'primary' : 'secondary', 
                // @ts-ignore
                bg: refuelAmount === value ? colorPrimary || undefined : undefined, onClick: () => {
                    dispatch(setRefuelAmount(value));
                } }, `${value} ${NETWORKS_DATA[networkId].ticker}`));
        })))));
};
