import { OMedia, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { selectSourceTokenValueInUsd } from '../../../../selectors';
import { useAppSelector } from '../../../../../../redux/hooks';
// import { useAppSelector } from '../../../../redux/hooks'
// import { EEntityStatus } from '../../../../redux/redux-entity'
// import { useIsAmountTyping } from '../../__hooks__/use-is-amount-typing'
import { SDetails } from '../../../../../__common__/styled/s-details';
export const SourceTokenDetails = () => {
    // const selectedTokenSelector = useMemo(makeSelectedTokenSelector, [])
    const tokenValueInUsd = useAppSelector(selectSourceTokenValueInUsd);
    return (React.createElement(SDetails, null,
        React.createElement(OMedia, { alignItems: "center", px: "8px", className: 'ondefy__validations-inner ondefy__validations-inner--visible' }, tokenValueInUsd && (React.createElement(SText, { type: "small", opacity: "0.7" },
            "\u2248 ",
            tokenValueInUsd)))));
};
