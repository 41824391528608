import { EPages } from '../constants';
import { selectPage, selectTokenContractsAssoc } from '../selectors';
import { checkIfNetworkIsValid } from '../../1 - buy/transducers/check-iIf-network-is-valid';
import { NETWORKS_DATA } from '../constants/networks';
import { setNetworkIdTargetFiat, setTargetTokenIdFiat } from '../../1 - buy/slice';
import { setNetworkIdTargetSwap, setTargetTokenIdSwap } from '../../2 - swap/slice';
import _ from 'lodash/fp';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { setIframeMode } from '../slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
export const useInitFromUrl = () => {
    const dispatch = useAppDispatch();
    const tokensAssoc = useAppSelector(selectTokenContractsAssoc);
    const [inited, setInited] = useState(false);
    const page = useAppSelector(selectPage);
    // const isIframe = useAppSelector(selectIsIframe)
    useEffect(() => {
        if (page === EPages.init) {
            setInited(false);
        }
    }, [page]);
    useEffect(() => {
        // 1. Do nothing if not ready
        if (inited || !tokensAssoc) {
            return;
        }
        // 2. Do this hook only once and then block
        setInited(true);
        // 3. Parse url query parameters
        const { tokenId, networkId } = qs.parse(window.location.search, {
            ignoreQueryPrefix: true
        });
        // 4. This method will be used later
        const setTargetTokenFromSupportedNetworks = () => {
            let token;
            // Try to find the first match of the token among all networks
            for (const networkId of Object.keys(NETWORKS_DATA)) {
                const network = _.path(networkId, NETWORKS_DATA);
                token = _.path([network.id, String(tokenId).toLowerCase()], tokensAssoc);
                if (token) {
                    dispatch(setIframeMode(true));
                    dispatch(setTargetTokenIdFiat(token.id));
                    dispatch(setNetworkIdTargetFiat(network.networkId));
                    // Duplicate the same for swap mode
                    dispatch(setTargetTokenIdSwap(token.id));
                    dispatch(setNetworkIdTargetSwap(network.networkId));
                    break;
                }
            }
            // Reset previous values? Why? I will use sessionStorage - no need in doing this
            // if (!token && !isIframe) {
            //   // Just reset previously selected token ids
            //   dispatch(setTargetTokenIdFiat(''))
            //   dispatch(setTargetTokenIdSwap(''))
            // }
        };
        // 5. Find the network among our supported networks
        let newNetworkId;
        if (networkId && checkIfNetworkIsValid(parseInt(networkId))) {
            newNetworkId = parseInt(networkId);
        }
        // 6. If we have a valid networkId from query parameter
        if (newNetworkId) {
            dispatch(setIframeMode(true));
            dispatch(setNetworkIdTargetFiat(newNetworkId));
            // 7. Disatch again for swap mode
            dispatch(setNetworkIdTargetSwap(newNetworkId));
            // 8. If token id is also in query parameters
            if (tokenId) {
                // 9. Let's find this token using coingecko response
                const token = _.path([NETWORKS_DATA[newNetworkId].id, String(tokenId).toLowerCase()], tokensAssoc);
                if (token) {
                    dispatch(setTargetTokenIdFiat(token.id));
                    // 10. Do it again for swap mode
                    dispatch(setTargetTokenIdSwap(token.id));
                }
                else {
                    // 11. If we didn't find this token in the provided network we will try to find it in all supported networks and update networkId
                    setTargetTokenFromSupportedNetworks();
                }
            }
        }
        else if (tokenId) {
            // 12. Try to find the token in all supported networks by its id and update networkId
            setTargetTokenFromSupportedNetworks();
        }
        // eslint-disable-next-line
    }, [tokensAssoc, page]);
};
