import { ReduxEntity } from '../../redux/redux-entity';
import { ETransactionStatus } from '../__common__/constants/transaction';
import { initialState } from './state';
export const setTransactionData = (state, { payload }) => {
    state.transactionData = payload;
};
export const setDestinationAddress = (state, { payload }) => {
    state.destinationAddress = payload;
};
export const setTargetTokenIdCustom = (state, { payload }) => {
    state.targetTokenIdCustom = payload;
};
export const setNetworkIdTargetCustom = (state, { payload }) => {
    state.networkIdTargetCustom = payload;
};
export const flush = (state) => {
    return Object.assign(Object.assign({}, initialState), { networkIdTargetCustom: state.networkIdTargetCustom, targetTokenIdCustom: state.targetTokenIdCustom });
};
export const reset = (state) => {
    return Object.assign(Object.assign({}, initialState), { networkIdTargetCustom: state.networkIdTargetCustom, targetTokenIdCustom: state.targetTokenIdCustom });
};
export const getZyfiGeneralTx = (state) => {
    ReduxEntity.loading(state.zyfiGeneralTx);
};
export const getZyfiGeneralTxRejected = (state, action) => {
    ReduxEntity.error(state.zyfiGeneralTx, action);
};
export const getZyfiGeneralTxFullfilled = (state, action) => {
    ReduxEntity.success(state.zyfiGeneralTx, action);
};
export const saveTransaction = (state, { payload }) => {
    if (payload.status !== ETransactionStatus.pending) {
        // remove completed
        state.pendingTransactions = state.pendingTransactions.filter(transaction => {
            return transaction.txHash !== payload.txHash;
        });
        return;
    }
    if (!state.pendingTransactions.find(transaction => transaction.txHash === payload.txHash)) {
        // add new
        state.pendingTransactions.push(payload);
    }
};
export const saveTransactionComplete = (state, { payload }) => {
    // TODO: test if it works properly
    // TODO: test if it works properly
    // TODO: test if it works properly
    state.pendingTransactions = state.pendingTransactions.filter(transaction => {
        return transaction.txHash !== payload.txHash;
    });
};
export const customTransaction = (state) => {
    ReduxEntity.loading(state.customTransaction);
};
export const customTransactionRejected = (state, action) => {
    ReduxEntity.error(state.customTransaction, action);
};
export const customTransactionFullfilled = (state, action) => {
    ReduxEntity.success(state.customTransaction, action);
};
