import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { selectTargetTokenFiat, makeSelectedFiatSelector, selectSourceAmount } from '../../../selectors';
import { useAppSelector } from '../../../../../redux/hooks';
export const useIsAmountTyping = () => {
    const selectedFiatSelector = useMemo(makeSelectedFiatSelector, []);
    const targetToken = useAppSelector(selectTargetTokenFiat);
    const selectedFiatAmount = useAppSelector(selectSourceAmount);
    const selectedFiat = useAppSelector(selectedFiatSelector);
    const selectedFiatAmountRef = useRef(selectedFiatAmount);
    const [prevAmount, setPrevAmount] = useState(selectedFiatAmount);
    const isTyping = prevAmount !== selectedFiatAmount;
    const checkAndFetchRate = () => {
        if (selectedFiatAmount && targetToken && selectedFiat) {
            selectedFiatAmountRef.current = selectedFiatAmount;
        }
    };
    useDebounce(() => {
        // console.log('typing stopped');
        setPrevAmount(selectedFiatAmount);
        /*
        // it will skip this function after refresh and field rehydration from localStorage
        if (selectedFiatAmountRef.current === selectedFiatAmount) {
          return;
        }
        */
        checkAndFetchRate();
    }, 1500, [selectedFiatAmount]);
    useEffect(() => {
        checkAndFetchRate();
        // eslint-disable-next-line
    }, [targetToken, selectedFiat]);
    return { isTyping };
};
