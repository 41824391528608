import { toast } from '@ondefy/ondefy-ui';
import { ofType } from 'redux-observable';
import { delay, from, merge, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MaxUint256 } from '../../../__common__/web3/constants';
import { Web3$ } from '../../../__common__/web3/web3-observable';
import { ETransactionStatus, ETransactionType } from '../../../__common__/constants/transaction';
import { selectUserAddress, selectWalletType } from '../../../wallet/selectors';
import { selectBestSwapRate, selectNetworkIdSourceSwap, selectSourceTokenSwap, selectZyfiTokenApprovalTx } from '../../selectors';
import { saveTransaction, unlockTransaction, unlockTransactionFullfilled, unlockTransactionRejected } from '../../slice';
export const epicRunUnlockTokenForSwapZyfi = (action$, state$) => {
    return action$.pipe(ofType(unlockTransaction.toString()), switchMap(() => {
        const { value } = state$;
        const userAddress = selectUserAddress(value);
        const sourceNetworkId = selectNetworkIdSourceSwap(value);
        const bestRate = selectBestSwapRate(value);
        const fromToken = selectSourceTokenSwap(value);
        const submitParams = {
            type: ETransactionType.approve,
            userAddress,
            networkId: sourceNetworkId,
            tokenId: fromToken.id,
            tokenAddress: fromToken.address,
            tokenSymbol: fromToken.symbol,
            tokenDecimals: fromToken.decimals,
            tokenLogoURI: fromToken.logoURI,
            tokenAmount: MaxUint256,
            spenderAddress: bestRate.spenderAddress,
            status: ETransactionStatus.pending,
            isHidden: 0,
            timestamp: Math.floor(Date.now() / 1000)
        };
        const rawTx = selectZyfiTokenApprovalTx(value).value.txData;
        const { value: Web3 } = Web3$;
        const transactionParams = {
            rawTx,
            networkId: sourceNetworkId
            // gasPriceData: 0,
            // gasLimit: 0
        };
        const walletType = selectWalletType(value);
        console.log(`Wallet run unlock transaction ${walletType}----> `, transactionParams);
        return of(null).pipe(switchMap(() => {
            return from(Web3.broadcastTransaction(transactionParams, { type: ETransactionType.approve }));
        }), 
        // retry({
        //   count: 10,
        //   delay: 1000
        // }),
        // 4. Save transaction to our backend
        switchMap(txHash => {
            const submitParamsWithHash = Object.assign(Object.assign({}, submitParams), { txHash });
            return merge(of(unlockTransactionFullfilled(submitParamsWithHash)), 
            // save this transaction to our server
            of(saveTransaction(submitParamsWithHash))).pipe(delay(2000));
        }), catchError(e => {
            var _a;
            console.log(((_a = e.data) === null || _a === void 0 ? void 0 : _a.message) || e.message);
            toast.next({ variant: 'error', message: 'Unlock rejected' });
            return of(unlockTransactionRejected({
                message: 'Transaction failed'
            }));
        }));
    }));
};
