import { createContext } from 'react';
export const PaperLayoutMeasureContext = createContext({
    width: undefined,
    height: undefined
});
export const PaperStackContext = createContext({
    stack: [],
    setStack: () => { },
    papers: {},
    setPapers: () => { }
});
