// import numeral from 'numeral'
/*
 * Comparison of BigNumber Libraries in JavaScript:
 * - An insightful comparison of BigNumber libraries can be found here:
 *   https://dev.to/fvictorio/a-comparison-of-bignumber-libraries-in-javascript-2gc5
 *   Notably, BigNumber.js was previously part of web3.js but was replaced for performance reasons (see issue details: https://github.com/web3/web3.js/issues/2171).
 *
 * ethers.js Updates:
 * - ethers.js has transitioned to using native BigInt for large integers, aligning with modern JavaScript standards.
 *   Reference for BigInt: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt
 *   Migrating to BigInt in ethers.js: https://docs.ethers.org/v6/migrating/#migrate-bigint
 * - For handling decimals, ethers.js introduces FixedNumber:
 *   Documentation for FixedNumber: https://docs.ethers.org/v6/api/utils/fixed-point-math/#FixedNumber
 *
 *
 * | Library          | Purpose                                                  | Documentation                                       | GitHub Repository                                   |
 * |------------------|----------------------------------------------------------|-----------------------------------------------------|-----------------------------------------------------|
 *
 * #big_numbers
 * | BigNumber.js     | Decimal with fewer functions (was a part of web3.js)     | N/A                                                 | https://github.com/MikeMcl/bignumber.js/            |
 * | Decimal.js       | Decimals with trigonometric                              | N/A                                                 | https://github.com/MikeMcl/decimal.js/              |
 * | Decimal.js-light | A lighter version without trigonometric functions        | N/A                                                 | https://github.com/MikeMcl/decimal.js-light/        |
 * | FixedNumber      | Internal class of ethers.js                              | https://docs.ethers.org/v6/api/utils/fixed-point-math/#FixedFormat | N/A                                  |
 *
 * #formatting
 * | Numeral.js       | Number formatting                                        | http://numeraljs.com/                               | https://github.com/adamwdraper/Numeral-js           |

 * #money_specific
 * | Dinero.js        | Currency values manipulation (BEST for Currency)         | https://v2.dinerojs.com/docs                        | https://github.com/dinerojs/dinero.js               |
 * | Money.js         | Currency conversion                                      | https://openexchangerates.github.io/money.js/       | https://github.com/openexchangerates/money.js       |
 * | Accounting.js    | Number formatting and currency utilities                 | https://openexchangerates.github.io/accounting.js/  | https://github.com/openexchangerates/accounting.js  |
 * | Currency.js      | Currency values manipulation                             | https://currency.js.org/                            | https://github.com/scurker/currency.js              |
 *
 * #math
 * | Math.js          | Uses Decimal.js under the hood for advanced mathematical functions | N/A                                       | https://github.com/josdejong/mathjs                 |
 */
// import { BigNumberish, FixedNumber, formatUnits, parseUnits } from 'ethers'
// import { none, Option, some, match } from 'fp-ts/Option'
// import numeral from 'numeral'
// import { isNumber, negate, isInteger, overSome, flow, toNumber, isString, parseInt } from 'lodash/fp'
// // import { flow } from 'fp-ts/function'
//
// const isFloatNumber = flow(isInteger, negate)
//
// const isFloatString = flow(toNumber, isFloatNumber)
//
// export const isFloat = overSome([flow([isNumber, isFloatNumber]), flow([isString, isFloatString])])
//
// type UnitName = 'wei' | 'kwei' | 'mwei' | 'gwei' | 'szabo' | 'finney' | 'ether'
//
// enum EtherUnits {
//   wei = 0, // 1 wei is 10^0 wei
//   kwei = 3, // 1 kwei is 10^3 wei
//   mwei = 6, // 1 mwei is 10^6 wei
//   gwei = 9, // 1 gwei is 10^9 wei
//   szabo = 12, // 1 szabo is 10^12 wei
//   finney = 15, // 1 finney is 10^15 wei
//   ether = 18 // 1 ether is 10^18 wei
// }
//
// export const Numbo = {
//   // WARNING: Doesn't support float numbers!!!
//   // ethers.js is a complete garbage in regards to Math https://docs.ethers.org/v6/api/utils/#about-maths
//   // ethers.js: formatUnits => string; parseUnits => bigint; so no way to get floatNumber
//   fromUnits(amount: BigNumberish | null | undefined, unit?: BigNumberish | UnitName): Option<FixedNumber> {
//     if (amount === null || amount === undefined || Number.isNaN(amount) || amount === Infinity) {
//       return none
//     }
//
//     const unitIsString = isString(unit)
//     const unitStringIsValid = unitIsString && unit in EtherUnits
//     const unitIsIntegerLike = unitIsString && /^\d+$/.test(unit)
//
//     if (unitIsString && !unitStringIsValid) {
//       console.warn('Numbo exception: at fromUnits unit: ', unit)
//       return none
//     }
//
//     const unitAdjusted = unitIsString
//       ? unitStringIsValid
//         ? EtherUnits[unit as UnitName]
//         : unitIsIntegerLike
//         ? parseInt(10)(unit)
//         : EtherUnits.wei
//       : unit || EtherUnits.wei
//
//     try {
//       let res
//       if (isFloat(amount)) {
//         res = FixedNumber.fromString(isString(amount) ? amount : String(amount))
//       } else {
//         const amountParsed = formatUnits(amount, unitAdjusted)
//         res = FixedNumber.fromString(amountParsed)
//       }
//
//       return some(res)
//     } catch (e: any) {
//       console.warn('Numbo exception: at fromUnits: ', amount)
//       console.warn('Numbo exception: at fromUnits: ' + e.message)
//       return none
//     }
//   },
//
//   format(opt: Option<FixedNumber>, { zeros }: { zeros?: number }): string {
//     return match<FixedNumber, string>(
//       () => '', // Function to execute if the Option is None
//       fixedNum => {
//         const zerosAdjusted = ''.padStart(zeros || 1, '0')
//         const million = FixedNumber.fromString('1000000')
//         const format = fixedNum.lt(million) ? `0,0.[${zerosAdjusted}]` : `0,0.[${zerosAdjusted}]a`
//
//         return numeral(fixedNum.toString()).format(format)
//       } // Function to execute if the Option is Some
//     )(opt)
//   },
//
//   toString(opt: Option<FixedNumber>): string {
//     return match<FixedNumber, string>(
//       () => '', // Function to execute if the Option is None
//       fixedNum => fixedNum.toString() // Function to execute if the Option is Some
//     )(opt)
//   }
// }
// TODO wrapper Numbo with BigNumber interface but based on monads maybe fp-ts
// const Decimal = require('decimal.js');
//
// function parseUnits(value, decimals = 18) {
//   return new Decimal(value).mul(new Decimal(10).pow(decimals)).toString();
// }
//
// function formatUnits(value, decimals = 18) {
//   return new Decimal(value).div(new Decimal(10).pow(decimals)).toString();
// }
//
// // Example usage:
// const value = parseUnits("123.456", 18);
// console.log(value); // Outputs the value in its smallest unit
//
// const formattedValue = formatUnits(value, 18);
// console.log(formattedValue); // Outputs '123.456'
import Decimal from 'decimal.js-light';
import { BigNumber, parseUnits, formatUnits, parseEther, formatEther } from './big-numbers/big-numbers-utils';
import { bigNumberToFloat } from './big-numbers/big-number-to-float';
import { floatToBigNumber } from './big-numbers/float-to-big-number';
import { floatToFloat } from './big-numbers/float-to-float';
import { toFixedBig } from './to-fixed-big';
import { formatWithDecimals } from './format-with-decimals';
import { hexZeroPad, isBytesLike } from './big-numbers/bytes-utils';
import { sanitizeFloatValue } from './sanitize-float-value';
import { formatCurrency } from './format-currency';
import { formatNumberWithLetterOrExp } from './format-number-with-letter-or-exp';
import { injectDecimalsForUsdNumber } from './inject-decimals-for-usd-number';
import { truncate } from './truncate';
import { formatNumber } from './format-number/format-number';
import { injectDecimals } from './big-numbers/inject-decimals';
export const Numbo = {
    format: formatNumber,
    from: (value) => {
        return BigNumber.from(value);
    },
    fromDecimal: (value) => {
        return new Decimal(value);
    },
    injectDecimals,
    bigNumberToFloat,
    floatToBigNumber,
    formatWithDecimals,
    floatToFloat,
    parseUnits,
    formatUnits,
    parseEther,
    formatEther,
    toFixedBig,
    sanitizeFloatValue,
    formatCurrency,
    formatNumber: formatNumberWithLetterOrExp,
    injectDecimalsForUsdNumber,
    truncate,
    bytes: {
        hexZeroPad,
        isBytesLike
    }
};
