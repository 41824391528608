import { EMPTY } from 'rxjs';
import { ENV } from '../env';
import { epicGetGeneralTx } from '../features/2 - swap/epics/_zyfi_/epic.2.get-general-tx';
import { epicOnBestRateSuccess } from '../features/2 - swap/epics/_zyfi_/epic.on-best-rate-success';
import { epicRunSwapRawTransaction } from '../features/2 - swap/epics/_zyfi_/epic.run.swap-raw-transaction';
import { epicGetTokenContracts } from '../features/__common__/epics/epic.get-token-contracts';
import { epicGetFiatToTokenRate } from '../features/1 - buy/epics/epic.get-fiat-to-token-rate';
import { epicGetTransakFiat } from '../features/1 - buy/epics/epic.get-transak-fiat';
import { epicGetTransakTokens } from '../features/1 - buy/epics/epic.get-transak-tokens';
import { epicOnFlushBuy } from '../features/1 - buy/epics/epic.on-flush-buy';
import { epicOnNetworkChange } from '../features/1 - buy/epics/epic.on-network-change';
import { epicOnSetIsIframeBuy } from '../features/1 - buy/epics/epic.on-set-is-iframe-buy';
import { epicPostOrder } from '../features/1 - buy/epics/epic.post-order';
import { epicSignPermit } from '../features/1 - buy/epics/epic.sign-permit';
import { epicRunUnlockTokenForSwap } from '../features/2 - swap/epics/_ondefy_/epic.run-unlock-token-for-swap';
import { epicTransactionPolling } from '../features/1 - buy/epics/epic.transaction-polling';
import { epicTransactionPollingTrigger } from '../features/1 - buy/epics/epic.transaction-polling-trigger';
import { epicGetGasFeeFinal } from '../features/2 - swap/epics/_ondefy_/epic.1.get-gas-fee-final';
import { epicGetTransactionApprovalDetailsZyfi } from '../features/2 - swap/epics/_zyfi_/epic.1.get-transaction-approval-details.zyfi';
import { epicRunUnlockTokenForSwapZyfi } from '../features/2 - swap/epics/_zyfi_/epic.run.unlock-token-for-swap.zyfi';
import { epicGetAllowance } from '../features/2 - swap/epics/epic.3.get-allowance';
import { epicGetGasFees } from '../features/2 - swap/epics/epic.2.get-gas-fees';
import { epicGetSwapRates } from '../features/2 - swap/epics/epic.1.get-swap-rates';
import { epicOnBestSwapRateChange } from '../features/2 - swap/epics/epic.on.best-swap-rate-change';
import { epicOnFlushSwap } from '../features/2 - swap/epics/epic.on.flush-swap';
import { epicOnSetIsIframeSwap } from '../features/2 - swap/epics/epic.on.set-is-iframe-swap';
import { epicOnSourceTokenAmountChange } from '../features/2 - swap/epics/epic.on.source-token-amount-change';
import { epicOnUnlockTransactionSuccess } from '../features/2 - swap/epics/epic.on.unlock-transaction-success';
import { epicPollingMultipleTransactionSyncStatus } from '../features/2 - swap/epics/epic.polling.multiple-transaction-sync-status';
import { epicPollingSwapUnlockTransaction } from '../features/2 - swap/epics/epic.polling.swap-unlock-transaction';
import { epicPollingUpdateSelectedSwapper } from '../features/2 - swap/epics/epic.polling.update-selected-swapper';
import { epicRunGetSwapAndGasFeesPolling } from '../features/2 - swap/epics/epic.run.get-swap-and-gas-fees-polling';
import { epicRunSwapTransaction } from '../features/2 - swap/epics/_ondefy_/epic.run-swap-transaction';
import { epicSaveTransaction } from '../features/2 - swap/epics/epic.save-transaction';
import { epicIframePostMessages } from '../features/iframe/epics/epic.iframe-post-messages';
import { epicGetUserWallet } from '../features/wallet/epics/epic.get-user-wallet';
import { epicOnUserСonnect } from '../features/wallet/epics/epic.on.wallet-connect';
import { epicOnWalletDisconnect } from '../features/wallet/epics/epic.on.wallet-disconnect';
import { epicOnWalletFlush } from '../features/wallet/epics/epic.on.wallet-flush';
import { epicOnWalletReset } from '../features/wallet/epics/epic.on.wallet-reset';
import { epicSyncUserWalletConnection } from '../features/wallet/epics/epic.sync-user-wallet-connection';
import { epicRunSwapTransactionZyfi } from '../features/2 - swap/epics/_zyfi_/epic.run.swap-transaction-zyfi';
import { epicOnSourceTokenChange } from '../features/2 - swap/epics/epic.on.source-token-change';
import { epicSendGetGeneralTx } from '../features/4 - send/epics/epic.send.get-general-tx';
import { epicSendOnFlushReset } from '../features/4 - send/epics/epic.send.on-flush-reset';
import { epicSendTriggerGetGeneralTx } from '../features/4 - send/epics/epic.send.trigger-get-general-tx';
import { epicSendPollingTransaction } from '../features/4 - send/epics/epic.send.polling-transaction';
import { epicSendRunTransaction } from '../features/4 - send/epics/epic.send.run-transaction';
import { epicSendSaveTransaction } from '../features/4 - send/epics/epic.send.save-transaction';
import { epicSendPollingMultipleTransactionSyncStatus } from '../features/4 - send/epics/epic.send.polling-multiple-transaction-sync-status';
// custom transaction
import { epicCustomGetGeneralTx } from '../features/3 - custom/epics/epic.custom-get-general-tx';
import { epicCustomOnFlushReset } from '../features/3 - custom/epics/epic.custom-on-flush-reset';
import { epicCustomPollingMultipleTransactionSyncStatus } from '../features/3 - custom/epics/epic.custom-polling-multiple-transaction-sync-status';
import { epicCustomPollingTransaction } from '../features/3 - custom/epics/epic.custom-polling-transaction';
import { epicCustomRunTransaction } from '../features/3 - custom/epics/epic.custom-run-transaction';
import { epicCustomSaveTransaction } from '../features/3 - custom/epics/epic.custom-save-transaction';
import { epicCustomTriggerGetGeneralTx } from '../features/3 - custom/epics/epic.custom-trigger-get-general-tx';
export const appEpics = [
    // array of objects here is to allow the following syntax `import * as commonEpics from '../common/epics'`
    {
        epicIframePostMessages,
        epicTransactionPolling,
        epicTransactionPollingTrigger,
        epicOnNetworkChange,
        epicOnSetIsIframeBuy,
        epicOnResetWallet: epicOnWalletReset,
        epicOnFlushBuy,
        epicOnSetIsIframeSwap,
        epicOnFlushSwap,
        epicGetSwapRates,
        epicGetTokenContracts,
        epicGetUserWallet,
        epicGetFiatToTokenRate,
        epicGetTransakTokens,
        epicGetTransakFiat,
        epicPostOrder,
        epicGetGasFees,
        epicSignPermit,
        epicOnFlushWallet: epicOnWalletFlush,
        epicGetAllowance,
        epicGetGasFeeFinal,
        epicUnlockScreenInit: ENV.isOndefy() ? epicGetGasFeeFinal : epicGetTransactionApprovalDetailsZyfi,
        epicRunSwapTransaction: ENV.isOndefy() ? epicRunSwapTransaction : epicRunSwapTransactionZyfi,
        epicRunUnlockTokenForSwap: ENV.isOndefy() ? epicRunUnlockTokenForSwap : epicRunUnlockTokenForSwapZyfi,
        epicGetGeneralTx: ENV.isZyfi() ? epicGetGeneralTx : () => EMPTY,
        epicRunSwapRawTransaction: ENV.isZyfi() ? epicRunSwapRawTransaction : () => EMPTY,
        epicOnBestSwapRateChange,
        epicSaveTransaction,
        epicPollingSwapUnlockTransaction,
        epicOnUnlockTransactionSuccess,
        epicRunGetSwapAndGasFeesPolling,
        epicPollingUpdateSelectedSwapper,
        epicPollingMultipleTransactionSyncStatus,
        epicOnSourceTokenAmountChange,
        epicSyncUserWalletConnection,
        epicCustomGetGeneralTx,
        epicCustomOnFlushReset,
        epicCustomPollingMultipleTransactionSyncStatus,
        epicCustomPollingTransaction,
        epicCustomRunTransaction,
        epicCustomSaveTransaction,
        epicCustomTriggerGetGeneralTx,
        epicOnBestRateSuccess: ENV.isZyfi() ? epicOnBestRateSuccess : () => EMPTY,
        epicOnDisconnect: epicOnWalletDisconnect,
        epicOnSourceTokenChange,
        epicOnUserСonnect,
        epicSendGetGeneralTx: ENV.isZyfi() ? epicSendGetGeneralTx : () => EMPTY,
        epicSendOnFlushReset: ENV.isZyfi() ? epicSendOnFlushReset : () => EMPTY,
        epicSendTriggerGetGeneralTx: ENV.isZyfi() ? epicSendTriggerGetGeneralTx : () => EMPTY,
        epicSendPollingTransaction: ENV.isZyfi() ? epicSendPollingTransaction : () => EMPTY,
        epicSendRunTransaction: ENV.isZyfi() ? epicSendRunTransaction : () => EMPTY,
        epicSendSaveTransaction: ENV.isZyfi() ? epicSendSaveTransaction : () => EMPTY,
        epicSendPollingMultipleTransactionSyncStatus: ENV.isZyfi()
            ? epicSendPollingMultipleTransactionSyncStatus
            : () => EMPTY
        // †epic
    }
];
