import React from 'react';
import { useTheme, SText, SFlexbox, OButton, OSkeleton, OSvgIcon, OIcon } from '@ondefy/ondefy-ui';
import { EQUAL_ROUND_SYMBOL } from '../../../__common__/constants';
import { SWAPPER_NAME_BY_IDX } from '../../../__common__/constants/contracts';
import { NumboLabel } from '../../../__common__/numbo/components/numbo-label';
import { getSwapperIconName } from '../../../__common__/transducers/get-swapper-icon-name';
import { Numbo } from '../../../__common__/numbo/transducers';
import { selectBestSwapRate, selectSwapRates } from '../../selectors';
import { setSelectedSwapperIndex } from '../../slice';
import { useIframeUrlColors } from '../../../iframe/hooks/use-iframe-url-colors';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { EEntityStatus } from '../../../../redux/redux-entity';
import { usePaperSwapDetails } from './__hooks__/use-paper-swap-details';
import { SWrapper } from './__styled__/s-wrapper';
import _ from 'lodash/fp';
export const Rates = () => {
    var _a;
    const { theme } = useTheme();
    const swapRates = useAppSelector(selectSwapRates);
    const dispatch = useAppDispatch();
    const bestRate = useAppSelector(selectBestSwapRate);
    const selectedSwapperIndex = _.path(['swapperIdx'], bestRate);
    // const clickedSwapperIndex = useAppSelector(selectSelectedSwapperIndex)
    const { colorPrimary } = useIframeUrlColors();
    const paperSwapDetails = usePaperSwapDetails();
    return (React.createElement(SWrapper, null,
        React.createElement(SText, { px: "15px", type: "h6", fontWeight: "semibold" }, "Swap using"),
        React.createElement(SFlexbox, { flexDirection: "column", px: "15px", gap: "13px", alignItems: "center" }, swapRates.status === EEntityStatus.loading ? (React.createElement(React.Fragment, null,
            React.createElement(OSkeleton, { height: 60, width: "100%" }),
            React.createElement(OSkeleton, { height: 60, width: "100%" }),
            React.createElement(OSkeleton, { height: 60, width: "100%" }))) : (React.createElement(React.Fragment, null, (_a = swapRates.value) === null || _a === void 0 ? void 0 : _a.filter(_.complement(_.isNil)).map((rate, index) => {
            const selected = rate.swapperIdx === selectedSwapperIndex;
            const isValueGain = _.path('isValueGain', rate);
            return (React.createElement(OButton, { key: rate.swapperIdx, width: "100%", height: "60px", 
                // @ts-ignore
                bg: rate.swapperIdx === selectedSwapperIndex && colorPrimary ? colorPrimary : undefined, variant: rate.swapperIdx === selectedSwapperIndex ? 'primary' : 'secondary', onClick: () => {
                    dispatch(setSelectedSwapperIndex(rate.swapperIdx));
                    paperSwapDetails.setIsOpened(false);
                }, iconLeft: React.createElement(OSvgIcon, { name: getSwapperIconName(rate.swapperIdx, theme.id), size: 28 }), iconRight: index === 0 ? (React.createElement(SText
                // @ts-ignore
                , { 
                    // @ts-ignore
                    color: selected ? theme.colors.bg2 : theme.colors.typo }, "BEST")) : (React.createElement(SText
                // @ts-ignore
                , { 
                    // @ts-ignore
                    color: selected ? theme.colors.bg2 : isValueGain ? theme.colors.accent : theme.colors.error }, _.path('valueLossFormatted', rate))), justifyContent: "space-between" },
                React.createElement(SFlexbox, { width: "100%", flexDirection: "column", alignItems: "flex-start" },
                    React.createElement(SText
                    // @ts-ignore
                    , { 
                        // @ts-ignore
                        color: selected ? theme.colors.bg2 : theme.colors.typo },
                        _.path('toAmount', bestRate) ? (React.createElement(NumboLabel, { label: Numbo.injectDecimals(_.path('toAmount', bestRate), _.path('toAmountDecimals', bestRate)), toFixed: 4, groupZeros: 3 })) : undefined,
                        ' ', rate === null || rate === void 0 ? void 0 :
                        rate.toSymbol,
                        " (", rate === null || rate === void 0 ? void 0 :
                        rate.toAmountUsdFormatted,
                        ") on ",
                        SWAPPER_NAME_BY_IDX[rate.swapperIdx]),
                    React.createElement(SText
                    // @ts-ignore
                    , { 
                        // @ts-ignore
                        color: selected ? theme.colors.bg3 : theme.colors.bg4 },
                        EQUAL_ROUND_SYMBOL,
                        " ", rate === null || rate === void 0 ? void 0 :
                        rate.toAmountUsdWithGasFeesFormatted,
                        " after gas fees (",
                        React.createElement(OIcon, { className: "rates__fuel-icon", name: "fuel", pt: "5px" }),
                        ' ', rate === null || rate === void 0 ? void 0 :
                        rate.gasFeeUsdFormatted,
                        ")"))));
        }))))));
};
