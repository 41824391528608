import { ofType } from 'redux-observable';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getTransakFiatCurrenciesFulfilled, getTransakFiatCurrenciesRejected, getTransakFiatCurrencies } from '../slice';
import { getTransakFiatCurrenciesRx } from '../api/integrations/transak';
export const epicGetTransakFiat = (action$, state$) => {
    return action$.pipe(ofType(getTransakFiatCurrencies.toString()), switchMap(() => {
        return getTransakFiatCurrenciesRx().pipe(map(v => {
            return getTransakFiatCurrenciesFulfilled(v);
        }), catchError(e => {
            return of(getTransakFiatCurrenciesRejected(e));
        }));
    }));
};
