import { SBox, styled } from '@ondefy/ondefy-ui';
export const SScreenInner = styled(SBox) `
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  padding: 8px;
`;
