import React from 'react';
import { useAppSelector } from '../../../../../../redux/hooks';
import { Alert } from '../../../../../__common__/components/alert';
import { selectIsZyfiPriceHigh, selectSourceTokenIdSwapIsEthereum } from '../../../../selectors';
import { useIsInsufficientToPay } from '../../__hooks__/use-is-insufficient-to-pay';
export const ZyfiWarningPrice = () => {
    const isPriceHigh = useAppSelector(selectIsZyfiPriceHigh);
    const isEthereumSwap = useAppSelector(selectSourceTokenIdSwapIsEthereum);
    const isInsufficiantToPay = useIsInsufficientToPay();
    if (isInsufficiantToPay && isEthereumSwap) {
        // return <Alert variant="error">Insufficient amount of fee token to pay for gas.</Alert>
        return React.createElement(Alert, { variant: "error" }, "Not enough fee token to pay for gas.");
    }
    if (!isPriceHigh) {
        return null;
    }
    return React.createElement(Alert, { variant: "warning" }, "Fees exceed 50% of the swap amount!");
};
