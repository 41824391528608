import React from 'react';
import { OIcon, OIconNet, OMedia, OSkeleton, SBox, SText, SFlexbox } from '@ondefy/ondefy-ui';
import { ENV } from '../../../../env';
import { useAppSelector } from '../../../../redux/hooks';
import { ENetworkNames } from '../../../__common__/constants/networks';
import { NumboLabel } from '../../../__common__/numbo/components/numbo-label';
import { selectBestSwapRate, selectFeeTokenIdSwap, selectFeeTokenSwap, selectSourceTokenIdSwapIsEthereum, selectSourceTokenSwap, selectSwapRates, selectZyFiGeneralTx } from '../../selectors';
import { isEtherToken } from '../../../__common__/transducers/is-ether-token';
import { formatZyfiValue } from './transaction-details/__transducers__/format-zyfi-value';
import { formatZyfiValueForEthFee } from './transaction-details/__transducers__/format-zyfi-value-for-eth-fee';
export const ZyfiTxFee = ({ pl, showIncludingGasCost }) => {
    const generalTxZyfi = useAppSelector(selectZyFiGeneralTx);
    const feeToken = useAppSelector(selectFeeTokenSwap);
    const feeTokenId = useAppSelector(selectFeeTokenIdSwap);
    const sourceToken = useAppSelector(selectSourceTokenSwap);
    const isEthereumSource = useAppSelector(selectSourceTokenIdSwapIsEthereum);
    const swapRates = useAppSelector(selectSwapRates);
    const bestRate = useAppSelector(selectBestSwapRate);
    const isZyfiEthFeeMode = isEtherToken(feeTokenId) && ENV.isZyfi() && isEthereumSource;
    const isSwapRatesForEthFeeLoading = isZyfiEthFeeMode && swapRates.isLoading;
    const formattedZyfiValue = formatZyfiValue(generalTxZyfi);
    return (React.createElement(SFlexbox, { flex: "1", pl: pl },
        ((generalTxZyfi.isLoading && (!isEthereumSource || (isEthereumSource && feeToken))) ||
            isSwapRatesForEthFeeLoading) && (React.createElement(SBox, null,
            React.createElement(OSkeleton, { width: "60" }))),
        !isSwapRatesForEthFeeLoading &&
            !generalTxZyfi.isLoading &&
            (generalTxZyfi.value || generalTxZyfi.isError || (isZyfiEthFeeMode && swapRates.value)) && (React.createElement(OMedia, { alignItems: "center" },
            React.createElement(OIcon, { name: "fuel", mr: "4px", color: "accent", size: 14 }),
            React.createElement(React.Fragment, null,
                React.createElement(SBox, { position: "relative" },
                    React.createElement(SText, { type: "small", mb: "0", position: "relative", display: "block", lineHeight: "1", top: "-0.5px", opacity: "0.7" },
                        "Fees in",
                        showIncludingGasCost && (React.createElement(SBox, { position: "absolute", left: "-16px", width: "300%", top: "16px" },
                            React.createElement(SText, { type: "small", fontSize: "8px", display: "block" }, "(incl. gas cost)"))))),
                React.createElement(SBox, { display: "inline-flex", mr: "-4px", style: { transform: 'scale(0.5)' } },
                    React.createElement(OIconNet, { network: ENetworkNames.zksync, coinImage: (isEthereumSource ? feeToken === null || feeToken === void 0 ? void 0 : feeToken.logoURI : sourceToken === null || sourceToken === void 0 ? void 0 : sourceToken.logoURI), coinName: isEthereumSource ? feeToken === null || feeToken === void 0 ? void 0 : feeToken.name : sourceToken === null || sourceToken === void 0 ? void 0 : sourceToken.name, mr: "8px" })),
                React.createElement(SText, { type: "small", mb: "0", position: "relative", top: "1px", opacity: "0.7" }, generalTxZyfi.isError ? ('N/A') : isZyfiEthFeeMode ? (formatZyfiValueForEthFee(bestRate, feeToken === null || feeToken === void 0 ? void 0 : feeToken.symbol)) : formattedZyfiValue ? (React.createElement(React.Fragment, null,
                    React.createElement(NumboLabel, { label: formattedZyfiValue.feeTokenAmountWithDecimals, min: 0.0001, groupZeros: 3, toFixed: 4 }),
                    ' ',
                    "(\u2248 $",
                    React.createElement(NumboLabel, { label: formattedZyfiValue.formattedUSD, toFixed: 2, min: 0.01 }),
                    ")")) : null))))));
};
