import _ from 'lodash/fp';
import { ofType } from 'redux-observable';
import { switchMap, map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { AppState } from '../../../redux/store'
import { NATIVE_ADDRESS } from '../../__common__/constants/contracts';
import { selectNetworkIdTargetFiat, selectTargetTokenFiat, selectTargetTokenIdFiat, selectSourceAmount, selectRefuelAmount, selectSelectedFiatSymbol, selectTransakCryptoCurrencies } from '../selectors';
import { getFiatToTokenRateRx } from '../api/get-fiat-to-token-rate';
import { getFiatToTokenRateFulfilled, getFiatToTokenRateRejected, getFiatToTokenRate } from '../slice';
export const epicGetFiatToTokenRate = (action$, state$) => {
    return action$.pipe(ofType(getFiatToTokenRate.toString()), switchMap(() => {
        const { value } = state$;
        const transakCryptoCurrencies = selectTransakCryptoCurrencies(value);
        const fiatAmount = parseFloat(selectSourceAmount(value));
        const networkId = selectNetworkIdTargetFiat(value);
        const selectedTargetToken = selectTargetTokenFiat(value);
        const fiatSymbol = selectSelectedFiatSymbol(value);
        const tokenId = selectTargetTokenIdFiat(value);
        const tokenAddress = (selectedTargetToken === null || selectedTargetToken === void 0 ? void 0 : selectedTargetToken.isNative) ? NATIVE_ADDRESS : _.path('address', selectedTargetToken);
        const tokenDecimals = _.path('decimals', selectedTargetToken);
        const refuelAmount = selectRefuelAmount(value);
        // console.log('1. epic.get-fiat-to-token-rate start')
        if (!fiatAmount || !networkId || !selectedTargetToken || !fiatSymbol || !transakCryptoCurrencies.value) {
            return of(getFiatToTokenRateRejected({
                message: 'Not all required fields are ready'
            }));
        }
        return getFiatToTokenRateRx({
            transakCyptocurrencies: transakCryptoCurrencies.value,
            fiatSymbol,
            fiatAmount,
            tokenId,
            tokenAddress,
            tokenDecimals,
            refuelAmount,
            networkId
        }).pipe(map(v => getFiatToTokenRateFulfilled(v)), catchError(e => {
            return of(getFiatToTokenRateRejected({
                message: e.message
            }));
        }));
    }));
};
