import { setIframeColorPrimary } from '../slice';
import qs from 'qs';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
export const useSetIframeColorPrimary = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const { 
        // tokenId,
        // tokenAddress,
        // networkId,
        // defaultNetworkId,
        // fiatAmount,
        // defaultFiatAmount,
        // fiatCurrency,
        // defaultFiatCurrency,
        colorPrimary } = qs.parse(window.location.search, {
            ignoreQueryPrefix: true
        });
        if (colorPrimary) {
            dispatch(setIframeColorPrimary(colorPrimary));
        }
        // eslint-disable-next-line
    }, []);
};
