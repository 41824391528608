import React from 'react';
import { OButton, OIcon, OMedia, SFlexbox, SText } from '@ondefy/ondefy-ui';
import { Alert } from '../../../../../__common__/components/alert';
import { NumboLabel } from '../../../../../__common__/numbo/components/numbo-label';
import { calculatePercentage } from '../../../../../wallet/transducers/calculate-percentage';
import { selectPercentage, selectSourceTokeInUserWallet, selectSourceTokenAmount, selectSourceTokenIdSwapIsEthereum, selectSourceTokenSwap } from '../../../../selectors';
import { setPercentage, setSourceTokenAmount } from '../../../../slice';
import { useUserWallet } from '../../../../../wallet/hooks/use-user-wallet';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import _ from 'lodash/fp';
import { useIsInsufficientToPay } from '../../__hooks__/use-is-insufficient-to-pay';
export const SourceTokenBalance = () => {
    const sourceToken = useAppSelector(selectSourceTokenSwap);
    const userWallet = useUserWallet();
    const percentage = useAppSelector(selectPercentage);
    const dispatch = useAppDispatch();
    const sourceTokenInUserWallet = useAppSelector(selectSourceTokeInUserWallet);
    const isEthereumSwap = useAppSelector(selectSourceTokenIdSwapIsEthereum);
    const sourceTokenAmount = useAppSelector(selectSourceTokenAmount);
    const isInsufficiantToPay = useIsInsufficientToPay();
    if (!userWallet.value || !sourceToken)
        return null;
    if (!sourceTokenInUserWallet) {
        return null;
    }
    const onPercentageClick = (newPercentage) => () => {
        const amountCalculated = calculatePercentage(sourceTokenInUserWallet, sourceToken, newPercentage);
        if (sourceTokenAmount === amountCalculated && !percentage) {
            dispatch(setPercentage(newPercentage));
        }
        if (sourceTokenAmount !== amountCalculated) {
            dispatch(setPercentage(newPercentage));
            dispatch(setSourceTokenAmount(amountCalculated));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OMedia, { alignItems: "center", justifyContent: "space-between", px: "8px" },
            React.createElement(OMedia, { alignItems: "center" },
                React.createElement(OIcon, { name: "balance", mr: "5px" }),
                React.createElement(SText, { type: "small" },
                    React.createElement(NumboLabel, { label: _.path('amount', sourceTokenInUserWallet), groupZeros: 3, toFixed: 4 }),
                    ' ', sourceToken === null || sourceToken === void 0 ? void 0 :
                    sourceToken.symbol,
                    " \u2248 $",
                    React.createElement(NumboLabel, { label: _.path('amountUSD', sourceTokenInUserWallet), toFixed: 2, min: 0.01 }))),
            React.createElement(SFlexbox, { gap: "5px" },
                React.createElement(OButton, { onClick: onPercentageClick(25), size: "sm", variant: percentage === 25 ? 'tertiary' : 'secondary' }, "25%"),
                React.createElement(OButton, { onClick: onPercentageClick(50), variant: percentage === 50 ? 'tertiary' : 'secondary', size: "sm" }, "50%"),
                React.createElement(OButton, { onClick: onPercentageClick(75), size: "sm", variant: percentage === 75 ? 'tertiary' : 'secondary' }, "75%"),
                React.createElement(OButton, { size: "sm", onClick: onPercentageClick(100), variant: percentage === 100 ? 'tertiary' : 'secondary' }, "Max"))),
        isInsufficiantToPay && !isEthereumSwap && (React.createElement(Alert, { variant: "error" }, "Insufficient amount to pay for gas, select a smaller amount."))));
};
