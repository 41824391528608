import { SBox } from '@ondefy/ondefy-ui';
import React from 'react';
import { OPaper } from '../../../__common__/components/o-paper';
import { usePaperSwapDetails } from './__hooks__/use-paper-swap-details';
import { Rates } from './rates';
import { Slippage } from './slippage';
export const PaperSwapDetails = () => {
    const paper = usePaperSwapDetails();
    return (React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom", title: "More details" }),
        React.createElement(SBox, { mb: "20px" },
            React.createElement(Slippage, null)),
        React.createElement(Rates, null)));
};
