import { OMedia, SBox, SFlexbox } from '@ondefy/ondefy-ui';
import React from 'react';
import { ENV } from '../../../../env';
import { useAppSelector } from '../../../../redux/hooks';
import { APP_MODE } from '../../constants';
import { selectAppMode } from '../../selectors';
import { SHeader } from './__styled__/s-header';
import { MenuButton } from './menu-button';
import { ModeButton } from './mode-button';
export const Header = () => {
    const appMode = useAppSelector(selectAppMode);
    return (React.createElement(SHeader, { gridTemplateColumns: {
            xs: '2fr 1fr'
            // lg: '1fr 1fr',
            // xs: '1fr 1fr',
        } },
        React.createElement(SFlexbox
        // order={{ xs: 3, lg: 2 }}
        , { 
            // order={{ xs: 3, lg: 2 }}
            justifyContent: "flext-start", gap: "8px", pl: "8px" },
            React.createElement(ModeButton, { variant: APP_MODE.buy, hidden: ENV.isZyfi() }),
            React.createElement(ModeButton, { variant: APP_MODE.swap, hidden: appMode === APP_MODE.custom || appMode === APP_MODE.send }),
            React.createElement(ModeButton, { variant: APP_MODE.send, hidden: ENV.isOndefy() || appMode === APP_MODE.custom || appMode === APP_MODE.swap }),
            React.createElement(ModeButton, { variant: APP_MODE.custom, hidden: ENV.isOndefy() || appMode === APP_MODE.send || appMode === APP_MODE.swap })),
        React.createElement(SBox, null,
            React.createElement(OMedia, { justifyContent: "flex-end" },
                React.createElement(MenuButton, null)))));
};
