import { formatUnits } from './big-numbers/big-numbers-utils';
// import numeral from 'numeral'
// import _ from 'lodash/fp'
import { toFixedBig } from './to-fixed-big';
export const formatWithDecimals = (amount, decimals, numberOfFixed) => {
    const bigNumber = BigInt(amount);
    const stringValue = formatUnits(bigNumber, decimals);
    const numberValue = Number(stringValue);
    if (!numberOfFixed) {
        return stringValue;
    }
    const threshold = 0.1 / 10 ** numberOfFixed;
    if (numberValue < threshold) {
        // I'd like to get 1.81e-16 instead of 1.810000e-16 - no need for additional fixed
        // return numberValue.toExponential(numberOfFixed)
        return numberValue.toExponential();
    }
    else {
        // const zeros = _.repeat(numberOfFixed, '0')
        // return numeral(numberValue.toString()).format(`0.[${zeros}]a`)
        return toFixedBig(numberValue.toString(), numberOfFixed);
    }
};
