import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { selectUserAddress } from '../selectors';
import { getUserWallet } from '../slice';
export const epicOnUserСonnect = (action$, state$) => {
    return state$.pipe(map(selectUserAddress), distinctUntilChanged(), filter((v) => {
        return Boolean(v);
    }), map(() => {
        return getUserWallet();
    }));
};
