import { Numbo } from '../../__common__/numbo/transducers';
export const getParseUnits = (amount, decimals) => {
    let units = '';
    try {
        units = Numbo.parseUnits(Numbo.toFixedBig(amount, decimals), decimals).toString();
    }
    catch (e) {
        console.log('ERROR getParseUnits', { amount, decimals });
    }
    return units;
};
