var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getPublicClient } from '../../../__common__/web3/get-public-client';
export const getGasFeeEstimate = ({ networkId, rawTx, gasPriceData }) => __awaiter(void 0, void 0, void 0, function* () {
    const client = getPublicClient(networkId);
    if (!rawTx) {
        return;
    }
    // const { maxFeePerGas, maxPriorityFeePerGas /*, gasPrice */ }: TGasFeeConfig = gasPriceData as TGasFeeConfig
    const { maxFeePerGas, maxPriorityFeePerGas, gasPrice } = gasPriceData;
    const params = {
        data: rawTx.data,
        to: rawTx.to,
        account: rawTx.from,
        value: rawTx.value ? BigInt(rawTx.value) : BigInt(0),
        nonce: rawTx.nonce,
        // TODO: test it
        gasLimit: rawTx.gasLimit ? BigInt(rawTx.gasLimit) : undefined
    };
    if (maxFeePerGas && maxPriorityFeePerGas) {
        // @ts-ignore
        params.maxFeePerGas = BigInt(maxFeePerGas);
        // @ts-ignore
        params.maxPriorityFeePerGas = BigInt(maxPriorityFeePerGas);
    }
    else if (gasPrice) {
        // @ts-ignore
        params.gasPrice = BigInt(gasPrice);
    }
    return yield client.estimateGas(params);
});
