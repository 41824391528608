import { SFlexbox, styled, breakpoint } from '@ondefy/ondefy-ui';
import { zyfiBackgroundMixin } from '../../../styled/zyfi-background-mixin';
export const SWrapper = styled(SFlexbox) `
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-left: ${p => (p.isIframe || p.isXsScreen ? undefined : '10px')};
  margin-right: ${p => (p.isIframe || p.isXsScreen ? undefined : '10px')};
  flex: 1;
  width: 100%;
  margin-bottom: 0;
  border-radius: ${p => (p.isIframe || p.isXsScreen ? undefined : '18px')};

  background-color: ${p => zyfiBackgroundMixin(p.theme.colors.bg2)};

  @media ${breakpoint.sm} {
    max-width: ${p => (p.isIframe ? undefined : '450px')};
  }
`;
