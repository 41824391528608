import { EBreakpoints, SGridbox, styled } from '@ondefy/ondefy-ui';
export const SHeader = styled(SGridbox) `
  width: 100%;
  position: absolute;
  transform: translateY(calc(-100% - 1px));

  @media (max-width: ${EBreakpoints.sm}) {
    transform: translateY(calc(-100% + 1px));
  }
`;
