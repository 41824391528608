import { toast } from '@ondefy/ondefy-ui';
import { ofType } from 'redux-observable';
import { switchMap, zip, of, takeUntil, delay, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ENV } from '../../../env';
import { NETWORKS_DATA } from '../../__common__/constants/networks';
import { waitForState } from '../../__common__/rxjs-utils/wait-for-state';
import { selectTokenContractsAssoc } from '../../__common__/selectors';
import { setAppMode, setPage } from '../../__common__/slice';
import { isEtherToken } from '../../__common__/transducers/is-ether-token';
import { showSubtractInfo } from '../epics.taps/show-subtract-info';
import { selectNetworkIdTargetSwap, selectTargetTokenSwap, selectGasFees, selectSlippage, selectSourceTokenSwap, selectSourceTokenAmount, selectSourceTokenAmountWithDecimals, selectFeeTokenIdSwap, selectSourceTokenIdSwapIsEthereum, selectPercentage } from '../selectors';
import { selectUserAddress, selectUserWallet } from '../../wallet/selectors';
import { getSwapperRateRx } from '../api/get-swapper-rate';
import { noSwappersError, noSwappersRetries } from '../signals/no-swappers-error';
import { getSwapRatesFullfilled, getSwapRates, getSwapRatesRejected, restartBestRatesPolling, setSourceTokenAmount } from '../slice';
import { calculateNewSourceAmountForEthFee } from '../transducers/calculate-new-source-amount-for-eth-fee';
import { normalizeBestRatesWithGasFees } from '../transducers/normalize-best-rates-with-gas-fees';
export const epicGetSwapRates = (action$, state$) => {
    /*
     * Required fields changes will trigger the process
     * */
    return action$.pipe(ofType(getSwapRates.toString()), switchMap(v => {
        const { value } = state$;
        const networkTargetId = selectNetworkIdTargetSwap(value);
        const tokenSource = selectSourceTokenSwap(value);
        const tokenTarget = selectTargetTokenSwap(value);
        const sourceTokenAmount = selectSourceTokenAmountWithDecimals(value);
        const userAddress = selectUserAddress(value);
        const slippage = selectSlippage(value);
        const params = {
            networkId: networkTargetId,
            userAddress,
            amount: sourceTokenAmount,
            slippage,
            srcToken: tokenSource,
            destToken: tokenTarget
        };
        // TODO: ask Clement what is this logic about
        const { authorizedSwappersFOT, authorizedSwappers } = NETWORKS_DATA[networkTargetId];
        const swappers = tokenSource.feesOnTransfer === 1 || tokenTarget.feesOnTransfer === 1
            ? authorizedSwappersFOT
            : authorizedSwappers;
        const requests = swappers.map(swapperIndex => {
            return getSwapperRateRx(Object.assign({ swapperIndex }, params));
        });
        const isAuthorized = !!userAddress;
        // TODO: check with debounce that all parameters have been selected
        return zip(...requests).pipe(
        // TODO: check if gasFees are flushed during new iteration after timeout
        waitForState(state$, selectGasFees, { ignoreSourceCompletion: true }), isAuthorized
            ? waitForState(state$, selectUserWallet, { ignoreSourceCompletion: true })
            : tap(() => {
                // skip blocking
            }), takeUntil(action$.pipe(ofType(getSwapRatesRejected.toString(), setPage.toString(), setAppMode.toString()))), switchMap((swapRatesRes) => {
            const { value } = state$;
            const tokensAssoc = selectTokenContractsAssoc(value);
            const { value: gasPrices } = selectGasFees(value);
            const tokenSource = selectSourceTokenSwap(value);
            const sourceTokenAmount = selectSourceTokenAmount(value);
            const formattedResponse = normalizeBestRatesWithGasFees({
                tokensAssoc: tokensAssoc,
                gasPrices: gasPrices,
                networkTargetId,
                tokenTarget,
                tokenSource,
                sourceTokenAmount,
                swapRatesRes
            });
            // TODO: add calculation
            // TODO for zyfy for ETH fee token → return of(setSourceTokenAmount(newAmount))
            if ((formattedResponse === null || formattedResponse === void 0 ? void 0 : formattedResponse.length) === 0 && noSwappersRetries.value <= 4) {
                return of(restartBestRatesPolling()).pipe(tap(() => {
                    noSwappersRetries.value += 1;
                    if (noSwappersRetries.value === 1) {
                        toast.next({
                            variant: 'info',
                            message: `Swappers were not found. I'll try again`
                        });
                    }
                }), delay(2000));
            }
            if ((formattedResponse === null || formattedResponse === void 0 ? void 0 : formattedResponse.length) === 0) {
                // @ts-ignore
                return throwError(() => {
                    return new Error('Failed to find swappers for this transaction');
                });
            }
            const feeTokenId = selectFeeTokenIdSwap(value);
            const sourceTokenIsEther = selectSourceTokenIdSwapIsEthereum(value);
            const percentage = selectPercentage(value);
            // subtract fee if we use percentage buttons for the case when we swap ETH and pay with ETH
            if (ENV.isZyfi() && isEtherToken(feeTokenId) && sourceTokenIsEther && percentage) {
                const { shouldSubtract, newSourceTokenAmount } = calculateNewSourceAmountForEthFee(value, formattedResponse);
                if (shouldSubtract && newSourceTokenAmount !== sourceTokenAmount) {
                    return of(setSourceTokenAmount(String(newSourceTokenAmount))).pipe(tap(() => {
                        // console.log('Gas fee subtracted from source')
                        showSubtractInfo();
                    }));
                }
                return of(getSwapRatesFullfilled(formattedResponse));
            }
            return of(getSwapRatesFullfilled(formattedResponse)).pipe(tap(() => {
                // Hide retry error
                noSwappersError.value = null;
                // Reset retry counter
                noSwappersRetries.value = 0;
            }));
        }), catchError(e => {
            return of(getSwapRatesRejected(e)).pipe(tap(() => {
                // Show retry error
                noSwappersError.value = 'No swappers found';
                // Reset retry counter
                noSwappersRetries.value = 0;
                showSubtractInfo();
            }));
        }));
    }));
};
