import { signal } from '@preact/signals-react';
import axios from 'axios';
import { connect, filter, merge, of, switchMap, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ENV } from '../../../../env';
import { ApiObservable } from '../../api-observable/api-observable';
import { Numbo } from '../../numbo/transducers';
// import qs from 'qs'
import { ESwappers, NATIVE_ADDRESS, SWAPPER_NAME_BY_IDX } from '../../constants/contracts';
import { getGasFeeForOdos } from './transducers/get-gas-fee-for-odos';
import { themeZyfiDark } from '@ondefy/ondefy-ui';
// import { signal } from '@preact/signals-react'
// contact the 1inch proxy on the back-end server
const apirxOdos = ApiObservable.from(axios.create({
    baseURL: `https://api.odos.xyz`
}));
export const apiOdosError = signal(null);
// export const api1InchError = signal<string | null>(null)
// function apiRequestUrl(networkId: number, methodName: string, queryParams: TOneInchReqParams) {
//   return `/${networkId}/${methodName}?${qs.stringify(queryParams)}`
// }
const ODOS_NATIVE_ADDRESS = '0x0000000000000000000000000000000000000000';
export function getOdosSwapDataRx({ networkId, usingOndefyRouter, fromAddress, destReceiver, srcToken, destToken, amount, slippage }) {
    const isEthSource = srcToken === NATIVE_ADDRESS;
    srcToken = isEthSource ? ODOS_NATIVE_ADDRESS : srcToken;
    destToken = destToken === NATIVE_ADDRESS ? ODOS_NATIVE_ADDRESS : destToken;
    // TODO: what should be sent?
    const quoteParams = {
        chainId: networkId,
        inputTokens: [{ amount, tokenAddress: srcToken }],
        outputTokens: [{ proportion: 1, tokenAddress: destToken }],
        userAddr: fromAddress,
        slippageLimitPercent: slippage,
        referralCode: 262569,
        likeAsset: true,
        disableRFQs: true,
        pathVizImage: true,
        pathVizImageConfig: {
            linkColors: ['#2660A4', '#A17C6B', '#A6D3A0'],
            nodeColor: themeZyfiDark.colors.bg,
            nodeTextColor: themeZyfiDark.colors.typo,
            legendTextColor: themeZyfiDark.colors.typo
            // width: 1200,
            // height: 800
        }
    };
    const API_TIMEOUT_ODOS = 5000;
    const timeoutConfig = {
        timeout: API_TIMEOUT_ODOS
    };
    const setOdosError = (e) => {
        console.info('Odos api error: ', e);
        apiOdosError.value = e === null || e === void 0 ? void 0 : e.detail;
    };
    return apirxOdos.post('/sor/quote/v2', quoteParams, timeoutConfig).pipe(tap({
        next: () => {
            apiOdosError.value = null;
        },
        error: () => {
            apiOdosError.value = null;
        }
    }), connect(shared$ => merge(shared$.pipe(filter(() => !fromAddress), switchMap(quoteResData => {
        const { gasEstimate, gasLimit } = getGasFeeForOdos(quoteResData.gasEstimate);
        const result = {
            swapperIdx: ESwappers.ODOS_SWAPPER_IDX,
            swapperName: SWAPPER_NAME_BY_IDX[ESwappers.ODOS_SWAPPER_IDX],
            // extra fields
            networkId,
            fromTokenAddress: srcToken,
            fromAmount: amount,
            toTokenAddress: destToken,
            toAmount: quoteResData.outAmounts[0],
            gasEstimate,
            gasLimit
        };
        return of(result);
    })), shared$.pipe(filter(() => Boolean(fromAddress)), switchMap(quoteResData => {
        const swapParams = {
            pathId: quoteResData.pathId,
            userAddr: fromAddress,
            simulate: ENV.isZyfi() && isEthSource
        };
        return apirxOdos.post('/sor/assemble', swapParams, timeoutConfig).pipe(switchMap(swapResData => {
            return of([quoteResData, swapResData]);
        }), catchError(e => {
            console.error('getOdosSwapData', e);
            return throwError(() => new Error('Swap rates failed for odos'));
        }));
    }), switchMap(([quoteResData, swapResData]) => {
        // console.log('ODOS: swap fetch success', {
        //   quoteResData,
        //   swapResData
        // })
        if (!swapResData.transaction) {
            return throwError(() => new Error('invalid response from odos'));
        }
        const { gasEstimate, gasLimit } = getGasFeeForOdos(swapResData.gasEstimate);
        const result = {
            swapperIdx: ESwappers.ODOS_SWAPPER_IDX,
            swapperName: SWAPPER_NAME_BY_IDX[ESwappers.ODOS_SWAPPER_IDX],
            // extra fields
            networkId,
            fromTokenAddress: srcToken,
            fromAmount: amount,
            toTokenAddress: destToken,
            toAmount: swapResData.outputTokens[0].amount,
            gasEstimate,
            gasLimit,
            meta: {
                gasFeeInUsd: swapResData.gasEstimateValue,
                gasFeeInUsdFormatted: Numbo.formatCurrency(swapResData.gasEstimateValue),
                // feeTokenAmount: (quoteResData as TOdosQuoteRes).gweiPerGas * (swapResData as TOdosSwapRes).gasEstimate,
                feeTokenAmount: Numbo.parseUnits(String(quoteResData.gweiPerGas), 'gwei')
                    .mul(swapResData.gasEstimate)
                    .toString(),
                // in gwei 10^-9
                feeTokenDecimals: 9,
                pathVizImage: quoteResData.pathVizImage
            }
        };
        /* note : only relevant if we want to broadcast transactions to our router contract from here
        const callData = getCallData({
          srcToken,
          destToken,
          data: swapResData.tx.data,
          amount,
          swapperIndex: ESwappers.ONE_INCH_SWAPPER_IDX,
          network: NETWORKS_DATA[networkId]
        })

        // else return all data needed for swap
        if (callData) {
          return of({
            ...result,
            ...callData
          })
        }
        */
        return of(Object.assign(Object.assign({}, result), { callData: swapResData.transaction.data, contractAddress: swapResData.transaction.to, spenderAddress: swapResData.transaction.to }));
    }), catchError(e => {
        console.error('getOdosSwapData', e);
        setOdosError(e);
        return of(null);
    })))), catchError(e => {
        console.error('getOdosSwapData', e);
        setOdosError(e);
        return of(null);
    }));
}
