/*
 * STATE DEFAULT
 * */
import { ReduxEntity } from '../../redux/redux-entity';
export const initialState = {
    isWeb3Busy: false,
    walletType: undefined,
    isWalletConnected: false,
    userAddress: undefined,
    userEmail: undefined,
    userWallet: ReduxEntity.init()
};
