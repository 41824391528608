import { useState, useEffect, useRef } from 'react';
import Pusher from 'pusher-js';
import { TRANSAK_NETWORK_TO_ID } from '../../../constants/transak';
import { selectPartnerOrderId, selectTransakOrderNetworkId } from '../../../selectors';
import { Analytics } from '../../../../analytics';
import { useAppSelector } from '../../../../../redux/hooks';
import { toast } from '@ondefy/ondefy-ui';
export const useTrackOrderCreation = () => {
    const pusherRef = useRef(
    // TODO: move app key outside!!!
    // TODO: move app key outside!!!
    // TODO: move app key outside!!!
    new Pusher('1d9ffac87de599c61283', { cluster: 'ap2' }));
    const apiKey = process.env.TRANSAK_API_KEY;
    const transakOrderNetworkId = useAppSelector(selectTransakOrderNetworkId);
    const partnerOrderId = useAppSelector(selectPartnerOrderId);
    const channelName = `${apiKey}_${partnerOrderId}`;
    const [isOrderProcessing, setIsOrderProcessing] = useState(false);
    useEffect(() => {
        try {
            const channel = pusherRef.current.subscribe(channelName);
            /*
            channel.bind(`ORDER_CREATED`, (orderData: any) => {
              console.log('ORDER_CREATED', orderData);
            });
            */
            channel.bind('ORDER_PAYMENT_VERIFYING', (orderData) => {
                // console.log('ORDER_PAYMENT_VERIFYING', orderData);
                if (TRANSAK_NETWORK_TO_ID[orderData === null || orderData === void 0 ? void 0 : orderData.network] === transakOrderNetworkId && (orderData === null || orderData === void 0 ? void 0 : orderData.id)) {
                    setIsOrderProcessing(true);
                }
                else {
                    toast.next({
                        variant: 'error',
                        message: 'Invalid order data received from Transak'
                    });
                }
                pusherRef.current.unsubscribe(channelName);
            });
            channel.bind('ORDER_PROCESSING', (orderData) => {
                // console.log('ORDER_PROCESSING', orderData);
                if (TRANSAK_NETWORK_TO_ID[orderData === null || orderData === void 0 ? void 0 : orderData.network] === transakOrderNetworkId && (orderData === null || orderData === void 0 ? void 0 : orderData.id)) {
                    setIsOrderProcessing(true);
                }
                else {
                    toast.next({
                        variant: 'error',
                        message: 'Invalid order data received from Transak'
                    });
                }
                pusherRef.current.unsubscribe(channelName);
            });
            channel.bind('ORDER_COMPLETED', (orderData) => {
                // console.log('ORDER_COMPLETED', orderData);
                if (TRANSAK_NETWORK_TO_ID[orderData === null || orderData === void 0 ? void 0 : orderData.network] === transakOrderNetworkId && (orderData === null || orderData === void 0 ? void 0 : orderData.id)) {
                    setIsOrderProcessing(true);
                    Analytics.transakCompleted();
                }
                else {
                    toast.next({
                        variant: 'error',
                        message: 'Invalid order data received from Transak'
                    });
                }
                pusherRef.current.unsubscribe(channelName);
            });
            return () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                pusherRef.current.unsubscribe(channelName);
                channel.unbind_all();
            };
        }
        catch (e) {
            pusherRef.current.unsubscribe(channelName);
            return () => { };
        }
        // eslint-disable-next-line
    }, []);
    return isOrderProcessing;
};
