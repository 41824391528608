import { ENetworkIdByName } from '../../../constants/networks';
import { GAS_OVERHEAD_SWAP_ROUTER, GAS_OVERHEAD_SWAP_ROUTER_ARBITRUM } from '../constants/gas-fee-estimate';
// TODO: add unit tests!!!
// TODO: add unit tests!!!
// TODO: add unit tests!!!
export const getGasFeeFor1inch = (networkId, quoteResData, usingOndefyRouter) => {
    let gasEstimate = Number(quoteResData.gas);
    if (gasEstimate > 0 && usingOndefyRouter) {
        gasEstimate +=
            networkId === ENetworkIdByName['arbitrum-one'] ? GAS_OVERHEAD_SWAP_ROUTER_ARBITRUM : GAS_OVERHEAD_SWAP_ROUTER;
    }
    const gasLimit = gasEstimate * 1.5;
    return {
        gasLimit,
        gasEstimate
    };
};
