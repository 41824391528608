import { useState, useEffect } from 'react';
/**
 * useDelayedTrue hook.
 *
 * @param {boolean} condition - The condition to listen to.
 * @param {number} delay - Delay in milliseconds before setting state to true.
 * @return {boolean} - The state value.
 */
export const useDelayedTrue = (condition, delay) => {
    const [state, setState] = useState(false);
    useEffect(() => {
        let timerId;
        if (condition) {
            // Set state to true with a delay
            timerId = setTimeout(() => {
                setState(true);
            }, delay);
        }
        else {
            // Set state to false immediately
            setState(false);
            // Clear the timer if the condition changes before the delay is over
            // @ts-ignore
            if (timerId) {
                clearTimeout(timerId);
            }
        }
        // Cleanup function to clear the timer when the component unmounts or condition changes
        return () => clearTimeout(timerId);
    }, [condition, delay]);
    return state;
};
