import { OIcon, OMedia, SBox, SFlexbox, SText, themeLight } from '@ondefy/ondefy-ui';
// @ts-ignore
import SvgOndefy from '@ondefy/ondefy-ui/dist/assets/logo-ondefy-dark.svg';
import { Numbo } from '../../../../../__common__/numbo/transducers';
import { selectTransactionStatus, selectTransakOrderNetworkId, selectTransakOrderWalletAddress } from '../../../../selectors';
import { useIframeUrlColors } from '../../../../../iframe/hooks/use-iframe-url-colors';
import { NETWORKS_DATA } from '../../../../../__common__/constants/networks';
import { ORDER_RAMP_STATUS_FAILED, ORDER_RAMP_STATUS_FULFILLED } from '../../../../constants/order-ramp-status';
import { ORDER_SWAP_STATUS_FULFILLED } from '../../../../constants/order-swap-status';
import React from 'react';
import { EEntityStatus } from '../../../../../../redux/redux-entity';
import { useAppSelector } from '../../../../../../redux/hooks';
import { ThemeProvider } from 'styled-components';
import _ from 'lodash/fp';
export const TransactionReceipt = React.forwardRef(({ orderId, fiatAmount, fiatSymbol, fiatToTokenRate, selectedToken, refuelAmount, isTransakSupported }, ref) => {
    var _a, _b;
    const orderWalletAddress = useAppSelector(selectTransakOrderWalletAddress);
    const orderNetworkId = useAppSelector(selectTransakOrderNetworkId);
    const network = NETWORKS_DATA[orderNetworkId];
    const transactionStatus = useAppSelector(selectTransactionStatus);
    const { swapStatus, rampStatus, swapTxHash, feeUsd, fromTokenDecimals } = transactionStatus.value || {};
    const isCompleted = transactionStatus.status === EEntityStatus.success;
    const { colorPrimary } = useIframeUrlColors();
    return (React.createElement(SBox, { mt: "20px", className: "transaction__route", ref: ref, p: '40px' },
        React.createElement(ThemeProvider, { theme: themeLight },
            React.createElement(SBox, null,
                React.createElement(SBox, { pl: "18px", pt: "40px" },
                    React.createElement(SvgOndefy, null))),
            React.createElement(SBox, null,
                React.createElement(SText, { type: "h5", 
                    // @ts-ignore
                    color: colorPrimary || 'accent', pl: "18px", pt: "15px" }, "Transaction Receipt")),
            React.createElement(SBox, { className: "transaction__steps", p: "18px" },
                React.createElement(OMedia, { justifyContent: "space-between" },
                    React.createElement(SFlexbox, { alignItems: "center" },
                        React.createElement(SText, { type: "small", fontWeight: "semibold" },
                            '• Order ID : ',
                            " ",
                            orderId))),
                React.createElement(OMedia, { justifyContent: "space-between" },
                    React.createElement(SFlexbox, { alignItems: "center" },
                        React.createElement(SText, { type: "small", fontWeight: "semibold" },
                            '• User Wallet Address : ',
                            " ",
                            orderWalletAddress))),
                React.createElement(OMedia, { justifyContent: "space-between" },
                    React.createElement(SFlexbox, { alignItems: "center" },
                        React.createElement(SText, { type: "small", fontWeight: "semibold" },
                            '• Fiat amount paid : ',
                            " ",
                            fiatAmount,
                            " ",
                            fiatSymbol))),
                isTransakSupported ? (React.createElement(OMedia, { "justify-content": "space-between" },
                    React.createElement(SFlexbox, { alignItems: "center" },
                        React.createElement(SText, { type: "small", fontWeight: "semibold" },
                            React.createElement(React.Fragment, null,
                                '• Tokens received from Transak : ',
                                " ", (_a = fiatToTokenRate === null || fiatToTokenRate === void 0 ? void 0 : fiatToTokenRate.value) === null || _a === void 0 ? void 0 :
                                _a.buyAmount,
                                " ", selectedToken === null || selectedToken === void 0 ? void 0 :
                                selectedToken.symbol,
                                ' ',
                                React.createElement(SText, { opacity: "0.66", fontWeight: "normal" }, `on ${network === null || network === void 0 ? void 0 : network.name}`)))))) : (React.createElement(OMedia, { justifyContent: "space-between" },
                    React.createElement(SFlexbox, { alignItems: "center" },
                        React.createElement(SText, { type: "small", fontWeight: "semibold" },
                            '• Intermediate tokens received from Transak : ',
                            " ", (_b = fiatToTokenRate === null || fiatToTokenRate === void 0 ? void 0 : fiatToTokenRate.value) === null || _b === void 0 ? void 0 :
                            _b.intermediateBuyAmount,
                            ' ',
                            "USDC",
                            ' ',
                            React.createElement(SText, { opacity: "0.66", fontWeight: "normal" }, `on ${network === null || network === void 0 ? void 0 : network.name}`))),
                    rampStatus === ORDER_RAMP_STATUS_FULFILLED && (React.createElement(OIcon, { name: "check", 
                        // @ts-ignore
                        color: colorPrimary || 'accent' })),
                    (rampStatus === ORDER_RAMP_STATUS_FAILED ||
                        (rampStatus !== ORDER_RAMP_STATUS_FULFILLED && isCompleted)) && (React.createElement(OIcon, { name: "close", color: "error" })))),
                React.createElement(SBox, null,
                    !isTransakSupported && (React.createElement(OMedia, { justifyContent: "space-between" },
                        React.createElement(SFlexbox, { alignItems: "center" },
                            React.createElement(SText, { type: "small", fontWeight: "semibold" }, '• Ondefy Swap :')),
                        isCompleted && swapStatus === ORDER_SWAP_STATUS_FULFILLED && (React.createElement(OIcon, { name: "check", 
                            // @ts-ignore
                            color: colorPrimary || 'accent' })),
                        isCompleted && swapStatus !== ORDER_SWAP_STATUS_FULFILLED && (React.createElement(OIcon, { name: "close", color: "error" })))),
                    isCompleted && swapStatus === ORDER_SWAP_STATUS_FULFILLED && (React.createElement(SBox, null,
                        React.createElement(OMedia, { justifyContent: "space-between" },
                            React.createElement(SFlexbox, { alignItems: "center" },
                                React.createElement(SText, { type: "small", fontWeight: "semibold" },
                                    `- output ≈ ${parseFloat(_.path(['value', 'buyAmount'], fiatToTokenRate)).toFixed(6)} ${_.path('symbol', selectedToken)} ${refuelAmount ? `+ ${refuelAmount} ${network === null || network === void 0 ? void 0 : network.ticker}` : ''}`,
                                    ' ',
                                    React.createElement(SText, { opacity: "0.66", fontWeight: "normal" }, `on ${network === null || network === void 0 ? void 0 : network.name}`)))),
                        feeUsd && (React.createElement(OMedia, { justifyContent: "space-between" },
                            React.createElement(SFlexbox, { alignItems: "center" },
                                React.createElement(SText, { type: "small", fontWeight: "semibold" }, `- total charged fee : ${Numbo.formatWithDecimals(feeUsd.toString(), fromTokenDecimals)} USDC`)))),
                        React.createElement(OMedia, { justifyContent: "space-between" },
                            React.createElement(SFlexbox, { alignItems: "center" },
                                React.createElement(SText, { type: "small", fontWeight: "semibold" }, `- transaction hash : ${swapTxHash}`))))))))));
});
TransactionReceipt.displayName = 'TransactionReceipt';
