import { SBox } from '@ondefy/ondefy-ui';
import { APP_MODE } from '../../constants';
import { selectAppMode, selectTemporaryNetworkId } from '../../selectors';
import { ONetworkLine } from '../../components/o-network-line';
import React, { useMemo } from 'react';
import { OPaper } from '../../components/o-paper';
import { usePaperNetworkExtended } from './__hooks__/use-paper-network-extended';
import { useAppSelector } from '../../../../redux/hooks';
import { getNetworksList } from './__transducers__/get-networks-list';
export const PaperNetworkExtended = () => {
    const networkId = useAppSelector(selectTemporaryNetworkId);
    const appMode = useAppSelector(selectAppMode);
    const paperNetworkExtended = usePaperNetworkExtended();
    const networks = useMemo(() => {
        return getNetworksList(appMode);
    }, [appMode]);
    return (React.createElement(OPaper, Object.assign({}, paperNetworkExtended, { appearFrom: "bottom", title: "Choose your network" }),
        React.createElement(SBox, null,
            networks.map(network => (React.createElement(ONetworkLine, { key: network.id, network: network, selected: network.networkId === networkId, displayTokenCount: true, onSelect: () => {
                    paperNetworkExtended.setIsOpened(!paperNetworkExtended.isOpened);
                } }))),
            appMode === APP_MODE.swap && (React.createElement(ONetworkLine, { selected: !networkId, displayTokenCount: true, onSelect: () => {
                    paperNetworkExtended.setIsOpened(!paperNetworkExtended.isOpened);
                } })))));
};
