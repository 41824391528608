import { SBox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { OPaper } from '../../../__common__/components/o-paper';
import { usePaperFeeTokenInfo } from './__hooks__/use-paper-fee-token-info';
export const PaperFeeTokenInfo = () => {
    const paper = usePaperFeeTokenInfo();
    return (React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom", title: "What is Fee Token?" }),
        React.createElement(SBox, { p: "12px" },
            React.createElement(SText, { type: "p" }, "Only when you swap Ethereum tokens to some other tokens there should be an explicit fee token to pay for such transaction."))));
};
