import { getAddress, isAddress } from 'viem';
import { truncateAddress } from './transducers/truncate-address';
// inspired by Hop Protocol https://github.com/hop-protocol/hop
export class Address {
    constructor(address) {
        let newAddress;
        if (address instanceof Address) {
            newAddress = address.toString();
        }
        else if (typeof address === 'string') {
            newAddress = getAddress(address);
        }
        if (!newAddress || !isAddress(newAddress)) {
            throw new Error('Invalid address');
        }
        this.address = newAddress;
    }
    static from(address) {
        return new Address(address);
    }
    static asString(address) {
        return new Address(address).toString();
    }
    toString() {
        return this.address;
    }
    truncate() {
        return truncateAddress(this.address);
    }
    toLowercase() {
        return this.address.toLowerCase();
    }
}
