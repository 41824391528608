import { SBox, styled } from '@ondefy/ondefy-ui';
import { zyfiBackgroundMixin } from '../../../styled/zyfi-background-mixin';
export const SPaper = styled(SBox) `
  position: ${p => (p.isFixed ? 'fixed' : 'absolute')};
  right: 0;
  top: 0;
  border-radius: 18px;
  pointer-events: none;
  z-index: 2;

  .paper__box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${p => p.height};
    overflow: auto;
    position: absolute;
    border-radius: 18px;
    padding: 0;
    background-color: ${p => zyfiBackgroundMixin(p.theme.colors.bg2)};
    opacity: 0;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    pointer-events: none;
    transform: ${p => p.appearFrom === 'top'
    ? 'translateY(-20px)'
    : p.appearFrom === 'right'
        ? 'translateX(20px)'
        : 'translateY(20px)'};
    right: 0;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      top: -3px;
      right: 12px;
      border-bottom: 3px solid ${p => p.theme.colors.bg2};
    }

    &.opened {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
      pointer-events: auto;
    }
  }

  &.no-animation .paper__box {
    transition: none;
    transform: none;
  }
`;
