import { ENV } from '../../../../../../env';
import { api1InchError } from '../../../../../__common__/api/integrations/1inch';
import { isEtherToken } from '../../../../../__common__/transducers/is-ether-token';
import { selectAllowance, selectBestSwapRate, selectEnoughBalance, selectFeeTokenIdSwap, selectFeeTokenSwap, selectIsNotSupportedZyfiSourceToken, selectIsZyfiNotEnough, selectSourceTokenIdSwapIsEthereum, selectSourceTokenSwap, selectSwapRates, selectSwapStartDisabled, selectZyFiGeneralTx } from '../../../../selectors';
import { selectIsWalletConnected, selectIsWeb3Busy, selectUserWallet } from '../../../../../wallet/selectors';
import { usePaperConnection } from '../../../../../__common__/papers/paper-connection/__hooks__/use-paper-connection';
import { MainButton } from '../../../../../__common__/components/main-button';
import { noSwappersError } from '../../../../signals/no-swappers-error';
import { zyfiRawSwapProgress } from '../../../../signals/zyfi-raw-swap-progress';
import { SFooter } from './__styled__/s-footer';
import { useOnContinue } from './__hooks__/use-on-continue';
import React from 'react';
import { useAppSelector } from '../../../../../../redux/hooks';
import _ from 'lodash/fp';
export const Footer = () => {
    const isWalletConnected = useAppSelector(selectIsWalletConnected);
    const { setIsOpened } = usePaperConnection();
    const onContinue = useOnContinue();
    const isContinueDisabledForSwap = useAppSelector(selectSwapStartDisabled);
    const isEnoughTokens = useAppSelector(selectEnoughBalance);
    const bestRate = useAppSelector(selectBestSwapRate);
    const sourceToken = useAppSelector(selectSourceTokenSwap);
    const allowance = useAppSelector(selectAllowance);
    const generalTx = useAppSelector(selectZyFiGeneralTx);
    const isNotEnoughZyfi = useAppSelector(selectIsZyfiNotEnough);
    const isSourceEthereumZyfi = useAppSelector(selectSourceTokenIdSwapIsEthereum);
    const feeToken = useAppSelector(selectFeeTokenSwap);
    const feeTokenId = useAppSelector(selectFeeTokenIdSwap);
    const swapRates = useAppSelector(selectSwapRates);
    const isWeb3Busy = useAppSelector(selectIsWeb3Busy);
    const userWallet = useAppSelector(selectUserWallet);
    const notSupportedYet = useAppSelector(selectIsNotSupportedZyfiSourceToken);
    let text = 'Continue';
    if (isSourceEthereumZyfi && isEtherToken(feeTokenId)) {
        text = 'Swap';
    }
    if (bestRate && !swapRates.isLoading && isNotEnoughZyfi) {
        text = `Insufficient ${_.path('symbol', isSourceEthereumZyfi ? feeToken : sourceToken)} balance`;
    }
    if (isEnoughTokens === false && bestRate && !swapRates.isLoading) {
        text = `Insufficient ${_.path('symbol', sourceToken)} balance`;
    }
    if (notSupportedYet) {
        text = 'Not supported yet';
    }
    // if ((isEnoughTokens === false && bestRate) || (isNotEnoughZyfi && bestRate)) {
    //   text = `Insufficient ${_.path('symbol', isSourceEthereumZyfi ? feeToken : sourceToken) as string} balance`
    // }
    if (api1InchError.value) {
        text = _.capitalize(api1InchError.value);
    }
    if (noSwappersError.value) {
        text = noSwappersError.value;
    }
    if (zyfiRawSwapProgress.value) {
        text = 'Pending - Wallet Request';
    }
    if (swapRates.isLoading) {
        text = 'Searching best route';
    }
    if (allowance.isLoading) {
        text = 'Checking allowance';
    }
    if (ENV.isZyfi() && generalTx.isLoading) {
        text = 'Fetching transaction fee';
    }
    if (ENV.isZyfi() && isSourceEthereumZyfi && !feeTokenId) {
        text = 'For ETH Select Fee Token';
    }
    if (ENV.isZyfi() && generalTx.isError && !generalTx.isLoading) {
        text = 'Transaction preparation failed';
    }
    if (userWallet.isLoading && !userWallet.isLoaded) {
        text = 'Loading user assets';
    }
    if (!isWalletConnected) {
        text = isWeb3Busy ? 'Connecting' : 'Connect';
    }
    // console.log(
    //   swapRates.isLoading,
    //   isWeb3Busy,
    //   isWalletConnected && (userWallet.isLoading || zyfiRawSwapProgress.value),
    //   allowance.isLoading,
    //   ENV.isZyfi() && generalTx.isLoading && (!isSourceEthereumZyfi || (isSourceEthereumZyfi && !!feeToken))
    // )
    return (React.createElement(SFooter, null,
        React.createElement(MainButton, { loading: swapRates.isLoading ||
                isWeb3Busy ||
                (isWalletConnected && ((userWallet.isLoading && !userWallet.isLoaded) || zyfiRawSwapProgress.value)) ||
                allowance.isLoading ||
                (ENV.isZyfi() && generalTx.isLoading && (!isSourceEthereumZyfi || (isSourceEthereumZyfi && !!feeToken))), onClick: () => {
                if (!isWalletConnected) {
                    setIsOpened(true).catch(() => { });
                }
                else {
                    onContinue();
                }
            }, disabled: Boolean(isWeb3Busy ||
                (isWalletConnected &&
                    (isContinueDisabledForSwap ||
                        (ENV.isZyfi() &&
                            (generalTx.isLoading ||
                                notSupportedYet ||
                                generalTx.isError ||
                                zyfiRawSwapProgress.value ||
                                (isNotEnoughZyfi && bestRate) ||
                                (isSourceEthereumZyfi && !feeToken)))))) }, text)));
};
