import { APP_MODE } from '../../../constants';
import { selectAppMode } from '../../../selectors';
import { selectNetworkIdTargetFiat } from '../../../../1 - buy/selectors';
import { selectNetworkIdTargetSwap } from '../../../../2 - swap/selectors';
import { selectIsWalletConnected } from '../../../../wallet/selectors';
import { checkIfNetworkIsValid } from '../../../../1 - buy/transducers/check-iIf-network-is-valid';
import { NETWORKS_DATA } from '../../../constants/networks';
import { useAppSelector } from '../../../../../redux/hooks';
import { usePaperMenu } from '../../../papers/paper-menu/__hooks__/use-paper-menu';
import { SMenuButton } from './__styled__/s-menu-button';
import { OButton, OIcon, OMedia, OSvgIcon, SBox } from '@ondefy/ondefy-ui';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash/fp';
export const MenuButton = () => {
    const [urlSearchParams] = useSearchParams();
    const isWalletConnected = useAppSelector(selectIsWalletConnected);
    const appMode = useAppSelector(selectAppMode);
    const networkId = useAppSelector(appMode === APP_MODE.buy ? selectNetworkIdTargetFiat : selectNetworkIdTargetSwap);
    const currentNetwork = _.path(networkId, NETWORKS_DATA);
    const paperMenu = usePaperMenu();
    return (React.createElement(SMenuButton, null,
        isWalletConnected && React.createElement("i", { className: " menu__wallet-connected" }),
        React.createElement(OButton, { id: paperMenu.id, 
            // iconRight={
            //   checkIfNetworkIsValid(
            //     parseInt(urlSearchParams.get('networkId') as string)
            //   ) ? null : (
            //     <OIcon name="down" color="accent" />
            //   )
            // }
            variant: "secondary", className: "menu__network", bg: "bg", onClick: () => {
                paperMenu.setIsOpened(!paperMenu.isOpened);
            }, disabled: checkIfNetworkIsValid(parseInt(urlSearchParams.get('networkId'))) },
            React.createElement(OMedia, { alignItems: "center" },
                React.createElement(OSvgIcon, { name: _.pathOr('ondefy', ['id'], currentNetwork), color: currentNetwork ? undefined : 'accent', mr: "15px" }))),
        React.createElement(SBox, { ml: "8px", position: "relative", className: "menu__burger" },
            React.createElement(OButton
            // disabled={disabled!}
            , { 
                // disabled={disabled!}
                id: paperMenu.id, variant: "secondary", bg: "bg2", onClick: () => {
                    paperMenu.setIsOpened(!paperMenu.isOpened);
                } },
                React.createElement(OMedia, { alignItems: "center" },
                    React.createElement(OIcon, { name: paperMenu.isOpened ? 'close' : 'bars' }))))));
};
