import axios from 'axios';
import { of, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiObservable } from '../../api-observable/api-observable';
import { ONDEFY_WALLET } from '../../constants';
import { ESwappers, SWAPPER_NAME_BY_IDX } from '../../constants/contracts';
import { getGasFeeForParaswap } from './transducers/get-gas-fee-for-paraswap';
// https://developers.paraswap.network/api/get-rate-for-a-token-pair
// https://developers.paraswap.network/api/build-parameters-for-transaction
const apirxParaswap = ApiObservable.from(axios.create({
    baseURL: 'https://apiv5.paraswap.io'
    // headers: { Accept: 'application/vnd.github.v3+json' },
}));
export function getParaswapSwapDataRx({ networkId, usingOndefyRouter, userAddress, receiver, srcToken, destToken, amount, slippage, srcDecimals, destDecimals }) {
    const routeParams = {
        network: networkId,
        srcToken,
        srcDecimals,
        destToken,
        destDecimals,
        amount,
        side: 'SELL',
        partner: 'ondefy',
        partnerAddress: ONDEFY_WALLET
        // excludeDEXS: ParaSwapPool,ParaSwapLimitOrders
    };
    return apirxParaswap.get('/prices', routeParams).pipe(switchMap(routeResData => {
        if (!((routeResData === null || routeResData === void 0 ? void 0 : routeResData.priceRoute) && routeResData.priceRoute.destAmount)) {
            return throwError(() => new Error('invalid route response from Paraswap'));
        }
        const swapParams = {
            srcToken,
            srcDecimals,
            destToken,
            destDecimals,
            srcAmount: amount,
            slippage: slippage.toString(),
            userAddress: userAddress || ONDEFY_WALLET,
            receiver,
            priceRoute: routeResData.priceRoute,
            // TODO: ask Clement about these params
            // TODO: ask Clement about these params
            // TODO: ask Clement about these params
            partner: 'ondefy',
            partnerAddress: ONDEFY_WALLET
        };
        return apirxParaswap
            .post(`/transactions/${networkId}`, swapParams, {
            params: {
                ignoreChecks: true
            }
        })
            .pipe(switchMap(swapResData => {
            if (!(swapResData === null || swapResData === void 0 ? void 0 : swapResData.data) || !swapResData.to) {
                return throwError(() => new Error('invalid swap response from Paraswap'));
            }
            const { gasEstimate, gasLimit } = getGasFeeForParaswap(networkId, routeResData, usingOndefyRouter);
            const result = {
                swapperIdx: ESwappers.PARASWAP_SWAPPER_IDX,
                swapperName: SWAPPER_NAME_BY_IDX[ESwappers.PARASWAP_SWAPPER_IDX],
                // extra fields
                networkId,
                fromTokenAddress: srcToken,
                fromAmount: amount,
                toTokenAddress: destToken,
                // TODO: check
                toAmount: routeResData.priceRoute.destAmount,
                gasEstimate,
                gasLimit
            };
            if (!userAddress) {
                return of(result);
            }
            /* note : only relevant if we want to broadcast transactions to our router contract from here
            const callData = getCallData({
              srcToken,
              destToken,
              data: swapResData.data,
              amount,
              swapperIndex: ESwappers.PARASWAP_SWAPPER_IDX,
              network: NETWORKS_DATA[networkId]
            })

            // else return all data needed for swap
            if (callData) {
              return of({
                ...result,
                ...callData
              })
            }
            */
            return of(Object.assign(Object.assign({}, result), { callData: swapResData.data, contractAddress: swapResData.to, spenderAddress: routeResData.priceRoute.tokenTransferProxy }));
        }), catchError(e => {
            console.error('getParaswapSwapData', e);
            return of(null);
        }));
    }), catchError(e => {
        console.log(e.message);
        return of(null);
    }));
}
