export function toFixedBig(s, decimals) {
    if (typeof s === 'number') {
        s = String(s);
    }
    // Extract integer and decimal parts
    const matches = s.match(/^(\d*)\.?(\d*)/);
    if (matches === null || (matches[1] === '' && matches[2] === '')) {
        return s; // return original string if no match or empty matches
    }
    const integerPart = matches[1] || '0';
    let decimalPart = matches[2] || '';
    // Limit the decimal part to the required length
    decimalPart = decimalPart.substring(0, decimals);
    // Return formatted string
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
}
