import { EMPTY, merge, of, switchMap } from 'rxjs';
import { ofValue } from '../../__common__/rxjs-utils/of-value';
import { selectSourceTokenAmount, selectSourceTokenSwap } from '../selectors';
import { setSourceTokenAmount } from '../slice';
import { Numbo } from '../../__common__/numbo/transducers';
export const epicOnSourceTokenAmountChange = (action$, state$) => {
    return merge(ofValue(state$, selectSourceTokenAmount), ofValue(state$, selectSourceTokenSwap)).pipe(switchMap(() => {
        const { value } = state$;
        const amount = selectSourceTokenAmount(value);
        const token = selectSourceTokenSwap(value);
        if (token && amount && !(amount === null || amount === void 0 ? void 0 : amount.endsWith('.'))) {
            const normalizedAmount = Numbo.toFixedBig(amount, token.decimals);
            if (normalizedAmount !== amount) {
                return of(setSourceTokenAmount(Numbo.toFixedBig(amount, token.decimals)));
            }
        }
        return EMPTY;
    }));
};
