export var APP_MODE;
(function (APP_MODE) {
    APP_MODE["buy"] = "buy";
    APP_MODE["swap"] = "swap";
    APP_MODE["custom"] = "custom";
    APP_MODE["send"] = "send";
})(APP_MODE || (APP_MODE = {}));
export const sliceNamespace = 'common';
export const EQUAL_ROUND_SYMBOL = '≈';
// default address for when no user is logged in and an address must be passed to obtain swap rates
export const ONDEFY_WALLET = '0x47E739Ae6BdF1A04436d4D24Fd1580BD20986dff';
export var EPages;
(function (EPages) {
    EPages["init"] = "init";
    // swap pages
    EPages["swapUnlock"] = "swapUnlock";
    EPages["swapSubmit"] = "swapSubmit";
    EPages["swapResult"] = "swapResult";
    // zksync swap pages
    EPages["swapUnlockZyfi"] = "swapUnlockZyfi";
    EPages["swapSubmitZyfi"] = "swapSubmitZyfi";
    EPages["swapResultZyfi"] = "swapResultZyfi";
    // buy pages
    EPages["buyPermit"] = "buyPermit";
    EPages["buyRamp"] = "buyRamp";
    EPages["buySummary"] = "buySummary";
    // custom pages
    EPages["customResult"] = "customResult";
    EPages["sendResult"] = "sendResult";
})(EPages || (EPages = {}));
