var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash/fp';
import { ENV } from '../../../../../env';
import { Nonce } from '../../../../2 - swap/nonce';
import { ETransactionType } from '../../../../__common__/constants/transaction';
import { getNewWeb3Provider } from '../../../../__common__/web3/get-new-web3-provider';
export const sendTransaction = (params, provider, broadcastConfig) => __awaiter(void 0, void 0, void 0, function* () {
    const web3Provider = yield getNewWeb3Provider(provider, params.networkId);
    const signer = web3Provider.getSigner
        ? web3Provider.getSigner()
        : web3Provider;
    const nonce = (broadcastConfig === null || broadcastConfig === void 0 ? void 0 : broadcastConfig.type) === ETransactionType.swap ? Nonce.applyNonce() : undefined;
    // const txHash = await (ENV.isZyfi() && !broadcastConfig?.useUncheckedTransaction
    //   ? signer.sendTransaction({
    //       ...params.rawTx,
    //       nonce
    //     })
    //   : signer.sendUncheckedTransaction(params.rawTx))
    // TODO: should I do
    const accounts = yield provider.request({
        method: 'eth_requestAccounts'
    });
    const [account] = accounts;
    // TODO: should I do like this?
    // const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' })
    let txHash;
    if (ENV.isZyfi() && (broadcastConfig === null || broadcastConfig === void 0 ? void 0 : broadcastConfig.useUncheckedTransaction)) {
        const txHash = yield signer.sendUncheckedTransaction(params.rawTx);
        return _.path('hash', txHash) || txHash;
    }
    if (ENV.isZyfi() && !(broadcastConfig === null || broadcastConfig === void 0 ? void 0 : broadcastConfig.useUncheckedTransaction)) {
        txHash = yield signer.sendTransaction(Object.assign(Object.assign({}, params.rawTx), { 
            // @ts-ignore
            nonce }));
    }
    else {
        // TODO: this will use an ondefy router
        txHash = yield signer.sendTransaction({
            account,
            to: params.rawTx.to,
            value: BigInt(params.rawTx.value),
            // chain: providersByNetworkId[params.networkId]
            chain: signer.chain,
            // gasPrice: params.rawTx.gasPrice,
            // gasLimit: params.rawTx.gasLimit,
            // TODO: maxFeePerGas???
            // TODO: maxFeePerGas???
            // TODO: maxFeePerGas???
            maxFeePerGas: params.rawTx.maxFeePerGas ? BigInt(params.rawTx.maxFeePerGas) : undefined,
            maxPriorityFeePerGas: params.rawTx.maxPriorityFeePerGas ? BigInt(params.rawTx.maxPriorityFeePerGas) : undefined,
            data: params.rawTx.data
        });
    }
    // const txHash = await (ENV.isZyfi() && !broadcastConfig?.useUncheckedTransaction
    //   ? (signer as Signer).sendTransaction({
    //       ...params.rawTx,
    //       // @ts-ignore
    //       nonce
    //     })
    //   : (signer as WalletClient).sendTransaction({
    //       account,
    //       to: params.rawTx.to as T0xString,
    //       value: BigInt(params.rawTx.value!),
    //       // chain: providersByNetworkId[params.networkId]
    //       chain: (signer as WalletClient).chain,
    //       // gasPrice: params.rawTx.gasPrice,
    //       // gasLimit: params.rawTx.gasLimit,
    //       maxFeePerGas: params.rawTx.maxFeePerGas ? BigInt(params.rawTx.maxFeePerGas) : undefined,
    //       maxPriorityFeePerGas: params.rawTx.maxPriorityFeePerGas ? BigInt(params.rawTx.maxPriorityFeePerGas) : undefined,
    //       data: params.rawTx.data as T0xString
    //     }))
    if (_.isObject(txHash) && (broadcastConfig === null || broadcastConfig === void 0 ? void 0 : broadcastConfig.type) === ETransactionType.approve) {
        Nonce.setNonce(txHash.nonce);
    }
    return _.path('hash', txHash) || txHash;
});
