// https://ondefy.atlassian.net/browse/FTAT-69
export var AnalyticsEvents;
(function (AnalyticsEvents) {
    AnalyticsEvents["buttonLoaded"] = "buttonLoaded";
    AnalyticsEvents["buttonTriggered"] = "buttonTriggered";
    AnalyticsEvents["sendButtonTriggered"] = "buttonTriggered";
    AnalyticsEvents["widgetLoaded"] = "widgetLoaded";
    AnalyticsEvents["walletConnected"] = "walletConnected";
    AnalyticsEvents["permitSigned"] = "permitSigned";
    AnalyticsEvents["transakLoaded"] = "transakLoaded";
    AnalyticsEvents["transakCompleted"] = "transakCompleted";
    AnalyticsEvents["transactionLoading"] = "transactionLoading";
    AnalyticsEvents["transactionCompleted"] = "transactionCompleted";
    AnalyticsEvents["appOpened"] = "appOpened";
    // events for the swap
    AnalyticsEvents["swapButtonTriggered"] = "swapButtonTriggered";
    AnalyticsEvents["customButtonTriggered"] = "customButtonTriggered";
    AnalyticsEvents["buyButtonTriggered"] = "buyButtonTriggered";
    AnalyticsEvents["tokenFromSelected"] = "tokenFromSelected";
    AnalyticsEvents["tokenToSelected"] = "tokenToSelected";
    AnalyticsEvents["tokenFeeSelected"] = "tokenFeeSelected";
    AnalyticsEvents["dexSelectionOpened"] = "dexSelectionOpened";
    AnalyticsEvents["continueTriggered"] = "continueTriggered";
    AnalyticsEvents["swapTriggered"] = "swapTriggered";
    AnalyticsEvents["completeTriggered"] = "completeTriggered"; // - user taps the complete button
})(AnalyticsEvents || (AnalyticsEvents = {}));
