import { OButton, OMedia, OSpinner, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { useIframeUrlColors } from '../../../iframe/hooks/use-iframe-url-colors';
import { PoweredBy } from './__components__/powered-by';
export const MainButton = ({ disabled, onClick, children, loading }) => {
    const { colorPrimary, colorSecondary } = useIframeUrlColors();
    return (React.createElement("div", null,
        React.createElement(OButton, { width: "100%", onClick: onClick, size: "lg", disabled: disabled, 
            // @ts-ignore
            bg: colorPrimary || undefined },
            React.createElement(OMedia, { alignItems: "center" },
                React.createElement(SText, { fontWeight: "semibold", type: "h5", m: "0", mr: loading ? '10px' : undefined, 
                    // @ts-ignore
                    color: colorSecondary || 'inherit' }, children),
                loading && (React.createElement(OSpinner
                // @ts-ignore
                , { 
                    // @ts-ignore
                    color: colorSecondary || 'inherit' })))),
        React.createElement(PoweredBy, null)));
};
