import { SBox } from '@ondefy/ondefy-ui';
import React from 'react';
import { HeaderNavBack } from './header-nav-back';
import { usePaperResetConfirm } from '../../papers/paper-reset-confirm/__hooks__/use-paper-reset-confirm';
import { Breadcrumbs } from './breadcrumbs';
export const HeaderWithBreadcrumbs = () => {
    const paperResetConfirm = usePaperResetConfirm();
    return (React.createElement(SBox, { position: "relative", height: "50px" },
        React.createElement(SBox, { position: "absolute", top: "7px", left: "-7px", width: "100%" },
            React.createElement(HeaderNavBack, { onBack: () => {
                    paperResetConfirm.tryReset();
                }, title: React.createElement(Breadcrumbs, null) }))));
};
