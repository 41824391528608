import { EThemeVariant, OButton, OIcon, OLink, OMedia, OSpinner, OSvgIcon, OSwitch, setTheme, SFlexbox, SText, SBox, toast, useTheme, OTag } from '@ondefy/ondefy-ui';
import React from 'react';
import { EAppVariant, ENV } from '../../../../env';
import { resetUserWalletCache } from '../../../wallet/api/get-user-wallet';
import { APP_MODE } from '../../constants';
import { useIsIframe } from '../../hooks/use-is-iframe';
import { selectNetworkIdTargetSwap } from '../../../2 - swap/selectors';
import { selectNetworkIdTargetFiat } from '../../../1 - buy/selectors';
import { selectAppMode } from '../../selectors';
import { Analytics } from '../../../analytics';
import { OLine } from '../../components/o-line';
import { OLogo } from '../../components/o-logo';
import { OPaper } from '../../components/o-paper';
import { selectIsWalletConnected, selectIsWeb3Busy, selectUserAddress, selectWalletType } from '../../../wallet/selectors';
import { checkIfNetworkIsValid } from '../../../1 - buy/transducers/check-iIf-network-is-valid';
import { NETWORKS_DATA } from '../../constants/networks';
import { truncateAddress } from '../../../wallet/transducers/truncate-address';
import { useWallet } from '../../../wallet/hooks/use-wallet';
import { WALLETS } from '../../../wallet/constants/wallets';
import { flush } from '../../slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { usePaperConnection } from '../paper-connection/__hooks__/use-paper-connection';
import { usePaperHistoryTransactions } from '../paper-history-transactions/__hooks__/use-paper-history-transactions';
import { usePaperResetConfirm } from '../paper-reset-confirm/__hooks__/use-paper-reset-confirm';
import { usePaperMenu } from './__hooks__/use-paper-menu';
import { SMenu } from './__styled__/s-menu';
import { CopyTokenUrl } from './__components__/copy-token-url';
const IconButton = ({ children, onClick, iconName, disabled, loading }) => {
    return (React.createElement(OButton, { className: "dropdown__action", disabled: disabled, icon: !children ? React.createElement(OIcon, { name: iconName }) : null, iconLeft: children ? React.createElement(OIcon, { name: iconName }) : null, variant: "secondary", size: "sm", onClick: onClick },
        React.createElement(OMedia, { alignItems: "center" },
            children,
            loading && (React.createElement(SBox, { pl: "8px", position: "relative", top: "1px" },
                React.createElement(OSpinner, { size: 12 }))))));
};
export const PaperMenu = () => {
    const { disconnectWallet } = useWallet();
    const { theme } = useTheme();
    const appMode = useAppSelector(selectAppMode);
    const networkId = useAppSelector(appMode === APP_MODE.swap ? selectNetworkIdTargetSwap : selectNetworkIdTargetFiat);
    const userAddress = useAppSelector(selectUserAddress);
    const walletType = useAppSelector(selectWalletType);
    const isWalletConnected = useAppSelector(selectIsWalletConnected);
    // const [isCopied, setIsCopied] = useState(false);
    const { setIsOpened } = usePaperConnection();
    const dispatch = useAppDispatch();
    const { tryReset } = usePaperResetConfirm();
    // const [selected, setSelected] = useState();
    const isIframe = useIsIframe();
    const isWeb3Busy = useAppSelector(selectIsWeb3Busy);
    const paperHistory = usePaperHistoryTransactions();
    const paperMenu = usePaperMenu();
    return (React.createElement(OPaper, Object.assign({}, paperMenu, { appearFrom: "top", title: React.createElement(OMedia, { alignItems: "center", justifyContent: "space-between", pt: "10px" },
            React.createElement(OLogo, { onClick: tryReset }),
            React.createElement(OButton, { mr: "8px", variant: "secondary", onClick: () => {
                    paperMenu.setIsOpened(false);
                }, icon: React.createElement(OIcon, { name: "close" }) })) }),
        React.createElement(SMenu, null,
            React.createElement("div", null,
                React.createElement(OMedia, { justifyContent: isWalletConnected ? (isIframe ? 'center' : 'space-between') : 'right', alignItems: isWalletConnected && isIframe ? 'center' : 'right', flexDirection: isIframe && isWalletConnected ? 'column' : undefined, className: "dropdown-menu__header" },
                    isWalletConnected && (React.createElement("div", { className: "dropdown-menu__section" },
                        React.createElement(OMedia, { alignItems: "center", justifyContent: isIframe ? 'center' : undefined, className: "dropdown__menu-number" },
                            React.createElement(SText, { type: "small" }, isIframe ? userAddress : truncateAddress(userAddress || '', 9, 6))))),
                    React.createElement(SFlexbox, { justifyContent: "space-around" },
                        isWalletConnected && !isIframe && (React.createElement(IconButton, { iconName: "clone", onClick: () => {
                                navigator.clipboard.writeText(userAddress || '').catch(() => { });
                                // setIsCopied(true);
                                toast.next({
                                    variant: 'success',
                                    message: 'Copied!'
                                });
                            } })),
                        isWalletConnected && (React.createElement(IconButton, { iconName: "right-from-bracket", onClick: () => {
                                if (!networkId) {
                                    return;
                                }
                                checkIfNetworkIsValid(networkId) &&
                                    window.open(NETWORKS_DATA[networkId].blockExplorer + '/address/' + userAddress, '_blank');
                            } })),
                        isWalletConnected && walletType === WALLETS.magicAuth.key && !isWeb3Busy && (React.createElement(IconButton, { onClick: () => window.open('https://reveal.magic.link/ondefy', '_blank'), iconName: "key-old" })),
                        React.createElement(React.Fragment, null,
                            isWalletConnected && (React.createElement(IconButton, { disabled: isWeb3Busy, loading: isWeb3Busy, onClick: () => {
                                    disconnectWallet()
                                        // .then(() => paperMenu.setIsOpened(false))
                                        .catch(e => {
                                        console.log(e);
                                    });
                                    dispatch(flush());
                                    resetUserWalletCache();
                                }, iconName: "powerbutton" },
                                React.createElement(SText, { color: "inherit", type: "small" }, isWeb3Busy ? 'Connecting' : 'Disconnect'))),
                            !isWalletConnected && (React.createElement(IconButton, { disabled: isWeb3Busy, loading: isWeb3Busy, onClick: () => {
                                    setIsOpened(true).catch(() => { });
                                }, iconName: "powerbutton" },
                                React.createElement(SText, { color: "inherit", type: "small" }, isWeb3Busy ? 'Connecting' : 'Connect')))))),
                isWalletConnected && (React.createElement(OLine, null,
                    React.createElement(OLink, { isExternal: true, borderless: true, noIcon: true, disabled: true, onClick: e => {
                            e.preventDefault();
                            paperHistory.setIsOpened(true);
                        }, variant: "secondary" },
                        React.createElement(OMedia, { alignItems: "center" },
                            React.createElement(OIcon, { name: "swap" }),
                            React.createElement(SText, null, "Transaction history"),
                            React.createElement(OTag, { ml: "8px", variant: "secondary", size: "sm", label: "Soon" }))))),
                ENV.app === EAppVariant.ondefy && (React.createElement(OLine, null,
                    React.createElement(OLink, { isExternal: true, borderless: true, noIcon: true, href: "https://app.ondefy.com/", onClick: () => {
                            Analytics.appOpened();
                        }, variant: "secondary" },
                        React.createElement(OMedia, { alignItems: "center" },
                            React.createElement(OIcon, { name: "grid-dotted" }),
                            React.createElement(SText, null, "App"))))),
                !isIframe && !ENV.isZyfi() && (React.createElement(OLine, { className: "dropdown-menu__link dropdown-menu__link--theme" },
                    React.createElement(OMedia, { alignItems: "center", onClick: () => {
                            setTheme(theme.id === EThemeVariant.dark ? EThemeVariant.light : EThemeVariant.dark);
                        } },
                        React.createElement(OIcon, { name: theme.id === EThemeVariant.dark ? 'moon' : 'sun' }),
                        React.createElement(SText, { mr: "8px" },
                            theme.id === EThemeVariant.dark ? 'Dark' : 'Light',
                            " mode"),
                        React.createElement(OSwitch, { onChange: e => {
                                // @ts-ignore
                                // e.stopPropagation()
                            }, value: theme.id === EThemeVariant.dark })))),
                React.createElement(CopyTokenUrl, null)),
            React.createElement(SFlexbox, { justifyContent: "center" },
                React.createElement(OButton, { icon: React.createElement(OSvgIcon, { name: "telegram", size: 18 }), variant: "quaternary", size: "sm", onClick: () => window.open('https://t.me/joinchat/3-fAd9pmy9gwODlk') }),
                React.createElement(OButton, { icon: React.createElement(OSvgIcon, { name: "discord", size: 20 }), variant: "quaternary", size: "sm", ml: { md: '8px', xs: '20px' }, onClick: () => window.open('https://discord.com/invite/KHchZXmv8Q') }),
                React.createElement(OButton, { icon: React.createElement(OSvgIcon, { name: "email", size: 18 }), variant: "quaternary", size: "sm", ml: { md: '8px', xs: '20px' }, onClick: () => (window.location.href = 'mailto:contact@ondefy.com') })))));
};
