import { SBox, styled } from '@ondefy/ondefy-ui';
export const SDetails = styled(SBox) `
  min-height: 20px;

  .ondefy__validations-inner {
    opacity: 0;
    transition: opacity 0.1s;

    &--visible {
      opacity: 1;
    }
  }
`;
