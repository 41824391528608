import { selectIsWalletConnected, selectIsWeb3Busy } from '../../../../../wallet/selectors';
import { usePaperConnection } from '../../../../../__common__/papers/paper-connection/__hooks__/use-paper-connection';
import { MainButton } from '../../../../../__common__/components/main-button';
import { useContinueDisabledForBuy } from './__hooks__/use-continue-disabled-for-buy';
import { SFooter } from './__styled__/s-footer';
import { useOnContinue } from './__hooks__/use-on-continue';
import React from 'react';
import { useAppSelector } from '../../../../../../redux/hooks';
export const Footer = () => {
    const isWalletConnected = useAppSelector(selectIsWalletConnected);
    const isWeb3Busy = useAppSelector(selectIsWeb3Busy);
    const { setIsOpened } = usePaperConnection();
    const onContinue = useOnContinue();
    const isContinueDisabledForBuy = useContinueDisabledForBuy();
    return (React.createElement(SFooter, null,
        React.createElement(MainButton, { loading: isWeb3Busy, onClick: () => {
                if (!isWalletConnected) {
                    setIsOpened(true).catch(() => { });
                }
                else {
                    onContinue();
                }
            }, disabled: isWeb3Busy || (isWalletConnected && isContinueDisabledForBuy) }, isWalletConnected ? 'Continue' : isWeb3Busy ? 'Connecting' : 'Connect')));
};
