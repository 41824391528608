import _ from 'lodash/fp';
import { NATIVE_ADDRESS } from '../../__common__/constants/contracts';
import { NETWORKS_DATA } from '../../__common__/constants/networks';
import { checkIfNetworkIsValid } from './check-iIf-network-is-valid';
export const convertTransakCryptoCurrencies = _.reduce((acc, c) => {
    const networkId = Number(_.path(['network', 'chainId'], c));
    const defaultCoinMatch = _.pathEq([networkId, 'defaultCoinId'], c.coinId)(NETWORKS_DATA);
    if (c.isAllowed && checkIfNetworkIsValid(networkId) && (c.address || defaultCoinMatch)) {
        const address = c.address ? c.address.toLowerCase() : NATIVE_ADDRESS;
        return Object.assign(Object.assign({}, acc), { [networkId]: Object.assign(Object.assign({}, _.pathOr({}, [networkId], acc)), { [address]: {
                    isListed: true,
                    symbol: c.symbol,
                    forbiddenCurrencies: _.compose(_.fromPairs, _.map(({ fiatCurrency }) => [fiatCurrency, true]), _.uniqBy('fiatCurrency'), _.defaultTo([]))(c.network.fiatCurrenciesNotSupported)
                } }) });
    }
    return acc;
}, {});
