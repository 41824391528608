var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../../redux/hooks';
import { METAMASK_CONFIGS } from '../../constants/metamask.configs';
import { WALLETS } from '../../constants/wallets';
import { Address } from '../../model.address';
import { resetUserWallet, setIsWalletConnected, setUserAddress, setWalletType } from '../../slice';
import { getSignature } from './utils/get-signature';
import { sendTransaction } from './utils/send-transaction';
export const useMetamaskConnector = () => {
    const dispatch = useAppDispatch();
    const [isSupportedByBrowser, setIsSupportedByBrowser] = useState(undefined);
    const [provider, setProvider] = useState(null);
    const disconnect = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!isSupportedByBrowser) {
            throw new Error('Metamask not supported by browser');
        }
        if (!provider) {
            throw new Error('Metamask not initialized');
        }
        provider.removeAllListeners(['accountsChanged']);
        dispatch(resetUserWallet());
    }), [provider, dispatch, isSupportedByBrowser]);
    const connect = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!isSupportedByBrowser) {
            throw new Error('Metamask not supported by browser');
        }
        const accounts = yield provider.request({
            method: 'eth_requestAccounts'
        });
        provider.on('accountsChanged', (_accounts) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (_accounts.length > 0) {
                (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.reload();
            }
            else {
                // statement called when user disconnects from metamask
                yield disconnect().catch(err => {
                    console.error(err);
                });
            }
        }));
        dispatch(setUserAddress(Address.asString(accounts[0])));
        dispatch(setWalletType(WALLETS.metamask.key));
        dispatch(setIsWalletConnected(true));
    }), [provider, disconnect, dispatch, isSupportedByBrowser]);
    const broadcastTransaction = useCallback((params, broadcastConfig) => __awaiter(void 0, void 0, void 0, function* () {
        if (!isSupportedByBrowser) {
            throw new Error('Metamask not supported by browser');
        }
        if (!provider) {
            throw new Error('Metamask not initialized');
        }
        try {
            yield provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${params.networkId.toString(16)}` }]
            });
        }
        catch (err) {
            const error = err;
            if (error.code === 4902) {
                yield provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [METAMASK_CONFIGS[params.networkId]]
                });
            }
            else {
                throw err;
            }
        }
        return yield sendTransaction(params, provider, broadcastConfig);
    }), [isSupportedByBrowser, provider]);
    const signMessage = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        if (!provider) {
            throw new Error('Metamask not initialized');
        }
        try {
            yield provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${params.networkId.toString(16)}` }]
            });
        }
        catch (err) {
            const error = err;
            if (error.code === 4902) {
                yield provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [METAMASK_CONFIGS[params.networkId]]
                });
            }
            else {
                throw err;
            }
        }
        return yield getSignature(params, provider);
    }), [provider]);
    const addTokenToPlugin = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        if (!provider) {
            throw new Error('Metamask not initialized');
        }
        try {
            yield provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${params.networkId.toString(16)}` }]
            });
        }
        catch (err) {
            const error = err;
            if (error.code === 4902) {
                yield provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [METAMASK_CONFIGS[params.networkId]]
                });
            }
            else {
                throw err;
            }
        }
        yield provider.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: params.address,
                    symbol: params.symbol,
                    decimals: params.decimals,
                    image: params.logoURI
                }
            }
        });
    }), [provider]);
    useEffect(() => {
        var _a, _b, _c, _d;
        // @ts-ignore
        if (((_a = window === null || window === void 0 ? void 0 : window.ethereum) === null || _a === void 0 ? void 0 : _a.isMetaMask) && !((_b = window === null || window === void 0 ? void 0 : window.ethereum) === null || _b === void 0 ? void 0 : _b.isBraveWallet)) {
            // @ts-ignore
            setProvider(window.ethereum);
            setIsSupportedByBrowser(true);
            // @ts-ignore
        }
        else if (((_d = (_c = window === null || window === void 0 ? void 0 : window.ethereum) === null || _c === void 0 ? void 0 : _c.providers) === null || _d === void 0 ? void 0 : _d.length) > 0) {
            // @ts-ignore
            const newProvider = window.ethereum.providers.find(_provider => _provider.isMetaMask && !_provider.isBraveWallet);
            if (newProvider) {
                setProvider(newProvider);
                setIsSupportedByBrowser(true);
            }
            else {
                setIsSupportedByBrowser(false);
            }
        }
        else {
            setIsSupportedByBrowser(false);
        }
    }, []);
    const web3Config = useMemo(() => ({
        walletType: WALLETS.metamask.key,
        isSupportedByBrowser,
        connect,
        disconnect,
        broadcastTransaction,
        signMessage,
        addTokenToPlugin
    }), [isSupportedByBrowser, connect, disconnect, broadcastTransaction, signMessage, addTokenToPlugin]);
    return web3Config;
};
