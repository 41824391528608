import { styled } from '@ondefy/ondefy-ui';
export const SMyTransactions = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;

  .my-transaction__item {
    display: flex;
    width: 100%;
    align-items: center;
    height: 60px;
    cursor: default;

    > div:last-child {
      width: 100%;
    }

    > svg {
      margin-right: 10px;
    }
  }
`;
