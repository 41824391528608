import { EPages } from '../constants';
import { selectPage } from '../selectors';
// import { DEFAULT_CURRENCY } from '../../_buy_/constants'
import { resetFiatToTokenRate, setSourceAmount } from '../../1 - buy/slice';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
export const useResetOnPageRefresh = () => {
    const dispatch = useAppDispatch();
    const page = useAppSelector(selectPage);
    useEffect(() => {
        if (page === EPages.init) {
            dispatch(setSourceAmount(''));
            // TODO: why do we need it at all???
            // TODO: why do we need it at all???
            // TODO: why do we need it at all???
            // if (!fiatCurrency && !defaultFiatCurrency) {
            //   dispatch(setSelectedFiatSymbol(DEFAULT_CURRENCY))
            // }
        }
        dispatch(resetFiatToTokenRate());
        // eslint-disable-next-line
    }, []);
};
