import { OMedia, styled } from '@ondefy/ondefy-ui';
export const SMenuButton = styled(OMedia) `
  justify-content: flex-end;
  position: relative;
  padding-right: 8px;

  .menu__wallet-connected {
    display: block;
    position: absolute;
    z-index: 2;

    width: 8px;
    height: 8px;
    left: -20px;
    bottom: 5px;
    background-color: ${p => p.theme.colors.accent + '40'};
    border-radius: 100%;

    &::after {
      content: '';
      display: block;
      height: 4px;
      width: 4px;
      top: 2px;
      left: 2px;
      position: absolute;
      border-radius: 100%;
      background-color: ${p => p.theme.colors.accent};
      z-index: 0;
    }
  }

  .menu__network {
    position: absolute;
    right: 9px;
    width: 75px;
    pointer-events: none;
    max-width: 75px;

    & > div {
      position: relative;
      left: -11px;
    }
  }

  .menu__burger {
    position: relative;
  }
`;
