import { SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { selectSlippage } from '../../../../../selectors';
import { useAppSelector } from '../../../../../../../redux/hooks';
import { InfoBlock } from '../__shared__/info-block';
export const Slippage = () => {
    const slippage = useAppSelector(selectSlippage);
    return (React.createElement(InfoBlock, null,
        React.createElement(SText, { fontWeight: "semibold" },
            slippage,
            "% slippage")));
};
