import { ofType } from 'redux-observable';
import { from, merge, of, switchMap } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
// import { reject } from '../../__common__/rxjs-utils/reject'
import _ from 'lodash/fp';
import { WETH_WITHDRAW_SIG_HASH } from '../../__common__/constants/contracts';
import { waitForState } from '../../__common__/rxjs-utils/wait-for-state';
import { setAppMode, setPage } from '../../__common__/slice';
import { selectIsWalletConnected, selectUserAddress } from '../../wallet/selectors';
import { getAllowance as getAllowanceApi } from '../api/get-allowance';
import { selectBestSwapRate, selectNetworkIdTargetSwap, selectSourceTokenSwap } from '../selectors';
import { getAllowance, getAllowanceFullfilled, getAllowanceRejected } from '../slice';
import { checkIfCanSkipAllowance } from '../transducers/check-if-can-skip-allowance';
export const epicGetAllowance = (action$, state$) => {
    return merge(action$.pipe(ofType(getAllowance.toString())).pipe(waitForState(state$, selectIsWalletConnected), waitForState(state$, selectSourceTokenSwap), waitForState(state$, selectBestSwapRate), 
    // TODO: // filter(   if zkSync = return false   )
    // reject(() => {
    //   const { value } = state$
    //   return _.flow(selectSourceTokenSwap, checkIfCanSkipAllowance)(value)
    // }),
    switchMap(() => {
        var _a;
        const { value } = state$;
        // TODO: check if loading of swap rate will block waitForState guard
        // TODO: check if loading of swap rate will block waitForState guard
        // TODO: check if loading of swap rate will block waitForState guard
        if (_.flow(selectSourceTokenSwap, checkIfCanSkipAllowance)(value)) {
            return of(getAllowanceFullfilled(null));
        }
        const bestRate = selectBestSwapRate(value);
        const { spenderAddress } = bestRate;
        if ((_a = bestRate.callData) === null || _a === void 0 ? void 0 : _a.startsWith(WETH_WITHDRAW_SIG_HASH)) {
            return of(getAllowanceFullfilled(null));
        }
        const userAddress = selectUserAddress(value);
        const tokenSource = selectSourceTokenSwap(value);
        const networkId = selectNetworkIdTargetSwap(value);
        return from(getAllowanceApi({
            userAddress: userAddress,
            spenderAddress: spenderAddress,
            networkId: networkId,
            tokenSourceAddress: tokenSource.address
        })).pipe(takeUntil(action$.pipe(ofType(setPage.toString(), setAppMode.toString()))), switchMap(allowance => {
            return of(getAllowanceFullfilled(allowance));
        }), catchError(e => {
            console.error(e);
            console.log(e.message);
            return of(getAllowanceRejected({
                message: e.message
            }));
        }));
    })));
};
