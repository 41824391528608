// import { HeaderNavBack } from '../header-nav-back'
// import { usePaperResetConfirm } from '../paper-reset-confirm/__hooks__/use-paper-reset-confirm'
import { OIconNet, SFlexbox, SText } from '@ondefy/ondefy-ui';
import _ from 'lodash/fp';
import React from 'react';
import { useMount } from 'react-use';
import { ENV } from '../../../../env';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { HeaderNavBack } from '../../../__common__/components/header-with-breadcrumbs/header-nav-back';
import { MainButton } from '../../../__common__/components/main-button';
import { EPages } from '../../../__common__/constants';
import { ETransactionStatus } from '../../../__common__/constants/transaction';
import { useGetTokenContracts } from '../../../__common__/hooks/use-get-token-contracts';
import { setPage } from '../../../__common__/slice';
import { useUserWallet } from '../../../wallet/hooks/use-user-wallet';
import { selectIsWalletConnected } from '../../../wallet/selectors';
import { useGasFee } from '../../hooks/use-gas-fee';
import { selectAllowance, selectIfAllowanceSufficient, selectSourceTokenSwap, selectUnlockTransaction } from '../../selectors';
import { unlockTransaction, unlockTransactionReset, updateSwapperRate } from '../../slice';
import SuccessSvg from '../__components__/__assets__/success.svg';
// import { usePaperResetConfirm } from '../../../papers/paper-reset-confirm/__hooks__/use-paper-reset-confirm'
import { LoadingIndicator } from '../__components__/loading-indicator';
import { SScreenInner } from './__styled__/s-screen-inner';
export const ScreenUnlock = () => {
    var _a;
    const isWalletConnected = useAppSelector(selectIsWalletConnected);
    const dispatch = useAppDispatch();
    const sourceToken = useAppSelector(selectSourceTokenSwap);
    const isSufficientAllowance = useAppSelector(selectIfAllowanceSufficient);
    const unlockEntity = useAppSelector(selectUnlockTransaction);
    const allowance = useAppSelector(selectAllowance);
    const unlockTransactionEntity = useAppSelector(selectUnlockTransaction);
    const gasFee = useGasFee();
    const isPendingTransaction = ((_a = unlockTransactionEntity.value) === null || _a === void 0 ? void 0 : _a.status) === ETransactionStatus.pending;
    const isAnythingLoading = allowance.isLoading || unlockEntity.isLoading || gasFee.isLoading || isPendingTransaction;
    const tokenContracts = useGetTokenContracts();
    useUserWallet();
    // const paperResetConfirm = usePaperResetConfirm()
    useMount(() => {
        dispatch(unlockTransactionReset());
        dispatch(updateSwapperRate());
    });
    // const bestRate = useAppSelector(selectBestSwapRate)!
    // const targetNetworkId = useAppSelector(selectNetworkIdTargetSwap)!
    // const networkName = _.path([targetNetworkId, 'name'], NETWORKS_DATA)
    // const networkKey = _.path([targetNetworkId, 'id'], NETWORKS_DATA)
    // TODO: if allowance is not enough even after unlocking - we should show some error message
    // TODO: if allowance is not enough even after unlocking - we should show some error message
    // TODO: if allowance is not enough even after unlocking - we should show some error message
    let title = null;
    let text = 'Continue';
    if (tokenContracts.isLoaded) {
        title = `Unlock ${_.path('symbol', sourceToken)}`;
    }
    if (tokenContracts.isLoading) {
        title = 'Unlock';
    }
    if (gasFee.isLoading) {
        text = 'Gas fee is loading';
    }
    if (!isSufficientAllowance && !gasFee.isLoading) {
        text = `Unlock ${sourceToken === null || sourceToken === void 0 ? void 0 : sourceToken.symbol} for swap`;
    }
    if (allowance.isLoading) {
        text = 'Checking allowance';
    }
    return (React.createElement(SScreenInner, null,
        React.createElement(HeaderNavBack, { title: title, onBack: () => {
                // paperResetConfirm.tryReset()
                dispatch(setPage(EPages.init));
            } }),
        React.createElement(SFlexbox, { alignItems: "center", justifyContent: "center" },
            isSufficientAllowance && !isAnythingLoading && React.createElement(SuccessSvg, null),
            isAnythingLoading && React.createElement(LoadingIndicator, null),
            !isSufficientAllowance && !isAnythingLoading && (React.createElement(SFlexbox, { transform: "scale(4.7)", transformOrigin: "center" },
                React.createElement(OIconNet, { network: sourceToken === null || sourceToken === void 0 ? void 0 : sourceToken.cgKey, coinImage: sourceToken === null || sourceToken === void 0 ? void 0 : sourceToken.logoURI })))),
        React.createElement(SFlexbox, { alignItems: "center", px: "8px", justifyContent: "center" },
            React.createElement(SText, { type: "h5", textAlign: "center" },
                "Your approval is required so that your ", sourceToken === null || sourceToken === void 0 ? void 0 :
                sourceToken.symbol,
                " can be moved on your behalf")),
        React.createElement(MainButton, { loading: allowance.isLoading || tokenContracts.isLoading || gasFee.isLoading, onClick: () => {
                if (isSufficientAllowance) {
                    dispatch(setPage(ENV.isZyfi() ? EPages.swapSubmitZyfi : EPages.swapSubmit));
                }
                else {
                    dispatch(unlockTransaction());
                }
            }, disabled: !isWalletConnected || isAnythingLoading }, text)));
};
