import { Numbo } from '../../__common__/numbo/transducers';
import _ from 'lodash/fp';
export const checkIfEnoughFeeBalance = (generalTxZyfi, sourceTokenAmount, sourceTokenInWallet, feeTokenInWallet) => {
    if (!generalTxZyfi.value || !sourceTokenAmount) {
        return;
    }
    if (!sourceTokenInWallet || !feeTokenInWallet) {
        return false;
    }
    const feeTokenAmountRequired = Numbo.from(generalTxZyfi.value.feeTokenAmount); // fee amount is bigint 18000000...
    // TODO add logs supress error
    try {
        const sourceAmountDesired = Numbo.floatToBigNumber(sourceTokenAmount, sourceTokenInWallet.decimals);
        const sourceAmountInWallet = Numbo.floatToBigNumber(sourceTokenInWallet.amount || '0', sourceTokenInWallet.decimals);
        const feeAmountInWallet = Numbo.floatToBigNumber(feeTokenInWallet.amount || '0', feeTokenInWallet.decimals);
        if (_.isEqual(sourceTokenInWallet, feeTokenInWallet)) {
            return sourceAmountInWallet.add(feeTokenAmountRequired).lte(sourceAmountDesired);
        }
        return feeAmountInWallet.gte(feeTokenAmountRequired);
    }
    catch (e) {
        console.log(e);
        console.log({
            sourceTokenAmount,
            sourceTokenInWallet,
            feeTokenInWallet
        });
    }
};
