import { OButton, OIcon, OIconNet, OMedia, OSkeleton, SBox, SFlexbox, SText } from '@ondefy/ondefy-ui';
import React, { memo } from 'react';
import { selectTokenContracts } from '../../selectors';
import { useIframeUrlColors } from '../../../iframe/hooks/use-iframe-url-colors';
import { useAppSelector } from '../../../../redux/hooks';
import { EEntityStatus } from '../../../../redux/redux-entity';
import { usePaperTokenSelect } from '../../papers/paper-token-select/__hooks__/use-paper-token-select';
import { SIconNetTag } from '../../styled/s-icon-net-tag';
const TokenDropdownComponent = ({ fullWidth, selectedToken, tokenSelectedFromUrl, variant, placeholder }) => {
    const paper = usePaperTokenSelect(variant);
    const tokenContracts = useAppSelector(selectTokenContracts);
    const { colorPrimary } = useIframeUrlColors();
    const { status, value } = tokenContracts;
    const isLoading = status === EEntityStatus.loading || status === EEntityStatus.idle;
    const isLoaded = !!value;
    if (isLoading && !isLoaded) {
        return (React.createElement(OSkeleton, { width: fullWidth ? '100%' : 140, height: 40 }));
    }
    if (fullWidth) {
        return (React.createElement(SFlexbox, { pointerEvents: tokenSelectedFromUrl ? 'none' : 'all', width: "100%" },
            React.createElement(OButton, { justifyContent: "space-between", width: "100%", iconRight: tokenSelectedFromUrl ? null : (React.createElement(OIcon, { name: "down", 
                    // @ts-ignore
                    color: colorPrimary || 'accent' })), variant: "secondary", bg: "bg2", onClick: () => {
                    paper.setIsOpened(true);
                }, disabled: status === EEntityStatus.error },
                React.createElement(OMedia, { alignItems: "center" },
                    selectedToken && (React.createElement(SBox, { pr: "8px", pt: "2px" },
                        React.createElement(OIconNet, { network: selectedToken.cgKey, coinImage: selectedToken.logoURI, coinName: selectedToken.symbol }))),
                    React.createElement(SText, { fontWeight: "semibold", color: "inherit" },
                        tokenContracts.status === EEntityStatus.error
                            ? 'N/A'
                            : (selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.symbol) ||
                                (tokenSelectedFromUrl ? `N/A ${tokenSelectedFromUrl}` : placeholder || 'Select Token'),
                        ' ',
                        selectedToken && React.createElement(SText, { opacity: "0.7" },
                            "on ",
                            selectedToken.cgKey))))));
    }
    return (React.createElement(SFlexbox, { position: "relative", alignItems: "center", pointerEvents: tokenSelectedFromUrl ? 'none' : 'all' },
        selectedToken && (React.createElement(SIconNetTag, { label: React.createElement(SBox, { position: "relative", left: "-2px", top: "2px" },
                React.createElement(OIconNet, { network: selectedToken.cgKey, coinImage: selectedToken.logoURI, coinName: selectedToken.symbol })), variant: "quaternary" })),
        React.createElement(OButton, { iconRight: tokenSelectedFromUrl ? null : (React.createElement(OIcon, { name: "down", 
                // @ts-ignore
                color: colorPrimary || 'accent' })), variant: "secondary", bg: "bg2", onClick: () => {
                paper.setIsOpened(true);
            }, disabled: status === EEntityStatus.error },
            React.createElement(SText, { fontWeight: "semibold", color: "inherit" }, tokenContracts.status === EEntityStatus.error
                ? 'N/A'
                : (selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.symbol) ||
                    (tokenSelectedFromUrl ? `N/A ${tokenSelectedFromUrl}` : placeholder || 'Select Token')))));
};
export const TokenDropdown = memo(TokenDropdownComponent);
