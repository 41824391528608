var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { from, map } from 'rxjs';
import { apirxBlockNative } from '../../__common__/api/services/api-block-native';
import { ENetworkIdByName, NETWORKS_DATA } from '../../__common__/constants/networks';
import { Numbo } from '../../__common__/numbo/transducers';
import { getPublicClient } from '../../__common__/web3/get-public-client';
import { getGasFeesConfig } from '../transducers/get-gas-fees-config';
import _ from 'lodash/fp';
export const getLegacyGasPrices = (networkId) => __awaiter(void 0, void 0, void 0, function* () {
    const customClient = getPublicClient(networkId);
    const gasPrice = Numbo.from(yield customClient.getGasPrice());
    let L1GasPrice;
    // TODO: why so???
    // TODO: why so???
    // TODO: why so???
    if (networkId === ENetworkIdByName['optimistic-ethereum']) {
        const L1Client = getPublicClient(ENetworkIdByName.ethereum);
        L1GasPrice = Numbo.from(yield L1Client.getGasPrice());
    }
    return {
        networkId,
        slow: {
            gasPrice: gasPrice.mul(95).div(100).toString(),
            L1GasPrice: L1GasPrice ? L1GasPrice.mul(95).div(100).toString() : undefined
        },
        normal: {
            gasPrice: gasPrice.toString(),
            L1GasPrice: L1GasPrice ? L1GasPrice.toString() : undefined
        },
        fast: {
            gasPrice: gasPrice.mul(11).div(10).toString(),
            L1GasPrice: L1GasPrice ? L1GasPrice.mul(11).div(10).toString() : undefined
        }
    };
});
export const getGasPricesRx = (networkId) => {
    // if (NETWORKS_DATA[networkId]?.supportsEIP1559) {
    if (_.path([networkId, 'supportsEIP1559'], NETWORKS_DATA)) {
        return apirxBlockNative
            .get('/gasprices/blockprices', {
            confidenceLevels: '91,95,99',
            withBaseFees: false,
            chainid: networkId
        })
            .pipe(map(res => getGasFeesConfig(res, networkId)));
    }
    return from(getLegacyGasPrices(networkId));
};
