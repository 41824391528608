import _ from 'lodash/fp';
import { Numbo } from '../../../../../__common__/numbo/transducers';
export function formatZyfiValue(generalTxZyfi, symbol) {
    if (!generalTxZyfi.value) {
        return;
    }
    const feeTokenAmount = _.path(['value', 'feeTokenAmount'], generalTxZyfi);
    const feeTokenDecimals = _.path(['value', 'feeTokendecimals'], generalTxZyfi);
    const feeTokenAmountWithDecimals = Numbo.injectDecimals(feeTokenAmount, feeTokenDecimals);
    const feeUSD = _.path(['value', 'feeUSD'], generalTxZyfi);
    const formattedUSD = Numbo.format(feeUSD, { toFixed: 2, min: 0.01 });
    return {
        feeTokenAmountWithDecimals,
        formattedUSD
    };
    // return `${Numbo.format(feeTokenAmountWithDecimals, { min: 0.0001, toFixed: 4 }) as string} ${symbol || ''} (≈ $${
    //   formattedUSD as string
    // })`
}
