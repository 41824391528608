import { useCallback, useMemo } from 'react';
import { Nonce } from '../../../../2 - swap/nonce';
import { resetUserWalletCache } from '../../../../wallet/api/get-user-wallet';
import { api1InchError } from '../../../api/integrations/1inch';
import { APP_MODE, EPages } from '../../../constants';
import { usePaper } from '../../../components/o-paper';
import { selectAppMode, selectPage } from '../../../selectors';
import { reset as resetCommon } from '../../../slice';
import { reset as resetWallet } from '../../../../wallet/slice';
import { reset as resetBuy } from '../../../../1 - buy/slice';
import { reset as resetSwap } from '../../../../2 - swap/slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
export const usePaperResetConfirm = () => {
    const dispatch = useAppDispatch();
    const page = useAppSelector(selectPage);
    const paper = usePaper('paper-reset-confirm');
    const appMode = useAppSelector(selectAppMode);
    const isSwapMode = appMode === APP_MODE.swap;
    const resetAll = useCallback(() => {
        if (isSwapMode) {
            dispatch(resetSwap());
        }
        else {
            dispatch(resetBuy());
        }
        dispatch(resetWallet());
        dispatch(resetCommon());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSwapMode]);
    const config = useMemo(() => (Object.assign(Object.assign({}, paper), { tryReset: () => {
            if (page === EPages.buySummary || page === EPages.swapResult) {
                resetUserWalletCache();
                resetAll();
                Nonce.flush();
                api1InchError.value = null;
            }
            else if (page !== EPages.init) {
                paper.setIsOpened(true);
            }
        }, reset: () => {
            resetUserWalletCache();
            resetAll();
            Nonce.flush();
            api1InchError.value = null;
        }, 
        // TODO: check if transak supported maybe we have only page 2 as the last one?
        isResetPossible: page !== EPages.init })), [page, paper, resetAll]);
    return config;
};
