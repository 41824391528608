import { ReduxEntity } from '../../redux/redux-entity';
import { ETransactionStatus } from '../__common__/constants/transaction';
import { Numbo } from '../__common__/numbo/transducers';
import { initialState } from './state';
export const setDestinationAddress = (state, { payload }) => {
    state.destinationAddress = payload;
};
export const setFeeTokenIdSend = (state, { payload }) => {
    state.feeTokenIdSend = payload;
};
export const setNetworkIdFeeSend = (state, { payload }) => {
    state.networkIdFeeSend = payload;
};
export const setSourceTokenIdSend = (state, { payload }) => {
    if (state.sourceTokenIdSend === payload) {
        return;
    }
    state.sourceTokenIdSend = payload;
    state.percentage = initialState.percentage;
    if (!state.feeTokenIdSend) {
        state.feeTokenIdSend = payload;
    }
};
export const setNetworkIdSourceSend = (state, { payload }) => {
    state.networkIdSourceSend = payload;
};
export const flush = (state) => {
    return Object.assign(Object.assign({}, initialState), { networkIdSourceSend: state.networkIdSourceSend, sourceTokenIdSend: state.sourceTokenIdSend, networkIdFeeSend: state.networkIdFeeSend, feeTokenIdSend: state.feeTokenIdSend });
};
export const reset = (state) => {
    return Object.assign(Object.assign({}, initialState), { networkIdSourceSend: state.networkIdSourceSend, sourceTokenIdSend: state.sourceTokenIdSend, networkIdFeeSend: state.networkIdFeeSend, feeTokenIdSend: state.feeTokenIdSend });
};
export const getZyfiGeneralTx = (state) => {
    ReduxEntity.loading(state.zyfiGeneralTx);
};
export const getZyfiGeneralTxRejected = (state, action) => {
    ReduxEntity.error(state.zyfiGeneralTx, action);
};
export const getZyfiGeneralTxFullfilled = (state, action) => {
    ReduxEntity.success(state.zyfiGeneralTx, action);
};
export const saveTransaction = (state, { payload }) => {
    if (payload.status !== ETransactionStatus.pending) {
        // remove completed
        state.pendingTransactions = state.pendingTransactions.filter(transaction => {
            return transaction.txHash !== payload.txHash;
        });
        return;
    }
    if (!state.pendingTransactions.find(transaction => transaction.txHash === payload.txHash)) {
        // add new
        state.pendingTransactions.push(payload);
    }
};
export const saveTransactionComplete = (state, { payload }) => {
    // TODO: test if it works properly
    // TODO: test if it works properly
    // TODO: test if it works properly
    state.pendingTransactions = state.pendingTransactions.filter(transaction => {
        return transaction.txHash !== payload.txHash;
    });
};
export const sendTransaction = (state) => {
    ReduxEntity.loading(state.sendTransaction);
};
export const sendTransactionReset = (state) => {
    ReduxEntity.reset(state.sendTransaction);
};
export const sendTransactionRejected = (state, action) => {
    ReduxEntity.error(state.sendTransaction, action);
};
export const sendTransactionFullfilled = (state, action) => {
    ReduxEntity.success(state.sendTransaction, action);
};
export const setPercentage = (state, { payload }) => {
    state.percentage = payload;
};
export const setSourceTokenAmount = (state, { payload }) => {
    state.sourceTokenAmount = Numbo.sanitizeFloatValue(payload);
};
