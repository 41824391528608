var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Magic, RPCError, RPCErrorCode } from 'magic-sdk';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from '../../../../redux/hooks';
import { NETWORKS_DATA } from '../../../__common__/constants/networks';
import { WALLETS } from '../../constants/wallets';
import { setUserAddress, resetUserWallet, setUserEmail, setWalletType, setIsWalletConnected } from '../../slice';
import { Address } from '../../model.address';
import { ENV } from '../../../../env';
import { getSignature } from './utils/get-signature';
import { sendTransaction } from './utils/send-transaction';
export const useMagicAuthConnector = () => {
    const dispatch = useAppDispatch();
    const disconnect = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const magic = new Magic(process.env.MAGIC_PK);
        yield magic.user.logout().catch(err => {
            console.error('Error while logging out from Magic ', err);
        });
        dispatch(resetUserWallet());
    }), [dispatch]);
    const connect = useCallback((email) => __awaiter(void 0, void 0, void 0, function* () {
        if (!email) {
            throw new Error('missing email address');
        }
        const magic = new Magic(process.env.MAGIC_PK);
        try {
            // TODO: try to do it via magic link
            // await magic.auth.loginWithMagicLink({ email, showUI: true })
            // await magic.auth.loginWithEmailOTP({ email })
            yield magic.auth.loginWithEmailOTP({ email, showUI: true });
            const { publicAddress } = yield magic.user.getInfo();
            dispatch(setUserAddress(Address.asString(publicAddress)));
            dispatch(setUserEmail(email));
            dispatch(setWalletType(WALLETS.magicAuth.key));
            dispatch(setIsWalletConnected(true));
        }
        catch (err) {
            console.log(err);
            if (err instanceof RPCError) {
                switch (err.code) {
                    case RPCErrorCode.MagicLinkFailedVerification:
                    case RPCErrorCode.MagicLinkExpired:
                    case RPCErrorCode.MagicLinkRateLimited:
                    case RPCErrorCode.UserAlreadyLoggedIn:
                        // Handle errors accordingly :)
                        break;
                }
            }
            // for compatibility
            throw new Error(err.message);
        }
    }), [dispatch]);
    const broadcastTransaction = useCallback((params, broadcastConfig) => __awaiter(void 0, void 0, void 0, function* () {
        const { rpc } = NETWORKS_DATA[params.networkId];
        if (!rpc) {
            throw new Error('Invalid network');
        }
        const customNodeOptions = {
            rpcUrl: rpc,
            chainId: params.networkId
        };
        const magic = new Magic(process.env.MAGIC_PK, {
            network: customNodeOptions
        });
        const isLoggedIn = yield magic.user.isLoggedIn();
        if (!isLoggedIn) {
            dispatch(resetUserWallet);
            throw new Error('User is not logged in');
        }
        if (ENV.isOndefy()) {
            if (params.gasPriceData.maxFeePerGas && params.gasPriceData.maxPriorityFeePerGas) {
                params.rawTx.maxFeePerGas = params.gasPriceData.maxFeePerGas;
                params.rawTx.maxPriorityFeePerGas = params.gasPriceData.maxPriorityFeePerGas;
            }
            else if (params.gasPriceData.gasPrice) {
                params.rawTx.gasPrice = params.gasPriceData.gasPrice;
            }
            if (params.gasLimit) {
                params.rawTx.gasLimit = params.gasLimit;
            }
        }
        const provider = magic.rpcProvider;
        return yield sendTransaction(params, provider, broadcastConfig);
    }), [dispatch]);
    const signMessage = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        const { rpc } = NETWORKS_DATA[params.networkId];
        if (!rpc) {
            throw new Error('Invalid network');
        }
        const customNodeOptions = {
            rpcUrl: rpc,
            chainId: params.networkId
        };
        const magic = new Magic(process.env.MAGIC_PK, {
            network: customNodeOptions
        });
        const isLoggedIn = yield magic.user.isLoggedIn();
        if (!isLoggedIn) {
            dispatch(resetUserWallet);
            throw new Error('User is not logged in');
        }
        const provider = magic.rpcProvider;
        return yield getSignature(params, provider);
    }), [dispatch]);
    const web3Config = useMemo(() => ({
        walletType: WALLETS.magicAuth.key,
        isSupportedByBrowser: true,
        connect,
        disconnect,
        broadcastTransaction,
        signMessage
    }), [connect, disconnect, broadcastTransaction, signMessage]);
    return web3Config;
};
