import { SBox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { OPaper } from '../../../__common__/components/o-paper';
import { usePaperDestinationAddressInfo } from './__hooks__/use-paper-destination-address-info';
export const PaperSendDestinationAddressInfo = () => {
    const paper = usePaperDestinationAddressInfo();
    return (React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom", title: "What is Destination Address?" }),
        React.createElement(SBox, { p: "12px" },
            React.createElement(SText, { type: "p" }, "Enter a valid wallet destination address where you will receive the tokens on. Double-check the address, as transactions are irreversible and must be sent to the correct contract."))));
};
