import { checkIfNetworkIsValid } from '../../1 - buy/transducers/check-iIf-network-is-valid';
// TODO: add unit tests
// TODO: add unit tests
// TODO: add unit tests
export const checkIfSwapStartDisabled = (networkId, bestSwapRate, swapRates, canAllowanceBeSkipped, isEnoughBalance, allowance) => {
    const isNetworkValid = networkId && checkIfNetworkIsValid(networkId);
    const allowanceNotReady = !canAllowanceBeSkipped && !allowance.isLoaded;
    return (!isEnoughBalance ||
        // TODO: userAllowance
        // TODO: userAllowance
        // TODO: userAllowance
        allowanceNotReady ||
        !isNetworkValid ||
        !bestSwapRate ||
        // isTyping ||
        swapRates.isError ||
        swapRates.isLoading
    // TODO: ask Clement when we should block it
    // TODO: ask Clement when we should block it
    // TODO: ask Clement when we should block it
    // (isRateAbnormal(fiatToTokenRate?.value?.valueLoss) && !allowWithWarning)
    );
};
