import { SBox, styled } from '@ondefy/ondefy-ui';
export const SPaperTransactionDetails = styled(SBox) `
  padding: 0 10px;

  [class*='icon-'] {
    transition: transform 0.2s;
  }

  .transaction__route {
    .transaction__icon {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: ${p => p.theme.colors.bg2};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
    }

    .transaction__icon--scaled {
      transform: scale(0.67);
      transform-origin: center;
    }

    .transaction__icon--light {
      border: 1px solid ${p => p.theme.colors.bg3};
    }

    .transaction__icon--ondefy {
      width: 16px;
      height: 16px;
      border: 1px solid ${p => p.theme.colors.accent};
      background-color: transparent;
      border-radius: 100%;

      > div {
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        background: ${p => p.theme.gradients.default};
        border-radius: 100%;
      }
    }

    .transaction__steps {
      padding-bottom: 15px;
    }

    .transaction__steps > div {
      position: relative;
    }

    .transaction__steps > div:not(:last-child) {
      margin-bottom: 19px;
    }

    .transaction__step-separator {
      display: none;
      top: -20px;
      position: absolute;
    }

    .transaction__steps > div:not(:first-child) {
      .transaction__step-separator {
        display: block;
      }
    }
  }
`;
