import { ofType } from 'redux-observable';
import { merge, filter, delay, switchMap, from } from 'rxjs';
import { debounceTime, takeWhile } from 'rxjs/operators';
import { APP_MODE, EPages } from '../../__common__/constants';
import { ofValue } from '../../__common__/rxjs-utils/of-value';
import { repeatSwitch } from '../../__common__/rxjs-utils/repeat-switch';
import { selectAppMode, selectPage } from '../../__common__/selectors';
import { selectIsNotSupportedZyfiSourceToken, selectNetworkIdTargetSwap, selectSlippage, selectSourceTokenAmount, selectSourceTokenSwap, selectTargetTokenSwap } from '../selectors';
import { getSwapRates, getSwapRatesFullfilled, getGasFees, getSwapRatesRejected, restartBestRatesPolling, abortPending } from '../slice';
import { checkIfAllExist } from '../../__common__/transducers/check-if-all-exist';
export const epicRunGetSwapAndGasFeesPolling = (action$, state$) => {
    return merge(
    // TODO: update on user connected/disconnected
    // TODO: update on user connected/disconnected
    // TODO: update on user connected/disconnected
    ofValue(state$, selectNetworkIdTargetSwap), ofValue(state$, selectSourceTokenSwap), ofValue(state$, selectTargetTokenSwap), ofValue(state$, selectSourceTokenAmount), ofValue(state$, selectSlippage), action$.pipe(ofType(restartBestRatesPolling.toString()))).pipe(debounceTime(500), filter(() => {
        const { value } = state$;
        const networkTargetId = selectNetworkIdTargetSwap(value);
        const tokenSource = selectSourceTokenSwap(value);
        const tokenTarget = selectTargetTokenSwap(value);
        const sourceTokenAmount = selectSourceTokenAmount(value);
        const slippage = selectSlippage(value);
        const hasNumbers = /[1-9]/.test(sourceTokenAmount === null || sourceTokenAmount === void 0 ? void 0 : sourceTokenAmount.replace('.', ''));
        const isNotSupportedZyfiSourceToken = selectIsNotSupportedZyfiSourceToken(value);
        const appMode = selectAppMode(value);
        if (!sourceTokenAmount ||
            (sourceTokenAmount && !hasNumbers) ||
            isNotSupportedZyfiSourceToken ||
            appMode !== APP_MODE.swap) {
            return false;
        }
        return checkIfAllExist(networkTargetId, tokenSource, tokenTarget, sourceTokenAmount, slippage);
    }), 
    // when we get a new trigger - run actions and schedule infinite repeat of this running on the observable condition
    repeatSwitch({
        // TODO: ask Clement if 10 seconds is ok
        // TODO: ask Clement if 10 seconds is ok
        // TODO: ask Clement if 10 seconds is ok
        delay: () => action$.pipe(ofType(
        // wait until the next success getSwapRates action
        getSwapRatesFullfilled.toString(), 
        // wait until the next error getSwapRates action
        getSwapRatesRejected.toString()), 
        // then wait for 30 seconds and run the process again
        delay(30000), 
        // TODO: stop when unlockTransaction or swapTransacition?? in redux
        // TODO: stop when unlockTransaction or swapTransacition?? in redux
        // TODO: stop when unlockTransaction or swapTransacition?? in redux
        takeWhile(() => {
            // TODO: make it compact
            // TODO: make it compact
            // TODO: make it compact
            const { value } = state$;
            const networkTargetId = selectNetworkIdTargetSwap(value);
            const tokenSource = selectSourceTokenSwap(value);
            const tokenTarget = selectTargetTokenSwap(value);
            const sourceTokenAmount = selectSourceTokenAmount(value);
            const page = selectPage(value);
            const appMode = selectAppMode(value);
            if (page !== EPages.init || appMode !== APP_MODE.swap) {
                // stop polling
                return false;
            }
            return checkIfAllExist(networkTargetId, tokenSource, tokenTarget, sourceTokenAmount);
        })
        // TODO: takeUntil all the fields are valid
        )
        // count: 3
    }), filter(() => {
        const { value } = state$;
        return selectPage(value) === EPages.init;
    }), 
    // TODO add take until in case of Insufficient balance in error rejected
    // connect(source$ =>
    //   merge(
    //     // trigger getSwapRates()
    //     source$.pipe(map(() => getSwapRates())),
    //     // trigger getGasFees()
    //     source$.pipe(map(() => getGasFees()))
    //   )
    // ),
    switchMap(() => {
        return from([abortPending(), getSwapRates(), getGasFees()]);
    }));
};
