import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useAppSelector } from '../../../redux/hooks';
import { Analytics } from '../index';
import { selectUserAddress } from '../../wallet/selectors';
export const useAnalytics = () => {
    const userAddress = useAppSelector(selectUserAddress);
    useEffect(() => {
        /* === CONNECT GOOGLE TAG MANAGER === */
        const tagManagerArgs = {
            gtmId: process.env.GOOGLE_TAG_MANAGER_ID
        };
        TagManager.initialize(tagManagerArgs);
        /* === CONNECT MIXPANEL === */
        mixpanel.init(process.env.MIXPANEL_TOKEN, { debug: true, track_pageview: true, persistence: 'localStorage' });
        Analytics.widgetLoaded();
    }, []);
    useEffect(() => {
        mixpanel.reset();
        mixpanel.identify(userAddress);
    }, [userAddress]);
};
