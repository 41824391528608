import { setTheme } from '@ondefy/ondefy-ui';
import { EMPTY, merge, Observable, of } from 'rxjs';
import { tap, switchMap, debounceTime, ignoreElements } from 'rxjs/operators';
import { ofValue } from '../../__common__/rxjs-utils/of-value';
import { selectNetworkIdTargetFiat, selectTargetTokenIdFiat } from '../../1 - buy/selectors';
import { setIframeColorPrimary } from '../slice';
import { reset, setAppMode } from '../../__common__/slice';
import { setNetworkIdTargetFiat } from '../../1 - buy/slice';
const externalMessageBus = () => new Observable(observer => {
    const messageReceiverFunction = (e) => {
        var _a, _b, _c, _d;
        if (((_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.action) === null || _b === void 0 ? void 0 : _b.startsWith('ondefy:')) || ((_d = (_c = e === null || e === void 0 ? void 0 : e.data) === null || _c === void 0 ? void 0 : _c.action) === null || _d === void 0 ? void 0 : _d.startsWith('zyfi:'))) {
            observer.next(e.data);
        }
    };
    window.addEventListener('message', messageReceiverFunction);
    return () => {
        window.removeEventListener('message', messageReceiverFunction);
        observer.complete();
    };
});
const SET_THEME = 'ondefy:set-theme';
const actionsSelector = (config) => {
    if (config.action === 'ondefy:select-network') {
        return setNetworkIdTargetFiat(config.payload);
    }
    if (config.action === 'ondefy:reset') {
        return reset();
    }
    if (config.action === 'zyfi:mode' || config.action === 'ondefy:mode') {
        return setAppMode(config.payload);
    }
    if (config.action === 'ondefy:set-color-primary') {
        return setIframeColorPrimary(config.payload);
    }
    if (config.action === SET_THEME) {
        return {
            type: SET_THEME,
            payload: config.payload
        };
    }
};
const emitToParent = (actionName, payload) => {
    window.parent.postMessage({
        action: actionName,
        payload
    }, '*');
};
export const epicIframePostMessages = (action$, state$) => {
    return merge(merge(of(null).pipe(tap(v => emitToParent('ondefy:ready', v))), ofValue(state$, selectTargetTokenIdFiat).pipe(debounceTime(16), tap(v => {
        // console.log('ondefy:select-token', v)
        emitToParent('ondefy:select-token', v);
    })), ofValue(state$, selectNetworkIdTargetFiat).pipe(debounceTime(16), tap(v => {
        // console.log('ondefy:select-network', v)
        emitToParent('ondefy:select-network', v);
    }))).pipe(ignoreElements()), externalMessageBus().pipe(switchMap(config => {
        const action = actionsSelector(config);
        console.log(config);
        if (action && action.type === SET_THEME) {
            setTheme(action.payload, true);
            return EMPTY;
        }
        if (action) {
            return of(action);
        }
        return EMPTY;
    })));
};
