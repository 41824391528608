import { catchError, delay, EMPTY, filter, mergeMap, of, tap } from 'rxjs';
import { expand, takeWhile } from 'rxjs/operators';
import { ETransactionStatus, ETxBlockchainStatus } from '../../__common__/constants/transaction';
import { ofValue } from '../../__common__/rxjs-utils/of-value';
import { resetUserWalletCache } from '../../wallet/api/get-user-wallet';
import { checkTransactionStatus } from '../api/check-transaction-status';
import { selectPendingTransactions, selectCustomTransaction } from '../selectors';
import { saveTransaction } from '../slice';
import _ from 'lodash/fp';
// TODO: retest everything carefully
// TODO: retest everything carefully
// TODO: retest everything carefully
export const epicCustomPollingMultipleTransactionSyncStatus = (action$, state$) => {
    // Maintain a list of ongoing transaction checks
    const ongoingChecks = new Set();
    const isTransactionBeingChecked = (txHash) => ongoingChecks.has(txHash);
    const addTransactionToOngoingChecks = (txHash) => ongoingChecks.add(txHash);
    const removeTransactionFromOngoingChecks = (txHash) => ongoingChecks.delete(txHash);
    const handleTransaction = (transaction) => {
        return checkTransactionStatus(transaction).pipe(mergeMap(res => {
            // If status is 1 or 0, dispatch saveTransaction and remove txHash from ongoingChecks
            if (!res || res.status === ETxBlockchainStatus.success || res.status === ETxBlockchainStatus.error) {
                removeTransactionFromOngoingChecks(transaction.txHash);
                // TODO: just update stars
                // TODO: just update stars
                // TODO: just update stars
                // if(res.status === 1) {
                //   if (tx.type === TRANSACTION_TYPE.SWAP) {
                //     dispatch({
                //       type: SET_USER_VOLUME_POINTS,
                //       payload: volumePoints + Number(tx.volumeUsd),
                //     });
                //   }
                // }
                return of(saveTransaction(Object.assign(Object.assign({}, transaction), { 
                    // TODO: ask Clement about expired transaction - where to find the description of res.status numbers???
                    // TODO: ask Clement about expired transaction - where to find the description of res.status numbers???
                    // TODO: ask Clement about expired transaction - where to find the description of res.status numbers???
                    status: (res === null || res === void 0 ? void 0 : res.status) === ETxBlockchainStatus.success ? ETransactionStatus.confirmed : ETransactionStatus.failed }))).pipe(tap(() => {
                    if (res.status === ETxBlockchainStatus.success) {
                        resetUserWalletCache();
                    }
                }));
            }
            else {
                // If status is neither 1 nor 0, delay for 10 seconds and then retry
                return of(transaction).pipe(delay(10000));
            }
        }), catchError(err => {
            // Ignore errors from blockchain provider's response
            console.error(err);
            return EMPTY;
        }), 
        // Recursively retry until the condition is met
        expand(result => {
            if (result.payload) {
                // If it's an action
                return EMPTY; // Terminate the recursion for this path
            }
            // If it's a transaction, continue polling
            return handleTransaction(result);
        }), 
        // Stop the observable once an action is emitted
        takeWhile(result => !result.payload, true));
    };
    // ofValue(state$, selectPendingTransactions) ==> Observable<TTransactionAny[]>
    return ofValue(state$, selectPendingTransactions).pipe(
    // tap(v => {
    //   console.log(v, 'NEW TRANSACTION ADDED')
    // }),
    // Flatten the array of transactions into individual transactions
    mergeMap((pendingTransactions) => pendingTransactions), 
    // Filter out transactions that are already being checked
    filter(transaction => !isTransactionBeingChecked(transaction.txHash)), filter(transaction => {
        // Filter out transactions that are tracked in own epics - like active custom transactions
        const { value } = state$;
        const customTransaction = selectCustomTransaction(value);
        const txHash = _.path(['value', 'txHash'], customTransaction);
        return transaction.txHash !== txHash;
    }), 
    // Process the transaction and manage the ongoingChecks list
    mergeMap(transaction => {
        // Add to ongoing checks
        addTransactionToOngoingChecks(transaction.txHash);
        return handleTransaction(transaction);
    }));
};
