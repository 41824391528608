import { SGridbox, styled } from '@ondefy/ondefy-ui';
export const SCarousel = styled(SGridbox) `
  --s: 38px; /* the size */

  display: grid;
  justify-content: stretch;
  transform-style: preserve-3d;
  --_p: perspective(calc(1000 * var(--s)));
  animation: r 9s infinite;
  grid-gap: 0; /* Add this line to remove the gap between slides */

  > div {
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: ${p => p.theme.colors.bg2};

    height: 38px;
    overflow: hidden;
    grid-area: 1/1;
    object-fit: cover;
    transform: var(--_t) translateZ(calc(var(--s) / 2 - var(--s) / 9)); /* Adjust the translateZ value */

    &:nth-child(2) {
      --_t: rotateX(-120deg);
    }
    &:nth-child(3) {
      --_t: rotateX(120deg);
    }
  }

  @keyframes r {
    0%,
    2% {
      transform: var(--_p);
    }
    10%,
    34% {
      transform: var(--_p) rotateX(120deg);
    }
    40%,
    67% {
      transform: var(--_p) rotateX(240deg);
    }
    75%,
    100% {
      transform: var(--_p) rotateX(360deg);
    }
  }
`;
