import { toast } from '@ondefy/ondefy-ui';
export const showSubtractInfo = () => {
    const timestamp = new Date().getTime();
    // Define the timestamp property if it doesn't exist
    if (showSubtractInfo.timestamp === undefined) {
        showSubtractInfo.timestamp = 0;
    }
    // Show it once in 10 seconds
    if (timestamp > showSubtractInfo.timestamp + 10000) {
        toast.next({
            variant: 'info',
            message: 'Gas fee subtracted from source'
        });
        showSubtractInfo.timestamp = timestamp;
    }
};
// Initialize the static property
showSubtractInfo.timestamp = 0;
