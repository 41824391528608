import { styled } from '@ondefy/ondefy-ui';
import { OLine } from '../../o-line';
export const SNetworkLine = styled(OLine) `
  display: flex;
  width: 100%;
  height: 50px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  &.selected,
  &:hover {
    &:not(.disabled) {
      background-color: ${p => (p.theme.id === 'light' ? p.theme.colors.bg6 : p.theme.colors.hoverTransparent)};
    }
  }

  .dropdown__item-text {
    white-space: nowrap;
    margin-bottom: 0;
  }

  .dropdown__item-token-count {
    transform: scale(0.8);
  }
`;
