import { Magic } from 'magic-sdk';
import { ofType } from 'redux-observable';
import { EMPTY, delay, filter, from, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { widgetConnectExternal } from '../api/widget-connect-external';
import { widgetConnectMagic } from '../api/widget-connect-magic';
import { WALLETS } from '../constants/wallets';
import { selectUserAddress, selectUserEmail, selectWalletType } from '../selectors';
import { setIsWalletConnected } from '../slice';
export const epicSyncUserWalletConnection = (action$, state$) => {
    return action$.pipe(ofType(setIsWalletConnected.toString()), delay(16), filter(v => {
        // send request on when is connected equals true
        return v === true;
    }), switchMap(() => {
        const { value } = state$;
        const email = selectUserEmail(value);
        const walletType = selectWalletType(value);
        const isMagic = walletType === WALLETS.magicAuth.key;
        const userAddress = selectUserAddress(value);
        // send request to the backend for magic wallets
        if (isMagic) {
            const magic = new Magic(process.env.MAGIC_PK);
            return from(magic.user.getIdToken()).pipe(switchMap(did => {
                return widgetConnectMagic(email, did).pipe(catchError(() => {
                    // ignore errors
                    return EMPTY;
                }), switchMap(() => {
                    // ignore success
                    return EMPTY;
                }));
            }), catchError(() => {
                // ignore magic errors
                return EMPTY;
            }));
        }
        // send request to the backend for external wallets
        return widgetConnectExternal(userAddress, walletType).pipe(switchMap(() => {
            // ignore success
            return EMPTY;
        }), catchError(() => {
            // ignore errors
            return EMPTY;
        }));
    }));
};
