import { SBox, styled } from '@ondefy/ondefy-ui';
import { zyfiBackgroundMixin } from '../../../styled/zyfi-background-mixin';
export const STitle = styled(SBox) `
  position: sticky;
  top: 0;
  height: 50px;
  flex-shrink: 0;
  background: ${p => zyfiBackgroundMixin(p.theme.colors.bg2)};
  z-index: 2;
`;
