import _ from 'lodash/fp';
import { NETWORK_NAME_BY_ID } from '../../__common__/constants/networks';
// TODO: write unit tests
// TODO: write unit tests
// TODO: write unit tests
export const sourceTokenInUserWallet = (userWallet, sourceNetworkId, sourceToken) => {
    if (!sourceNetworkId || !userWallet.value || !sourceToken) {
        return null;
    }
    const sourceNetworkName = _.path(sourceNetworkId, NETWORK_NAME_BY_ID);
    const sourceTokenInUserWallet = _.path(['entities', sourceNetworkName, 'assetsAssoc', _.path('id', sourceToken)], userWallet.value);
    return sourceTokenInUserWallet;
};
