const NETWORK_EXPLORER_BY_ID = {
    1: 'https://etherscan.io/tx/',
    137: 'https://polygonscan.com/tx/',
    42161: 'https://arbiscan.io/tx/',
    43114: 'https://snowtrace.io/tx/',
    250: 'https://ftmscan.com/tx/',
    42220: 'https://celoscan.io/tx/',
    // 42220: 'https://explorer.celo.org/mainnet/tx/',
    10: 'https://optimistic.etherscan.io/tx/',
    324: 'https://explorer.zksync.io/tx/',
    8453: 'https://basescan.org/tx'
};
export const getExplorerLink = (txHash, networkId) => {
    return NETWORK_EXPLORER_BY_ID[networkId] + txHash;
};
