import { EPages } from '../../../../../../__common__/constants';
import { selectNetworkIdTargetFiat, selectIsTransakSupported } from '../../../../../selectors';
import { selectUserAddress } from '../../../../../../wallet/selectors';
import { setPartnerOrderId, setTransakOrderNetworkId, setTransakOrderWalletAddress, postOrder } from '../../../../../slice';
import { setPage } from '../../../../../../__common__/slice';
import { useNanoid } from '@ondefy/ondefy-ui';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
export const useOnContinue = () => {
    const dispatch = useAppDispatch();
    const userAddress = useAppSelector(selectUserAddress);
    const networkId = useAppSelector(selectNetworkIdTargetFiat);
    const isTransakSupported = useAppSelector(selectIsTransakSupported);
    const id = useNanoid();
    return () => {
        dispatch(setPartnerOrderId(id));
        dispatch(setTransakOrderNetworkId(networkId));
        dispatch(setTransakOrderWalletAddress(userAddress || ''));
        if (isTransakSupported) {
            // save order here
            dispatch(postOrder());
        }
        else {
            dispatch(setPage(EPages.buyPermit));
        }
    };
};
