// https://github.com/vercel/next.js/blob/canary/examples/with-redux/src/features/counter/counterSlice.ts
import { createSlice } from '@reduxjs/toolkit';
// import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { sliceNamespace } from './constants';
import { initialState } from './state';
// @ts-ignore
import * as reducers from './reducers';
/*
 * REDUCERS
 * */
export const slice = createSlice({
    name: sliceNamespace,
    initialState,
    /*
     * REDUCERS SIMPLE
     * */
    reducers
});
/*
 * ACTIONS
 * */
export const { setIsWeb3Busy, setWalletType, setIsWalletConnected, setUserAddress, setUserEmail, resetUserWallet, reset, getUserWallet, getUserWalletRejected, getUserWalletFullfilled, flush } = slice.actions;
/*
 * PERSIST CONFIG
 * */
const persistConfig = {
    key: sliceNamespace,
    storage: localForage,
    whitelist: ['walletType', 'userAddress', 'userEmail']
};
export default persistReducer(persistConfig, slice.reducer);
