import { selectSourceAmount, selectFiatToTokenRate, selectRefuelAmount, selectSelectedFiatSymbol, selectTransactionStatus, selectPartnerOrderId, selectIsTransakSupported, selectTargetTokenFiat } from '../../selectors';
import { HeaderNavBack } from '../../../__common__/components/header-with-breadcrumbs/header-nav-back';
import { PoweredBy } from '../../../__common__/components/main-button/__components__/powered-by';
import { ButtonOnly } from '../../../__common__/components/main-button/button-only';
import { usePaperResetConfirm } from '../../../__common__/papers/paper-reset-confirm/__hooks__/use-paper-reset-confirm';
import { TransactionRoute } from './__components__/transaction-route/transaction-route';
import { useConfetti } from './__hooks__/use-confetti';
import { useGetTokenContracts } from '../../../__common__/hooks/use-get-token-contracts';
import React from 'react';
import { SFlexbox, SBox, SText, OMedia, useTheme, OButton, OIcon, OReactToPdf } from '@ondefy/ondefy-ui';
import { useMount } from 'react-use';
import { EEntityStatus } from '../../../../redux/redux-entity';
import { SCollapseMoreDetails } from '../screen-0-init-buy/__components__/5-more-details/s-components/s-collapse-more-details';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getTransactionStatus } from '../../slice';
import { SSeparator } from './__components__/transaction-route/__styled__/s-separator';
import Confetti from 'react-confetti';
import { TransactionReceipt } from './__components__/transaction-route/transaction-receipt';
export const ScreenSummary = () => {
    const { theme } = useTheme();
    const dispatch = useAppDispatch();
    const partnerOrderId = useAppSelector(selectPartnerOrderId);
    const fiatAmount = useAppSelector(selectSourceAmount);
    const fiatSymbol = useAppSelector(selectSelectedFiatSymbol);
    const fiatToTokenRate = useAppSelector(selectFiatToTokenRate);
    const transactionStatus = useAppSelector(selectTransactionStatus);
    const isTransakSupported = useAppSelector(selectIsTransakSupported);
    const selectedToken = useAppSelector(selectTargetTokenFiat);
    const refuelAmount = useAppSelector(selectRefuelAmount);
    useGetTokenContracts();
    useMount(() => {
        if (transactionStatus.status === EEntityStatus.idle) {
            dispatch(getTransactionStatus());
        }
    });
    const { confetti, width, height } = useConfetti();
    const paperResetConfirm = usePaperResetConfirm();
    return (React.createElement(SCollapseMoreDetails, null,
        React.createElement(HeaderNavBack, { onBack: () => {
                paperResetConfirm.tryReset();
            }, title: "Transaction Status" }),
        confetti && (React.createElement(Confetti, { width: width, height: height, numberOfPieces: 50 })),
        React.createElement(SFlexbox, { flex: "1" },
            React.createElement(SFlexbox, { flexDirection: "column", justifyContent: "space-between", borderRadius: "18px", backgroundColor: theme.colors.bg2, m: "0 auto" },
                React.createElement(OMedia, { p: "40px 10px 8px 10px" },
                    React.createElement(SFlexbox, { flex: "1", alignItems: "center", flexDirection: "column", justifyContent: "space-between" },
                        React.createElement(SText, { type: "h5", fontWeight: "semibold", textAlign: "center" },
                            "Order ID : ",
                            partnerOrderId),
                        React.createElement(SText, { type: "h5", textAlign: "center" }, "Your tokens can take between 5 min to 24 hours to arrive. This tab can now be closed."))),
                React.createElement(SSeparator, null),
                React.createElement(TransactionRoute, { fiatAmount: fiatAmount, fiatSymbol: fiatSymbol, fiatToTokenRate: fiatToTokenRate, selectedToken: selectedToken, refuelAmount: refuelAmount, isTransakSupported: isTransakSupported }),
                React.createElement(SSeparator, null),
                React.createElement(OMedia, { mt: "20px", px: "18px" },
                    React.createElement(SFlexbox, { width: "100%", alignItems: "center", gap: "18px" },
                        React.createElement(OReactToPdf, { filename: "ondefy-transaction-receipt.pdf" }, ({ toPdf, targetRef }) => (React.createElement(React.Fragment, null,
                            React.createElement(OButton, { variant: "quaternary", iconRight: React.createElement(OIcon, { name: "download" }), size: "sm", disabled: transactionStatus.status !== EEntityStatus.success, onClick: () => {
                                    // ReactPDF.renderToFile(<TransactionPdf />);
                                    // ReactPDF.renderToStream(<TransactionPdf />);
                                    toPdf();
                                } },
                                React.createElement(SText, { color: "inherit" }, "PDF Receipt")),
                            React.createElement(SBox, { position: "absolute", top: "-9999px", left: "0", bg: "bg2", width: "794px" },
                                React.createElement(TransactionReceipt, { orderId: partnerOrderId, fiatAmount: fiatAmount, fiatSymbol: fiatSymbol, fiatToTokenRate: fiatToTokenRate, selectedToken: selectedToken, refuelAmount: refuelAmount, ref: targetRef, isTransakSupported: isTransakSupported }))))),
                        React.createElement(ButtonOnly, { size: "sm", text: "View your assets", onClick: () => window.open('https://app.ondefy.com/wallet/') }))),
                React.createElement(SFlexbox, { justifyContent: "center", pb: "18px" },
                    React.createElement(PoweredBy, null))))));
};
