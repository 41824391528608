import { filter, connect, merge, map } from 'rxjs';
import { EPages } from '../../__common__/constants';
import { ofValue } from '../../__common__/rxjs-utils/of-value';
import { selectPage } from '../../__common__/selectors';
import { selectBestSwapRate, selectSwapRates } from '../selectors';
import { getAllowance, getGasFee } from '../slice';
export const epicOnBestSwapRateChange = (action$, state$) => ofValue(state$, selectBestSwapRate).pipe(filter(() => {
    const { value } = state$;
    const page = selectPage(value);
    if (page === EPages.swapResult) {
        return false;
    }
    return selectSwapRates(value).isLoaded;
}), connect(shared$ => merge(shared$.pipe(map(v => {
    return getAllowance();
})), shared$.pipe(filter(() => {
    const page = selectPage(state$.value);
    return page === EPages.swapUnlock || page === EPages.swapResult;
}), 
// don't call for zyfi
map(() => {
    return getGasFee();
})))));
