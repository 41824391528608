import { OButton, OIcon, OInput, OMedia, SFlexbox, SText } from '@ondefy/ondefy-ui';
import React, { useState } from 'react';
import { ENV } from '../../../../../../env';
import { api1InchError } from '../../../../../__common__/api/integrations/1inch';
import { apiOdosError } from '../../../../../__common__/api/integrations/odos';
import { Alert } from '../../../../../__common__/components/alert';
import { EQUAL_ROUND_SYMBOL } from '../../../../../__common__/constants';
import { selectBestSwapRate, selectSlippage, selectSourceTokenIdSwapIsEthereum, selectSwapRates, selectZyFiGeneralTx } from '../../../../selectors';
import { setSlippage } from '../../../../slice';
import { initialState } from '../../../../state';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { SDetails } from '../../../../../__common__/styled/s-details';
import _ from 'lodash/fp';
import { ZyfiTxFee } from '../../../__components__/zyfi-tx-fee';
import { ZyfiWarningPrice } from '../6-zyfi-warning-price';
export const TargetTokenDetails = () => {
    const slippage = useAppSelector(selectSlippage);
    const dispatch = useAppDispatch();
    const [focused, setFocused] = useState(false);
    const [opened, setOpened] = useState(false);
    const bestRate = useAppSelector(selectBestSwapRate);
    const swapRates = useAppSelector(selectSwapRates);
    const toAmountUsdFormatted = _.path('toAmountUsdFormatted', bestRate);
    const gasFeeUsdFormatted = _.path('gasFeeUsdFormatted', bestRate);
    const valueLoss = _.path('valueLossFormatted', bestRate);
    const isValueLossSmall = _.path('isValueLossSmall', bestRate);
    const isValueGain = _.path('isValueGain', bestRate);
    const isEthereumSource = useAppSelector(selectSourceTokenIdSwapIsEthereum);
    const generalTx = useAppSelector(selectZyFiGeneralTx);
    return (React.createElement(SDetails, null,
        bestRate && (React.createElement(OMedia, { alignItems: "center", pt: "8px", px: "8px", className: 'ondefy__validations-inner ondefy__validations-inner--visible' },
            React.createElement(React.Fragment, null,
                toAmountUsdFormatted && (React.createElement(OMedia, { alignItems: "center", position: "relative" },
                    React.createElement(SText, { type: "small", mb: "0", opacity: "0.7" },
                        EQUAL_ROUND_SYMBOL,
                        " ",
                        toAmountUsdFormatted))),
                ENV.isZyfi() && React.createElement(ZyfiTxFee, { pl: "16px" }),
                ENV.isOndefy() && gasFeeUsdFormatted && (React.createElement(OMedia, { alignItems: "center", px: "16px" },
                    React.createElement(OIcon, { name: "fuel", mr: "4px", color: "accent", size: 14 }),
                    React.createElement(SText, { type: "small", opacity: "0.7" }, gasFeeUsdFormatted))),
                ENV.isOndefy() && valueLoss && !isValueLossSmall && (React.createElement(OMedia, { alignItems: "center", gap: "4px" },
                    React.createElement(OIcon, { name: isValueGain ? 'arrow-up' : 'arrow-down', color: isValueGain ? 'accent' : 'error', size: 14 }),
                    React.createElement(SText, { type: "small", color: isValueGain ? 'accent' : 'error', opacity: "0.7" }, valueLoss),
                    React.createElement(SText, { type: "small", mr: "4px", opacity: "0.7" }, isValueGain ? 'Gain' : 'Loss')))))),
        ENV.isZyfi() && !generalTx.isLoading && !swapRates.isLoading && React.createElement(ZyfiWarningPrice, null),
        React.createElement(OMedia, { alignItems: "center", p: "8px", justifyContent: "space-between" },
            !opened && (React.createElement(SFlexbox, { alignItems: "center" },
                React.createElement(SText, { type: "small", opacity: "0.7" },
                    slippage,
                    "% Slippage"),
                React.createElement(OButton, { variant: "secondary", icon: React.createElement(OIcon, { name: "pencil" }), size: "sm", ml: "8px", onClick: () => {
                        setOpened(true);
                    } }))),
            opened && (React.createElement(SFlexbox, { alignItems: ENV.isZyfi() && isEthereumSource ? 'flex-start' : 'center', flexDirection: ENV.isZyfi() && isEthereumSource ? 'column' : 'row' },
                React.createElement(SText, { type: "small", mb: ENV.isZyfi() && isEthereumSource ? '12px' : 0, mr: "8px", opacity: "0.7" }, "Slippage"),
                React.createElement(SFlexbox, { gap: "8px" },
                    React.createElement(OButton, { size: "sm", variant: !focused && slippage === 0.5 ? 'tertiary' : 'secondary', onClick: () => {
                            dispatch(setSlippage(0.5));
                        } }, "0.5%"),
                    React.createElement(OButton, { size: "sm", variant: !focused && slippage === 1 ? 'tertiary' : 'secondary', onClick: () => {
                            dispatch(setSlippage(1));
                        } }, "1%"),
                    React.createElement(OButton, { size: "sm", variant: !focused && slippage === 3 ? 'tertiary' : 'secondary', onClick: () => {
                            dispatch(setSlippage(3));
                        } }, "3%"),
                    React.createElement(OInput, { size: "sm", width: "74px", type: "number", placeholder: "Custom", value: (focused || ![0.5, 1, 3].includes(slippage)) && slippage, onBlur: () => {
                            setFocused(false);
                            if (!slippage) {
                                return dispatch(setSlippage(initialState.slippage));
                            }
                        }, onChange: (value) => {
                            let normalizedValue = Math.abs(parseFloat(value));
                            if (normalizedValue > 50) {
                                normalizedValue = 50;
                            }
                            setFocused(true);
                            dispatch(setSlippage(normalizedValue));
                        } }))))),
        !generalTx.isLoading && !swapRates.isLoading && api1InchError.value && (React.createElement(Alert, { variant: "error" }, "No liquidity for this swap, please try a different token")),
        !generalTx.isLoading && !swapRates.isLoading && apiOdosError.value && (React.createElement(Alert, { variant: "error" }, "Routing unavailable for token"))));
};
