import axios from 'axios';
import { of, switchMap, throwError, zip } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiObservable } from '../../api-observable/api-observable';
import { ONDEFY_WALLET } from '../../constants';
import { ENetworkIdByName } from '../../constants/networks';
import { resolvePath } from '../../transducers/resolve-path';
import qs from 'qs';
import { ESwappers, SWAPPER_NAME_BY_IDX } from '../../constants/contracts';
import { getGasFeeFor1inch } from './transducers/get-gas-fee-for-1inch';
import { signal } from '@preact/signals-react';
// contact the 1inch proxy on the back-end server
const apirx1Inch = ApiObservable.from(axios.create({
    baseURL: resolvePath(process.env.BACKEND_SERVER_URL, `/proxy/1inch/swap/v5.2`)
}));
export const api1InchError = signal(null);
function apiRequestUrl(networkId, methodName, queryParams) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `/${networkId}/${methodName}?${qs.stringify(queryParams)}`;
}
export function get1inchSwapDataRx({ networkId, usingOndefyRouter, fromAddress, destReceiver, srcToken, destToken, amount, slippage }) {
    const swapParams = {
        src: srcToken,
        dst: destToken,
        amount,
        from: fromAddress || ONDEFY_WALLET,
        receiver: destReceiver || ONDEFY_WALLET,
        slippage,
        disableEstimate: true,
        allowPartialFill: false,
        // referrer: ONDEFY_WALLET, // Uncomment if referrer is needed
        // fee: ONE_INCH_FEE_PRCT // Uncomment if fee is needed
        // Add new optional query params as per migration guide
        includeTokensInfo: true,
        includeProtocols: true,
        includeGas: true
    };
    // Some faulty liquidity sources are removed here for some chains : (in particular 1inch limit order V3 and Maverick)
    // Lists of liquidity sources are hardcoded instead of fetched dynamically and filtered, to prevent 1inch api's rate limit.
    if (networkId === ENetworkIdByName.zksync) {
        // list to update regularly
        //   swapParams.protocols =
        //     'ZKSYNC_MUTE,ZKSYNC_PMMX,ZKSYNC_SPACEFI,ZKSYNC_SYNCSWAP,ZKSYNC_GEM,ZKSYNC_WOOFI_V2,ZKSYNC_PANCAKESWAP_V3'
    }
    if (networkId === ENetworkIdByName['polygon-pos']) {
        // list to update regularly
        swapParams.protocols =
            'POLYGON_QUICKSWAP,POLYGON_CURVE,POLYGON_SUSHISWAP,POLYGON_AAVE_V2,COMETH,DFYN,POLYGON_MSTABLE,FIREBIRD_FINANCE,ONESWAP,POLYDEX_FINANCE,POLYGON_ONE_INCH_LIMIT_ORDER,POLYGON_ONE_INCH_LIMIT_ORDER_V2,POLYGON_WAULTSWAP,POLYGON_BALANCER_V2,POLYGON_KYBER_DMM,POLYGON_DODO,POLYGON_DODO_V2,POLYGON_JETSWAP,IRONSWAP,POLYGON_UNIFI,POLYGON_DFX_FINANCE,POLYGON_APESWAP,POLYGON_SAFE_SWAP,POLYCAT_FINANCE,POLYGON_CURVE_V2,POLYGON_UNISWAP_V3,POLYGON_ELK,POLYGON_SYNAPSE,POLYGON_GRAVITY,POLYGON_NERVE,POLYGON_DYSTOPIA,POLYGON_RADIOSHACK,POLYGON_MESHSWAP,POLYGON_KYBERSWAP_ELASTIC,POLYGON_WOOFI,POLYGON_MAVERICK,POLYGON_CLIPPER_COVES,POLYGON_SWAAP,MM_FINANCE,POLYGON_PMM8,POLYGON_KYBER_DMM_STATIC,POLYGON_AAVE_V3,POLYGON_QUICKSWAP_V3,POLYGON_ZK_BOB,POLYGON_TRIDENT,POLYGON_WOOFI_V2,POLYGON_DFX_FINANCE_V2,POLYGON_SATIN,POLYGON_SATIN_4POOL,POLYGON_METAVAULT_TRADE,POLYGON_NOMISWAPEPCS,POLYGON_PEARL,POLYGON_SUSHISWAP_V3,POLYGON_RETRO,POLYGON_PMM11';
    }
    const quoteReq = apiRequestUrl(networkId, 'quote', swapParams);
    const swapReq = apiRequestUrl(networkId, 'swap', swapParams);
    return zip(apirx1Inch.get(quoteReq), apirx1Inch.get(swapReq)).pipe(tap(() => {
        api1InchError.value = null;
    }), switchMap(([quoteResData, swapResData]) => {
        var _a;
        if (!(swapResData === null || swapResData === void 0 ? void 0 : swapResData.toAmount) || !((_a = swapResData === null || swapResData === void 0 ? void 0 : swapResData.tx) === null || _a === void 0 ? void 0 : _a.data)) {
            return throwError(() => new Error('invalid response from 1inch'));
        }
        // Output the protocols used for this swap
        quoteResData === null || quoteResData === void 0 ? void 0 : quoteResData.protocols.forEach(outerArray => {
            console.log('Protocols used for this swap:');
            outerArray.forEach(innerArray => {
                innerArray.forEach(protocol => {
                    console.log(`${protocol.name} - ${protocol.part}%`);
                });
            });
        });
        const { gasEstimate, gasLimit } = getGasFeeFor1inch(networkId, quoteResData, usingOndefyRouter);
        const result = {
            swapperIdx: ESwappers.ONE_INCH_SWAPPER_IDX,
            swapperName: SWAPPER_NAME_BY_IDX[ESwappers.ONE_INCH_SWAPPER_IDX],
            // extra fields
            networkId,
            fromTokenAddress: srcToken,
            fromAmount: amount,
            toTokenAddress: destToken,
            toAmount: swapResData.toAmount,
            gasEstimate,
            gasLimit
        };
        // for display only, not used in an actual swap
        if (!fromAddress) {
            return of(result);
        }
        /* note : only relevant if we want to broadcast transactions to our router contract from here
        const callData = getCallData({
          srcToken,
          destToken,
          data: swapResData.tx.data,
          amount,
          swapperIndex: ESwappers.ONE_INCH_SWAPPER_IDX,
          network: NETWORKS_DATA[networkId]
        })
  
        // else return all data needed for swap
        if (callData) {
          return of({
            ...result,
            ...callData
          })
        }
        */
        return of(Object.assign(Object.assign({}, result), { callData: swapResData.tx.data, contractAddress: swapResData.tx.to, spenderAddress: swapResData.tx.to }));
    }), catchError(e => {
        console.error('get1inchSwapData', e);
        if (e.description === 'insufficient liquidity') {
            api1InchError.value = e.description;
        }
        return of(null);
    }));
}
