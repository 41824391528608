import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { selectUserAddress } from '../selectors';
import { flush } from '../../__common__/slice';
export const epicOnWalletDisconnect = (action$, state$) => {
    return state$.pipe(map(selectUserAddress), distinctUntilChanged(), filter(v => {
        return !v;
    }), map(() => {
        return flush();
    }));
};
