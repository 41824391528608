import { ENetworkIdByName } from '../../../constants/networks';
import { GAS_OVERHEAD_SWAP_ROUTER, GAS_OVERHEAD_SWAP_ROUTER_ARBITRUM } from '../constants/gas-fee-estimate';
// TODO: add unit tests!!!
// TODO: add unit tests!!!
// TODO: add unit tests!!!
export const getGasFeeFor0x = (networkId, resData, usingOndefyRouter) => {
    let gasEstimate = Number(resData.estimatedGas);
    let gasLimit = Number(resData.gas) * 1.3;
    if (gasEstimate > 0 && usingOndefyRouter) {
        gasEstimate +=
            networkId === ENetworkIdByName['arbitrum-one'] ? GAS_OVERHEAD_SWAP_ROUTER_ARBITRUM : GAS_OVERHEAD_SWAP_ROUTER;
        gasLimit +=
            (networkId === ENetworkIdByName['arbitrum-one'] ? GAS_OVERHEAD_SWAP_ROUTER_ARBITRUM : GAS_OVERHEAD_SWAP_ROUTER) *
                1.3;
    }
    return {
        gasEstimate,
        gasLimit
    };
};
