import { useMount } from 'react-use';
import { EPages } from '../../../__common__/constants';
import { setPage } from '../../../__common__/slice';
import { useUserWallet } from '../../../wallet/hooks/use-user-wallet';
import { useGasFee } from '../../hooks/use-gas-fee';
import { selectSwapTransaction } from '../../selectors';
import { swapTransaction as runSwapTransaction, swapTransactionReset, updateSwapperRate } from '../../slice';
import { Analytics } from '../../../analytics';
import { selectIsWalletConnected } from '../../../wallet/selectors';
import { HeaderNavBack } from '../../../__common__/components/header-with-breadcrumbs/header-nav-back';
import { MainButton } from '../../../__common__/components/main-button';
// import { usePaperResetConfirm } from '../../../papers/paper-reset-confirm/__hooks__/use-paper-reset-confirm'
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useGetTokenContracts } from '../../../__common__/hooks/use-get-token-contracts';
import { TransactionDetails } from '../__components__/transaction-details';
import { SScreenInner } from './__styled__/s-screen-inner';
export const ScreenOverview = () => {
    const isWalletConnected = useAppSelector(selectIsWalletConnected);
    const dispatch = useAppDispatch();
    useUserWallet();
    useGetTokenContracts();
    useMount(() => {
        dispatch(swapTransactionReset());
        dispatch(updateSwapperRate());
    });
    // const paperResetConfirm = usePaperResetConfirm()
    const gasFee = useGasFee();
    const swapTransaction = useAppSelector(selectSwapTransaction);
    return (React.createElement(SScreenInner, null,
        React.createElement(HeaderNavBack, { title: "Swap overview", onBack: () => {
                dispatch(setPage(EPages.init));
            } }),
        React.createElement(TransactionDetails, null),
        React.createElement(MainButton, { disabled: !isWalletConnected || gasFee.isError || gasFee.isLoading || swapTransaction.isLoading, onClick: () => {
                dispatch(runSwapTransaction());
                Analytics.swapTriggered();
            } }, "Swap")));
};
