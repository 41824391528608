import { useContext } from 'react';
import { PaperStackContext } from '../context';
export const usePaper = (id) => {
    const { papers, setPapers } = useContext(PaperStackContext);
    return {
        isOpened: papers[id],
        setIsOpened: (v) => {
            setPapers((o) => (Object.assign(Object.assign({}, o), { [id]: v })));
        },
        id
    };
};
