import { EFontSizes, SFlexbox, styled } from '@ondefy/ondefy-ui';
export const SZyfiFeeToken = styled(SFlexbox) `
  max-width: 105px;
  padding: 4px;
  padding-right: 8px;
  flex-direction: column;
  align-items: flex-end;

  small {
    font-size: ${EFontSizes.bodySMobile};
  }

  small + button {
    height: auto;
    padding: 8px;
  }
`;
