import { Numbo } from '../../__common__/numbo/transducers';
import { EEntityStatus } from '../../../redux/redux-entity';
import _ from 'lodash/fp';
/// TODO - add try catch
export const checkIfEnoughSourceBalance = (userWallet, sourceToken, sourceTokenAmount, feeToken, generalTxZyfi) => {
    if (userWallet.status === EEntityStatus.idle || !sourceToken || sourceTokenAmount === '' || !generalTxZyfi.value) {
        return undefined;
    }
    const sourceTokenInUserWallet = _.path(['value', 'entities', sourceToken.cgKey, 'assetsAssoc', sourceToken.id], userWallet);
    const feeTokenInUserWallet = _.path(['value', 'entities', feeToken.cgKey, 'assetsAssoc', feeToken.id], userWallet);
    if (!sourceTokenInUserWallet) {
        return false;
    }
    const tokenValueInUserWallet = Numbo.floatToBigNumber(_.path('amount', sourceTokenInUserWallet), _.path('decimals', sourceTokenInUserWallet));
    const sourceTokenAmountDesired = Numbo.floatToBigNumber(sourceTokenAmount, _.path('decimals', sourceTokenInUserWallet));
    if (_.isEqual(feeTokenInUserWallet, feeTokenInUserWallet)) {
        const feeTokenAmountRequired = Numbo.from(generalTxZyfi.value.feeTokenAmount); // fee amount is bigint 18000000...
        return tokenValueInUserWallet.add(feeTokenAmountRequired).gte(sourceTokenAmountDesired);
    }
    return tokenValueInUserWallet.gte(sourceTokenAmountDesired);
};
