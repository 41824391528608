import { SBox, styled } from '@ondefy/ondefy-ui';
export const SMenu = styled(SBox) `
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 50px);

  .dropdown-menu__header {
    padding: 5px 8px 10px;
  }

  .menu__network-box {
    align-items: center;

    > .menu__network-box-title {
      padding-left: 8px;
    }

    > .menu__network-box-dropdown {
      justify-content: space-between;
    }
  }

  .dropdown__menu-number {
    padding-left: 6px;
    padding-right: 6px;
    height: 30px;
    border-radius: 8px;
    background-color: ${p => (p.theme.id === 'light' ? p.theme.colors.bg : p.theme.colors.bg2)};
  }

  .dropdown__action {
    margin-left: 4px;

    span,
    i {
      color: ${p => p.theme.colors.typo};
    }

    &:hover {
      span,
      i {
        color: ${p => p.theme.colors.accent};
      }
    }
  }

  .dropdown-menu__link--theme {
    align-items: center;
    justify-content: left;
    cursor: default;

    > div {
      cursor: pointer;
    }
  }

  .dropdown-menu__link--theme label {
    pointer-events: none;
  }

  a {
    width: 100%;
  }
`;
