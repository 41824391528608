import { Numbo } from '../../__common__/numbo/transducers';
// TODO: write unit tests!!!
// TODO: write unit tests!!!
// TODO: write unit tests!!!
export const formatTokenAmountWithDecimals = (amount, token) => {
    if (!token || amount === '') {
        return;
    }
    let res;
    try {
        res = Numbo.parseUnits(Numbo.toFixedBig(String(amount), token.decimals), token.decimals).toString();
    }
    catch (e) {
        console.log('ERROR formatTokenAmountWithDecimals', { amount, decimals: token.decimals });
    }
    // TODO
    return res;
};
