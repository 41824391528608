import _ from 'lodash/fp';
import { of, switchMap, throwError, zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { checkIfNetworkIsValid } from '../../transducers/check-iIf-network-is-valid';
import { DEFAULT_SWAP_SLIPPAGE } from '../../../__common__/constants/contracts';
import { NETWORK_ID_TO_AVERAGE_SWAP_GAS_USAGE, NETWORK_ID_TO_MAX_GAS_PRICE_GWEI, NETWORKS_DATA } from '../../../__common__/constants/networks';
import { findSupportedToken } from '../../transducers/find-supported-token';
import { getParseUnits } from '../../transducers/get-parse-units';
import { getBestQuoteRx } from './get-best-quote';
import { getCoinPriceUsdRx } from './get-coin-price-usd';
import { getCoingeckoTokenPriceRx } from './get-coingecko-token-price';
import { getGasPriceGweiRx } from './get-gas-price-gwei';
import { getTransakOnRampRateRx } from '../integrations/transak';
export const getFiatToTokenRateRx = ({ transakCyptocurrencies, fiatSymbol, fiatAmount, tokenId, tokenAddress, tokenDecimals, refuelAmount, networkId }) => {
    // check if network is supported
    if (!checkIfNetworkIsValid(networkId)) {
        return throwError(() => new Error('Please switch to a supported network'));
    }
    // check if token is supported by Transak
    const transakToken = findSupportedToken(transakCyptocurrencies, networkId, tokenAddress, fiatSymbol);
    const isTransakSupported = !!transakToken;
    // console.log('1. start')
    /**
     * - first you check if desired token is supported by Transak -> instanciate isTransakSupported
     * - then you have 2 cases :
     * - if isTransakSupported === true you just fetch transak rate and that’s it
     * - if isTransakSupported === false you need fetch transak rate,
     *   our fee that includes swap gas cost (so you need to estimate tx fee in usd)
     *   and swap rate : fiatAmount -> Transak -> some USDC amount (minus our fee) -> swap -> desired token
     */
    if (isTransakSupported) {
        // console.log('2. rates for transak')
        // return directly Transak rate
        return zip(getTransakOnRampRateRx({
            fiatSymbol,
            fiatAmount,
            tokenSymbol: transakToken.symbol,
            networkId
        }), getCoingeckoTokenPriceRx({ tokenId, fiatSymbol })).pipe(switchMap(([transakRate, tokenPriceInFiat]) => {
            if (!_.path('buyAmount', transakRate)) {
                return throwError(() => new Error('invalid response from Transak'));
            }
            return of({
                buyAmount: transakRate.buyAmount.toString(),
                buyAmountInFiatCurrency: (transakRate.buyAmount * _.path([fiatSymbol.toLowerCase()], tokenPriceInFiat)).toFixed(2),
                isTransakSupported,
                valueLoss: 0
            });
        }));
    }
    // console.log('2. rates for NOT transak')
    // return combination of Transak and swap rates
    const inputToken = NETWORKS_DATA[networkId].ftatInputToken;
    return zip(getGasPriceGweiRx(networkId), getCoinPriceUsdRx(networkId)).pipe(switchMap(([gasPrice, coinPriceUsd]) => {
        // console.log('3. after gas price')
        // estimate swap tx fee
        const gasPriceMin = Math.min(gasPrice, NETWORK_ID_TO_MAX_GAS_PRICE_GWEI[networkId]);
        if (!gasPriceMin || !coinPriceUsd) {
            return throwError(() => Error('failed to fetch gasPrice or coinPrice'));
        }
        const gasUsageEstimate = NETWORK_ID_TO_AVERAGE_SWAP_GAS_USAGE[networkId];
        const extimatedTxCostInUsd = (gasPriceMin * gasUsageEstimate * coinPriceUsd) / 1e9;
        const inputFee = Number(Math.max(extimatedTxCostInUsd, 1));
        // console.log('4. get transak rate')
        // get Transak rate
        return zip(getTransakOnRampRateRx({
            fiatSymbol,
            fiatAmount,
            tokenSymbol: inputToken.symbol,
            networkId
        }), getCoingeckoTokenPriceRx({ tokenId, fiatSymbol })).pipe(switchMap(([transakRate, tokenPriceInFiat]) => {
            // console.log('5. transak rate finish')
            if (!_.path('buyAmount', transakRate)) {
                return throwError(() => Error('invalid response from Transak'));
            }
            if (!(inputFee < Number(transakRate.buyAmount))) {
                return throwError(() => new Error('amount is unsufficient to cover fees'));
            }
            const refuelAmountUsd = Number(refuelAmount) * coinPriceUsd || 0;
            // get swap rate
            const transakBuyAmountAdjusted = (Number(transakRate.buyAmount) - inputFee - refuelAmountUsd).toFixed(inputToken.decimals);
            if (!(parseFloat(transakBuyAmountAdjusted) > 0)) {
                return throwError(() => new Error('amount is unsufficient to cover fees and refuel'));
            }
            // console.log('6. get best quote')
            return getBestQuoteRx({
                networkId,
                srcToken: inputToken.contractAddress,
                destToken: tokenAddress,
                amountMinusFee: getParseUnits(transakBuyAmountAdjusted, inputToken.decimals),
                slippage: DEFAULT_SWAP_SLIPPAGE,
                srcDecimals: inputToken.decimals,
                destDecimals: tokenDecimals
            }).pipe(switchMap(swapRate => {
                // console.log('7. finish get best quote')
                if (!swapRate) {
                    return throwError(() => new Error('no liquidity was found for this token'));
                }
                // TODO: move to transducer and add unit test
                // check if obtained swap rate is in line with public token price. 'inputToken' needs to be usd stablecoin for this to work.
                const valueLoss = 1 - (parseFloat(swapRate) * _.path('usd', tokenPriceInFiat)) / Number(transakBuyAmountAdjusted);
                // console.log('8. return bet quote')
                return of({
                    buyAmount: swapRate,
                    // TODO: use typesafe type conversion
                    intermediateBuyAmount: transakRate.buyAmount.toString(),
                    isTransakSupported,
                    inputFee: inputFee.toFixed(2),
                    // TODO: it will not work for currency not USD or EUR not in GEL as tokenPriceInFiat = {usd: string, eur?: string}
                    buyAmountInFiatCurrency: (parseFloat(swapRate) * _.path([fiatSymbol.toLowerCase()], tokenPriceInFiat)).toFixed(2),
                    refuelAmountUsd,
                    valueLoss
                });
            }), catchError(e => {
                // console.log('9. error of best quote get')
                return throwError(() => new Error(e.message));
            }));
        }));
    }));
};
