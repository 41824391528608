// import { EEntityStatus } from '../../redux/redux-entity'
import { ENV } from '../../env';
import { ReduxEntity } from '../../redux/redux-entity';
import { ENetworkIdByName } from '../__common__/constants/networks';
import { ETransactionStatus } from '../__common__/constants/transaction';
import { Numbo } from '../__common__/numbo/transducers';
import { initialState } from './state';
import { isEtherToken } from '../__common__/transducers/is-ether-token';
import { formatTargetTokenAmount } from './transducers/format-target-token-amount';
import { getBestSwapRate } from './transducers/get-best-swap-rate';
// import { initialState } from './constants/initial-state'
const resetOnChange = (state) => {
    state.swapRates = initialState.swapRates;
    state.gasFees = initialState.gasFees;
    state.allowance = initialState.allowance;
    state.selectedSwapperIndex = initialState.selectedSwapperIndex;
};
export const restartBestRatesPolling = (state) => { };
export const setSourceTokenAmount = (state, { payload }) => {
    state.sourceTokenAmount = Numbo.sanitizeFloatValue(payload);
    resetOnChange(state);
};
export const setSourceTokenIdSwap = (state, { payload }) => {
    state.sourceTokenIdSwap = payload;
    if (isEtherToken(payload)) {
        state.feeTokenIdSwap = payload;
    }
    resetOnChange(state);
};
export const setTargetTokenIdSwap = (state, { payload }) => {
    state.targetTokenIdSwap = payload;
    resetOnChange(state);
};
export const setFeeTokenIdSwap = (state, { payload }) => {
    state.zyfiGeneralTx = initialState.zyfiGeneralTx;
    state.feeTokenIdSwap = payload;
};
export const swapTokens = (state) => {
    const { sourceTokenIdSwap, targetTokenIdSwap } = state;
    state.targetTokenIdSwap = sourceTokenIdSwap;
    state.sourceTokenIdSwap = targetTokenIdSwap;
    const bestRate = getBestSwapRate(state.swapRates, state.selectedSwapperIndex);
    const targetTokenAmount = formatTargetTokenAmount(bestRate);
    state.sourceTokenAmount = targetTokenAmount;
    resetOnChange(state);
};
export const abortPending = (state) => {
    state.swapRates = initialState.swapRates;
    state.gasFees = initialState.gasFees;
    state.allowance = initialState.allowance;
    state.gasFee = initialState.gasFee;
    state.zyfiTokenApprovalTx = initialState.zyfiTokenApprovalTx;
    state.zyfiGeneralTx = initialState.zyfiGeneralTx;
};
export const flush = (state) => {
    return Object.assign(Object.assign({}, initialState), { pendingTransactions: state.pendingTransactions, isIframe: state.isIframe, slippage: state.slippage, networkIdTargetSwap: ENV.isZyfi()
            ? ENetworkIdByName.zksync
            : state.isIframe
                ? state.networkIdTargetSwap
                : initialState.networkIdTargetSwap, networkIdSourceSwap: ENV.isZyfi()
            ? ENetworkIdByName.zksync
            : state.isIframe
                ? state.networkIdSourceSwap
                : initialState.networkIdSourceSwap, networkIdFeeSwap: ENV.isZyfi()
            ? ENetworkIdByName.zksync
            : state.isIframe
                ? state.networkIdFeeSwap
                : initialState.networkIdFeeSwap, targetTokenIdSwap: ENV.isZyfi()
            ? state.targetTokenIdSwap
            : state.isIframe
                ? state.targetTokenIdSwap
                : initialState.targetTokenIdSwap });
};
export const reset = (state) => {
    return Object.assign(Object.assign({}, initialState), { pendingTransactions: state.pendingTransactions, isIframe: state.isIframe, slippage: state.slippage, networkIdTargetSwap: ENV.isZyfi()
            ? ENetworkIdByName.zksync
            : state.isIframe
                ? state.networkIdTargetSwap
                : initialState.networkIdTargetSwap, networkIdSourceSwap: ENV.isZyfi()
            ? ENetworkIdByName.zksync
            : state.isIframe
                ? state.networkIdSourceSwap
                : initialState.networkIdSourceSwap, networkIdFeeSwap: ENV.isZyfi()
            ? ENetworkIdByName.zksync
            : state.isIframe
                ? state.networkIdFeeSwap
                : initialState.networkIdFeeSwap, targetTokenIdSwap: ENV.isZyfi()
            ? state.targetTokenIdSwap
            : state.isIframe
                ? state.targetTokenIdSwap
                : initialState.targetTokenIdSwap });
};
export const setSlippage = (state, { payload }) => {
    state.slippage = payload;
    resetOnChange(state);
};
export const setPercentage = (state, { payload }) => {
    state.percentage = payload;
};
export const setSelectedSwapperIndex = (state, { payload }) => {
    state.selectedSwapperIndex = payload;
};
export const getSwapRates = (state) => {
    ReduxEntity.loading(state.swapRates);
};
export const getSwapRatesRejected = (state, action) => {
    ReduxEntity.error(state.swapRates, action);
};
export const getSwapRatesFullfilled = (state, action) => {
    ReduxEntity.success(state.swapRates, action);
};
export const getGasFees = (state) => {
    ReduxEntity.loading(state.gasFees);
};
export const getGasFeesRejected = (state, action) => {
    ReduxEntity.error(state.gasFees, action);
};
export const getGasFeesFullfilled = (state, action) => {
    ReduxEntity.success(state.gasFees, action);
};
export const setNetworkIdSourceSwap = (state, { payload }) => {
    state.networkIdSourceSwap = payload;
    // FOR now we support only swap within one network - therefore I will flush all the data from other network on select
    if (state.networkIdTargetSwap !== payload) {
        state.targetTokenIdSwap = '';
    }
    state.networkIdTargetSwap = payload;
};
export const setNetworkIdTargetSwap = (state, { payload }) => {
    state.networkIdTargetSwap = payload;
    // FOR now we support only swap within one network - therefore I will flush all the data from other network on select
    if (state.networkIdSourceSwap !== payload) {
        state.sourceTokenIdSwap = '';
    }
    state.networkIdSourceSwap = payload;
};
export const setNetworkIdFeeSwap = (state, { payload }) => {
    state.networkIdTargetSwap = payload;
    // FOR now we support only swap within one network - therefore I will flush all the data from other network on select
    if (state.networkIdFeeSwap !== payload) {
        state.feeTokenIdSwap = '';
    }
    state.networkIdFeeSwap = payload;
};
export const getAllowance = (state) => {
    ReduxEntity.loading(state.allowance);
};
export const getAllowanceRejected = (state, action) => {
    ReduxEntity.error(state.allowance, action);
};
export const getAllowanceFullfilled = (state, action) => {
    ReduxEntity.success(state.allowance, action);
};
export const getGasFee = (state) => {
    ReduxEntity.loading(state.gasFee);
};
export const getGasFeeRejected = (state, action) => {
    ReduxEntity.error(state.gasFee, action);
};
export const getGasFeeFullfilled = (state, action) => {
    ReduxEntity.success(state.gasFee, action);
};
export const swapTransaction = (state) => {
    ReduxEntity.loading(state.swapTransaction);
};
export const swapTransactionReset = (state) => {
    ReduxEntity.reset(state.swapTransaction);
};
export const swapTransactionRejected = (state, action) => {
    ReduxEntity.error(state.swapTransaction, action);
};
export const swapTransactionFullfilled = (state, action) => {
    ReduxEntity.success(state.swapTransaction, action);
};
export const unlockTransactionReset = (state) => {
    ReduxEntity.reset(state.unlockTransaction);
};
export const unlockTransaction = (state) => {
    ReduxEntity.loading(state.unlockTransaction);
};
export const unlockTransactionRejected = (state, action) => {
    ReduxEntity.error(state.unlockTransaction, action);
};
export const unlockTransactionFullfilled = (state, action) => {
    ReduxEntity.success(state.unlockTransaction, action);
};
export const saveTransaction = (state, { payload }) => {
    if (payload.status !== ETransactionStatus.pending) {
        // remove completed
        state.pendingTransactions = state.pendingTransactions.filter(transaction => {
            return transaction.txHash !== payload.txHash;
        });
        return;
    }
    if (!state.pendingTransactions.find(transaction => transaction.txHash === payload.txHash)) {
        // add new
        state.pendingTransactions.push(payload);
    }
};
export const saveTransactionComplete = (state, { payload }) => {
    // TODO: test if it works properly
    // TODO: test if it works properly
    // TODO: test if it works properly
    state.pendingTransactions = state.pendingTransactions.filter(transaction => {
        return transaction.txHash !== payload.txHash;
    });
};
export const updateSwapperRate = (state) => {
    ReduxEntity.loading(state.swapRates);
};
export const updateSwapperRateFullfilled = (state, action) => {
    ReduxEntity.success(state.swapRates, action);
};
export const updateSwapperRateRejected = (state, action) => {
    ReduxEntity.error(state.swapRates, action);
};
export const setIframeMode = (state, { payload }) => {
    state.isIframe = payload;
};
export const getZyfiTokenApprovalTx = (state) => {
    ReduxEntity.loading(state.zyfiTokenApprovalTx);
};
export const getZyfiTokenApprovalTxRejected = (state, action) => {
    ReduxEntity.error(state.zyfiTokenApprovalTx, action);
};
export const getZyfiTokenApprovalTxFullfilled = (state, action) => {
    ReduxEntity.success(state.zyfiTokenApprovalTx, action);
};
export const getZyfiGeneralTx = (state) => {
    ReduxEntity.loading(state.zyfiGeneralTx);
};
export const getZyfiGeneralTxRejected = (state, action) => {
    ReduxEntity.error(state.zyfiGeneralTx, action);
};
export const getZyfiGeneralTxFullfilled = (state, action) => {
    ReduxEntity.success(state.zyfiGeneralTx, action);
};
export const swapTransactionRaw = () => { };
