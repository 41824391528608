import { toast } from '@ondefy/ondefy-ui';
import _ from 'lodash/fp';
import { ofType } from 'redux-observable';
import { concatMap, of, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EPages } from '../../__common__/constants';
import { NETWORKS_DATA } from '../../__common__/constants/networks';
import { selectTargetTokenFiat, selectSourceAmount, selectFiatToTokenRate, selectRefuelAmount, selectSelectedFiatSymbol, selectPartnerOrderId, selectIsTransakSupported, selectTransakOrderNetworkId, selectTransakOrderWalletAddress } from '../selectors';
import { setPage } from '../../__common__/slice';
import { postOrder as createOrder } from '../api/post-order';
import { RES_FORBIDDEN } from '../constants/backend-api';
import { postOrder, postOrderFulfilled, postOrderRejected } from '../slice';
import { getParseUnits } from '../transducers/get-parse-units';
export const epicPostOrder = (action$, state$) => {
    return action$.pipe(ofType(postOrder.toString()), switchMap(({ payload: signedPermit }) => {
        var _a, _b;
        const { value } = state$;
        const partnerOrderId = selectPartnerOrderId(value);
        const transakOrderWalletAddress = selectTransakOrderWalletAddress(value);
        const transakOrderNetworkId = selectTransakOrderNetworkId(value);
        const fiatSymbol = selectSelectedFiatSymbol(value);
        const fiatAmount = parseFloat(selectSourceAmount(value));
        const fiatToTokenRate = selectFiatToTokenRate(value);
        const isTransakSupported = selectIsTransakSupported(value);
        const intermediateToken = isTransakSupported
            ? null
            : _.path([transakOrderNetworkId, 'ftatInputToken'], NETWORKS_DATA);
        const intermediateBuyAmount = _.pathOr('', ['value', 'intermediateBuyAmount'], fiatToTokenRate);
        const selectedToken = selectTargetTokenFiat(value);
        const refuelAmount = selectRefuelAmount(value);
        if (!partnerOrderId ||
            !transakOrderWalletAddress ||
            !transakOrderNetworkId ||
            !fiatSymbol ||
            !fiatAmount ||
            !fiatToTokenRate ||
            (!isTransakSupported && !(intermediateToken && signedPermit)) ||
            !((_a = fiatToTokenRate === null || fiatToTokenRate === void 0 ? void 0 : fiatToTokenRate.value) === null || _a === void 0 ? void 0 : _a.buyAmount) ||
            !selectedToken) {
            return of(postOrderRejected({
                message: 'Not all required fields are ready'
            }));
        }
        return createOrder(partnerOrderId, transakOrderWalletAddress, !!isTransakSupported, transakOrderNetworkId, fiatSymbol, fiatAmount.toString(), isTransakSupported ? undefined : _.path('contractAddress', intermediateToken), isTransakSupported ? undefined : _.path('decimals', intermediateToken), isTransakSupported ? undefined : getParseUnits(intermediateBuyAmount, _.path('decimals', intermediateToken)), selectedToken.address, selectedToken.decimals, getParseUnits((_b = fiatToTokenRate === null || fiatToTokenRate === void 0 ? void 0 : fiatToTokenRate.value) === null || _b === void 0 ? void 0 : _b.buyAmount, selectedToken.decimals), signedPermit, refuelAmount).pipe(concatMap(v => {
            return of(postOrderFulfilled(v), setPage(EPages.buyRamp));
        }), tap({
            error: err => {
                var _a;
                // TODO: test this scenario
                if (err.message === RES_FORBIDDEN) {
                    // TODO: should we check err or err.message ?
                    toast.next({
                        variant: 'error',
                        message: `An order is already pending on chain ${(_a = NETWORKS_DATA[transakOrderNetworkId]) === null || _a === void 0 ? void 0 : _a.name}`
                    });
                }
                else {
                    toast.next({
                        variant: 'error',
                        message: 'Failed to save the order.'
                    });
                }
            }
        }), catchError(e => {
            return of(postOrderRejected(e));
        }));
    }));
};
