import Decimal from 'decimal.js-light';
export function formatNumberSmallExp(number, options) {
    const num = new Decimal(number);
    const thresholdAdjusted = Math.pow(10, -Math.abs((options === null || options === void 0 ? void 0 : options.expStart) ? (options === null || options === void 0 ? void 0 : options.expStart) - 1 : 3));
    const toFixedAdjusted = (options === null || options === void 0 ? void 0 : options.toFixed) || 2;
    let res;
    // return thresholdAdjusted
    if (num.abs().lt(thresholdAdjusted)) {
        // If the number is smaller than the threshold, return in exponential format
        res = toFixedAdjusted !== null ? num.toExponential(toFixedAdjusted) : num.toExponential();
    }
    else {
        // If the number is greater than or equal to the threshold, return the original number
        // Optionally format it with a fixed number of decimal places
        res = toFixedAdjusted !== null ? num.toFixed(toFixedAdjusted) : num.toString();
    }
    // Corrected RegExp to remove trailing zeros in both exponential and non-exponential formats
    res = res
        .replace(/(\.\d*?[1-9])0+e/, '$1e') // Remove trailing zeros in exponential format
        .replace(/\.0+e/, 'e') // Remove decimal point if all digits after it are zeros in exponential format
        .replace(/(\.\d*?[1-9])0+$/, '$1') // Remove trailing zeros in non-exponential format
        .replace(/\.$/, ''); // Remove decimal point if there are no digits after it in non-exponential format
    return res;
}
