import { v4 as uuidv4 } from 'uuid';
export const makeUniqueGradient = (input) => {
    const regexp = /(linearGradient-\d)/g;
    const replacement = (v) => {
        // @ts-ignore
        if (replacement[v] === undefined) {
            // @ts-ignore
            replacement[v] = uuidv4();
        }
        // @ts-ignore
        return replacement[v];
    };
    const newSvg = input.replace(regexp, replacement);
    return newSvg;
};
