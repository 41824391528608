import { ESwappers } from './contracts';
export var ENetworkNames;
(function (ENetworkNames) {
    ENetworkNames["arbitrum-one"] = "arbitrum-one";
    ENetworkNames["avalanche"] = "avalanche";
    ENetworkNames["binance-smart-chain"] = "binance-smart-chain";
    ENetworkNames["ethereum"] = "ethereum";
    ENetworkNames["fantom"] = "fantom";
    ENetworkNames["celo"] = "celo";
    ENetworkNames["optimistic-ethereum"] = "optimistic-ethereum";
    ENetworkNames["polygon-pos"] = "polygon-pos";
    ENetworkNames["zksync"] = "zksync";
    ENetworkNames["base"] = "base";
})(ENetworkNames || (ENetworkNames = {}));
export const NETWORK_NAME_BY_ID = {
    1: ENetworkNames.ethereum,
    56: ENetworkNames['binance-smart-chain'],
    42161: ENetworkNames['arbitrum-one'],
    43114: ENetworkNames.avalanche,
    250: ENetworkNames.fantom,
    42220: ENetworkNames.celo,
    10: ENetworkNames['optimistic-ethereum'],
    137: ENetworkNames['polygon-pos'],
    324: ENetworkNames.zksync,
    8453: ENetworkNames.base
};
export var ENetworkIdByName;
(function (ENetworkIdByName) {
    ENetworkIdByName[ENetworkIdByName["ethereum"] = 1] = "ethereum";
    ENetworkIdByName[ENetworkIdByName["binance-smart-chain"] = 56] = "binance-smart-chain";
    ENetworkIdByName[ENetworkIdByName["arbitrum-one"] = 42161] = "arbitrum-one";
    ENetworkIdByName[ENetworkIdByName["avalanche"] = 43114] = "avalanche";
    ENetworkIdByName[ENetworkIdByName["fantom"] = 250] = "fantom";
    ENetworkIdByName[ENetworkIdByName["celo"] = 42220] = "celo";
    ENetworkIdByName[ENetworkIdByName["optimistic-ethereum"] = 10] = "optimistic-ethereum";
    ENetworkIdByName[ENetworkIdByName["polygon-pos"] = 137] = "polygon-pos";
    ENetworkIdByName[ENetworkIdByName["zksync"] = 324] = "zksync";
    ENetworkIdByName[ENetworkIdByName["base"] = 8453] = "base";
})(ENetworkIdByName || (ENetworkIdByName = {}));
// export const NETWORKS_IDS = {
//   [ENetworkNames.ethereum]: 1,
//   [ENetworkNames['polygon-pos']]: 137,
//   [ENetworkNames['arbitrum-one']]: 42161
// }
// Networks
export const NETWORKS_DATA = {
    1: {
        networkId: 1,
        id: ENetworkNames.ethereum,
        name: 'Ethereum',
        transakId: 'ethereum',
        // image: '/assets/img/net/ethereum.svg',
        ticker: 'ETH',
        rpc: process.env.RPC_ETHEREUM,
        blockExplorer: 'https://etherscan.io',
        defaultCoinId: 'ethereum',
        supportsEIP1559: true,
        routerAddress: '0x0D1cc763eF54440311e99E426ccF8B9a92224932',
        adaptersAddresses: {
            [ESwappers.ZERO_X_SWAPPER_IDX]: '0x3D49B1515394ede7D8aFA55E20b6925E79f6B993',
            [ESwappers.ONE_INCH_SWAPPER_IDX]: '0xCEE85a334E6E2A0273C2f4234C25cDb270Bf285A',
            [ESwappers.KYBERSWAP_SWAPPER_IDX]: '0x521680bBB733e620DCcf4556F8802E033E08f57b'
        },
        // from master https://github.com/ondefy/ondefy-ramp/pull/357/files
        // authorizedSwappers: [ONE_INCH_SWAPPER_IDX, PARASWAP_SWAPPER_IDX, LIFI_SWAPPER_IDX],
        authorizedSwappers: [
            ESwappers.ONE_INCH_SWAPPER_IDX,
            // ESwappers.PARASWAP_SWAPPER_IDX,
            ESwappers.LIFI_SWAPPER_IDX,
            ESwappers.ZERO_X_SWAPPER_IDX,
            ESwappers.KYBERSWAP_SWAPPER_IDX
        ],
        authorizedSwappersFOT: [],
        // It means that the network is supported to buy tokens using fiat currency
        ftatInputToken: {
            id: 'usd-coin',
            contractAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
            decimals: 6,
            networkId: 1,
            name: 'USD Coin',
            symbol: 'USDC'
        }
    },
    56: {
        networkId: 56,
        id: ENetworkNames['binance-smart-chain'],
        name: 'Binance Smart Chain',
        transakId: 'bsc',
        // image: '/assets/img/net/binance-smart-chain.svg',
        ticker: 'BNB',
        rpc: 'https://bsc-dataseed1.defibit.io/',
        blockExplorer: 'https://bscscan.com',
        defaultCoinId: 'binancecoin',
        supportsEIP1559: false,
        routerAddress: '', // '0xB9e6ae112d3Af0450a8B08D692e91963714C209A',
        adaptersAddresses: {
            [ESwappers.ZERO_X_SWAPPER_IDX]: '0x902C21a3780f67D7148ff2580c2586FA182A0415',
            [ESwappers.ONE_INCH_SWAPPER_IDX]: '0x9f4817aEF6BCbf1B0b57242d76e3C01063Aafb8F',
            [ESwappers.PARASWAP_SWAPPER_IDX]: '0x2dDdD8ed77063f93e31B6C7E8118Dd6A89A6b53a',
            [ESwappers.LIFI_SWAPPER_IDX]: '0xAac1709a995bD28cB0572D715B378f01C457052d',
            [ESwappers.PANCAKESWAP_SWAPPER_IDX]: '0x88f5f01c7Ca6FB20E05dE362249903057Dab6A39'
        },
        authorizedSwappers: [
            ESwappers.ZERO_X_SWAPPER_IDX,
            ESwappers.ONE_INCH_SWAPPER_IDX,
            // ESwappers.PARASWAP_SWAPPER_IDX,
            ESwappers.LIFI_SWAPPER_IDX,
            ESwappers.KYBERSWAP_SWAPPER_IDX
        ],
        authorizedSwappersFOT: [ESwappers.PANCAKESWAP_SWAPPER_IDX]
    },
    137: {
        networkId: 137,
        id: ENetworkNames['polygon-pos'],
        name: 'Polygon',
        transakId: 'polygon',
        // image: '/assets/img/net/polygon-pos.svg',
        ticker: 'MATIC',
        rpc: process.env.RPC_POLYGON,
        blockExplorer: 'https://polygonscan.com',
        defaultCoinId: 'matic-network',
        supportsEIP1559: true,
        routerAddress: '0x88d86D9644085FA75b3684a970e539ca5B641cB2',
        adaptersAddresses: {
            [ESwappers.ZERO_X_SWAPPER_IDX]: '0x061af2Ac6475a599e304415195Fc9BCfb9dfc8eC',
            [ESwappers.ONE_INCH_SWAPPER_IDX]: '0x9b1079B38D4EFe71022CDaf4EA98459a9d3434f4',
            [ESwappers.PARASWAP_SWAPPER_IDX]: '0x97BFf7dDe3c75586868d85a7364689E3FE38581C',
            [ESwappers.LIFI_SWAPPER_IDX]: '0x34b095C329C286010a485F3D89beAF148814B011',
            [ESwappers.KYBERSWAP_SWAPPER_IDX]: '0xa08f737293995248c1e542D486dC7d20b969fBF8'
        },
        // from master https://github.com/ondefy/ondefy-ramp/pull/357/files
        // authorizedSwappers: [ONE_INCH_SWAPPER_IDX, PARASWAP_SWAPPER_IDX, LIFI_SWAPPER_IDX],
        authorizedSwappers: [
            ESwappers.ZERO_X_SWAPPER_IDX,
            ESwappers.ONE_INCH_SWAPPER_IDX,
            // ESwappers.PARASWAP_SWAPPER_IDX,
            ESwappers.LIFI_SWAPPER_IDX,
            ESwappers.KYBERSWAP_SWAPPER_IDX
        ],
        authorizedSwappersFOT: [],
        ftatInputToken: {
            id: 'usd-coin',
            contractAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            decimals: 6,
            networkId: 137,
            name: 'USD Coin (PoS)',
            symbol: 'USDC'
        }
    },
    10: {
        networkId: 10,
        id: ENetworkNames['optimistic-ethereum'],
        name: 'Optimism',
        transakId: 'optimism',
        // image: '/assets/img/net/optimistic-ethereum.svg',
        ticker: 'ETH',
        rpc: 'https://opt-mainnet.g.alchemy.com/v2/QUwxJKOtcZZY5teOJ7EMqb2RNjG-2nXE',
        blockExplorer: 'https://optimistic.etherscan.io',
        defaultCoinId: 'ethereum',
        supportsEIP1559: false,
        routerAddress: '', // '0x521680bBB733e620DCcf4556F8802E033E08f57b',
        adaptersAddresses: {
            [ESwappers.ZERO_X_SWAPPER_IDX]: '0x8B878AeAb6Ae4020f2e0743BA844c96f43F0373D',
            [ESwappers.ONE_INCH_SWAPPER_IDX]: '0xb06cC611E653310285D4687339af4a1ff623f3Aa',
            [ESwappers.PARASWAP_SWAPPER_IDX]: '0x558CA2AE990602cc1833E6435A30D7D50a563810',
            [ESwappers.LIFI_SWAPPER_IDX]: '0x3c34Ce7D0A5E3bb824ef00263DF2E8dAa2Ff4F81'
        },
        authorizedSwappers: [
            ESwappers.ZERO_X_SWAPPER_IDX,
            ESwappers.ONE_INCH_SWAPPER_IDX,
            // ESwappers.PARASWAP_SWAPPER_IDX,
            ESwappers.LIFI_SWAPPER_IDX,
            ESwappers.KYBERSWAP_SWAPPER_IDX
        ],
        authorizedSwappersFOT: []
    },
    42161: {
        networkId: 42161,
        id: ENetworkNames['arbitrum-one'],
        name: 'Arbitrum',
        transakId: 'arbitrum',
        // image: '/assets/img/net/arbitrum-one.svg',
        ticker: 'ETH',
        rpc: process.env.RPC_ARBITRUM,
        blockExplorer: 'https://arbiscan.io',
        defaultCoinId: 'ethereum',
        supportsEIP1559: false,
        routerAddress: '0x8B878AeAb6Ae4020f2e0743BA844c96f43F0373D',
        adaptersAddresses: {
            [ESwappers.ZERO_X_SWAPPER_IDX]: '0xAac1709a995bD28cB0572D715B378f01C457052d',
            [ESwappers.ONE_INCH_SWAPPER_IDX]: '0xb06cC611E653310285D4687339af4a1ff623f3Aa',
            [ESwappers.PARASWAP_SWAPPER_IDX]: '0x558CA2AE990602cc1833E6435A30D7D50a563810',
            [ESwappers.LIFI_SWAPPER_IDX]: '0xB9e6ae112d3Af0450a8B08D692e91963714C209A',
            [ESwappers.KYBERSWAP_SWAPPER_IDX]: '0x280265f0b68AbDeF96D99Baf25c5113f8F8fC734'
        },
        authorizedSwappers: [
            ESwappers.ZERO_X_SWAPPER_IDX,
            ESwappers.ONE_INCH_SWAPPER_IDX,
            // ESwappers.PARASWAP_SWAPPER_IDX,
            ESwappers.LIFI_SWAPPER_IDX,
            ESwappers.KYBERSWAP_SWAPPER_IDX
        ],
        authorizedSwappersFOT: [],
        ftatInputToken: {
            id: 'usd-coin',
            contractAddress: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
            decimals: 6,
            networkId: 42161,
            name: 'USD Coin (Arb1)',
            symbol: 'USDC'
        }
    },
    43114: {
        networkId: 43114,
        id: ENetworkNames.avalanche,
        name: 'Avalanche',
        transakId: 'avaxcchain',
        // image: '/assets/img/net/avalanche.svg',
        ticker: 'AVAX',
        rpc: 'https://api.avax.network/ext/bc/C/rpc',
        blockExplorer: 'https://cchain.explorer.avax.network',
        defaultCoinId: 'avalanche-2',
        supportsEIP1559: false,
        routerAddress: '', // '0x8B878AeAb6Ae4020f2e0743BA844c96f43F0373D',
        adaptersAddresses: {
            [ESwappers.ZERO_X_SWAPPER_IDX]: '0xb06cC611E653310285D4687339af4a1ff623f3Aa',
            [ESwappers.ONE_INCH_SWAPPER_IDX]: '0x558CA2AE990602cc1833E6435A30D7D50a563810',
            [ESwappers.PARASWAP_SWAPPER_IDX]: '0x3c34Ce7D0A5E3bb824ef00263DF2E8dAa2Ff4F81',
            [ESwappers.LIFI_SWAPPER_IDX]: '0xcE528c02634363cd23e3dC44E8c7bc5EF4F9B9e3'
        },
        authorizedSwappers: [
            ESwappers.ZERO_X_SWAPPER_IDX,
            ESwappers.ONE_INCH_SWAPPER_IDX,
            // ESwappers.PARASWAP_SWAPPER_IDX,
            ESwappers.LIFI_SWAPPER_IDX,
            ESwappers.KYBERSWAP_SWAPPER_IDX
        ],
        authorizedSwappersFOT: []
    },
    250: {
        networkId: 250,
        id: ENetworkNames.fantom,
        name: 'Fantom',
        transakId: 'fantom',
        // image: '/assets/img/net/fantom.svg',
        ticker: 'FTM',
        rpc: 'https://rpc.ftm.tools/',
        blockExplorer: 'https://ftmscan.com',
        defaultCoinId: 'fantom',
        supportsEIP1559: false,
        routerAddress: '', // '0xb06cC611E653310285D4687339af4a1ff623f3Aa',
        adaptersAddresses: {
            [ESwappers.ZERO_X_SWAPPER_IDX]: '0x558CA2AE990602cc1833E6435A30D7D50a563810',
            [ESwappers.ONE_INCH_SWAPPER_IDX]: '0x3c34Ce7D0A5E3bb824ef00263DF2E8dAa2Ff4F81',
            [ESwappers.PARASWAP_SWAPPER_IDX]: '0xcE528c02634363cd23e3dC44E8c7bc5EF4F9B9e3',
            [ESwappers.LIFI_SWAPPER_IDX]: '0xB9e6ae112d3Af0450a8B08D692e91963714C209A'
        },
        authorizedSwappers: [
            ESwappers.ZERO_X_SWAPPER_IDX,
            ESwappers.ONE_INCH_SWAPPER_IDX,
            // ESwappers.PARASWAP_SWAPPER_IDX,
            ESwappers.LIFI_SWAPPER_IDX,
            ESwappers.KYBERSWAP_SWAPPER_IDX
        ],
        authorizedSwappersFOT: []
    },
    42220: {
        networkId: 42220,
        id: ENetworkNames.celo,
        name: 'Celo',
        transakId: 'celo',
        // image: '/assets/img/net/celo.svg',
        ticker: 'CELO',
        rpc: 'https://forno.celo.org',
        blockExplorer: 'https://explorer.celo.org',
        defaultCoinId: 'celo',
        supportsEIP1559: false,
        routerAddress: '', // '0x8B878AeAb6Ae4020f2e0743BA844c96f43F0373D',
        adaptersAddresses: {
            [ESwappers.ZERO_X_SWAPPER_IDX]: '0xb06cC611E653310285D4687339af4a1ff623f3Aa',
            [ESwappers.LIFI_SWAPPER_IDX]: '0x558CA2AE990602cc1833E6435A30D7D50a563810'
        },
        authorizedSwappers: [ESwappers.ZERO_X_SWAPPER_IDX, ESwappers.LIFI_SWAPPER_IDX],
        authorizedSwappersFOT: []
    },
    324: {
        networkId: 324,
        id: ENetworkNames.zksync,
        name: 'zkSync',
        transakId: 'zksync',
        // image: '/assets/img/net/zksync-era.svg',
        ticker: 'ETH',
        rpc: process.env.RPC_ZKSYNC,
        blockExplorer: 'https://explorer.zksync.io',
        defaultCoinId: 'ethereum',
        supportsEIP1559: false,
        routerAddress: '',
        adaptersAddresses: {},
        // authorizedSwappers: [ESwappers.ONE_INCH_SWAPPER_IDX],
        authorizedSwappers: [ESwappers.ODOS_SWAPPER_IDX],
        authorizedSwappersFOT: []
        // supportedYieldProtocols: []
    },
    8453: {
        networkId: 8453,
        id: ENetworkNames.base,
        name: 'Base',
        transakId: 'base',
        ticker: 'ETH',
        rpc: 'https://mainnet.base.org',
        blockExplorer: 'https://basescan.org',
        defaultCoinId: 'ethereum',
        supportsEIP1559: false,
        routerAddress: '',
        adaptersAddresses: {},
        authorizedSwappers: [ESwappers.ZERO_X_SWAPPER_IDX, ESwappers.ONE_INCH_SWAPPER_IDX, ESwappers.KYBERSWAP_SWAPPER_IDX],
        authorizedSwappersFOT: []
        // supportedYieldProtocols: []
    }
};
export const DEFAULT_CHAIN_ID = ENetworkIdByName.ethereum;
export const NETWORK_ID_TO_AVERAGE_SWAP_GAS_USAGE = {
    1: 350000,
    137: 650000,
    42161: 1700000
};
export const NETWORK_ID_TO_MAX_GAS_PRICE_GWEI = {
    1: 100,
    137: 700,
    42161: 2
};
