export var ETransactionStatus;
(function (ETransactionStatus) {
    ETransactionStatus["pending"] = "pending";
    ETransactionStatus["confirmed"] = "confirmed";
    ETransactionStatus["failed"] = "failed";
    ETransactionStatus["expired"] = "expired";
})(ETransactionStatus || (ETransactionStatus = {}));
export var ETransactionType;
(function (ETransactionType) {
    ETransactionType["approve"] = "approve";
    ETransactionType["swap"] = "swap";
    ETransactionType["custom"] = "custom";
    ETransactionType["send"] = "send";
})(ETransactionType || (ETransactionType = {}));
// export enum ETxBlockchainStatus {
//   error = 0,
//   success = 1
// }
export var ETxBlockchainStatus;
(function (ETxBlockchainStatus) {
    ETxBlockchainStatus["error"] = "reverted";
    ETxBlockchainStatus["success"] = "success";
})(ETxBlockchainStatus || (ETxBlockchainStatus = {}));
