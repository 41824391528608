// import { ENetworkIdByName } from '../../../constants/networks'
// import { GAS_OVERHEAD_SWAP_ROUTER, GAS_OVERHEAD_SWAP_ROUTER_ARBITRUM } from '../constants/gas-fee-estimate'
// TODO: add unit tests!!!
// TODO: add unit tests!!!
// TODO: add unit tests!!!
export const getGasFeeForOdos = (gasEstimate) => {
    // if (gasEstimate > 0) {
    //   gasEstimate +=
    //     networkId === ENetworkIdByName['arbitrum-one'] ? GAS_OVERHEAD_SWAP_ROUTER_ARBITRUM : GAS_OVERHEAD_SWAP_ROUTER
    // }
    const gasLimit = gasEstimate * 1;
    return {
        gasLimit,
        gasEstimate
    };
};
