import { toast } from '@ondefy/ondefy-ui';
import { ofType } from 'redux-observable';
import { filter, merge, mergeMap, of, switchMap, takeUntil, timer } from 'rxjs';
import { catchError, distinctUntilChanged, expand, map, takeWhile, tap } from 'rxjs/operators';
import { ETransactionStatus, ETxBlockchainStatus } from '../../__common__/constants/transaction';
import { ofValue } from '../../__common__/rxjs-utils/of-value';
import { reject } from '../../__common__/rxjs-utils/reject';
import { resetUserWalletCache } from '../../wallet/api/get-user-wallet';
import { checkTransactionStatus } from '../api/check-transaction-status';
import { selectCustomTransaction } from '../selectors';
import { reset, customTransactionRejected, customTransactionFullfilled, saveTransaction } from '../slice';
import _ from 'lodash/fp';
export const epicCustomPollingTransaction = (action$, state$) => merge(ofValue(state$, selectCustomTransaction)).pipe(distinctUntilChanged((prev, curr) => { var _a, _b; return ((_a = prev.value) === null || _a === void 0 ? void 0 : _a.txHash) === ((_b = curr.value) === null || _b === void 0 ? void 0 : _b.txHash); }), filter(v => {
    return v.isLoaded;
}), 
// TODO: stop when unlockTransaction in redux ???
// TODO: stop when unlockTransaction in redux ???
// TODO: stop when unlockTransaction in redux ???
mergeMap(transaction => {
    const check = () => {
        // console.log('check transaction status')
        return checkTransactionStatus(transaction.value).pipe(map(transactionStatus => {
            // console.log('transactionStatus =====', transactionStatus)
            // TODO: ask Clement what to do with it
            // TODO: ask Clement what to do with it
            // TODO: ask Clement what to do with it
            if (!transactionStatus) {
                // console.log('unlock skip')
                return { type: null };
            }
            if (transactionStatus.status === ETxBlockchainStatus.success) {
                const params = Object.assign(Object.assign({}, transaction.value), { status: ETransactionStatus.confirmed });
                // console.log('custom complete')
                return customTransactionFullfilled(params);
            }
            if (transactionStatus.status === ETxBlockchainStatus.error) {
                const params = Object.assign(Object.assign({}, transaction.value), { status: ETransactionStatus.failed });
                // console.log('custom rejected')
                toast.next({ variant: 'error', message: 'Custom failed' });
                return customTransactionRejected(params);
            }
            // console.log('transaction check continue')
            // TODO: maybe I can just return EMPTY???
            return { type: null };
        }), catchError(e => {
            console.error(e);
            return of({ type: null });
        }));
    };
    const POLL = 5000;
    return check().pipe(expand(() => timer(POLL).pipe(switchMap(() => check()))), reject(({ type }) => type === null), takeUntil(action$.pipe(ofType(reset.toString()))), takeWhile(action => {
        if (_.pathEq(['payload', 'status'], ETransactionStatus.confirmed)(action)) {
            // console.log('stop polling', action)
            // stop polling
            return false;
        }
        else if (_.pathEq(['payload', 'status'], ETransactionStatus.failed)(action)) {
            // stop polling
            // console.log('stop polling', action)
            return false;
        }
        // console.log('continue polling')
        return true;
    }, true), tap(action => {
        if (action.type === customTransactionFullfilled.toString()) {
            resetUserWalletCache();
        }
    }), mergeMap(action => {
        return of(action, saveTransaction(action.payload));
    }));
}));
