import { OButton, OTag, styled } from '@ondefy/ondefy-ui';
import { css } from 'styled-components';
const rules = css `
  background-color: ${p => (p.theme.id === 'light' ? p.theme.colors.bg6 : p.theme.colors.bg2)};
  border: ${p => (p.theme.id === 'light' ? 'none' : undefined)};
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;
export const SAmountBockArrowTag = styled(OTag) `
  ${rules};
`;
export const SAmountBockArrowButton = styled(OButton) `
  ${rules};
`;
