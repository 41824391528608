// https://redux-toolkit.js.org/usage/usage-with-typescript
import { useDispatch, useSelector } from 'react-redux';
// Use throughout your app instead of plain `useDispatch` and `useSelector`
// export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
// export const useAppAction = <P = void, T extends string = string>(
//   action: ActionCreatorWithPayload<P, T> | ActionCreatorWithoutPayload<T>
// ) => {
//   const dispatch = useAppDispatch()
//
//   return useCallback(
//     (payload?: Parameters<typeof action>[0]) => {
//       if (!payload) {
//         return dispatch((action as ActionCreatorWithoutPayload<T>)())
//       }
//
//       return dispatch((action as ActionCreatorWithPayload<P, T>)(payload))
//     },
//     [action, dispatch]
//   )
// }
