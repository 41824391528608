import { 
// OMedia,
// OSpinner,
// SBox,
SFlexbox, SGridbox
// SText
 } from '@ondefy/ondefy-ui';
import _ from 'lodash/fp';
import { EPages } from '../../constants';
import { DEFAULT_CHAIN_ID, NETWORKS_DATA } from '../../constants/networks';
import { selectPage } from '../../selectors';
import { selectIsTransakSupported, selectTransactionStatus, selectTransakOrderNetworkId } from '../../../1 - buy/selectors';
import { Breadcrumb } from './breadcrumb';
import React, { useMemo } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { EEntityStatus } from '../../../../redux/redux-entity';
export const Breadcrumbs = () => {
    // const isWalletConnected = useAppSelector(selectIsWalletConnected)
    const transakOrderNetworkId = useAppSelector(selectTransakOrderNetworkId);
    const page = useAppSelector(selectPage);
    // const tokenSelector = useMemo(makeSelectedTokenSelector, [])
    // const token = useAppSelector(tokenSelector)
    // const dispatch = useAppDispatch()
    // const transakOrderIsProcessing = useAppSelector(
    //   selectTransakOrderIsProcessing
    // )
    // const tokenContracts = useAppSelector(selectTokenContracts)
    const isTransakSupported = useAppSelector(selectIsTransakSupported);
    const isTransakUndefined = isTransakSupported === undefined;
    const transactionStatus = useAppSelector(selectTransactionStatus);
    const isCompleted = transactionStatus.status === EEntityStatus.success;
    const intermediateTokenSymbol = useMemo(() => {
        return _.path(['ftatInputToken', 'symbol'], NETWORKS_DATA[transakOrderNetworkId] || NETWORKS_DATA[DEFAULT_CHAIN_ID]);
    }, [transakOrderNetworkId]); // display default chain's USDC if network not supported
    // Don't remove empty box!!!
    // if (!isWalletConnected) return <SBox></SBox>
    return (React.createElement(SFlexbox, { flex: "1", justifyContent: "center" },
        React.createElement(SGridbox, { gridAutoFlow: "column", gridAutoColumns: "1fr", transform: "scale(0.9)", width: (isTransakUndefined ? 110 : isTransakSupported ? 160 : 230) + 'px' }, !isTransakUndefined ? (React.createElement(React.Fragment, null,
            !isTransakSupported && (React.createElement(Breadcrumb, { iconName: "pen", label: "Sign", inProgress: page === EPages.buyPermit, done: page === EPages.buyRamp || page === EPages.buySummary })),
            React.createElement(Breadcrumb, { iconName: "transak", label: 'Buy ' + intermediateTokenSymbol, inProgress: page === EPages.buyRamp, done: page === EPages.buySummary }),
            React.createElement(Breadcrumb, { iconName: "ondefy", label: isTransakSupported ? 'Summary' : 'Swap', inProgress: !isCompleted && (page === EPages.buySummary || page === EPages.swapResult), done: isCompleted, last: true }))) : null)));
};
