import React, { Suspense } from 'react';
import { useSearchParam, useTitle } from 'react-use';
import { EAppVariant, ENV } from './env';
import { PaperFeeTokenInfo } from './features/2 - swap/papers/paper-fee-token-info';
import { PaperSwapRoutes } from './features/2 - swap/papers/paper-swap-routes';
import { ScreenSubmitZyfiLazy } from './features/2 - swap/screens/screen-2-submit-zyfi/lazy';
import { PaperCustomTokenInfo } from './features/3 - custom/papers/paper-custom-token-info';
import { PaperDestinationAddressInfo } from './features/3 - custom/papers/paper-destination-address-info';
import { ScreenCustomInitLazy } from './features/3 - custom/screens/screen-0-init-custom/lazy';
import { ScreenCustomResultLazy } from './features/3 - custom/screens/screen-1-result/lazy';
import { PaperSendDestinationAddressInfo } from './features/4 - send/papers/paper-send-destination-address-info';
import { PaperSendTokenInfo } from './features/4 - send/papers/paper-send-token-info';
import { ScreenSendInitLazy } from './features/4 - send/screens/screen-0-init-send/lazy';
import { ScreenSendResultLazy } from './features/4 - send/screens/screen-1-result/lazy';
import { APP_MODE, EPages } from './features/__common__/constants';
import { useInitFromUrlZyfi } from './features/__common__/hooks/_zyfi_/use-init-from-url-zyfi';
// import { useDisableScrolling } from '../features/__common__/hooks/use-disable-scroll'
import { useInitFromUrl } from './features/__common__/hooks/use-init-from-url';
import { useIsIframe } from './features/__common__/hooks/use-is-iframe';
import { useIsXsScreen } from './features/__common__/hooks/use-is-xs-screen';
import { useResetOnPageRefresh } from './features/__common__/hooks/use-reset-on-page-refresh';
import { useViewportHeight } from './features/__common__/hooks/use-viewport-height';
import { selectAppMode, selectPage } from './features/__common__/selectors';
import { useSetIframeColorPrimary } from './features/iframe/hooks/use-set-iframe-color-primary';
import { useAppSelector } from './redux/hooks';
import { GlobalContentWrapper } from './features/__common__/components/global-content-wrapper';
import { GlobalHeader } from './features/__common__/components/global-header';
import { Spinner } from './features/__common__/components/spinner';
import { SRootWrapper } from './features/__common__/styled/s-root-wrapper';
import { PaperCurrencyList } from './features/1 - buy/papers/paper-currency-list';
import { PaperGasFuelDescription } from './features/1 - buy/papers/paper-gas-fuel-description';
import { PaperTransactionDescription } from './features/1 - buy/papers/paper-transaction-description';
import { PaperTransactionDetails } from './features/1 - buy/papers/paper-transaction-details';
import { ScreenInitBuy } from './features/1 - buy/screens/screen-0-init-buy';
import { ScreenPermit } from './features/1 - buy/screens/screen-1-permit';
import { ScreenRamp } from './features/1 - buy/screens/screen-2-ramp';
// import { PaperScreenPermit } from './paper-screen-permit'
import { ScreenSummary } from './features/1 - buy/screens/screen-3-summary/screen-summary';
import { PaperSwapDetails } from './features/2 - swap/papers/paper-swap-details';
import { ScreenInitSwap } from './features/2 - swap/screens/screen-0-init-swap';
import { ScreenUnlock } from './features/2 - swap/screens/screen-1-unlock';
import { ScreenOverview } from './features/2 - swap/screens/screen-2-submit';
import { ScreenProgress } from './features/2 - swap/screens/screen-3-result';
// import { GlobalFooter } from './global-footer__DEPRECATED'
import { PaperConnection } from './features/__common__/papers/paper-connection';
import { PaperConnectionMagicAuth } from './features/__common__/papers/paper-connection-magic-auth';
import { PaperHistoryTransactions } from './features/__common__/papers/paper-history-transactions';
import { PaperMenu } from './features/__common__/papers/paper-menu';
import { PaperNetworkExtended } from './features/__common__/papers/paper-network-extended';
import { PaperResetConfirm } from './features/__common__/papers/paper-reset-confirm';
import { PaperTokenSelect } from './features/__common__/papers/paper-token-select';
import { ETokenPopupVariant } from './features/__common__/papers/paper-token-select/constants';
import { ScreenUnlockZyfiLazy } from './features/2 - swap/screens/screen-1-unlock-zyfi/lazy';
import { PaperCustomTransactionInfo } from './features/3 - custom/papers/paper-custom-transaction-info';
export const Layout = () => {
    const page = useAppSelector(selectPage);
    // const [isMounted, setMounted] = useState(false)
    const tokenId = useSearchParam('tokenId');
    useTitle(`Buy ${tokenId || 'any token'} with your card | Ondefy`);
    const isXsScreen = useIsXsScreen();
    const isIframe = useIsIframe();
    const appMode = useAppSelector(selectAppMode);
    const isBuyMode = appMode === APP_MODE.buy;
    const isSwapMode = appMode === APP_MODE.swap;
    const isCustomMode = appMode === APP_MODE.custom;
    const isSendMode = appMode === APP_MODE.send;
    // useMount(() => {
    //   setMounted(true)
    // })
    useSetIframeColorPrimary();
    const viewportHeight = useViewportHeight();
    // useDisableScrolling()
    if (ENV.app === EAppVariant.zyfi) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useInitFromUrlZyfi();
    }
    else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useInitFromUrl();
    }
    // TODO: it doesn't work as expected and remove everything even if you just navigate between screens
    useResetOnPageRefresh();
    return (React.createElement(React.Fragment, null,
        React.createElement(SRootWrapper, { viewportHeight: viewportHeight, isXsScreen: isXsScreen, isIframe: isIframe, isDesktop: !isXsScreen && !isIframe },
            !isIframe && !isXsScreen && React.createElement(GlobalHeader, null),
            React.createElement(GlobalContentWrapper, null,
                page === EPages.init && ENV.isZyfi() && isCustomMode && (React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
                    React.createElement(ScreenCustomInitLazy, null))),
                page === EPages.init && ENV.isOndefy() && isBuyMode && React.createElement(ScreenInitBuy, null),
                page === EPages.init && ENV.isOndefy() && isSwapMode && React.createElement(ScreenInitSwap, null),
                page === EPages.init && ENV.isZyfi() && isSwapMode && React.createElement(ScreenInitSwap, null),
                page === EPages.init && ENV.isZyfi() && isSendMode && (React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
                    React.createElement(ScreenSendInitLazy, null))),
                page === EPages.buyPermit && React.createElement(ScreenPermit, null),
                page === EPages.swapUnlock && React.createElement(ScreenUnlock, null),
                page === EPages.swapUnlockZyfi && (React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
                    React.createElement(ScreenUnlockZyfiLazy, null))),
                page === EPages.buyRamp && React.createElement(ScreenRamp, null),
                page === EPages.swapSubmit && React.createElement(ScreenOverview, null),
                page === EPages.swapSubmitZyfi && (React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
                    React.createElement(ScreenSubmitZyfiLazy, null))),
                page === EPages.buySummary && React.createElement(ScreenSummary, null),
                (page === EPages.swapResult || page === EPages.swapResultZyfi) && React.createElement(ScreenProgress, null),
                page === EPages.customResult && (React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
                    React.createElement(ScreenCustomResultLazy, null))),
                page === EPages.sendResult && (React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
                    React.createElement(ScreenSendResultLazy, null))),
                React.createElement(PaperCurrencyList, null),
                appMode && (React.createElement(React.Fragment, null,
                    React.createElement(PaperTokenSelect, { variant: ETokenPopupVariant.source }),
                    React.createElement(PaperTokenSelect, { variant: ETokenPopupVariant.target }),
                    React.createElement(PaperTokenSelect, { variant: ETokenPopupVariant.fee }))),
                React.createElement(PaperHistoryTransactions, null),
                React.createElement(PaperNetworkExtended, null),
                React.createElement(PaperTransactionDetails, null),
                React.createElement(PaperTransactionDescription, null),
                React.createElement(PaperGasFuelDescription, null),
                React.createElement(PaperSwapDetails, null),
                isSwapMode && React.createElement(PaperSwapRoutes, null),
                isCustomMode && (React.createElement(React.Fragment, null,
                    React.createElement(PaperCustomTransactionInfo, null),
                    React.createElement(PaperDestinationAddressInfo, null),
                    React.createElement(PaperCustomTokenInfo, null))),
                React.createElement(PaperFeeTokenInfo, null),
                isSendMode && (React.createElement(React.Fragment, null,
                    React.createElement(PaperSendDestinationAddressInfo, null),
                    React.createElement(PaperSendTokenInfo, null))),
                React.createElement(PaperMenu, null),
                React.createElement(PaperResetConfirm, null),
                React.createElement(PaperConnection, null),
                React.createElement(PaperConnectionMagicAuth, null)))));
};
