import { makeUniqueGradient } from './make-unique-gradient';
export const convertTransakFiatCurrencies = (resData) => {
    return resData
        .filter((c) => c.isAllowed && c.paymentOptions.map((opt) => opt.id).includes('credit_debit_card'))
        .map((c) => {
        const opt = c.paymentOptions.find((opt) => opt.id === 'credit_debit_card');
        return {
            symbol: c.symbol,
            logoSVG: makeUniqueGradient(c.icon),
            minAmount: opt.minAmount,
            maxAmount: opt.maxAmount
        };
    });
};
