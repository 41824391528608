import { useEffect } from 'react';
import { selectAllowWithWarning } from '../../../../../selectors';
import { setAllowWithWarning } from '../../../../../slice';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
export const useWarning = () => {
    const allowWithWarning = useAppSelector(selectAllowWithWarning);
    const dispatch = useAppDispatch();
    useEffect(() => {
        return () => {
            dispatch(setAllowWithWarning(false));
        };
        // eslint-disable-next-line
    }, []);
    return {
        allowWithWarning,
        setAllowWithWarning: (v) => dispatch(setAllowWithWarning(v))
    };
};
