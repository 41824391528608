import _ from 'lodash/fp';
import { APP_MODE } from '../../../constants';
import { NETWORKS_DATA } from '../../../constants/networks';
// TODO: write unit test!!!
// TODO: write unit test!!!
// TODO: write unit test!!!
export const getNetworksList = (appMode) => {
    const isBuyMode = appMode === APP_MODE.buy;
    return _.compose(_.filter(v => {
        if (isBuyMode) {
            return _.has('ftatInputToken', v);
        }
        return true;
    }), _.values)(NETWORKS_DATA);
};
