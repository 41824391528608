/*
 * SELECTORS
 * */
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash/fp';
import { selectTokenContractsAssoc } from '../__common__/selectors';
import { findTokenByIdInAssoc } from '../__common__/transducers/find-token-by-id';
import { selectUserWallet } from '../wallet/selectors';
import { sliceNamespace } from './constants';
import { checkIfCanSkipAllowance } from './transducers/check-if-can-skip-allowance';
import { checkIfAllowanceSufficient } from './transducers/check-if-allowance-sufficient';
import { checkIfSwapStartDisabled } from './transducers/check-if-swap-start-disabled';
import { getTransactionStatus } from './transducers/get-transaction-status';
import { formatTokenAmountWithDecimals } from './transducers/format-token-amount-with-decimals';
import { getBestSwapRate } from './transducers/get-best-swap-rate';
import { getTokenAmountInUsd } from './transducers/get-token-amount-in-usd';
import { checkIfEnoughBalance } from './transducers/check-if-enough-balance';
import { isEtherToken } from '../__common__/transducers/is-ether-token';
import { isNotSupportedZyfiSourceToken } from './transducers/is-not-supported-zyfi-source-token';
import { isZyfiPriceHigh } from './transducers/is-zyfi-price-high';
import { isZyfiTokenNotEnough } from './transducers/is-zyfi-token-not-enough';
import { sourceTokenInUserWallet } from './transducers/source-token-in-user-wallet';
export const selectSwap = (state) => _.path(sliceNamespace, state);
export const selectSlippage = createSelector(selectSwap, state => state.slippage);
export const selectPercentage = createSelector(selectSwap, state => state.percentage);
export const selectSelectedSwapperIndex = createSelector(selectSwap, state => state.selectedSwapperIndex);
export const selectSwapRates = createSelector(selectSwap, state => state.swapRates);
export const selectGasFees = createSelector(selectSwap, state => state.gasFees);
export const selectBestSwapRate = createSelector(selectSwapRates, selectSelectedSwapperIndex, getBestSwapRate);
export const selectSourceTokenIdSwap = createSelector(selectSwap, state => state.sourceTokenIdSwap);
export const selectFeeTokenIdSwap = createSelector(selectSwap, state => state.feeTokenIdSwap);
export const selectSourceTokenIdSwapIsEthereum = createSelector(selectSourceTokenIdSwap, isEtherToken);
export const selectTargetTokenIdSwap = createSelector(selectSwap, state => state.targetTokenIdSwap);
export const selectNetworkIdSourceSwap = createSelector(selectSwap, state => state.networkIdSourceSwap);
export const selectNetworkIdTargetSwap = createSelector(selectSwap, state => state.networkIdTargetSwap);
export const selectNetworkIdFeeSwap = createSelector(selectSwap, state => state.networkIdFeeSwap);
export const selectSourceTokenSwap = createSelector([selectTokenContractsAssoc, selectNetworkIdSourceSwap, selectSourceTokenIdSwap], findTokenByIdInAssoc);
export const selectTargetTokenSwap = createSelector([selectTokenContractsAssoc, selectNetworkIdTargetSwap, selectTargetTokenIdSwap], findTokenByIdInAssoc);
export const selectFeeTokenSwap = createSelector([selectTokenContractsAssoc, selectNetworkIdFeeSwap, selectFeeTokenIdSwap], findTokenByIdInAssoc);
export const selectSourceTokeInUserWallet = createSelector(selectUserWallet, selectNetworkIdSourceSwap, selectSourceTokenSwap, sourceTokenInUserWallet);
export const selectFeeTokeInUserWallet = createSelector(selectUserWallet, selectNetworkIdFeeSwap, selectFeeTokenSwap, sourceTokenInUserWallet);
export const selectSourceTokenAmount = createSelector(selectSwap, state => state.sourceTokenAmount);
export const selectSourceTokenAmountWithDecimals = createSelector(selectSourceTokenAmount, selectSourceTokenSwap, formatTokenAmountWithDecimals);
export const selectSourceTokenValueInUsd = createSelector(selectSourceTokenSwap, selectSourceTokenAmount, getTokenAmountInUsd);
export const selectCanAllowanceBeSkipped = createSelector(selectSourceTokenSwap, checkIfCanSkipAllowance);
// export const selectAllowance = createSelector(selectSwap, state => state.allowance)
export const selectAllowance = createSelector(selectSwap, state => state.allowance);
export const selectIfAllowanceSufficient = createSelector(selectAllowance, selectSourceTokenAmount, selectSourceTokenSwap, checkIfAllowanceSufficient);
export const selectGasFee = createSelector(selectSwap, state => state.gasFee);
export const selectUnlockTransaction = createSelector(selectSwap, state => state.unlockTransaction);
export const selectUnlockedTransactionStatus = createSelector(selectUnlockTransaction, getTransactionStatus);
export const selectSwapTransaction = createSelector(selectSwap, state => state.swapTransaction);
export const selectSwapTransactionStatus = createSelector(selectSwapTransaction, getTransactionStatus);
export const selectPendingTransactions = createSelector(selectSwap, state => state.pendingTransactions);
export const selectZyfiTokenApprovalTx = createSelector(selectSwap, state => state.zyfiTokenApprovalTx);
export const selectZyFiGeneralTx = createSelector(selectSwap, state => state.zyfiGeneralTx);
export const selectIsZyfiPriceHigh = createSelector(selectZyFiGeneralTx, selectSourceTokenAmount, selectSourceTokenIdSwapIsEthereum, selectFeeTokenIdSwap, selectBestSwapRate, isZyfiPriceHigh);
export const selectIsZyfiNotEnough = createSelector(selectZyFiGeneralTx, selectSourceTokenAmount, selectSourceTokeInUserWallet, selectFeeTokeInUserWallet, selectSourceTokenIdSwapIsEthereum, selectSourceTokenSwap, isZyfiTokenNotEnough);
export const selectIsNotSupportedZyfiSourceToken = createSelector(selectSourceTokenIdSwap, isNotSupportedZyfiSourceToken);
export const selectEnoughBalance = createSelector(selectUserWallet, selectSourceTokenSwap, selectSourceTokenAmount, selectFeeTokenSwap, 
// selectZyFiGeneralTx,
checkIfEnoughBalance);
export const selectSwapStartDisabled = createSelector(selectNetworkIdTargetSwap, selectBestSwapRate, selectSwapRates, selectCanAllowanceBeSkipped, selectEnoughBalance, selectAllowance, checkIfSwapStartDisabled);
