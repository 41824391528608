/*
 * SELECTORS
 * */
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash/fp';
import { sliceNamespace } from './constants';
export const selectWallet = (state) => _.path(sliceNamespace, state);
export const selectWalletType = createSelector(selectWallet, state => state.walletType);
export const selectIsWalletConnected = createSelector(selectWallet, state => state.isWalletConnected);
export const selectUserAddress = createSelector(selectWallet, state => state.userAddress);
export const selectUserEmail = createSelector(selectWallet, state => state.userEmail);
export const selectIsWeb3Busy = createSelector(selectWallet, state => state.isWeb3Busy);
/*
 * SELECTORS
 * */
export const selectUserWallet = createSelector(selectWallet, state => state.userWallet);
