import { useState, useEffect, useRef } from 'react';
import { useDebounce } from 'react-use';
import { Numbo } from '../transducers';
export const useInputNumberFormatted = (value) => {
    const [valueFormatted, setValueFormatted] = useState(Numbo.truncate(value));
    const blockChanges = useRef(true);
    const [debouncedValue, setDebouncedValue] = useState(value);
    useDebounce(() => {
        setDebouncedValue(value);
    }, 16, [value]);
    useEffect(() => {
        if (blockChanges.current) {
            setValueFormatted(Numbo.truncate(value));
        }
    }, [value]);
    const onFocus = () => {
        setValueFormatted('');
        blockChanges.current = false;
    };
    const onBlur = () => {
        blockChanges.current = true;
        setValueFormatted(Numbo.truncate(value));
    };
    return { value: valueFormatted || (value ? debouncedValue : value), onFocus, onBlur, type: 'text', blockChanges };
};
