import { ReduxEntity } from '../../redux/redux-entity';
import { initialState } from './state';
import { buildAssocTokenContractsMap } from './transducers/build-assoc-token-contracts-map';
export const setAppMode = (state, { payload }) => {
    state.appMode = payload;
};
export const setPage = (state, { payload }) => {
    state.page = payload;
};
export const reset = (state) => {
    return Object.assign(Object.assign({}, initialState), { 
        // keep only these fields
        appMode: state.appMode, tokenContracts: state.tokenContracts, tokenContractsAssoc: state.tokenContractsAssoc });
};
export const setSearchTokenValue = (state, { payload }) => {
    state.searchTokenValue = payload;
};
export const flush = (state) => {
    return Object.assign(Object.assign({}, initialState), { 
        // keep only these fields
        appMode: state.appMode, tokenContracts: state.tokenContracts, tokenContractsAssoc: state.tokenContractsAssoc });
};
export const getTokenContracts = (state) => {
    ReduxEntity.loading(state.tokenContracts);
};
export const getTokenContractsRejected = (state, action) => {
    ReduxEntity.error(state.tokenContracts, action);
    state.tokenContractsAssoc = undefined;
};
export const getTokenContractsFullfilled = (state, action) => {
    ReduxEntity.success(state.tokenContracts, action);
    state.tokenContractsAssoc = buildAssocTokenContractsMap(action.payload);
};
export const setTemporaryNetworkId = (state, { payload }) => {
    state.temporaryNetworkId = payload;
};
export const setIframeMode = (state, { payload }) => {
    state.isIframe = payload;
};
