import { Numbo } from '../../__common__/numbo/transducers';
export const getBestQuoteData = (destDecimals) => (sources) => {
    const res = sources.filter(r => r !== null).map(r => r);
    if (res.length === 0) {
        return null;
    }
    res.sort((a, b) => {
        const bigA = Numbo.from(a.toAmount);
        const bigB = Numbo.from(b.toAmount);
        return bigA.eq(bigB) ? 0 : bigA.gt(bigB) ? -1 : 1;
    });
    return Numbo.formatUnits(res[0].toAmount, destDecimals);
};
