import { toast } from '@ondefy/ondefy-ui';
import { ofType } from 'redux-observable';
import { from, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Web3$ } from '../../__common__/web3/web3-observable';
import { EPages } from '../../__common__/constants';
import { ETransactionStatus, ETransactionType } from '../../__common__/constants/transaction';
import { setPage } from '../../__common__/slice';
import { selectUserAddress } from '../../wallet/selectors';
// import { ETransactionStatus, ETransactionType } from '../constants'
import { selectZyFiGeneralTx, selectNetworkIdFeeSend, selectFeeTokenSend } from '../selectors';
import { saveTransaction, sendTransaction, sendTransactionFullfilled, sendTransactionRejected } from '../slice';
export const epicSendRunTransaction = (action$, state$) => {
    return action$.pipe(ofType(sendTransaction.toString()), switchMap(() => {
        const { value } = state$;
        const feeNetworkId = selectNetworkIdFeeSend(value);
        const generalTx = selectZyFiGeneralTx(value).value;
        const { value: Web3 } = Web3$;
        const transactionPayload = {
            rawTx: generalTx.txData,
            networkId: feeNetworkId
        };
        return from(Web3.broadcastTransaction(transactionPayload)).pipe(
        // 4. Save transaction to our backend
        switchMap(txHash => {
            const { value } = state$;
            const userAddress = selectUserAddress(value);
            const feeToken = selectFeeTokenSend(value);
            const submitParamsWithHash = {
                type: ETransactionType.send,
                userAddress,
                networkId: feeNetworkId,
                // feeRateBps: number
                toTokenId: feeToken.id,
                toTokenAddress: feeToken.address,
                toTokenSymbol: feeToken.symbol,
                toTokenLogoURI: feeToken.logoURI,
                // toTokenAmount: string
                toTokenDecimals: feeToken.decimals,
                toTokenPriceUsd: feeToken.price,
                // volumeUsd: string
                status: ETransactionStatus.pending,
                isHidden: 0,
                timestamp: Math.floor(Date.now() / 1000),
                txHash
            };
            return from([
                sendTransactionFullfilled(submitParamsWithHash),
                setPage(EPages.sendResult),
                saveTransaction(submitParamsWithHash)
            ]);
        }), catchError(e => {
            var _a;
            console.log(((_a = e.data) === null || _a === void 0 ? void 0 : _a.message) || e.message);
            toast.next({ variant: 'error', message: 'Transaction was rejected' });
            return of(sendTransactionRejected({
                message: 'Transaction failed'
            }));
        }));
    }));
};
