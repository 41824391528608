import axios from 'axios';
import { map } from 'rxjs/operators';
import { ApiObservable } from '../../../__common__/api-observable/api-observable';
import { NETWORKS_DATA } from '../../../__common__/constants/networks';
import { convertTransakCryptoCurrencies } from '../../transducers/convert-transak-cryptocurrencies';
import { convertTransakFiatCurrencies } from '../../transducers/convert-transak-fiat-currencies';
import { convertTransakOnRampRate } from '../../transducers/convert-transak-on-ramp-rate';
import _ from 'lodash/fp';
const apirxTransak = ApiObservable.from(axios.create({
    baseURL: process.env.TRANSAK_API_URL
    // headers: { Accept: 'application/vnd.github.v3+json' },
}));
export function getTransakFiatCurrenciesRx() {
    return apirxTransak
        .get('/fiat/public/v1/currencies/fiat-currencies')
        .pipe(map(_.path('response')), map(convertTransakFiatCurrencies));
}
// TODO group by networkId
export function getTransakCryptoCurrenciesRx() {
    return apirxTransak
        .get('/api/v2/currencies/crypto-currencies', {}, { cacheMinutes: 5 })
        .pipe(map(_.path('response')), map(convertTransakCryptoCurrencies));
}
export function getTransakOnRampRateRx({ fiatSymbol, fiatAmount, tokenSymbol, networkId }) {
    var _a;
    const network = (_a = NETWORKS_DATA[networkId]) === null || _a === void 0 ? void 0 : _a.transakId;
    return apirxTransak
        .get('/api/v1/pricing/public/quotes', {
        partnerApiKey: process.env.TRANSAK_API_KEY,
        fiatCurrency: fiatSymbol,
        isBuyOrSell: 'BUY',
        fiatAmount,
        cryptoCurrency: tokenSymbol,
        network
    }, { cacheMinutes: 5 })
        .pipe(map(_.path('response')), map(convertTransakOnRampRate));
}
