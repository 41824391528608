import { useRef, useCallback } from 'react';
export function useRepeatedCallGuard(interval = 3000) {
    const lastCallTimeRef = useRef(0);
    const repeatedCallGuard = useCallback((action, fallback) => {
        const now = Date.now();
        action();
        if (now - lastCallTimeRef.current < interval) {
            fallback();
        }
        lastCallTimeRef.current = now;
    }, [interval]);
    return repeatedCallGuard;
}
