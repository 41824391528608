import React, { useEffect } from 'react';
/* === CONNECT ONDEFY-UI === */
// normalize default browser css
import 'normalize.css';
// add icomoon font support
import '@ondefy/ondefy-ui/dist/assets/fonts/icomoon/style.css';
// add icomoon svg support
import '@ondefy/ondefy-ui/dist/assets/svg/icomoon/style.css';
import '@ondefy/ondefy-ui/dist/assets/svg/icomoon/svgxuse';
// connect custom fonts
import '@ondefy/ondefy-ui/dist/assets/fonts/dmsans/style.css';
// @ts-ignore
import SymbolDefsSVG from '@ondefy/ondefy-ui/dist/assets/svg/icomoon/symbol-defs.svg';
// connect provider, global styles and toasts
import { useTheme, GlobalStyles, OToast } from '@ondefy/ondefy-ui';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
/* === CONNECT ONDEFY-RAMP GlOBAL CSS === */
// import GlobalStyles from '@theme/global-styles';
/* === CONNECT REDUX === */
import { Provider } from 'react-redux';
import { useIsIframe } from './features/__common__/hooks/use-is-iframe';
import { AnalyticsRegister } from './features/analytics/components/analytics-register';
import { appReduxStore, persistor } from './redux/app-redux-store';
// @ts-ignore
// import { PersistGate } from 'redux-persist/integration/react'
/* === CONNECT WEB3 === */
import { ProviderWallet } from './features/wallet/provider.wallet';
/* === CONNECT ROUTING AND PAGES === */
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Layout } from './layout';
/* === INIT ENV === */
// import { initEnv } from './env'
const router = createBrowserRouter([
    {
        path: '*',
        element: React.createElement(Layout, null)
    }
]);
export const GlobalStylesRamp = createGlobalStyle `
  :root {
    touch-action: pan-x pan-y;
    height: 100%
  }

  html, body, #root {
    margin: 0 auto;
    min-height: 100vh;
  }

  //https://www.youtube.com/watch?v=cH8VbLM1958
  * {
    min-width: 0;
  }

  body {
    display: flex;
  }

  #root {
    display: flex;
    overflow: visible;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #root.ondefy__fullwidth {
    display: flex;
    margin: 0 auto;
  }
`;
const App = () => {
    const isIframe = useIsIframe();
    useEffect(() => {
        const root = document.getElementById('root');
        if (root) {
            root.classList.toggle('ondefy__fullwidth', isIframe);
        }
    }, [isIframe]);
    const { theme } = useTheme();
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { PersistGate } = require('redux-persist/integration/react');
    return (React.createElement(Provider, { store: appReduxStore },
        React.createElement(PersistGate, { loading: null, persistor: persistor },
            React.createElement(ProviderWallet, null,
                React.createElement(AnalyticsRegister, null),
                React.createElement(ThemeProvider, { theme: theme },
                    React.createElement(GlobalStyles, null),
                    React.createElement(GlobalStylesRamp, null),
                    React.createElement(SymbolDefsSVG, null),
                    React.createElement(RouterProvider, { router: router }),
                    React.createElement(OToast, null))))));
};
export default App;
