import { ENV } from '../../../../env';
import { OPaper } from '../../components/o-paper';
import { useIframeUrlColors } from '../../../iframe/hooks/use-iframe-url-colors';
import { selectIsWalletConnected } from '../../../wallet/selectors';
import { useCurrentBrowser } from '../../hooks/use-current-browser';
import { usePaperConnectionMagicAuth } from '../paper-connection-magic-auth/__hooks__/use-paper-connection-magic-auth';
import { MetamaskButton } from './__components__/metamask.button';
// import { usePaperMenu } from '../paper-menu/__hooks__/use-paper-menu'
import { usePaperConnection } from './__hooks__/use-paper-connection';
import { OButton, OLink, OSvgIcon, SBox, SFlexbox, SText } from '@ondefy/ondefy-ui';
import React, { useEffect } from 'react';
import { useWallet } from '../../../wallet/hooks/use-wallet';
import { WALLETS } from '../../../wallet/constants/wallets';
import { useAppSelector } from '../../../../redux/hooks';
import { SMetamaskLink } from './__styled__/s-metamask-link';
export const PaperConnection = () => {
    const paper = usePaperConnection();
    const { connectWallet, isWalletTypeSupportedByBrowser } = useWallet();
    const paperConnectionMagicAuth = usePaperConnectionMagicAuth();
    const { colorPrimary } = useIframeUrlColors();
    const isWalletConnected = useAppSelector(selectIsWalletConnected);
    // const paperMenu = usePaperMenu()
    const currentBrowser = useCurrentBrowser();
    useEffect(() => {
        if (isWalletConnected) {
            paperConnectionMagicAuth.setIsOpened(false);
            paper.setIsOpened(false).catch(() => { });
            // paperMenu.setIsOpened(false)
        }
        // eslint-disable-next-line
    }, [isWalletConnected]);
    return (React.createElement(React.Fragment, null,
        React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom", title: "Sign up or Connect Wallet" }),
            React.createElement(SBox, { p: "8px" },
                React.createElement(OButton, { iconLeft: React.createElement(OSvgIcon, { name: "email", 
                        // @ts-ignore
                        color: colorPrimary || 'accent', size: 28, ml: "6px", mr: "7px" }), variant: "secondary", width: "100%", height: "60px", mb: "8px", justifyContent: "flex-start", onClick: () => {
                        paperConnectionMagicAuth.setIsOpened(true);
                    } }, "Connect with email"),
                currentBrowser.isMobile && ENV.isOndefy() && (React.createElement(MetamaskButton, { disabled: true }, "Metamask on desktop browsers only")),
                ENV.isOndefy() &&
                    currentBrowser.isDesktop &&
                    (isWalletTypeSupportedByBrowser(WALLETS.metamask.key) ? (React.createElement(MetamaskButton, { onClick: () => {
                            if (isWalletTypeSupportedByBrowser(WALLETS.metamask.key)) {
                                connectWallet(WALLETS.metamask.key).catch(() => { });
                            }
                        } })) : (React.createElement(SMetamaskLink, { href: "https://metamask.io/download/", isExternal: true, borderless: true, noIcon: true },
                        React.createElement(MetamaskButton, null)))),
                ENV.isZyfi() && (React.createElement(OButton, { iconLeft: React.createElement(OSvgIcon, { name: "wallet-connect", size: 28, ml: "6px", mr: "7px" }), variant: "secondary", width: "100%", height: "60px", justifyContent: "flex-start", mb: "8px", onClick: () => {
                        connectWallet(WALLETS.web3Modal.key).catch(() => { });
                    } }, "Connect any wallet")),
                ENV.isOndefy() && (React.createElement(OButton, { iconLeft: React.createElement(OSvgIcon, { name: "wallet-connect", size: 28, ml: "6px", mr: "7px" }), variant: "secondary", width: "100%", height: "60px", justifyContent: "flex-start", mb: "8px", onClick: () => {
                        connectWallet(WALLETS.walletConnect.key).catch(() => { });
                    } }, "WalletConnect")),
                React.createElement(SFlexbox, { justifyContent: "center", pt: "10px", pb: "11px" },
                    React.createElement(SText, { fontSize: "12px", lineHeight: "1", mb: "10px" },
                        "By using ",
                        ENV.isOndefy() ? 'Ondefy' : 'Zyfi',
                        " you accept",
                        ' ',
                        React.createElement(OLink, { href: "https://www.ondefy.com/terms-and-conditions.pdf", variant: "secondary", isExternal: true }, "our general conditions")))))));
};
