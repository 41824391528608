// import { EEntityStatus } from '../../redux/redux-entity'
import { ReduxEntity } from '../../redux/redux-entity';
import { initialState } from './state';
// import { initialState } from './constants/initial-state'
export const setIsWeb3Busy = (state, { payload }) => {
    state.isWeb3Busy = payload;
};
export const setWalletType = (state, { payload }) => {
    state.walletType = payload;
};
export const setIsWalletConnected = (state, { payload }) => {
    state.isWalletConnected = payload;
};
export const setUserAddress = (state, { payload }) => {
    state.userAddress = payload;
};
export const setUserEmail = (state, { payload }) => {
    state.userEmail = payload;
};
export const resetUserWallet = (state) => {
    state.userAddress = initialState.userAddress;
    state.walletType = initialState.walletType;
    state.userEmail = initialState.userEmail;
    state.isWalletConnected = initialState.isWalletConnected;
};
export const reset = (state) => {
    return Object.assign(Object.assign({}, initialState), { 
        // keep only these fields
        // add these sideeffect to auth
        userAddress: state.userAddress, walletType: state.walletType, userEmail: state.userEmail, isWalletConnected: state.isWalletConnected, isWeb3Busy: state.isWeb3Busy });
};
export const flush = (state) => {
    return Object.assign({}, initialState);
};
/*
 * REDUCERS
 * */
export const getUserWallet = (state, action) => {
    ReduxEntity.loading(state.userWallet);
};
export const getUserWalletRejected = (state, action) => {
    ReduxEntity.error(state.userWallet, action);
};
export const getUserWalletFullfilled = (state, action) => {
    ReduxEntity.success(state.userWallet, action);
};
