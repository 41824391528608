var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { usePrevious } from 'react-use';
import { useInputNumberFormatted } from '../../../../../__common__/numbo/hooks/use-input-number-formatted';
import { Numbo } from '../../../../../__common__/numbo/transducers';
import { selectSourceTokenAmount, selectSourceTokenIdSwap, selectSourceTokenSwap } from '../../../../selectors';
import { setPercentage, setSourceTokenAmount } from '../../../../slice';
import { OInput } from '@ondefy/ondefy-ui';
import React, { useCallback, useEffect } from 'react';
// import { useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
export const SourceTokenAmountInput = () => {
    const dispatch = useAppDispatch();
    const value = useAppSelector(selectSourceTokenAmount);
    const sourceToken = useAppSelector(selectSourceTokenSwap);
    const sourceTokenId = useAppSelector(selectSourceTokenIdSwap);
    const _a = useInputNumberFormatted(value), { blockChanges } = _a, rest = __rest(_a, ["blockChanges"]);
    const setSourceAmount = useCallback((val) => {
        let sanitizedValue = Numbo.sanitizeFloatValue(val);
        if (sourceToken && sanitizedValue && !(sanitizedValue === null || sanitizedValue === void 0 ? void 0 : sanitizedValue.endsWith('.'))) {
            sanitizedValue = Numbo.floatToFloat(sanitizedValue, sourceToken.decimals);
        }
        dispatch(setSourceTokenAmount(sanitizedValue));
        dispatch(setPercentage(null));
    }, [sourceToken]);
    const prevSourceTokenId = usePrevious(sourceTokenId);
    useEffect(() => {
        if (value && prevSourceTokenId) {
            setSourceAmount(value);
        }
    }, [sourceTokenId]);
    return (React.createElement(OInput, Object.assign({ zIndex: "2", size: "lg", height: "40px !important", placeholder: "Enter amount", variant: "borderless" }, rest, { onChange: (val) => {
            if (blockChanges.current) {
                return;
            }
            let sanitizedValue = Numbo.sanitizeFloatValue(val);
            if (sourceToken && sanitizedValue && !sanitizedValue.endsWith('.')) {
                sanitizedValue = Numbo.toFixedBig(sanitizedValue, sourceToken.decimals);
            }
            // if (value !== sanitizedValue) {
            dispatch(setSourceTokenAmount(sanitizedValue));
            dispatch(setPercentage(null));
            // }
        } })));
};
