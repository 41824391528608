import { OIcon, OIconNet, OMedia, OTag, SBox, SText } from '@ondefy/ondefy-ui';
import React from 'react';
import { selectTokenContracts } from '../../../__common__/selectors';
import { OPaper } from '../../../__common__/components/o-paper';
import { NETWORKS_DATA } from '../../../__common__/constants/networks';
import { selectSourceAmount, selectFiatToTokenRate, selectRefuelAmount, selectSelectedFiatSymbol, selectIsTransakSupported, selectNetworkIdTargetFiat, selectTargetTokenFiat } from '../../selectors';
import { USDC_LOGO } from '../../constants';
import { useIframeUrlColors } from '../../../iframe/hooks/use-iframe-url-colors';
import { findTokenById } from '../../../__common__/transducers/find-token-by-id';
import { useAppSelector } from '../../../../redux/hooks';
import { usePaperTransactionDescription } from '../paper-transaction-description/__hooks__/use-paper-transaction-description';
import { usePaperTransactionDetails } from './__hooks__/use-paper-transaction-details';
// @ts-ignore
import SvgOndefyWhite from '@ondefy/ondefy-ui/dist/assets/svg/ondefy-white.svg';
// @ts-ignore
import SvgTransak from '@ondefy/ondefy-ui/dist/assets/svg/transak.svg';
import { SPaperTransactionDetails } from './__styled__/s-paper-transaction-details';
import _ from 'lodash/fp';
export const PaperTransactionDetails = () => {
    var _a, _b, _c, _d;
    const paper = usePaperTransactionDetails();
    const tokenContracts = useAppSelector(selectTokenContracts);
    const fiatAmount = useAppSelector(selectSourceAmount);
    const fiatSymbol = useAppSelector(selectSelectedFiatSymbol);
    const fiatToTokenRate = useAppSelector(selectFiatToTokenRate);
    const isTransakSupported = useAppSelector(selectIsTransakSupported);
    const refuelAmount = useAppSelector(selectRefuelAmount);
    const networkId = useAppSelector(selectNetworkIdTargetFiat);
    const network = NETWORKS_DATA[networkId];
    const selectedToken = useAppSelector(selectTargetTokenFiat);
    const paperTransactionDescription = usePaperTransactionDescription();
    const { colorPrimary } = useIframeUrlColors();
    return (React.createElement(OPaper, Object.assign({}, paper, { appearFrom: "bottom" }),
        React.createElement(SPaperTransactionDetails, { className: "dropdown__collapsable" },
            React.createElement(OMedia, { alignItems: "center", pt: "9px" },
                React.createElement(SText, { type: "small", fontWeight: "semibold", mr: "5px" }, "Preferred ramp solution for this transaction")),
            React.createElement(SBox, { pt: "5px" },
                React.createElement(OTag, { alignItems: "center", display: "flex", variant: "secondary", iconLeft: React.createElement(SBox, { mr: "-4px", mt: "4px" },
                        React.createElement(SvgTransak, { width: "20px" })), label: React.createElement(SText, { fontWeight: "semibold", mb: "0" }, "Transak") })),
            React.createElement(SBox, { mt: "20px", className: "transaction__route" },
                React.createElement(SBox, { mb: "5px" },
                    React.createElement(SText, { type: "small", fontWeight: "semibold", opacity: "0.8" }, "Transaction route")),
                React.createElement(SBox, { className: "transaction__steps" },
                    React.createElement(OMedia, { alignItems: "center" },
                        React.createElement(SBox, { className: "transaction__step-separator" },
                            React.createElement(OIcon, { name: "dots", 
                                // @ts-ignore
                                color: colorPrimary || 'accent' })),
                        React.createElement(SBox, { className: "transaction__icon" },
                            React.createElement(OIcon, { name: "money", size: 12, color: colorPrimary || 'accent' })),
                        React.createElement(SText, { type: "small", fontWeight: "semibold" },
                            fiatAmount,
                            " ",
                            fiatSymbol)),
                    React.createElement(OMedia, { alignItems: "center" },
                        React.createElement(SBox, { className: "transaction__step-separator" },
                            React.createElement(OIcon, { name: "dots", 
                                // @ts-ignore
                                color: colorPrimary || 'accent' })),
                        React.createElement(SBox, { className: "transaction__icon" },
                            React.createElement(SvgTransak, { width: "13px" })),
                        React.createElement(SText, { type: "small", fontWeight: "semibold" }, "Transak")),
                    React.createElement(OMedia, { alignItems: "center" },
                        React.createElement(SBox, { className: "transaction__step-separator" },
                            React.createElement(OIcon, { name: "dots", 
                                // @ts-ignore
                                color: colorPrimary || 'accent' })),
                        React.createElement(SBox, { className: "transaction__icon transaction__icon--scaled" },
                            React.createElement(OIconNet, { network: _.path('cgKey', selectedToken), coinImage: isTransakSupported ? selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.logoURI : USDC_LOGO, coinName: "ETH" })),
                        React.createElement(SText, { type: "small", fontWeight: "semibold" }, !isTransakSupported ? (React.createElement(React.Fragment, null, (_a = fiatToTokenRate === null || fiatToTokenRate === void 0 ? void 0 : fiatToTokenRate.value) === null || _a === void 0 ? void 0 :
                            _a.intermediateBuyAmount,
                            " ",
                            'USDC',
                            ' ',
                            React.createElement(SText, { opacity: "0.7", fontWeight: "normal", fontSize: "inherit" }, `on ${network === null || network === void 0 ? void 0 : network.name}`))) : (React.createElement(React.Fragment, null, (_b = fiatToTokenRate === null || fiatToTokenRate === void 0 ? void 0 : fiatToTokenRate.value) === null || _b === void 0 ? void 0 :
                            _b.buyAmount,
                            " ", selectedToken === null || selectedToken === void 0 ? void 0 :
                            selectedToken.symbol,
                            ' ',
                            React.createElement(SText, { opacity: "0.7", fontWeight: "normal", fontSize: "inherit" }, `on ${network === null || network === void 0 ? void 0 : network.name}`))))),
                    !isTransakSupported ? (React.createElement(React.Fragment, null,
                        React.createElement(OMedia, { alignItems: "center" },
                            React.createElement(SBox, { className: "transaction__step-separator" },
                                React.createElement(OIcon, { name: "dots", 
                                    // @ts-ignore
                                    color: colorPrimary || 'accent' })),
                            React.createElement(SBox, { className: "transaction__icon transaction__icon--ondefy" },
                                React.createElement(SBox, null,
                                    React.createElement(SvgOndefyWhite, null))),
                            React.createElement(OMedia, { alignText: "center", lineHeight: "1", cursor: "pointer", onClick: () => {
                                    paperTransactionDescription.setIsOpened(true);
                                } },
                                React.createElement(SText, { type: "small", fontWeight: "semibold", mr: "5px" },
                                    "Ondefy swap to ", selectedToken === null || selectedToken === void 0 ? void 0 :
                                    selectedToken.symbol,
                                    ' ',
                                    networkId === 1
                                        ? `(Swap gas cost ≈ ${_.path(['value', 'inputFee'], fiatToTokenRate) || 'N/A'} USDC)`
                                        : '(Swap gas cost + our fee = 1 USDC)'),
                                React.createElement(OIcon, { name: "issue" }))),
                        React.createElement(OMedia, { alignItems: "center" },
                            React.createElement(SBox, { className: "transaction__step-separator" },
                                React.createElement(OIcon, { name: "dots", 
                                    // @ts-ignore
                                    color: colorPrimary || 'accent' })),
                            React.createElement(SBox, { className: "transaction__icon transaction__icon--scaled" },
                                React.createElement(OIconNet, { network: _.path('cgKey', selectedToken), coinImage: selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.logoURI, coinName: selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.symbol })),
                            React.createElement(SText, { type: "small", fontWeight: "semibold" },
                                '≈ ' + ((_c = fiatToTokenRate === null || fiatToTokenRate === void 0 ? void 0 : fiatToTokenRate.value) === null || _c === void 0 ? void 0 : _c.buyAmount),
                                " ", selectedToken === null || selectedToken === void 0 ? void 0 :
                                selectedToken.symbol,
                                ' ',
                                React.createElement(SText, { opacity: "0.7", fontWeight: "normal", fontSize: "inherit" }, `on ${network === null || network === void 0 ? void 0 : network.name}`))),
                        !!refuelAmount && (React.createElement(OMedia, { alignItems: "center" },
                            React.createElement(SBox, { className: "transaction__step-separator" },
                                React.createElement(OIcon, { name: "plus", color: "accent" })),
                            React.createElement(SBox, { className: "transaction__icon transaction__icon--scaled" },
                                React.createElement(OIconNet, { network: network === null || network === void 0 ? void 0 : network.id, coinImage: (_d = findTokenById(network === null || network === void 0 ? void 0 : network.defaultCoinId, network === null || network === void 0 ? void 0 : network.networkId, tokenContracts)) === null || _d === void 0 ? void 0 : _d.logoURI, coinName: "ETH" })),
                            React.createElement(SText, { type: "small", fontWeight: "semibold" },
                                refuelAmount,
                                " ", network === null || network === void 0 ? void 0 :
                                network.ticker,
                                ' ',
                                React.createElement(SText, { opacity: "0.7", fontWeight: "normal", fontSize: "inherit" }, `on ${network === null || network === void 0 ? void 0 : network.name}`)))))) : (React.createElement(React.Fragment, null)))))));
};
