import { useLayoutMeasure } from '../../../../__common__/components/o-paper/__hooks__/use-layout-measure';
import { ORDER_RAMP_STATUS_FULFILLED } from '../../../constants/order-ramp-status';
import { ORDER_SWAP_STATUS_FULFILLED } from '../../../constants/order-swap-status';
import { useEffect, useState } from 'react';
import { selectIsTransakSupported, selectTransactionStatus } from '../../../selectors';
import { useAppSelector } from '../../../../../redux/hooks';
import { EEntityStatus } from '../../../../../redux/redux-entity';
export const useConfetti = () => {
    const [confetti, setConfetti] = useState(false);
    const { width, height } = useLayoutMeasure();
    const isTransakSupported = useAppSelector(selectIsTransakSupported);
    const transactionStatus = useAppSelector(selectTransactionStatus);
    useEffect(() => {
        if (
        // case 1
        (isTransakSupported &&
            transactionStatus.status === EEntityStatus.success &&
            transactionStatus.value.rampStatus === ORDER_RAMP_STATUS_FULFILLED) ||
            // case 2
            (transactionStatus.status === EEntityStatus.success &&
                transactionStatus.value.rampStatus === ORDER_RAMP_STATUS_FULFILLED &&
                transactionStatus.value.swapStatus === ORDER_SWAP_STATUS_FULFILLED)) {
            setConfetti(true);
            const timer = setTimeout(() => {
                setConfetti(false);
            }, 6000);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [transactionStatus.status]);
    return {
        confetti,
        width,
        height
    };
};
