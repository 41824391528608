import { OButton, OIcon, OInput, OLink, OMedia, OSkeleton, OSvgIcon, SBox, SText } from '@ondefy/ondefy-ui';
import _ from 'lodash/fp';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import { ENV } from '../../../../env';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { selectNetworkIdTargetFiat, selectTargetTokenIdFiat, selectTransakCryptoCurrencies } from '../../../1 - buy/selectors';
import { setNetworkIdTargetFiat, setTargetTokenIdFiat } from '../../../1 - buy/slice';
import { checkIfNetworkIsValid } from '../../../1 - buy/transducers/check-iIf-network-is-valid';
import { findSupportedToken } from '../../../1 - buy/transducers/find-supported-token';
import { selectNetworkIdSourceSwap, selectNetworkIdTargetSwap, selectNetworkIdFeeSwap, selectSourceTokenIdSwap, selectTargetTokenIdSwap } from '../../../2 - swap/selectors';
import { setNetworkIdSourceSwap, setNetworkIdTargetSwap, setNetworkIdFeeSwap, setSourceTokenIdSwap, setTargetTokenIdSwap, setFeeTokenIdSwap, swapTokens } from '../../../2 - swap/slice';
import { selectNetworkIdTargetCustom, selectTargetTokenIdCustom } from '../../../3 - custom/selectors';
import { setNetworkIdTargetCustom, setTargetTokenIdCustom } from '../../../3 - custom/slice';
import { selectNetworkIdFeeSend, selectFeeTokenIdSend } from '../../../4 - send/selectors';
import { setNetworkIdSourceSend, setNetworkIdFeeSend, setSourceTokenIdSend, setFeeTokenIdSend } from '../../../4 - send/slice';
import { Analytics } from '../../../analytics';
import { useIframeUrlColors } from '../../../iframe/hooks/use-iframe-url-colors';
import { useUserWallet } from '../../../wallet/hooks/use-user-wallet';
import { selectIsWalletConnected } from '../../../wallet/selectors';
import { OPaper } from '../../components/o-paper';
import { useLayoutMeasure } from '../../components/o-paper/__hooks__/use-layout-measure';
import { APP_MODE } from '../../constants';
import { NATIVE_ADDRESS } from '../../constants/contracts';
import { ENetworkIdByName, NETWORKS_DATA } from '../../constants/networks';
import { useGetTokenContracts } from '../../hooks/use-get-token-contracts';
import { selectAppMode, selectSearchTokenValue, selectTemporaryNetworkId } from '../../selectors';
import { selectFilteredTokenList, selectFilteredUserTokens } from '../../selectors-shared';
import { setSearchTokenValue, setTemporaryNetworkId } from '../../slice';
import { usePaperConnection } from '../paper-connection/__hooks__/use-paper-connection';
import { usePaperNetworkExtended } from '../paper-network-extended/__hooks__/use-paper-network-extended';
// https://drive.google.com/drive/folders/1eyynWSiOcRaYt0jfvBEMEXZ80SKAWTX_
// @ts-ignore
import CoinGeckoLogo from './__assets__/coin-gecko-logo.svg';
import { TokenLine } from './__components__/token-line';
import { usePaperTokenSelect } from './__hooks__/use-paper-token-select';
import { STokenListTitle } from './__styled__/s-token-list-title';
import { ETokenPopupVariant } from './constants';
const PaperTokenSelectComponent = ({ variant }) => {
    const dispatch = useAppDispatch();
    const paperTokenSelect = usePaperTokenSelect(variant);
    const paperNetworkExtended = usePaperNetworkExtended();
    const appMode = useAppSelector(selectAppMode);
    const isSwapMode = appMode === APP_MODE.swap;
    const isBuyMode = appMode === APP_MODE.buy;
    const isCustomMode = appMode === APP_MODE.custom;
    const isSendMode = appMode === APP_MODE.send;
    const isTargetVariant = variant === ETokenPopupVariant.target;
    const isFeeVariant = variant === ETokenPopupVariant.fee;
    const isSourceVariant = variant === ETokenPopupVariant.source;
    const isWalletConnected = useAppSelector(selectIsWalletConnected);
    const paperConnection = usePaperConnection();
    if (!isBuyMode && !isCustomMode && !isSendMode && !isSwapMode) {
        return null;
    }
    let selectNetworkId;
    let selectTokenId;
    if (isBuyMode) {
        selectNetworkId = selectNetworkIdTargetFiat;
        selectTokenId = selectTargetTokenIdFiat;
    }
    else if (isSwapMode) {
        switch (variant) {
            case ETokenPopupVariant.target: {
                selectNetworkId = selectNetworkIdTargetSwap;
                selectTokenId = selectTargetTokenIdSwap;
                break;
            }
            case ETokenPopupVariant.source: {
                selectNetworkId = selectNetworkIdSourceSwap;
                selectTokenId = selectSourceTokenIdSwap;
                break;
            }
            case ETokenPopupVariant.fee: {
                selectNetworkId = selectNetworkIdFeeSwap;
                selectTokenId = selectSourceTokenIdSwap;
                break;
            }
            default: {
                selectNetworkId = selectNetworkIdSourceSwap;
                selectTokenId = selectSourceTokenIdSwap;
            }
        }
        // selectNetworkId = isTargetVariant ? selectNetworkIdTargetSwap : selectNetworkIdSourceSwap
        // selectTokenId = isTargetVariant ? selectTargetTokenIdSwap : selectSourceTokenIdSwap
    }
    else if (isCustomMode) {
        selectNetworkId = selectNetworkIdTargetCustom;
        selectTokenId = selectTargetTokenIdCustom;
    }
    else if (isSendMode) {
        selectNetworkId = selectNetworkIdFeeSend;
        selectTokenId = selectFeeTokenIdSend;
    }
    /* eslint-disable react-hooks/rules-of-hooks */
    // @ts-ignore
    const selectedTokenId = useAppSelector(selectTokenId);
    const transakCryptoCurrencies = useAppSelector(selectTransakCryptoCurrencies);
    const sourceTokenIdSwap = useAppSelector(selectSourceTokenIdSwap);
    const selectedOppositeTokenIdSwap = useAppSelector(isTargetVariant ? selectSourceTokenIdSwap : selectTargetTokenIdSwap);
    const [urlSearchParams] = useSearchParams();
    const { colorPrimary } = useIframeUrlColors();
    const tokenContracts = useGetTokenContracts();
    const userWallet = useUserWallet({ auto: false });
    // @ts-ignore
    const networkId = useAppSelector(selectNetworkId);
    const temporaryNetworkId = useAppSelector(selectTemporaryNetworkId);
    useEffect(() => {
        if (networkId !== temporaryNetworkId && paperTokenSelect.isOpened) {
            dispatch(setTemporaryNetworkId(networkId));
        }
        // eslint-disable-next-line
    }, [networkId, paperTokenSelect.isOpened, appMode]);
    const searchTokenValue = useAppSelector(selectSearchTokenValue);
    const tokensFiltered = useAppSelector(selectFilteredTokenList);
    const selectedNetwork = _.path(temporaryNetworkId, NETWORKS_DATA);
    useEffect(() => {
        if (paperTokenSelect.isOpened) {
            // fetch a user wallet only if this popup appears
            if (isWalletConnected) {
                userWallet.query();
            }
            // TODO: add some caching and prevent second swap call on refresh
            tokenContracts.query();
        }
        // eslint-disable-next-line
    }, [paperTokenSelect.isOpened, isWalletConnected]);
    const onSelect = useCallback((networkId, tokenId) => {
        if (isSwapMode) {
            if (isTargetVariant) {
                dispatch(setTargetTokenIdSwap(tokenId));
                dispatch(setNetworkIdTargetSwap(networkId));
            }
            else if (isSourceVariant) {
                dispatch(setSourceTokenIdSwap(tokenId));
                dispatch(setNetworkIdSourceSwap(networkId));
            }
            else if (isFeeVariant) {
                dispatch(setFeeTokenIdSwap(tokenId));
                dispatch(setNetworkIdFeeSwap(networkId));
            }
        }
        else if (isBuyMode) {
            dispatch(setTargetTokenIdFiat(tokenId));
            dispatch(setNetworkIdTargetFiat(networkId));
        }
        else if (isCustomMode) {
            dispatch(setTargetTokenIdCustom(tokenId));
            dispatch(setNetworkIdTargetCustom(networkId));
        }
        else if (isSendMode) {
            if (isFeeVariant) {
                dispatch(setFeeTokenIdSend(tokenId));
                dispatch(setNetworkIdFeeSend(networkId));
            }
            else if (isSourceVariant) {
                dispatch(setSourceTokenIdSend(tokenId));
                dispatch(setNetworkIdSourceSend(networkId));
            }
        }
        if (isTargetVariant) {
            Analytics.tokenToSelected();
        }
        else if (isFeeVariant) {
            Analytics.tokenFeeSelected();
        }
        else {
            Analytics.tokenFromSelected();
        }
        paperTokenSelect.setIsOpened(false);
        // Add 300 ms timer to prevent instant switch of network dropdown visible selected option
        setTimeout(() => {
            dispatch(setTemporaryNetworkId(undefined));
            dispatch(setSearchTokenValue(''));
        }, 300);
    }, 
    // eslint-disable-next-line
    [appMode, variant]);
    const Row = useMemo(() => 
    // eslint-disable-next-line react/display-name
    ({ index, style }) => {
        const token = tokensFiltered[index] || {};
        const tokenAddress = token.isNative ? NATIVE_ADDRESS : token.address;
        const isTransakSupported = appMode === APP_MODE.buy &&
            !!findSupportedToken(transakCryptoCurrencies === null || transakCryptoCurrencies === void 0 ? void 0 : transakCryptoCurrencies.value, temporaryNetworkId, tokenAddress);
        const networkId = ENetworkIdByName[_.path(['cgKey'], token)];
        return (React.createElement("div", { style: style },
            React.createElement(TokenLine, { id: token.id, disabled: isSwapMode && token.id === selectedOppositeTokenIdSwap, onDisabledClick: tokenId => {
                    dispatch(swapTokens());
                    onSelect(networkId, tokenId);
                }, selectedId: selectedTokenId, isTransakSupported: appMode === APP_MODE.buy && isTransakSupported, networkId: networkId, name: token.name, symbol: token.symbol, price: token.price, img: token.logoURI, prct: _.path('prct.day', token), onSelect: tokenId => {
                    onSelect(networkId, tokenId);
                } })));
    }, [
        transakCryptoCurrencies === null || transakCryptoCurrencies === void 0 ? void 0 : transakCryptoCurrencies.value,
        tokensFiltered,
        selectedTokenId,
        selectedOppositeTokenIdSwap,
        temporaryNetworkId,
        appMode,
        isSwapMode,
        onSelect,
        dispatch
    ]);
    const layoutMeasure = useLayoutMeasure();
    const userAssets = useAppSelector(selectFilteredUserTokens);
    return (React.createElement(OPaper, Object.assign({}, paperTokenSelect, { appearFrom: "bottom", title: "Choose a token" }),
        React.createElement(SBox, { px: "8px" },
            React.createElement(OInput, { mt: "16px", mb: "12px", width: "100%", value: searchTokenValue, onChange: (val) => dispatch(setSearchTokenValue(val)), placeholder: "Search by name or symbol", iconRight: React.createElement(SBox, { mt: "-3px" },
                    React.createElement(OIcon, { name: "search" })) }),
            ENV.isOndefy() && (React.createElement(OButton, { width: "100%", id: paperTokenSelect.id, justifyContent: "space-between", iconRight: checkIfNetworkIsValid(parseInt(urlSearchParams.get('networkId'))) ? null : (React.createElement(OIcon, { name: "down", 
                    // @ts-ignore
                    color: colorPrimary || 'accent' })), variant: "secondary", onClick: () => {
                    paperNetworkExtended.setIsOpened(!paperNetworkExtended.isOpened);
                }, disabled: checkIfNetworkIsValid(parseInt(urlSearchParams.get('networkId'))) ||
                    Boolean(isSwapMode && sourceTokenIdSwap && isTargetVariant) },
                React.createElement(OMedia, { alignItems: "center" },
                    React.createElement(OSvgIcon, { name: _.pathOr('ondefy', 'id', selectedNetwork), color: !selectedNetwork ? 'accent' : undefined, mr: "7px" }),
                    React.createElement(SBox, null,
                        React.createElement(SText, null, _.pathOr('All networks', 'name', selectedNetwork))))))),
        React.createElement(SBox, null,
            React.createElement(React.Fragment, null,
                (((isCustomMode || isSwapMode || isSendMode) && isWalletConnected) ||
                    ((isCustomMode || isSendMode) && !isWalletConnected)) && (React.createElement(React.Fragment, null,
                    React.createElement(STokenListTitle, null,
                        React.createElement(SText, { opacity: "0.5", pr: "6px" }, "Tokens from your wallet")),
                    isWalletConnected && userWallet.isLoading && (!userWallet.isLoaded || userWallet.error) && (React.createElement(SBox, { p: "8px" },
                        React.createElement(OSkeleton, { height: 48 }))),
                    userWallet.isError && !userWallet.isLoading && (React.createElement(OMedia, { alignItems: "center", height: "48px", px: "16px" },
                        React.createElement(OIcon, { name: "warning", color: "warning" }),
                        React.createElement(SText, { px: "8px", color: "warning" }, "Failed to fetch your tokens"),
                        React.createElement(OButton, { onClick: () => {
                                userWallet.query();
                            }, size: "sm", variant: "secondary" }, "Retry"))),
                    (!userAssets || (userAssets === null || userAssets === void 0 ? void 0 : userAssets.length) === 0) && userWallet.isLoaded && !userWallet.isError && (React.createElement(OMedia, { px: "16px", height: "67px", alignItems: "center" },
                        React.createElement(OIcon, { name: "warning", color: "warning", mr: "4px" }),
                        React.createElement(SText, { color: "warning" }, "You have no tokens in the selected network"))),
                    (isCustomMode || isSendMode) && !isWalletConnected && (React.createElement(OMedia, { px: "16px", height: "67px", alignItems: "center" },
                        React.createElement(OIcon, { name: "warning", color: "warning", mr: "4px" }),
                        React.createElement(SText, { color: "warning" }, "Connect to see your tokens"),
                        React.createElement(OButton, { ml: "8px", onClick: () => {
                                paperConnection.setIsOpened(true).catch(() => { });
                            }, size: "sm", variant: "secondary" }, "Connect"))),
                    (userAssets === null || userAssets === void 0 ? void 0 : userAssets.length) > 0 &&
                        userWallet.isLoaded &&
                        !userWallet.isError &&
                        userAssets.map((asset) => {
                            const networkId = ENetworkIdByName[_.path(['cgKey'], asset)];
                            const isEthereum = _.path(['id'], asset) === 'ethereum';
                            const isZkloto = _.path(['id'], asset) === 'zklotto';
                            if ((isSendMode && isEthereum) || isZkloto) {
                                return null;
                            }
                            return (React.createElement(SBox, { height: "67px", key: _.path(['id'], asset) + _.path(['cgKey'], asset) },
                                React.createElement(TokenLine, { id: _.path(['id'], asset), selectedId: selectedTokenId, disabled: isSwapMode && !isFeeVariant && _.path(['id'], asset) === selectedOppositeTokenIdSwap, onDisabledClick: tokenId => {
                                        dispatch(swapTokens());
                                        onSelect(networkId, tokenId);
                                    }, isTransakSupported: false, networkId: networkId, isWalletAsset: true, name: _.path(['name'], asset), symbol: _.path(['symbol'], asset), price: _.path(['price'], asset), amount: _.path(['amount'], asset), decimals: _.path(['decimals'], asset), img: _.path(['logoURI'], asset), prct: _.path(['pcrt', 'day'], asset), onSelect: tokenId => {
                                        onSelect(networkId, _.path(['id'], asset));
                                    } })));
                        }))),
                tokensFiltered.length > 0 && !isCustomMode && !isFeeVariant && !isSendMode && (React.createElement(React.Fragment, null,
                    React.createElement(STokenListTitle, null,
                        React.createElement(SText, { opacity: "0.5", pr: "6px" }, "Token list from"),
                        React.createElement(OLink, { href: "https://www.coingecko.com/", isExternal: true, noIcon: true, borderless: true },
                            React.createElement(CoinGeckoLogo, { width: "18px", height: "18px" }))),
                    React.createElement(SBox, null,
                        React.createElement(List, { height: layoutMeasure.height, itemCount: tokensFiltered.length, itemSize: 67, width: "100%" }, Row))))))));
};
export const PaperTokenSelect = memo(PaperTokenSelectComponent);
